/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import '../../Components/NavBottom/NavBottom.css';

function FeedNavigation(props) {
  const [section, setSection] = useState(props.isProfilePage ? '1' : '0');
  const changeSection = (sectionNum) => {
    setSection(sectionNum);
  };
  const { user } = props;
  return (
    <div className="NavBottom flex-100 layout-row layout-wrap layout-align-start-start shadowBox">
      <RoutedTabs
        startPathWith="/feed"
        className={`flex-100 layout-row layout-align-start-start whiteBackground positionRelative tabs-container slide_${section}`}>
        <div id="slider" />
        <NavTab
          to="/store"
          className="flex layout-column layout-align-center-center padd10px primary"
          onClick={() => changeSection('0')}
        >
          <i className="las la-home font30 lineHeight1" />
          {/* <Trans i18nKey="store" /> */}
        </NavTab>
        <NavTab
          to={`/${user && user.public_id}`}
          className="flex layout-column layout-align-center-center padd10px primary"
          onClick={() => changeSection('1')}
        >
          <i className="las la-user-alt font30 lineHeight1" />
          {/* <Trans i18nKey="myProfile" /> */}
        </NavTab>
      </RoutedTabs>
    </div>
  );
}

export default FeedNavigation;
