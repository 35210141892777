/* eslint-disable no-trailing-spaces */

export const actionTypes = {
  CLEAR_CHOSEN_PUBLIC_ID: 'CLEAR_CHOSEN_PUBLIC_ID',
  CHANGE_FORM_STATE: 'CHANGE_FORM_STATE',
  CLEAR_FORM: 'CLEAR_FORM',
  GO_TO_PREV_STEP: 'GO_TO_PREV_STEP',
  GO_TO_NEXT_STEP: 'GO_TO_NEXT_STEP',
  SAVE_USER_INFO: 'SAVE_USER_INFO',
  HANDLE_ERROR_RESPONSE: 'HANDLE_ERROR_RESPONSE',
  HANDLE_SUCCESS_RESPONSE: 'HANDLE_SUCCESS_RESPONSE',
  SAVE_USER_CREDENTIALS: 'SAVE_USER_CREDENTIALS',
  SAVE_USER_PROFILE_INFO: 'SAVE_USER_PROFILE_INFO',
  SAVE_USER_CALENDAR_SETTINGS: 'SAVE_USER_CALENDAR_SETTINGS',
  SAVE_MY_CALENDAR_SETTINGS: 'SAVE_MY_CALENDAR_SETTINGS',
  SET_USER_SUBSCRIPTION_DETAILS: 'SET_USER_SUBSCRIPTION_DETAILS',
  SAVE_ASPIRE_MASSAGES: 'SAVE_ASPIRE_MASSAGES',
  CLEAR_ERROR: 'CLEAR_ERROR',
  HIDE_ALERT: 'HIDE_ALERT',
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT2: 'HIDE_ALERT2',
  SHOW_ALERT2: 'SHOW_ALERT2',
  HIDE_ALERT3: 'HIDE_ALERT3',
  SHOW_ALERT3: 'SHOW_ALERT3',
  CLEAR_ALERT: 'CLEAR_ALERT',
  SET_CHOSEN_CLIENT: 'SET_CHOSEN_CLIENT',
  SET_CHOSEN_CLIENT_BY_ID: 'SET_CHOSEN_CLIENT_BY_ID',
  CLEAR_CHOSEN_CLIENT: 'CLEAR_CHOSEN_CLIENT',
  SET_DEFAULT_CHOSEN_USER: 'SET_DEFAULT_CHOSEN_USER',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SAVE_PAGE_HEADERS: 'SAVE_PAGE_HEADERS',
  DISABLE_TOP_NAV_PLX: 'DISABLE_TOP_NAV_PLX',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  CLEAR_VALIDATION_ERROR: 'CLEAR_VALIDATION_ERROR',
  UPDATE_CLIENT_LIST: 'UPDATE_CLIENT_LIST',
  SET_ASPIRE_SERVICES: 'SET_ASPIRE_SERVICES',
  // app Actions
  SET_APP_LANG: 'SET_APP_LANG',
  REFRESH_PAGE_HEADER: 'REFRESH_PAGE_HEADER',
  REFRESH_PAGE_HEADER_INFO: 'REFRESH_PAGE_HEADER_INFO',
  REFRESH_BOTTOM_NAV: 'REFRESH_BOTTOM_NAV',
  BOTTOM_NAV_CLICKED: 'BOTTOM_NAV_CLICKED',
  HIDE_BOTTOM_NAV: 'HIDE_BOTTOM_NAV',
  SHOW_BOTTOM_NAV: 'SHOW_BOTTOM_NAV',
  LOAD_ERROR_ALERT: 'LOAD_ERROR_ALERT',
  SET_DYNAMIC_SEARCH_RESULTS: 'SET_DYNAMIC_SEARCH_RESULTS',
  SET_ALL_CATEGORIES: 'SET_ALL_CATEGORIES',
  SET_CATEGORY_DETAILS: 'SET_CATEGORY_DETAILS',
  DISABLE_BACK_BTN: 'DISABLE_BACK_BTN',
  ENABLE_BACK_BTN: 'ENABLE_BACK_BTN',
  MINIMIZE_UPLOAD: 'MINIMIZE_UPLOAD',
  EXPAND_UPLOAD: 'EXPAND_UPLOAD',
  ACTIVATE_TUTORIAL_MODE: 'ACTIVATE_TUTORIAL_MODE',
  DISABLE_TUTORIAL_MODE: 'DISABLE_TUTORIAL_MODE',
  ACTIVATE_ALICE_TUTORIAL_MODE: 'ACTIVATE_ALICE_TUTORIAL_MODE',
  DISABLE_ALICE_TUTORIAL_MODE: 'DISABLE_ALICE_TUTORIAL_MODE',
  SAVE_SEARCH_QUERY: 'SAVE_SEARCH_QUERY',
  CLEAR_SEARCH_QUERY: 'CLEAR_SEARCH_QUERY',
  // exercises Actions
  SET_POPULAR_EXERCISES: 'SET_POPULAR_EXERCISES',
  UPDATE_EXERCISE_LIST: 'UPDATE_EXERCISE_LIST',
  SET_EXERCISE_FILTERS: 'SET_EXERCISE_FILTERS',
  SET_EXERCISE_TO_TOP: 'SET_EXERCISE_TO_TOP',
  CLEAR_EXERCISE_FILTERS: 'CLEAR_EXERCISE_FILTERS',
  SET_MY_EXERCISES: 'SET_MY_EXERCISES',
  SET_EXERCISES_EQUIPMENTS: 'SET_EXERCISES_EQUIPMENTS',
  SET_MY_EXERCISES_FILTER: 'SET_MY_EXERCISES_FILTER',
  CLEAR_EXERCISE_DETAILS: 'CLEAR_EXERCISE_DETAILS',
  SET_EXERCISE_DETAILS: 'SET_EXERCISE_DETAILS',
  SET_MY_EXERCISE_PUBLIC_ID: 'SET_MY_EXERCISE_PUBLIC_ID',
  // workout Actions
  SET_WORKOUT_PROGRAMS: 'SET_WORKOUT_PROGRAMS',
  SAVE_CURRENT_WORKOUTLOGS: 'SAVE_CURRENT_WORKOUTLOGS',
  SET_WORKOUT_PROGRAM_DATA: 'SET_WORKOUT_PROGRAM_DATA',
  SET_WORKOUT_PROGRAM_DETAILS: 'SET_WORKOUT_PROGRAM_DETAILS',
  SET_USER_LATEST_WORKOUTLOGS_SESSION: 'SET_USER_LATEST_WORKOUTLOGS_SESSION',
  SET_ORIGINAL_WORKOUTLOGS_PROGRAM: 'SET_ORIGINAL_WORKOUTLOGS_PROGRAM',
  SET_CREATED_WORKOUT_ID: 'SET_CREATED_WORKOUT_ID',
  SET_CREATED_WORKOUT_PLAN_ID: 'SET_CREATED_WORKOUT_PLAN_ID',
  CLEAR_WORKOUT_PROGRAM_DETAILS: 'CLEAR_WORKOUT_PROGRAM_DETAILS',
  CLEAR_SESSION_DETAILS: 'CLEAR_SESSION_DETAILS',
  REMOVE_WORKOUT_PROGRAM: 'REMOVE_WORKOUT_PROGRAM',
  UPDATE_PROGRAM_LIST: 'UPDATE_PROGRAM_LIST',
  CLEAR_PROGRAM: 'CLEAR_PROGRAM',
  SET_NEW_WORKOUT_PROGRAM: 'SET_NEW_WORKOUT_PROGRAM',
  WORKOUT_HAS_CHANGED: 'WORKOUT_HAS_CHANGED',
  SET_WORKOUT_CREATION_PAGE: 'SET_WORKOUT_CREATION_PAGE',
  WORKOUT_PLAN_HAS_CHANGED: 'WORKOUT_PLAN_HAS_CHANGED',
  SAVE_COMPLETED_GOALS: 'SAVE_COMPLETED_GOALS',
  CLEAR_COMPLETED_GOALS: 'CLEAR_COMPLETED_GOALS',
  SET_WORKOUT_PROGRESS_DATA: 'SET_WORKOUT_PROGRESS_DATA',

  // workout programs
  SET_WORKOUT_PLANS: 'SET_WORKOUT_PLANS',
  SET_WORKOUT_PLAN_DATA: 'SET_WORKOUT_PLAN_DATA',
  SET_WORKOUT_PLAN_PAGE_DATA: 'SET_WORKOUT_PLAN_PAGE_DATA',
  SET_WORKOUT_PLAN: 'SET_WORKOUT_PLAN',
  SET_NEW_WORKOUT_PLAN: 'SET_NEW_WORKOUT_PLAN',
  REMOVE_WORKOUT_PLAN: 'REMOVE_WORKOUT_PLAN',
  SET_WORKOUT_PLAN_PROGRAMS: 'SET_WORKOUT_PLAN_PROGRAMS',
  SET_SELECTED_PROGRAM_TO_EDIT: 'SET_SELECTED_PROGRAM_TO_EDIT',
  SET_WORKOUT_PLAN_LIST: 'SET_WORKOUT_PLAN_LIST',
  CLEAR_PLAN: 'CLEAR_PLAN',
  GO_TO_PAGE: 'GO_TO_PAGE',
  REMOVE: 'REMOVE',
  DATE_INPUT_CHANGE: 'DATE_INPUT_CHANGE',
  // workout session
  SET_WORKOUT_SESSIONS: 'SET_WORKOUT_SESSIONS',
  SET_PROGRAM_SESSION_DETAILS: 'SET_PROGRAM_SESSION_DETAILS',
  SET_SESSIONS_DATA: 'SET_SESSIONS_DATA',
  SET_SESSION_DETAILS: 'SET_SESSION_DETAILS',
  SET_UNFINISHED_SESSION_DETAILS: 'SET_UNFINISHED_SESSION_DETAILS',
  UPDATE_SESSION_LIST: 'UPDATE_SESSION_LIST',
  SET_SESSION_CHOSEN_USER: 'SET_SESSION_CHOSEN_USER',
  REMOVE_SESSION_WORKOUTLOGS: 'REMOVE_SESSION_WORKOUTLOGS',
  REMOVE_SESSION: 'REMOVE_SESSION',
  CLEAR_SESSION: 'CLEAR_SESSION',
  CLEAR_ALL_WORKOUTS_PAGE_DATA: 'CLEAR_ALL_WORKOUTS_PAGE_DATA',
  SET_SESSION_WORKOUTLOGS: 'SET_SESSION_WORKOUTLOGS',
  CLEAR_PLAYED_PROP: 'CLEAR_PLAYED_PROP',
  ACTIVATE_PLAYED_PROP: 'ACTIVATE_PLAYED_PROP',
  ACTIVATE_NOTIFICATION_MODE: 'ACTIVATE_NOTIFICATION_MODE',
  UPDATE_NOTIFICATION_WORKOUT_LOG: 'UPDATE_NOTIFICATION_WORKOUT_LOG',
  UPDATE_CAROUSEL_POSITION: 'UPDATE_CAROUSEL_POSITION',
  RECORD_TIME_DONE: 'RECORD_TIME_DONE',
  RESET_TIMER_PROPS: 'RESET_TIMER_PROPS',
  SET_DURATION: 'SET_DURATION',
  MODIFY_SESSION_WORKOUT_LOGS: 'MODIFY_SESSION_WORKOUT_LOGS',
  UPDATE_EQUIPMENT_IN_SESSION: 'UPDATE_EQUIPMENT_IN_SESSION',
  // Stats
  SET_BODY_STATS: 'SET_BODY_STATS',
  CLEAR_BODY_STATS: 'CLEAR_BODY_STATS',
  SET_PERFORMANCE_STATS: 'SET_PERFORMANCE_STATS',
  REMOVE_BODY_STATS: 'REMOVE_BODY_STATS',
  CHANGE_EQUIPMENT_SELECT: 'CHANGE_EQUIPMENT_SELECT',
  SET_STATS_TYPE_HISTORY: 'SET_STATS_TYPE_HISTORY',
  SET_STATS_DATA: 'SET_STATS_DATA',
  SET_STATISTICS: 'SET_STATISTICS',
  CLEAR_STATISTICS: 'CLEAR_STATISTICS',
  SET_STATISTICS_HISTORY: 'SET_STATISTICS_HISTORY',
  CLEAR_STATISTICS_HISTORY: 'CLEAR_STATISTICS_HISTORY',
  SET_EXTRA_TITLE: 'SET_EXTRA_TITLE',
  SET_OVERALL_STATISTICS: 'SET_OVERALL_STATISTICS',
  SET_CUBE_STATISTICS: 'SET_CUBE_STATISTICS',
  SET_PROGRAM_STATISTICS: 'SET_PROGRAM_STATISTICS',
  SET_EXERCISE_STATISTICS: 'SET_EXERCISE_STATISTICS',
  SET_STATISTICS_HISTORY_PAGE: 'SET_STATISTICS_HISTORY_PAGE',

  // achievements
  SET_ACHIEVEMENTS: 'SET_ACHIEVEMENTS',
  SET_ACTIVE_ACHIEVEMENTS: 'SET_ACTIVE_ACHIEVEMENTS',
  SET_COMPLETED_ACHIEVEMENTS: 'SET_COMPLETED_ACHIEVEMENTS',
  CLEAR_ACHIEVEMENT_DATA: 'CLEAR_ACHIEVEMENT_DATA',
  SET_ACHIEVEMENT_DATA: 'SET_ACHIEVEMENT_DATA',
  SET_ACHIEVEMENT_PROGRESS: 'SET_ACHIEVEMENT_PROGRESS',
  REMOVE_ACHIEVEMENT: 'REMOVE_ACHIEVEMENT',
  SET_NEW_ACHIEVEMENT: 'SET_NEW_ACHIEVEMENT',
  // activities
  SET_UNREAD_ACTIVITY_LIST: 'SET_UNREAD_ACTIVITY_LIST',
  SET_ACTIVITY_LIST: 'SET_ACTIVITY_LIST',

  // Media
  SET_MEDIA_GALLERY: 'SET_MEDIA_GALLERY',
  SET_USER_MEDIA_GALLERY: 'SET_USER_MEDIA_GALLERY',
  SET_MEDIA_DATA: 'SET_MEDIA_DATA',
  REMOVE_MEDIA: 'REMOVE_MEDIA',
  CLEAR_MEDIA_DATA: 'CLEAR_MEDIA_DATA',
  CLEAR_MEDIA_GALLERY: 'CLEAR_MEDIA_GALLERY',

  // info
  SET_INFOS_LIST: 'SET_INFOS_LIST',
  UPDATE_INFO_LIST: 'UPDATE_INFO_LIST',
  SET_INFO_FILTERS: 'SET_INFO_FILTERS',
  SET_MY_INFOS: 'SET_MY_INFOS',
  SET_INFO_DETAILS: 'SET_INFO_DETAILS',
  SET_MY_INFO_PUBLIC_ID: 'SET_MY_INFO_PUBLIC_ID',
  CLEAR_INFO_DETAILS: 'CLEAR_INFO_DETAILS',
  CLEAR_INFO_FILTERS: 'CLEAR_INFO_FILTERS',
  SET_INFO_CATEGORIES: 'SET_INFO_CATEGORIES',
  SET_SOLUTION_DETAILS: 'SET_SOLUTION_DETAILS',

  // app
  CLEAR_UNREVIEWED_DOCUMENT: 'CLEAR_UNREVIEWED_DOCUMENT',
  CLEAR_UNREVIEWED_SURVEY: 'CLEAR_UNREVIEWED_SURVEY',

  SET_CALENDAR_SETTINGS: 'SET_CALENDAR_SETTINGS',
  SET_EVENTS_LIST: 'SET_EVENTS_LIST'
};

export const storeTypes = {
  SET_STORE_CATEGORIES: 'SET_STORE_CATEGORIES',
  SET_STORE_MAIN_PAGE_PROGRAMS: 'SET_STORE_MAIN_PAGE_PROGRAMS',
  SET_STORE_MAIN_PAGE_ARTICLES: 'SET_STORE_MAIN_PAGE_ARTICLES',
  SET_ARTICLES_CATEGORIES: 'SET_ARTICLES_CATEGORIES',
  SET_STORE_ARCHIVE: 'SET_STORE_ARCHIVE',
  CLEAR_STORE_CATEGORIES: 'CLEAR_STORE_CATEGORIES',
  CLEAR_STORE_MAIN_PAGE: 'CLEAR_STORE_MAIN_PAGE',
  CLEAR_STORE_ARCHIVE: 'CLEAR_STORE_ARCHIVE'
}

export const paperworkTypes = {
  SET_NEW_DOCUMENT: 'SET_NEW_DOCUMENT',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  SET_DOCUMENT_DATA: 'SET_DOCUMENT_DATA',
  CLEAR_DOCUMENT_DATA: 'CLEAR_DOCUMENT_DATA',
  CLEAR_DOCUMENTS_LIST: 'CLEAR_DOCUMENTS_LIST',
  REMOVE_DOCUMENT: 'REMOVE_DOCUMENT',
  SET_NEW_SURVEY: 'SET_NEW_SURVEY',
  SET_SURVEYS: 'SET_SURVEYS',
  SET_SURVEY_DATA: 'SET_SURVEY_DATA',
  SET_SURVEY_FILL_DATA: 'SET_SURVEY_FILL_DATA',
  CLEAR_SURVEY_DATA: 'CLEAR_SURVEY_DATA',
  REMOVE_SURVEY: 'REMOVE_SURVEY',
  CLEAR_SURVEY_LIST: 'CLEAR_SURVEY_LIST',
  CLEAR_FILL_SURVEY: 'CLEAR_FILL_SURVEY'
};

export const eventActions = {
  CHOSEN_CLIENTS_CHANGED: 'CHOSEN_CLIENTS_CHANGED',
  EXERCISE_DETAILS: 'EXERCISE_DETAILS',
  CONGRATS_PAGE: 'CONGRATS_PAGE',
  WELCOME_USER_MODAL: 'WELCOME_USER_MODAL',
  ALERT_MODAL: 'ALERT_MODAL',
  ALERT_MODAL_2: 'ALERT_MODAL_2',
  ALERT_MODAL_3: 'ALERT_MODAL_3',
  USER_MODAL: 'USER_MODAL',
  ASPIRE_MSG_MODAL: 'ASPIRE_MSG_MODAL',
  ASPIRE_MSG_CENTER: 'ASPIRE_MSG_CENTER',
  CHANGE_FIELD_MODAL: 'CHANGE_FIELD_MODAL',
  MEDIA_LIBRARY: 'MEDIA_LIBRARY',
  MEDIA_PICKER: 'MEDIA_PICKER',
  FILE_UPLOAD: 'FILE_UPLOAD',
  MEDIA_GALLERY: 'MEDIA_GALLERY',
  BODY_STATS_DETAILS: 'BODY_STATS_DETAILS',
  IFRAME_PAGE: 'IFRAME_PAGE',
  FULL_SCREEN_PAGE: 'FULL_SCREEN_PAGE',
  ONBOARDING_PAGE: 'ONBOARDING_PAGE',
  SURVEY_PAGE: 'SURVEY_PAGE',
  CREATE_EVENT: 'CREATE_EVENT',
  GET_NEW_WORKOUT_LOG: 'GET_NEW_WORKOUT_LOG',
  EXERCISE_LIST_SEARCH: 'EXERCISE_LIST_SEARCH',
  ADD_EXERCISE_TO_SESSION: 'ADD_EXERCISE_TO_SESSION',
  ADD_EXERCISE_TO_PROGRESS: 'ADD_EXERCISE_TO_PROGRESS',
  SUPERSET_EXERCISE_OPTIONS: 'SUPERSET_EXERCISE_OPTIONS',
  DYNAMIC_SEARCH_LIST: 'DYNAMIC_SEARCH_LIST',
  CHOOSE_EQUIPMENT: 'CHOOSE_EQUIPMENT',
  EVENT_DETAILS: 'EVENT_DETAILS',
  EVENT_UPDATE_OPTIONS: 'EVENT_UPDATE_OPTIONS',
  VERIFY_PHONE: 'VERIFY_PHONE',
  ASSIGN_PLAN_PAGE: 'ASSIGN_PLAN_PAGE'
};
