export const envs = {
  PROD: 'PROD',
  PRE_PROD: 'PRE_PROD',
  TEST: 'TEST',
  DEV: 'DEV',
  LOCAL: 'LOCAL',
};

const host = {
  local: ['http://localhost:8080', 'http://localhost:8081'],
  dev: 'aspireappwebdev.eu-central-1.elasticbeanstalk.com',
  test: 'https://aspire-app-dev-db21dc52e3af.herokuapp.com',
  aspire: 'application.aspire.co.il',
};

export const serverUrl = {
  LOCAL: 'http://localhost:2020',
  DEV: 'http://localhost:2020',
  TEST: 'https://aspire-server-prod.herokuapp.com',
  PROD: 'https://aspireserver-prod-eu-central-1.aspire.co.il', // 'https://aspireserver-prod.eu-central-1.elasticbeanstalk.com' , 'https://aspireserver-prod.eu-central-1.aspire.co.il'
};

export const GA = {
  PROD: 'G-KK6SEK1F80',
  DEV: 'G-ASYU8VAS87',
  TEST: 'G-ASYU8VAS87',
};

export const assetsDomain = {
  PROD: 'thumbs.aspire.co.il',
  DEV: 'thumbs-dev.aspire.co.il',
  TEST: 'thumbs.aspire.co.il',
};

window.globals = window.globals || {};
if (window.location.host === host.aspire) {
  window.globals.ENV = envs.PROD;
  window.globals.assetsDomain = assetsDomain.PROD;
  window.globals.googleAnalitics = GA.PROD;
} else if (window.location.host.indexOf(host.test) !== -1) {
  window.globals.ENV = envs.TEST;
  window.globals.assetsDomain = assetsDomain.TEST;
  window.globals.googleAnalitics = GA.TEST;
} else if (window.location.host.indexOf(host.local) !== -1) {
  window.globals.ENV = envs.LOCAL;
  window.globals.assetsDomain = assetsDomain.DEV;
  window.globals.googleAnalitics = GA.DEV;
} else {
  window.globals.ENV = envs.DEV;
  window.globals.assetsDomain = assetsDomain.DEV;
  window.globals.googleAnalitics = GA.DEV;
}
window.globals.ENV = window.globals.ENV || envs.DEV;
window.globals.assetsDomain = window.globals.assetsDomain || assetsDomain.DEV;

window.globals.isProd = () =>
  window.globals.ENV === envs.PROD || window.globals.ENV === envs.PRE_PROD;

window.onerror = function (msg, url, line, col, error) {
  if (!window.refreshedReact) window.refreshedReact = 0;
  if (
    msg === 'SyntaxError' &&
    error === "Unexpected token '<'" &&
    !window.location.hash &&
    window.refreshedReact &&
    window.refreshedReact < 3
  ) {
    window.location += '#refresh';
    window.refreshedReact += 1;
    window.location.reload();
  }
};
