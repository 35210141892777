/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import MediaDisplayUI from '../UI/MediaDisplayUI';
import { allowedTypesList, aspirePlaceholder, mediaType } from '../../services/enums';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { showAlert, showAlert2 } from '../../store/actions';
import './MediaUpload.css';

class MediaUploadWithThumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: this.props.media,
    };
  }

  openUploadModal () {
    const data = {
      isOpen: true,
      isModal: true,
      id: eventActions.FILE_UPLOAD,
      title: this.props.costumeTextBtn,
      data: {
        tags: [],
        folder: `${this.props.user.public_id}/${this.props.folder}`,
        isMulti: this.props.isMulti
      },
      allow: this.props.allowTypes || [...allowedTypesList.image],
      allowLibraryShow: this.props.allowLibraryShow || [mediaType.image],
      className: 'PageModal mediaUpload-uppy',
      buttonRight: {
        text: <i className="la la-arrow-left iconFontSize30 primary" />,
        onClick: () => {
          this.props.hideAlert2();
        }
      },
      successUpload: (mediaArray) => { this.onMediaUpload(mediaArray); }
    };
    this.props.showAlert2(data);
  }

  openUploadModalMiddleware () {
    if (this.props.modalMinimized) {
      const data = {
        isOpen: true,
        title: <Trans i18nKey="alert" />,
        content: <Trans i18nKey="thisModalCantBeOpened" />,
        className: 'ModalPage',
        id: eventActions.ALERT_MODAL,
        closeAlertBtn: {
          text: <Trans i18nKey="confirm" />
        }
      };
      this.props.showAlert(data);
    } else this.openUploadModal()
  }

  onMediaUpload(mediaArray) {
    const media = this.props.isMulti ? mediaArray : ((mediaArray && mediaArray[0]) || null);
    this.setState({ media, hasChanged: true}, () =>{
      if (this.props.onMediaUpload) this.props.onMediaUpload(this.state.media)
    });
  }

  removeMedia() {
    this.setState({ media: null });
    if (this.props.onMediaUpload) this.props.onMediaUpload()
  }

  showThumbnail(id, media, height) {
    return (
      <div className="flex-100 positionRelative" style={{height}}>
        <div className="zIndex10 layout-row width100 positionAbsoluteTopLeft">
          <div className="width100 margin1rem">
            <div className="flex-initial zIndex10 position-relative">
              <button type="button" className="btn btn-light icon-button btn-shadow" onClick={() => { this.removeMedia()}}>
                <i className="las la-trash-alt font24" />
              </button>
            </div>
          </div>
        </div>
        <MediaDisplayUI
          media={[{ media }]}
          width={window.innerWidth}
          shouldUpdate
          height={height}
          backUpImage={aspirePlaceholder[1]}
          appLang={this.props.locale}
          isImage={!media.type || (media.type === mediaType.image)}
          videoPoster={media.thumbnail}
          fallbackThumbnail={media.sourceOrigin || media.source}
          controls />
      </div>
    );
  }

  render() {
    const { media } = this.state;
    const { height, imageHeight, className, icon, text, iconClass, aspireLibrary, facebookSocial, btnClassName} = this.props;
    return (
      <div className={`MediaUploadWithThumbnail flex-100 layout-row layout-wrap layout-align-start-center ${className || ''}`}>
        { media && (media._id || media.id) ? (
          <div className="flex-100 layout-row layout-wrap layout-align-start-center">
            { this.showThumbnail((media._id || media.id), media, height || imageHeight || 350)}
          </div>
        ) : (
          <button
            type="button"
            onClick={() => { this.openUploadModalMiddleware()}}
            className={`btn-aspire-primary theme-color-1 height50px width100 layout-align-center-center text-center ${btnClassName || ''}`}
            style={{minHeight: height || 'inherit'}}>
            <i className="las la-camera font30"></i>
            <p className="margin0 paddStart10px font15 fontWeight600 text-capitalize"><Trans i18nKey={text} /></p>
          </button>
        )}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  modalMinimized: state.appR.modalMinimized
});

const mapDispatchToProps = dispatch => ({
  showAlert2: data => dispatch(showAlert2(data)),
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
  showAlert: data => dispatch(showAlert(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaUploadWithThumbnail);
