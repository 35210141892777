import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import momentTZ from 'moment-timezone';
import UserInfo from '../UserInfo/UserInfo';
import { aspirePlaceholder, eventStatus, eventTypes, userTypes } from '../../services/enums';
import Image from '../UI/Image';
import '../SquareCard/SquareCard.css';
import '../SquareCard/ProgramArchiveCard.css';
import './EventCard.css';

const EventCard2 = (props) => {
  const { data, user, trainerInfo, clientsListKV, clicked, timezone } = props;
  const participantsData = (data && data.original && data.original.participants) || data.participants || null;
  if (!participantsData) return null;
  const title = data.title || data.event.title;
  const event = (data && data.event) || data;
  const publicId = user && user.userType === userTypes.TRAINER ? user.public_id : trainerInfo.public_id;
  const maxFor = user && user.userType === userTypes.TRAINER ? 2 : 1;
  const participants = [];
  let thumbnail = aspirePlaceholder[0];
  let isWaiting = true;
  let approvedEvent = 0;
  participantsData.forEach((userPart) => {
    if (user && user.userType === userTypes.TRAINER && userPart.public_id !== publicId) {
      const userThumbnail = (clientsListKV && clientsListKV[userPart.public_id] && clientsListKV[userPart.public_id].thumbnail && clientsListKV[userPart.public_id].thumbnail.uri) || aspirePlaceholder[0];
      thumbnail = (data && data.original && data.original.thumbnail && data.original.thumbnail.thumbnail) || userThumbnail || aspirePlaceholder[0];
      userPart.thumbnail = thumbnail;
      participants.push(userPart);
    } else if (userPart.public_id !== publicId) {
      thumbnail =
        (data && data.original && data.original.thumbnail && data.original.thumbnail.thumbnail) ||
        (user && user.thumbnail && user.thumbnail.uri) ||
        aspirePlaceholder[0];
      userPart.thumbnail = thumbnail;
      participants.push(userPart);
    }
    if (user.public_id === userPart.public_id) {
      isWaiting = (userPart.status === eventStatus.pending);
    }
    if (userPart.status === eventStatus.confirmed) approvedEvent += 1;
  });
  const eventTitle = title && title.length > 22 ? `${title.slice(0,19)}...` : title;
  const startDate = momentTZ.tz(data.startDate, timezone).format('dddd');
  const startTime = momentTZ.tz(data.startDate, timezone).format('HH:mm');
  const isSameDay = momentTZ.tz(data.startDate, timezone).format('YYYY-MM-DD') === momentTZ.tz(data.endDate, timezone).format('YYYY-MM-DD');
  const endTime = isSameDay ? momentTZ.tz(data.endDate, timezone).format('HH:mm') : <Trans i18nKey="onGoing" />
  return (
    <div className="PlanArchiveCard EventCard_2 flex-100 layout-row layout-wrap layout-align-start-center SquareCard" onClick={() => clicked()}>
      <Image
        backgroundImage
        src={thumbnail}
        fallbackSrc={thumbnail || aspirePlaceholder[0]}
        height={300}
        className="mediaImg backgroundImage flex-initial layout-column layout-align-start-start positionRelative"
        color1="rgba(32, 18, 152, 0.3)"
        color2="rgba(14, 22, 86, 0.2)"
        color3="rgba(7, 18, 113, 0.2)"
        degree={-15} />
      <div className="flex layout-row layout-align-space-between padd10 infoContent">
        <div className="flex layout-column layout-wrap layout-align-space-between-start">
          <div className="flex-initial layout-row layout-wrap layout-align-start-start">
            { data && eventTitle && (
              <p className="flex-100 font15 title text-capitalize margin0">{eventTitle}</p>
            )}
            { isWaiting && (<p className="flex-100 font12 text-capitalize margin0 isWaiting"><Trans i18nKey="waitingForResponse" /></p>)}
            { !isWaiting && (<p className="flex-100 font12 text-capitalize margin0">
              <span className="isWaiting">{`${approvedEvent}`}</span>{`/${participantsData.length}`} <Trans i18nKey="confirmedArrival" /></p>)}
          </div>
          <div className="flex-initial layout-row layout-align-start-start layout-wrap">
            { participants && participants.length === 1 ? (
              <div className="flex layout-row layout-wrap layout-align-start-start">
                <p className="flex-100 font13 text-muted">{participants[0].name}</p>
              </div>
              ) : (
              <div className="flex-initial layout-row layout-align-center-center layout-wrap marginBottom5px">
                <div className="flex-100 layout-row layout-wrap layout-align-start-center">
                  {participantsData && participantsData.length > 0 && (
                  <UserInfo
                    users={participants || participantsData}
                    inProps="public_id"
                    text=""
                    updateUserIds
                    maxFor={maxFor} />
                )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex-initial layout-column layout-wrap layout-align-start-end">
          { event.allDay ? (
            <p className="font12 margin0"><Trans i18nKey="allDay" /></p>
          ) : (
            <Fragment>
              <p className="font13 fontWeight600 text-capitalize margin0">{startDate}</p>
              <p className="font12 margin0">{startTime}</p>
              <p className="font12 text-muted">{endTime}</p>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

EventCard2.propTypes = {
  data: PropTypes.shape({}),
  appLang: PropTypes.string
};

export default EventCard2;
