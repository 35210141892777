import React from 'react';
import ReactSVG from 'react-svg';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import loader from '../../assets/svg/aspireLogoLoader.svg';

const EmptyListUI = props => (
  <div className={`EmptyListUI layout-column width100 layout-align-center-center ${props.className ? props.className : ''}`}>
    { (props.isLoading || props.loadingOnly) ? (
      <div className="layout-column width100 layout-align-center-center aspireLoader">
        <div className="flex-initial layout-row layout-wrap layout-align-start-start">
          <ReactSVG
            className="marginBottom5px"
            evalScripts="never"
            src={loader} />
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-center-center animeDelay03 fadeIn-anime">
          <h2 className="text-uppercase fontWeight600">Aspire</h2>
        </div>
      </div>
    ) : (
      <div className={`width100 layout-column layout-align-center-center ${props.className ? props.className : ''}`}>
        <div className="flex-100 layout-column alignSelf-stretch layout-align-center-center text-center animeDelay03">
          <div className="flex-initial layout-row layout-wrap layout-align-start-start EmptyListSvg">
            <ReactSVG
              className="marginBottom15px svgEmptyIcon "
              svgClassName={props.svgClassName}
              evalScripts="never"
              src={props.svg} />
          </div>
          <div className="flex-initial layout-row layout-wrap layout-align-center-center text-center">
            <p className="font18 fontWeight600"><Trans i18nKey={props.text} /></p>
            { props.text2 && (<p className="flex-100 font16 fontWeight600 margin0"><Trans i18nKey={props.text2} /></p>)}
          </div>
        </div>
        { props.isTrainer && props.trainerLink && !props.trainerText && (
          <div className="flex-100 layout-row layout-wrap layout-align-center-center text-center">
            <p className="font18 fontWeight600 padd5px"><Trans i18nKey={props.trainerText} /></p>
          </div>
        )}
        <div className="flex-100 layout-row layout-wrap layout-align-center-center text-center">
          { props.isTrainer && props.trainerLink && props.trainerText && (
            <div className="width100 layout-row layout-wrap layout-align-center-center sidePadd15px">
              <button
                type="button"
                className="btn-aspire-primary whiteBg boxShadowAround flex-100 layout-row layout-align-center-center borderRadius5 addWorkoutBtn fontWeight600 marginBottom10px">
                <p className="margin0 font15 text-capitalize aspire-text"><Trans i18nKey={props.trainerText} /></p>
              </button>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);

export default EmptyListUI;
