import React, { Component } from 'react';
import './UIStyle.css';

class PhoneDisplayUI extends Component {
  constructor (props) {
    super(props);
    this.state = {
      thumbnail: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/logoScreen.jpg'
    };
  }

  componentDidMount () {
    const vid = document.querySelector(`#${this.props.uiId} video`);
    vid.onloadeddata = () => {
      const timeoutId = setTimeout(() => {
        vid.play();
        clearTimeout(timeoutId);
      }, 500);
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return false;
  }

  render () {
    const {
      isIphone, isImage, videoPoster, videoUrl
    } = this.props;
    return (
      <div id={this.props.uiId} className={`PhoneDisplayUI flex-initial layout-row layout-align-center-center  ${this.props.className ? this.props.className : ''}`}>
        <div className="phone-container positionRelative" style={{ paddingTop: isIphone ? '12px' : 0 }} id="phone">
          { isIphone && (
            <div className="iphone-header width100 layout-row layout-align-space-between-center directionLtr">
              <div className="flex-initial innerPadd">
                <p className="margin0 font11 fontWeight600">09:41</p>
              </div>
              <div className="flex-40 iphone-head" />
              <div className="flex-initial layout-row innerPadd">
                <i className="la la-wifi font14" />
                <i className="la la-battery-empty font14" />
              </div>
            </div>
          )}
          { isImage ? (
            <img alt="infoImg" src={videoPoster || this.state.thumbnail} className="width100" />
          ) : (
            <video
              className="video"
              autoPlay
              loop
              controls
              playsInline
              controlsList="nodownload nofullscreen"
              disablePictureInPicture
              poster={videoPoster || this.state.thumbnail}>
              <source src={`${videoUrl}`} type="video/mp4" />
              <source src={`${videoUrl}`} type="video/webm" />
              <source src={`${videoUrl}`} type="video/ogg" />
            </video>
          )}
          { isIphone && (
            <div className="iphone-bottom width100 layout-row layout-align-space-between-center">
              <div className="flex-40 iphone-bottom-line" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PhoneDisplayUI;
