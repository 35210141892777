import { actionTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { handleErrorResponse, handleSuccessResponse } from './index';
import { utils } from '../../services/utilsService';

const setWorkoutPrograms = (data, nextPage) => ({
  type: actionTypes.SET_WORKOUT_PROGRAMS,
  payload: data,
  nextPage
});

const setWorkoutProgramDetails = data => ({
  type: actionTypes.SET_WORKOUT_PROGRAM_DETAILS,
  payload: data
});

export const clearWorkoutProgram = () => ({
  type: actionTypes.CLEAR_PROGRAM
});

export const saveCurrentWorkoutLogs = workoutLogs => ({
  type: actionTypes.SAVE_CURRENT_WORKOUTLOGS,
  payload: workoutLogs
});

const setWorkoutProgressData = data => ({
  type: actionTypes.SET_WORKOUT_PROGRESS_DATA,
  payload: data
});

/* -------   WORKOUT PROGRAMS  ------- */

export const getWorkoutPrograms = (queryObj, nextPage) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : '';
    const data = await apiMethods.workoutProgram.getWorkoutPrograms(query);
    dispatch(setWorkoutPrograms(data, nextPage));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getWorkoutProgramsForPlan = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : '';
    const data = await apiMethods.workoutProgram.getWorkoutProgramsForPlan(query);
    dispatch(setWorkoutPrograms(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getWorkoutProgramData = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : '';
    const data = await apiMethods.workoutProgram.getWorkoutProgram(query);
    dispatch(setWorkoutProgramDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getWorkoutProgramPageData = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : '';
    const data = await apiMethods.workoutProgram.getWorkoutProgramPageData(query);
    dispatch(setWorkoutProgramDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const updateWorkoutProgram = (body, refreshData) => async (dispatch) => {
  try {
    const data = await apiMethods.workoutProgram.edit(body);
    let result = true;
    if (refreshData) {
      const query = { programId: data.public_id };
      result = await dispatch(getWorkoutProgramData(query));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeWorkoutProgram = publicId => async (dispatch) => {
  try {
    await apiMethods.workoutProgram.remove({ programId: publicId });
    return true;
    // dispatch(handleProgramRemovedSuccessfully(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const updateProgramList = payload => ({
  type: actionTypes.UPDATE_PROGRAM_LIST,
  payload
});

export const introduceProgramToClient = () => async (dispatch) => {
  try {
    return await apiMethods.workoutPlans.introduceProgramToClient();
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

/* -------   WORKOUT PLAN  ------- */

const setWorkoutPlans = (data, nextPage) => ({
  type: actionTypes.SET_WORKOUT_PLANS,
  payload: data,
  nextPage
});

const setWorkoutPlanData = data => ({
  type: actionTypes.SET_WORKOUT_PLAN_DATA,
  payload: data
});

const setWorkoutPlanPageData = data => ({
  type: actionTypes.SET_WORKOUT_PLAN_PAGE_DATA,
  payload: data
});

const setNewWorkoutPlan = data => ({
  type: actionTypes.SET_NEW_WORKOUT_PLAN,
  payload: data
});

const setWorkoutPlanPrograms = data => ({
  type: actionTypes.SET_WORKOUT_PLAN_PROGRAMS,
  payload: data
});

const handlePlanRemovedSuccessfully = publicId => ({
  type: actionTypes.REMOVE_WORKOUT_PLAN,
  payload: publicId
});

export const getWorkoutPlans = (queryObj, nextPage) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.workoutPlans.getWorkoutPlans(query);
    dispatch(setWorkoutPlans(data, nextPage));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getWorkoutPlanToModify = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.workoutPlans.getWorkoutPlanToModify(query);
    dispatch(setWorkoutPlanData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getWorkoutPlanPageData = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.workoutPlans.getWorkoutPlanPageData(query);
    dispatch(setWorkoutPlanPageData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getPlanPrograms = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : '';
    const data = await apiMethods.workoutProgram.getPlanPrograms(query);
    dispatch(setWorkoutPlanPrograms(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const updateWorkoutPlanPrograms = body => async (dispatch) => {
  try {
    const data = await apiMethods.workoutPlans.editPrograms(body);
    // dispatch(setWorkoutPlanPrograms(data));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createWorkoutPlan = body => async (dispatch) => {
  try {
    const data = await apiMethods.workoutPlans.create(body);
    dispatch(setNewWorkoutPlan(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateWorkoutPlan = (body) => async (dispatch) => {
  try {
    const data = await apiMethods.workoutPlans.edit(body);
    await dispatch(setWorkoutPlanData({ workoutPlan: body }));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const copyWorkoutPlan = body => async (dispatch) => {
  try {
    const data = await apiMethods.workoutPlans.copy(body);
    return data && data.workoutPlan;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const changeCurrentWeek = (body) => async (dispatch) => {
  try {
    return await apiMethods.workoutProgress.changeCurrentWeek(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeWorkoutPlan = publicIds => async (dispatch) => {
  try {
    if (!Array.isArray(publicIds)) {
      publicIds = [publicIds];
    }
    await apiMethods.workoutPlans.remove(publicIds);
    return true;
    // dispatch(handleProgramRemovedSuccessfully(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const addNewProgram = body => async (dispatch) => {
  try {
    const data = await apiMethods.workoutPlans.addNewProgram(body);
    const query = { programId: data.public_id };
    dispatch(getWorkoutProgramData(query));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

const setWorkoutSessions = data => ({
  type: actionTypes.SET_WORKOUT_SESSIONS,
  payload: data
});

const clearAllWorkoutPageData = data => ({
  type: actionTypes.CLEAR_ALL_WORKOUTS_PAGE_DATA,
  payload: data
});

export const getAllWorkoutPageData = (queryObj, isProfilePage) => async (dispatch) => {
  try {
    dispatch(clearAllWorkoutPageData());
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    let dataSessions = {};
    if (isProfilePage) dataSessions = await apiMethods.sessions.getSessionsProfile(query);
    else dataSessions = await apiMethods.sessions.getWorkoutSessions(query);
    dispatch(setWorkoutSessions(dataSessions));
    const dataPlans = await apiMethods.workoutPlans.getWorkoutPlans(query);
    dispatch(setWorkoutPlans(dataPlans));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

/* -------   WORKOUT PROGRESS  ------- */

export const getFullWorkoutProgress = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.workoutProgress.getFullWorkoutProgress(query);
    dispatch(setWorkoutProgressData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getWeeklyWorkoutProgress = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.workoutProgress.getWeeklyWorkoutProgress(query);
    dispatch(setWorkoutProgressData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateWorkoutProgress = (body) => async (dispatch) => {
  try {
    return await apiMethods.workoutProgress.update(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeWorkoutProgress = publicIds => async (dispatch) => {
  try {
    if (!Array.isArray(publicIds)) {
      publicIds = [publicIds];
    }
    await apiMethods.workoutProgress.remove(publicIds);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};
