import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import LoadingDots from './LoadingDots';
import './UIStyle.css';
import { addClass } from '../../services/utilsService';

class BtnLoader extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      shouldUpdate: false
    };
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !!((this.props.pageHeader && this.props.pageHeader.validationError) || nextState.shouldUpdate || nextProps.shouldUpdate);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

  }

  toggleLoading () {
    this.setState({ isLoading: true, shouldUpdate: true }, () => {
      if (this.props.addClassLoading) addClass('#BtnLoader.BtnLoader', 'isLoading');
      if (this.props.btnClicked) this.props.btnClicked();
    });
  }

  render () {
    const { id, containerClassName, className, classNameText, pageHeader } = this.props;
    if (pageHeader && pageHeader.validationError) this.state.isLoading = false;
    return (
      <div id={id || 'BtnLoader'}
        className={`BtnLoader ${this.state.isLoading ? 'isLoading' : ''} ${containerClassName || 'flex-initial layout-row layout-wrap layout-align-center-center'} positionRelative`}>
        <button
          type={this.props.type || 'button'}
          className={`btn btn-transparent layout-column layout-wrap layout-align-center-center font15 ${className || ''}`}
          onClick={() => {this.toggleLoading();}}>
          <span className={`text ${classNameText || ''}`}>
            <Trans i18nKey={this.props.btnText || 'next'} />
          </span>
          <div className="flex-initial positionAbsoluteTopLeft isLoadingDots padd10 width100">
            <LoadingDots />
          </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pageHeader: state.appR.pageHeader // all the permissions
});

export default connect(mapStateToProps)(BtnLoader);
