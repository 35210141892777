/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import Modal from '../../Components/Alerts/Modal';
import './ModalStyles.css'
import MsgModal from '../../Components/Alerts/MsgModal';
import ExerciseDetailsPage from './ExerciseDetailsPage';
import FileUploadPage from './FileUploadPage';
import EditEventOptionsModal from "../CalendarEventsPage/EditEventOptionsModal";
/* Lazy Modals */
const ChooseEquipmentModal = React.lazy(() => import('../../Components/Alerts/ChooseEquipmentModal'));
// const ExerciseDetailsPage = React.lazy(() => import('./ExerciseDetailsPage'));
const MediaLibraryPage = React.lazy(() => import('./MediaLibraryPage'));
const MediaPickerModal = React.lazy(() => import('../../Components/Alerts/MediaPickerModal'));
const PopupNotification = React.lazy(() => import('../../Components/UI/PopupNotification'));
const DynamicListSearchPage = React.lazy(() => import('./DynamicListSearchPage'));
const MediaGalleryPage = React.lazy(() => import('./MediaGalleryPage'));

class ModalsSecondLayerContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  openModal () {
    switch (this.props.alert2Props.id) {
      case eventActions.ALERT_MODAL_2:
        return <MsgModal {...this.props.alert2Props} hideAlertBtn={() => this.props.hideAlert2()} />;
      case eventActions.MEDIA_PICKER:
        return <MediaPickerModal {...this.props.alert2Props} config={this.props.alert2Props.config} />;
      case eventActions.MEDIA_LIBRARY:
        return <MediaLibraryPage appLang={this.props.appLang} config={this.props.alert2Props.config} hideAlertBtn={() => this.props.hideAlert2()} />;
      case eventActions.MEDIA_GALLERY:
        return <MediaGalleryPage appLang={this.props.appLang} media={this.props.alert2Props.media} showEditBtn={this.props.alert2Props.showEditBtn} itemIndex={this.props.alert2Props.itemIndex} />;
      case eventActions.FILE_UPLOAD:
        return (
          <FileUploadPage appLang={this.props.appLang}
            data={this.props.alert2Props.data}
            alertProps={this.props.alert2Props}
            hideAlertBtn={() => this.props.hideAlert2()} />
);
      case eventActions.EXERCISE_DETAILS:
        return <ExerciseDetailsPage isModalPage appLang={this.props.appLang} />;
      case eventActions.CHOOSE_EQUIPMENT:
        return <ChooseEquipmentModal {...this.props.alert2Props} exercise={this.props.alert2Props.exercise} />;
      case eventActions.EVENT_UPDATE_OPTIONS:
        return <EditEventOptionsModal {...this.props.alert2Props} hideAlertBtn={() => this.props.hideAlert2()} />;
      case eventActions.DYNAMIC_SEARCH_LIST:
        return (
          <DynamicListSearchPage
            isModalPage
            alertProps={this.props.alert2Props}
            title={this.props.alert2Props.title}
            description={this.props.alert2Props.description}
            appLang={this.props.appLang}
            config={this.props.alert2Props.config}
            itemsList={this.props.alert2Props.itemsList}
            filterResults={(query) => { this.props.alert2Props.filterResults(query); }}
            onSelect={array => this.props.alert2Props.onSelect(array)}
            closeSearchList={() => { this.props.alert2Props.closeSearchList(); }}
            hideAlertBtn={() => this.props.hideAlert2()}
          />
        );
      default:
        return null;
    }
  }

  render () {
    return (
      <div className="ModalsSecondLayerContainer flex-100 layout-row layout-wrap layout-align-start-start">
        { this.props.alert2Props && this.props.alert2Props.isOpen && (
          <Modal modalAlert2
            isOpen={this.props.alert2Props.isOpen}
            className={this.props.alert2Props.className}
            alertProps={this.props.alert2Props}
            hideAlertBtn={() => this.props.hideAlert2()}>
            <Suspense fallback={<div className="gogo-loading" />}>
              { this.openModal() }
            </Suspense>
          </Modal>
        )}
        { this.props.notificationProps && this.props.notificationProps.isOpen && (
          <PopupNotification text={this.props.notificationProps.text} icon={this.props.notificationProps.icon} color={this.props.notificationProps.color} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  alert2Props: state.appR.alert2Props,
  notificationProps: state.appR.notificationProps
});

const mapDispatchToProps = dispatch => ({
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 })
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsSecondLayerContainer);
