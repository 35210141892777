/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import nanoid from 'nanoid/generate';
import { Link } from 'react-router-dom';
import Slider from 'nuka-carousel';
import { refreshPageHeader } from '../../store/actions';
import { randomIntMinMax, securePostMsgUtils } from '../../services/utilsService';
import { langs } from '../../services/translationLangs';
import { actionTypes } from '../../store/actions/actionTypes';
import VideoSection from '../../Components/Survey/VideoSection';
import { nanoidOption } from '../../services/storeDoll';
import './AppIntroductionPage.css';
import { postMsgTypes } from '../../services/postMsgTypes';

const videoArray = {
  array1: ['intro_box_1.mp4', 'intro_train_female_3.mp4', 'intro_train_male_2.mp4'],
  array2: ['intro_train_male_1.mp4', 'intro_train_female_1.mp4', 'intro_train_female_2.mp4'],
  array3: ['intro_box_2.mp4', 'intro_box_3.mp4', 'intro_box_1.mp4'],
  array4: ['intro_train_female_5.mp4', 'intro_train_female_7.mp4', 'intro_train_female_8.mp4'],
}
const imgArray = {
  array1: ['intro_box_1.jpeg', 'intro_train_female_3.jpeg', 'intro_train_male_2.jpeg'],
  array2: ['intro_train_male_1.jpeg', 'intro_train_female_1.jpeg', 'intro_train_female_2.jpeg'],
  array3: ['intro_box_2.jpeg', 'intro_box_3.jpeg', 'intro_box_1.jpeg'],
  array4: ['intro_train_female_5.jpeg', 'intro_train_female_7.jpeg', 'intro_train_female_8.jpeg'],
}

class AppIntroductionPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      media: {},
      isPageReady: false,
      appLang: this.props.appLang,
      videos: videoArray.array1,
      images: imgArray.array1,
      url: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/'
    };
    this.props.refreshPageHeader('login');
    this.props.disableTopNavPlx(false);
  }

  async componentDidMount () {
    try {
      const keys = Object.keys(videoArray);
      const chosenKey = keys[randomIntMinMax(0, 3)];
      this.setState({ isPageReady: true, videos: videoArray[chosenKey] || videoArray.array1, images: imgArray[chosenKey] || videoArray.array1  }, () => {
        if (securePostMsgUtils.listenToValidMessage()) {
          window.addEventListener('message', this.receiveTokenMessage, false);
          const intervalId = setInterval(() => {
            if (this.checkForToken()) clearInterval(intervalId);
            else this.getToken()
          }, 1500);
        }
      });
    } catch (e) {
      console.log('handle error');
    }
  }

  getToken() {
    window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.GET_GCM_TOKEN }));
  }

  receiveTokenMessage = (event) => {
    const data = event && event.data ? event.data : null;
    if (!window.aspire) window.aspire = {};
    window.aspire.token = data && data.token;
    window.aspire.os = (data && data.os) || undefined;
  };

  checkForToken() {
    if (window && window.aspire && window.aspire.token && window.aspire.token.length > 5) return true;
    return false;
  }


  renderBtns () {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-center-start sidePadd10px">
        <div className="flex-100 layout-row layout-wrap layout-align-center-start marginBottom10px">
          <Link to="/login" className="uploadFilesBtn flex-100 layout-align-center-center text-center ">
            <p className="sidePadd10px margin0 font16 fontWeight600">{ `${langs[this.state.appLang].signin} / ${langs[this.state.appLang].invitedClient}` }</p>
          </Link>
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-center-start">
          <Link to="/signup" className="uploadFilesBtn flex-100 layout-align-center-center text-center ">
            <p className="sidePadd10px margin0 font16 fontWeight600 darkText">{ langs[this.state.appLang].createAccount }</p>
          </Link>
        </div>
      </div>
    )
  }

  changeLanguage(value){
    if (value === this.state.appLang) return;
    this.setState({appLang: value});
  }

  renderTranslationChange(){
    return (
      <div className="width100 positionAbsoluteTopLeft zIndex10 layout-row layout-align-start-start paddTop10px">
        <div className="TranslationChangeUI flex-100 layout-row layout-wrap layout-align-start-start directionChangeRtl">
          <div className="flex-initial layout-row layout-align-start-start">
            <button
              className="flex-initial btn btn-transparent layout-row layout-align-start-start colorWhite"
              onClick={() => { this.changeLanguage('he'); }}>
              <Trans i18nKey="HE_short" />
            </button>
            <div className="flex-initial layout-row layout-align-start-start height35px borderEndGrey" />
            <button
              className="flex-initial btn btn-transparent layout-row layout-align-start-start colorWhite"
              onClick={() => { this.changeLanguage('en'); }}>
              <Trans i18nKey="EN_short" />
            </button>
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { isPageReady, videos, images, url } = this.state;
    const translation = this.renderTranslationChange();
    return (
      <div className="AppIntroductionPage SurveyPage flex-100 layout-row layout-wrap layout-align-start-start fullSizeWindow content-start whiteBackground">
        { translation }
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className="positionRelative zIndex5 flex-100 layout-row layout-wrap layout-align-center-start">
            { isPageReady && (
              <Slider
                className="overFlowHidden sliderDirection fullSizeWindow"
                spring="gentle"
                autoGenerateStyleTag={false}
                // withoutControls
                autoplay
                autoplayInterval={9000}
                initialSlideHeight={window.innerHeight}
                disableEdgeSwiping>
                <VideoSection id={nanoid(nanoidOption, 4)}
                  className={this.state.appLang === 'en' ? 'directionLtr' : 'directionRtl'}
                  title={langs[this.state.appLang].aspireIntro_title_1}
                  description={langs[this.state.appLang].aspireIntro_desc_1}
                  video={`${url}${videos[0]}`}
                  backgroundImage={`${url}${images[0]}`}
                  backgroundOverlay="rgba(0,0,0,0.3)"
                  backgroundColor="transparent"
                  isSwiperCarousel
                  appLang={this.state.appLang} />
                <VideoSection
                  id={nanoid(nanoidOption, 4)}
                  className={this.state.appLang === 'en' ? 'directionLtr' : 'directionRtl'}
                  title={langs[this.state.appLang].aspireIntro_title_2}
                  description={langs[this.state.appLang].aspireIntro_desc_2}
                  video={`${url}${videos[1]}`}
                  backgroundImage={`${url}${images[1]}`}
                  backgroundColor="transparent"
                  backgroundOverlay="rgba(0,0,0,0.3)"
                  isSwiperCarousel
                  appLang={this.state.appLang} />
                <VideoSection id={nanoid(nanoidOption, 4)}
                  className={this.state.appLang === 'en' ? 'directionLtr' : 'directionRtl'}
                  title={langs[this.state.appLang].aspireIntro_title_3}
                  description={langs[this.state.appLang].aspireIntro_desc_3}
                  video={`${url}${videos[2]}`}
                  backgroundImage={`${url}${images[2]}`}
                  backgroundColor="transparent"
                  backgroundOverlay="rgba(0,0,0,0.3)"
                  isSwiperCarousel
                  btns={this.renderBtns()}
                  appLang={this.state.appLang} />
              </Slider>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  refreshPageHeader: page => dispatch(refreshPageHeader(page)),
  disableTopNavPlx: isDisable => dispatch({ type: actionTypes.DISABLE_TOP_NAV_PLX, payload: isDisable })
});

export default connect(mapStateToProps, mapDispatchToProps)(AppIntroductionPage);
