import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { uniq } from 'lodash-es'
import nanoid from 'nanoid';
import moment from 'moment';
import Input from '../UI/Input';
import { daysFullNameArray, repeatEventConditions, repeatEventType} from '../../services/enums'
import { stopConditions } from '../../services/storeDoll';
import DateTimeInput from '../DatePickers/DateTimeInput';
import './RecurringEventConfig.css';

class RecurringEventConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repeat: repeatEventType.daily,
      interval: 1,
      weekDays: [],
      recurringCondition: repeatEventConditions.never
    /*  recurring: {
        repeat: 'weekly', // repeating type
        until: '2021-03-28', // until date to be repeated
        weekDays: ['TU','WE','TH','FR'] // specific days to be repeated
        interval: 1, // every x days/weeks times to be repeated
        count: 1, // num time to be repeated
      } */
    };
    if (this.props.recurring && this.props.recurring.repeat) this.state = this.props.recurring;
  }

  componentDidMount() {
    this.setRecurringSettings();
  }

  handleChosenDay (day) {
    let { weekDays } = this.state;
    if(!weekDays) weekDays = [];
    if(weekDays.indexOf(day) === -1) weekDays.push(day);
    else weekDays.splice(weekDays.indexOf(day), 1)
    const weekDaysUniq = uniq(weekDays);
    this.setState({ weekDays: weekDaysUniq }, () => {
      this.setRecurringSettings()
    });
  }

  setRepeat (value) {
    this.setState({ repeat: value }, () => {
      this.setRecurringSettings()
    });
  }

  setInterval (event) {
    this.setState({ interval: Number(event.target.value) }, () => {
      this.setRecurringSettings()
    });
  }

  setCondition(value) {
    this.setState({ recurringCondition: value }, () => {
      this.setRecurringSettings()
    });
  }

  setCount (event) {
    this.setState({ count: Number(event.target.value), recurringCondition: repeatEventConditions.numOfTimes  }, () => {
      this.setRecurringSettings()
    });
  }

  setUntilDate (e) {
    this.setState({ until: e.value, recurringCondition: repeatEventConditions.untilDate }, () => {
      this.setRecurringSettings()
    });
  }

  handleChosenType(){
    switch (this.state.repeat) {
      case repeatEventType.daily:
        return this.renderDailyConfig();
      case repeatEventType.weekly:
        return this.renderWeeklyConfig();
      default:
        return this.renderDailyConfig();
    }
  }

  setRecurringSettings() {
    const {repeat, interval, weekDays, recurringCondition, until, count } = this.state;
    let recurring = {};
    if (repeat === repeatEventType.daily) recurring = { repeat, interval };
    else if (repeat === repeatEventType.weekly) recurring = { repeat, interval, weekDays };
    switch (recurringCondition) {
      case repeatEventConditions.untilDate:
        recurring.until = until || undefined;
        break;
      case repeatEventConditions.numOfTimes:
        recurring.count = count || undefined;
        break;
      case repeatEventConditions.never:
        recurring.endRecurring = moment().add(2, 'years');
        break;
      default: break;
    }
    this.props.setRecurringSettings(recurring)
  }

  renderDailyConfig () {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom5px">
        <div className="flex-100 layout-row layout-align-start-center">
          <p className="margin0"><Trans i18nKey="repeatEvery" /></p>
          <input
            className="form-control flex-initial every sideMargin5px"
            type="number"
            defaultValue={this.state.interval.toString()}
            onChange={(event) => this.setInterval(event)} />
          <p className="margin0"><Trans i18nKey="days" /></p>
        </div>
        <div className="flex-100 layout-row layout-align-start-start">
          <p className="text-muted"><Trans i18nKey="repeatEvery_day_info" /></p>
        </div>
      </div>
    )
  }

  renderWeeklyConfig() {
    const  { appLang } = this.props;
    const  { interval, weekDays } = this.state;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom5px">
        <div className="flex-100 layout-row layout-align-start-center">
          <p className="margin0"><Trans i18nKey="repeatEvery" /></p>
          <input
            className="form-control flex-initial every sideMargin5px"
            type="number"
            defaultValue={interval.toString()}
            onChange={(event) => this.setInterval(event)} />
          <p className="margin0"><Trans i18nKey="weeks" /></p>
        </div>
        <div className="flex-100 layout-row layout-align-start-start">
          <p className="text-muted"><Trans i18nKey="repeatEvery_week_info" /></p>
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
          { daysFullNameArray.map((day) => (
            <Input
              noLabel
              classes={appLang === 'en' ? 'flex-50' : 'flex-33'}
              className="marginBottom5px"
              name="weekDay"
              label={`day_${day.text.en}`}
              elementType="checkBox"
              clickText
              initialValue={(weekDays && weekDays.length > 0 && weekDays.indexOf(day.calendar) !== -1)}
              appLang="en"
              handleChange={() => { this.handleChosenDay(day.calendar); }}>
              <span>{day.text[appLang]}</span>
            </Input>
          ))}
        </div>
      </div>
    )
  }

  renderStopConditions() {
    const  { appLang } = this.props;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom5px">
        <div className="flex-100 layout-row layout-align-start-center marginBottom10px">
          <label className="formBoxInput primary text-capitalize rendered valued">
            <Trans i18nKey="stopConditions" />
          </label>
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          { stopConditions.map((input) => (
            <div key={input.name} className={`layout-row layout-align-start-center positionRelative ${input.containerClass ? input.containerClass : ''}`}>
              <Input
                noLabel
                id={`${input.name}_${nanoid(4)}`}
                key={input.name}
                name={input.name}
                label={input.name}
                group={input.group}
                elementType={input.elementType}
                appLang={appLang}
                initialValue={this.state.recurringCondition === input.conditionType}
                className="width100"
                handleChange={() => { this.setCondition(input.conditionType); }}>
                <div className="flex-100 layout-row layout-align-start-center">
                  <p className="margin0"><Trans i18nKey={input.text} /></p>
                  { input.textNumberInput && (
                    <input
                      className="form-control flex-initial every sideMargin5px"
                      type="number"
                      defaultValue={(this.state.count && this.state.count.toString()) || 1}
                      onChange={(event) => this.setCount(event)} />
                  )}
                  { input.textDateInput && (
                    <DateTimeInput
                      appLang={appLang}
                      className="marginBottom10px"
                      noLabel
                      controls={['date']}
                      initValue={this.state.until || moment().add(3, 'months')}
                      onChange={(event) => { this.setUntilDate(event); }}
                    />
                  )}
                  { input.text2 && (<p className="margin0"><Trans i18nKey={input.text2} /></p>)}
                </div>
                <div className="flex-100 layout-row layout-align-start-start">
                  <p className="text-muted"><Trans i18nKey={input.textInfo} /></p>
                </div>
              </Input>
            </div>
          ))}
        </div>
      </div>
    )
  }

  render() {
    const { repeat } = this.state;
    return (
      <div className="RecurringEventConfig flex-100 layout-row layout-wrap layout-align-start-start marginBottom5px">
        <div className="btn-group flex-100 layout-row layout-wrap layout-align-start-start" role="group">
          <button type="button" className={`btn flex ${repeat === repeatEventType.daily ? 'active' : ''}`} onClick={() => this.setRepeat(repeatEventType.daily)}><Trans i18nKey="daily" /></button>
          <button type="button" className={`btn flex ${repeat === repeatEventType.weekly ? 'active' : ''}`} onClick={() => this.setRepeat(repeatEventType.weekly)}><Trans i18nKey="weekly" /></button>
        </div>
        {this.handleChosenType()}
        {this.renderStopConditions()}
      </div>
    )
  }
}

export default RecurringEventConfig;
