import React from 'react';
import PropTypes from 'prop-types';
import Marker from '../MapMarker/MapMarker';
import { aspirePlaceholder } from '../../services/enums';
import { styleUtils } from '../../services/utilsService';
import './SquareCard.css';

const LocationCard = (props) => {
  return (
    <div className="LocationCard flex-100 layout-row layout-wrap layout-align-start-center bordered whiteBackground  backgroundImage borderRadius10 marginBottom10px">
      <div className="mediaImg backgroundImage flex-100 layout-column height110px layout-align-center-start positionRelative borderRadius10 top">
        <img src={props.thumbnail} className="borderRadius10 top height110px width100" alt={props.title} />
        <Marker />
      </div>
      <div className="flex-100 layout-column layout-wrap layout-align-space-between padd10 infoContent">
        <div className="flex-initial layout-row layout-wrap layout-align-start-start marginBottom5px">
          <p className="flex-100 font16 textLimitHeight lineHeight12 title text-capitalize" style={{ height: `${props.appLang === 'en' ? 45 : 45}px` }}>{(props.title) || ''}</p>
        </div>
      </div>
    </div>
  );
};

LocationCard.propTypes = {
  appLang: PropTypes.string
};

export default LocationCard;
