/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import { Trans } from 'react-i18next';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { showAlert3 } from '../../store/actions';
import '../NavBottom/NavBottom.css';
import './SocialButtons/SocialButtons.css';
import aspireLine from '../../assets/svg/aspire_line_logo.svg';
import { mediaType } from '../../services/enums';

class UploadOptionsNav extends Component {

  openAspireLibrary (aspireLibrary) {
    const data = {
      isOpen: true,
      isModal: true,
      allowImages: (this.props.allowLibraryShow.indexOf(mediaType.image) > -1),
      allowVideos: (this.props.allowLibraryShow.indexOf(mediaType.video) > -1),
      id: eventActions.MEDIA_LIBRARY,
      className: 'PageModal',
      initSlide: aspireLibrary.initSlide || 0,
      config: {
        hideSliderBtns: aspireLibrary.hideSliderBtns || false
      },
      buttonRight: {
        text: <i className="la la-arrow-left iconFontSize30 primary " />,
        onClick: () => {
          this.props.hideAlert3();
        }
      },
      chooseImageBtn: {
        onClick: (imagObj) => {
          this.props.onChooseImage(imagObj);
          this.props.hideAlert3();
        }
      }
    };
    this.props.showAlert3(data);
  }

  openUppyBrowser() {
    const button = document.querySelector('.uppy-Dashboard-AddFiles .uppy-Dashboard-AddFiles-title button.uppy-Dashboard-browse');
    if (button) button.click();
  }

  render() {
  const { aspireLibrary } = this.props;
  return (
    <div className="UploadOptionsNav fadeInBottom-anime padd10 flex-100 layout-row layout-wrap layout-align-start-center">
      <div className="flex-100 layout-row layout-wrap layout-align-start-center positionRelative height50px">
        { aspireLibrary && (
          <div className="flex-100 layout-row layout-wrap layout-align-center-start marginBottom10px">
            <button type="button" onClick={() => this.openUppyBrowser()} className="uploadFilesBtn flex-100 layout-align-center-center text-center">
              <i className="las la-cloud-upload-alt font30"></i>
              <p className="margin0 paddStart10px fontWeight600"><Trans i18nKey="selectFilesToUpload" /></p>
            </button>
            <button type="button" onClick={() => this.openAspireLibrary(aspireLibrary)} className="aspireLibraryBtn aspireBg flex-100 layout-align-center-center text-center">
              <ReactSVG src={aspireLine} className="aspireLine_svg" />
              <p className="margin0 paddStart10px fontWeight600"><Trans i18nKey="selectMyMedia" /></p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
};

const mapStateToProps = state => ({
  user: state.userR.userInfo
});

const mapDispatchToProps = dispatch => ({
  showAlert3: data => dispatch(showAlert3(data)),
  hideAlert3: () => dispatch({ type: actionTypes.HIDE_ALERT3 }),
});
export default connect(mapStateToProps, mapDispatchToProps)(UploadOptionsNav);
