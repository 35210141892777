import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { actionTypes } from '../../store/actions/actionTypes';
import { langs } from '../../services/translationLangs';
import { units, allowedFieldsList, inputConversionsPropsUnits } from '../../services/enums';
import { calcUtils, configureInputBySettings, inputUtils } from '../../services/utilsService';
import TimeInputUI from '../UI/TimeInputUI';
import './MsgModal.css';

class ChangeFieldModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      currentLogRef: {},
      inputInitialValues: {},
      isChanged: false
    };
  }

  componentDidMount () { // for auto focus
    const elem = document.querySelector(`.ChangeFieldModal input`);
    if (elem && this.props.autoFocus) {
      elem.focus();
    }
  }

  onFocus (event, propName) {
    inputUtils.onNumberInputFocus(event, `.ChangeFieldModal input#${propName}_input`);
  }

  onBlur (event, propName) {
    inputUtils.onNumberInputBlur(event, `.ChangeFieldModal input#${propName}_input`);
  }

  onInitialRender(){
    // get the value of weights / distance units and convert it to show LBS /INCHES inside the input
    const { logs, propName, indexNum, settings } = this.props ;
    if (logs && logs[indexNum] && logs[indexNum][propName]) {
      const currentType = inputConversionsPropsUnits[propName] && (settings && settings[inputConversionsPropsUnits[propName]]);
      const display = configureInputBySettings(settings, logs[indexNum][propName], currentType || units.weightUnits.KG , true);
      return display.value
    }
    return 0
  }

  handleChange (e, key) {
    this.state.isChanged = true;
    this[key] = e.currentTarget.value;
    this.state.currentLogRef[key] = null; // in case we used the +/- buttons before (clear the input)
  }

  changeInput (e, key, isPlus) {
    const inputElement = document.getElementsByName(`i_${key}`)[0];
    const step = (key === allowedFieldsList.weight ? '2.5' : (key === allowedFieldsList.distance ? '50' : '1'));
    const stepChange = Number(step);
    let sumNumber = 0;
    if (isPlus) {
      sumNumber = Number(inputElement.value) + stepChange;
      if (this.props.alertProps && this.props.alertProps.inputMax <= sumNumber) sumNumber = this.props.alertProps.inputMax;
    } else {
      sumNumber = Number(inputElement.value) - stepChange;
      if (sumNumber < 0) sumNumber = 0;
      if (this.props.alertProps && this.props.alertProps.inputMin > sumNumber) sumNumber = this.props.alertProps.inputMin;
    }
    this[key] = sumNumber;
    inputElement.value = sumNumber;
    if (!this.state.currentLogRef[key]) this.state.currentLogRef[key] = 0;
    this.state.currentLogRef[key] = sumNumber;
    this.state.isChanged = true;
  }

  saveChangedLog (event) {
    event.preventDefault();
    const { logs, propName, indexNum, settings } = { ...this.props };
    const log = logs && logs[indexNum];
    if (!this.state.isChanged) this.props.hideAlert(); // just close the modal;
    if (this[propName] && !this[propName].className) log[propName] = Number(this[propName]); // check if the ref to input is the input itself or its value - might be a better solution for this
    else if (this[propName] === 0) log[propName] = 0;
    else if (this.state.inputInitialValues[propName] && (log[propName] !== this.state.inputInitialValues[propName])) log[propName] = Number(this.state.inputInitialValues[propName]);
    if (this.state.currentLogRef[propName]) log[propName] = this.state.currentLogRef[propName];
    if (this.timeDone) log.timeDone = Number(this.timeDone) // new change of updating time done too
    // here in case we are in LBS units , we need to convert it from input to KG to be saved in server
    if (settings && (settings.weightUnits === units.weightUnits.LBS) && (inputConversionsPropsUnits[propName])){
      log[propName] = calcUtils.calcLBStoKG(log[propName]);
    }
    this.props.buttonRight.onClick(logs);
    this.props.hideAlert();
  }

  handleTimeInputChange (value, key) {
    this.state.isChanged = true;
    this[key] = value;
  }

  handleSideText (key) {
    switch (key) {
      case allowedFieldsList.time:
        return allowedFieldsList.time;
      case allowedFieldsList.weight:
        if (this.props.settings && this.props.settings.weightUnits === units.weightUnits.KG) return units.weightUnits.KG;
        return units.weightUnits.LBS;
      case allowedFieldsList.distance:
        if (this.props.settings && this.props.settings.distanceUnits === units.distanceUnits.CM) return units.distanceUnits.M;
        return units.distanceUnits.YARD;
      default:
        return '';
    }
  }

  renderInputTime (key, log) {
    this[key] = log[key];
    return (
      <div className="flex layout-row layout-wrap layout-align-start-start text-center">
        <TimeInputUI value={log[key]} onChange={(value) => { this.handleTimeInputChange(value, key, log); }} />
      </div>
    );
  }

  renderInput (key, log) {
    const step = (key === allowedFieldsList.weight ? '0.25' : (key === allowedFieldsList.distance ? '50' : '1'));
    const initialValue = this.onInitialRender()
    this[key] = log && log[key] ? log[key] : 0;
    return (
      <div className="flex layout-row layout-wrap layout-align-center-start">
        <div className="flex-100 layout-row layout-align-center-start">
          <button type="button" className="btn btn-transparent padd5" onClick={(event) => { this.changeInput(event, key, true); }}>
            <i className="la la-plus-circle font28" />
          </button>
        </div>
        <div className={`flex-100 layout-row layout-wrap layout-align-start-start positionRelative ${key}_input`}>
          <input
            id={`${key}_input`}
            required
            max={this.props.alertProps.inputMax}
            min={this.props.alertProps.inputMin || 0}
            type="number"
            step={step}
            placeholder={initialValue || 0}
            defaultValue={initialValue || 0}
            ref={input => this[key] = input}
            name={`i_${key}`} // dont change never!!
            onFocus={(event) => { this.onFocus(event, key); }}
            onBlur={(event) => { this.onBlur(event, key); }}
            onChange={(event) => { this.handleChange(event, key); }}
            className="form-control text-center" />
          <span className="sideInputText layout-row layout-align-center-center">
            <p className="flex-initial colorGrey font15 margin0 "><Trans i18nKey={this.handleSideText(key)} /></p>
          </span>
        </div>
        <div className="flex-100 layout-row layout-align-center-start">
          <button type="button" className="btn btn-transparent padd5" onClick={(event) => { this.changeInput(event, key); }}>
            <i className="la la-minus-circle font28" />
          </button>
        </div>
      </div>
    );
  }

  render () {
    const { logs, propName, indexNum } = { ...this.props };
    return (
      <div className="ChangeFieldModal flex-100 layout-row layout-align-start-start">
        <form
          name="inputChangeForm"
          onSubmit={event => this.saveChangedLog(event)}
          className="flex-100 layout-row layout-wrap layout-align-center-center ">
          <div className="flex-100 layout-row layout-align-center-center">
            <div className="title boldText primary">{langs[this.props.appLang][propName]}</div>
          </div>
          <div className="content flex-100 layout-row layout-wrap layout-align-center-center">
            <div className="flex-100 layout-row layout-wrap layout-align-center-center">
              <div className="flex-80 layout-row layout-wrap layout-align-start-start">
                { propName === allowedFieldsList.time ? this.renderInputTime(propName, logs[indexNum]) : (
                  this.renderInput(propName, logs[indexNum])
                )}
              </div>
              { propName === allowedFieldsList.time && logs[indexNum].timeDone && (
                <div className="flex-100 layout-row layout-wrap layout-align-center-center">
                  <div className="flex-100 layout-row layout-align-center-center">
                    <div className="title boldText primary">{langs[this.props.appLang].timeDone}</div>
                  </div>
                  <div className="flex-80 layout-row layout-wrap layout-align-start-start">
                    {this.renderInputTime(allowedFieldsList.timeDone, logs[indexNum])}
                  </div>
                </div>
              )}
              {/* <span> here we will put the input component </span> */}
            </div>
          </div>
          <div className="flex-100 layout-row layout-align-start-start">
            <button type="submit" className="button flex"><Trans i18nKey="confirm" /> </button>
          </div>
        </form>
      </div>
    );
  }
}

ChangeFieldModal.defaultProps = {
  logs: {},
  indexNum: 0,
  propName: '',
  appLang: 'en'
};

ChangeFieldModal.propTypes = {
  logs: PropTypes.shape({}),
  indexNum: PropTypes.number,
  propName: PropTypes.string,
  appLang: PropTypes.string
};

const mapStateToProps = state => ({
  alertProps: state.appR.alertProps,
  settings: state.userR.settings
});

const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeFieldModal);
