import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { Datepicker, localeHe, localeEn, localeEs } from '@mobiscroll/react';
import { langs } from '../../services/translationLangs';
import './DateTimeInput.css';

const locales = {
  he: localeHe,
  es: localeEs,
  en: localeEn,
};
class DateTimeInput extends Component {
  constructor(props) {
    super(props);
    const { initValue } = this.props;
    this.state = {
      locale: (locales && locales[this.props.appLang]) || locales.en,
      initValue: moment(initValue).format('DD.MM.YY HH:mm'),
      controls: props.controls || ['datetime'],
    };
  }

  componentDidMount() {}

  onChange(event) {
    this.props.onChange(event);
  }

  render() {
    const { className, locale, initValue } = this.state;
    return (
      <div
        className={`DateTimeInputs flex-100 layout-row layout-wrap layout-align-start-start ${
          className || ''
        }`}
      >
        <Fragment>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            {!this.props.noLabel && (
              <label className="formBoxInput primary text-capitalize rendered fontWeight400 valued">
                <Trans i18nKey="selectDate" />
              </label>
            )}
            <div className="flex-100 layout-row layout-align-space-between-start dateTime-form-control">
              <div className="flex layout-row layout-align-start-start date-form-control">
                <Datepicker
                  themeVariant="light"
                  cssClass="date-form-control-popup"
                  closeOnOverlayClick={false}
                  theme="ios"
                  controls={['calendar']}
                  select="date"
                  dateFormat="DD.MM.YY"
                  defaultValue={initValue}
                  locale={locale}
                  placeholder={langs[this.props.appLang].startDate}
                  onClose={(event, inst) => { this.props.onChange(event)}}
                  touchUi
                />
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}
export default DateTimeInput;
