/* eslint-disable max-len */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { he, en, es } from './translationLangs';

i18n
  .use(LanguageDetector) // detect the language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // lng: 'he', // 'en' | 'he'
    // we init with resources
    resources: {
      en: {
        translations: en,
      },
      he: {
        translations: he,
      },
      es: {
        translations: es,
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
