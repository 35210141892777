import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { aspirePlaceholder } from '../../services/enums';
import Image from '../UI/Image';

class ClientIcon extends Component {
  getUriOrChar (chosenUser) {
    if (chosenUser.thumbnail && chosenUser.thumbnail.uri && chosenUser.thumbnail.uri !== '') {
      return chosenUser.thumbnail;
    } else if (chosenUser.public_id === this.props.userInfo.public_id && (this.props.userInfo.thumbnail && this.props.userInfo.thumbnail.uri !== '')){
      if (this.props.userInfo.thumbnail && !this.props.userInfo.thumbnail.uri) this.props.userInfo.thumbnail.uri = '';
      return this.props.userInfo.thumbnail;
    }
    const name = chosenUser.name || chosenUser.firstName;
    return { text: (name && name.charAt(0)) || '' };
  }

  getNameProp (chosenUser) {
    let name = chosenUser.name || chosenUser.firstName;
    if (this.props.nameTag) {
      name = this.props.nameTag;
    } else if (name && this.props.firstName) {
      name = name.split(' ')[0] ? name.split(' ')[0] : name;
    }
    return name;
  }

  getThumbnail () {
    if (this.props.isLoading) return null;
    const { public_id } = this.props.user;
    if (public_id && this.props.user && this.props.user.thumbnail) {
      return this.getUriOrChar(this.props.user);
    } if (this.props.clientsListKV && Object.keys(this.props.clientsListKV).length > 0 && this.props.clientsListKV[public_id]) {
      const chosenUser = this.props.clientsListKV[public_id];
      return this.getUriOrChar(chosenUser);
    }
    return this.getUriOrChar(this.props.user || this.props.userInfo);
  }

  getName () {
    if (this.props.isLoading) return null;
    if (this.props.user && (this.props.user.name || this.props.user.firstName)) {
      return this.getNameProp(this.props.user);
    }
    const { public_id } = this.props.user;
    if (this.props.clientsListKV && Object.keys(this.props.clientsListKV).length > 0 && this.props.clientsListKV[public_id]) {
      const chosenUser = this.props.clientsListKV[public_id];
      return this.getNameProp(chosenUser);
    }
    return this.getNameProp(this.props.userInfo);
  }

  render () {
    const thumbnail = this.getThumbnail();
    const name = !this.props.disableName && this.getName(thumbnail);
    const color1 = this.props.color1 || 'rgba(48, 58, 139, 0.1)';
    const color2 = this.props.color2 || 'rgba(48, 58, 139, 0.1)';
    const color3 = this.props.color3 || 'rgba(48, 58, 139, 0.1)';
    return (
      <div className={`ClientIcon flex-initial layout-row layout-wrap layout-align-start-start ${this.props.className}`}>
        <div className="flex-initial layout-row layout-wrap layout-align-start-start">
          { this.props.isLoading ? (
            <button type="button" className="ClientIcon-placeholder flex-initial layout-row layout-wrap layout-align-center-start text-center btn-transparent">
              <div className="flex-initial marginBottom5px circleBorder">
                <div className="flex-initial layout-row layout-wrap layout-align-start-start positionRelative client-image loading circle" />
                <label className="propsName textLimitHeight borderRadius3">
                  <span className="flex-initial layout-row layout-wrap layout-align-start-start commentPlaceHolder positionRelative loading" />
                </label>
              </div>
            </button>
          ) : (
            <button type="button" onClick={() => { this.props.clicked ? this.props.clicked() : ''; }} className="flex-initial layout-row layout-wrap layout-align-center-start text-center btn-transparent">
              <div className="flex-initial marginBottom5px circleBorder">
                {thumbnail && thumbnail.uri && (
                  <Image
                    backgroundImage
                    src={thumbnail.uri}
                    fallbackSrc={thumbnail.uri || aspirePlaceholder[0]}
                    height={100}
                    className="circle backgroundImage"
                    color1={color1}
                    color2={color2}
                    color3={color3} />
                )}
                {!thumbnail.uri && thumbnail.text && (
                  <div
                    className="circle layout-row backgroundImage layout-wrap layout-align-center-center"
                    style={{ backgroundColor: 'rgb(38, 44, 98)' }}>
                    <span className="firstChar">{thumbnail.text || ''}</span>
                  </div>
                )}
              </div>
              {!this.props.disableName && (
                <p className="menu-title flex-100 layout-row layout-wrap layout-align-center-start textLimitWidth text-capitalize" style={{ maxWidth: `${70}px` }}>{name || ''}</p>
              )}
            </button>
          )}
        </div>
      </div>
    );
  }
}
// #20284e
const mapStateToProps = state => ({
  userInfo: state.userR.userInfo,
  clientsListKV: state.userR.clientsListKV
});

ClientIcon.defaultProps = {
  className: ''
};

ClientIcon.propTypes = {
  className: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
  nameTag: PropTypes.string,
  firstName: PropTypes.bool,
  disableName: PropTypes.bool,
  isLoading: PropTypes.bool,
  clicked: PropTypes.func,
  user: PropTypes.shape({
    public_id: PropTypes.string,
    firstName: PropTypes.string,
    thumbnail: PropTypes.object
  })
};

export default connect(mapStateToProps)(ClientIcon);
