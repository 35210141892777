import { actionTypes } from '../actions/actionTypes';
import { storeDoll, formConsts } from '../../services/storeDoll';

const initialState = {
  formState: 'login',
  step: 1,
  formInputs: [...storeDoll[formConsts.login]]
};

const multiStepForms = ['signUp'];
const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_FORM_STATE:
      let formInputs;
      if (multiStepForms.indexOf(action.payload.state) !== -1) {
        formInputs = { ...storeDoll[formConsts[action.payload.state]] };
        formInputs = [...formInputs[`step${action.payload.currentStep}`]];
      } else {
        formInputs = [...storeDoll[formConsts[action.payload.state]]];
      }
      return { ...state, formState: action.payload.state, formInputs };
    case actionTypes.GO_TO_PREV_STEP:
      formInputs = { ...storeDoll[formConsts[state.formState]] };
      if (formInputs[`step${action.payload.currentStep}`]) {
        formInputs = formInputs[`step${action.payload.currentStep}`];
      }
      return { ...state, formInputs, step: action.payload.currentStep };
    case actionTypes.GO_TO_NEXT_STEP:
      formInputs = { ...storeDoll[formConsts[state.formState]] };
      if (formInputs[`step${action.payload.currentStep}`]) {
        formInputs = formInputs[`step${action.payload.currentStep}`];
      }
      return { ...state, formInputs, step: action.payload.currentStep };
    case actionTypes.CLEAR_FORM:
      return {
        ...state, formState: null, step: 1, formInputs: [], dateInput: null
      };
    case actionTypes.DATE_INPUT_CHANGE:
      return { ...state, dateInput: action.payload };
    default:
      return state;
  }
};

export default formReducer;
