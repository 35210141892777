import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import RecurringEventConfig from './RecurringEventConfig';

const AddDescriptionToggle = props => {
  const [toggle, setToggle] = useState(false);

  const toggleChange = () => {
    if (toggle) props.clearRecurring()
    setToggle(!toggle);
  }

  return (
    <div className={`flex-100 layout-row layout-wrap layout-align-start-start ${props.className}`}>
      { !toggle && (
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <button className="btn-transparent primary fontWeight600 text-capitalize font14 sidePadd5px" onClick={() => {toggleChange()}}> + <Trans i18nKey="openRecurringOptions" /></button>
        </div>
      )}
      { toggle && (
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className="flex-100 layout-row layout-align-space-between-start marginBottom10px">
            <label className="flex-initial formBoxInput primary text-capitalize rendered valued">
              <Trans i18nKey="recurringEvent" />
            </label>
            <div className="paddStart5px flex-initial layout-row layout-wrap layout-align-start-start">
              <div className="flex-initial" onClick={() => {toggleChange()}}>
                <label className="flex-initial formBoxInput text-muted text-capitalize rendered fontWeight400 valued">
                  <Trans i18nKey="cancel" />
                </label>
              </div>
            </div>
          </div>
          <RecurringEventConfig appLang={props.appLang} setRecurringSettings={(settings)=> { props.setRecurringSettings(settings)}} recurring={props.recurring} />
        </div>
      )}
    </div>
  )
};

export default AddDescriptionToggle;
