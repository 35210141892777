import { paperworkTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse } from './index';

const setDocuments = data => ({
  type: paperworkTypes.SET_DOCUMENTS,
  payload: data
});

const setDocumentData = data => ({
  type: paperworkTypes.SET_DOCUMENT_DATA,
  payload: data
});

export const splitDocument = publicId => ({
  type: paperworkTypes.REMOVE_DOCUMENT,
  payload: publicId
});

// Documents
export const getDocuments = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.documents.getFilteredDocuments(query);
    dispatch(setDocuments(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getInitialDocuments = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.documents.getInitialDocuments(query);
    dispatch(setDocuments(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getDocumentData = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.documents.getDocumentByPublicId(publicId);
    dispatch(setDocumentData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createDocument = body => async (dispatch) => {
  try {
    return await apiMethods.documents.create(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateDocument = body => async (dispatch) => {
  try {
    return await apiMethods.documents.update(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeDocument = publicId => async (dispatch) => {
  try {
    const body = [publicId];
    await apiMethods.documents.remove(body);
    dispatch(splitDocument(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getSignedDocuments = body => async (dispatch) => {
  try {
    return await apiMethods.documents.getSignedDocuments(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const assignDocToClients = body => async (dispatch) => {
  try {
    return await apiMethods.documents.assignDocumentToClient(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const agreeToDocumentTerms = body => async (dispatch) => {
  try {
    return await apiMethods.documents.agreeToDocumentTerms(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const duplicateDocumentToTrainer = publicId => async (dispatch) => {
  try {
    const body = { publicId };
    return await apiMethods.documents.duplicateDocumentToTrainer(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

// Surveys
const setSurveys = data => ({
  type: paperworkTypes.SET_SURVEYS,
  payload: data
});

const setSurveyData = data => ({
  type: paperworkTypes.SET_SURVEY_DATA,
  payload: data
});

export const splitSurvey = publicId => ({
  type: paperworkTypes.REMOVE_SURVEY,
  payload: publicId
});

const setSurveyFillData = data => ({
  type: paperworkTypes.SET_SURVEY_FILL_DATA,
  payload: data
});

export const getInitialSurveys = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.survey.getInitialSurveys(query);
    dispatch(setSurveys(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getSurveys = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.survey.getFilteredSurveys(query);
    dispatch(setSurveys(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getSurveyData = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.survey.getSurveyByPublicId(publicId);
    dispatch(setSurveyData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createSurvey = body => async (dispatch) => {
  try {
    return await apiMethods.survey.create(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateSurvey = body => async (dispatch) => {
  try {
    const result = true;
    await apiMethods.survey.update(body);
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeSurvey = publicId => async (dispatch) => {
  try {
    const body = [publicId];
    await apiMethods.survey.remove(body);
    dispatch(splitSurvey(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const duplicateSurveyToTrainer = publicId => async (dispatch) => {
  try {
    const body = { surveyId: publicId };
    return await apiMethods.survey.duplicateSurveyToTrainer(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const assignSurveyToClients = body => async (dispatch) => {
  try {
    return await apiMethods.survey.assignSurveyToClients(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

// Filled Surveys
export const getFilledSurveys = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.surveyFill.getFilteredSurveyFills(query);
    dispatch(setSurveys(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getSurveyFillData = id => async (dispatch) => {
  try {
    const data = await apiMethods.surveyFill.getSurveyFillById(id);
    dispatch(setSurveyFillData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createSurveyFill = body => async (dispatch) => {
  try {
    const result = true;
    await apiMethods.surveyFill.createFill(body);
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateSurveyFill = body => async (dispatch) => {
  try {
    const result = true;
    await apiMethods.surveyFill.updateFill(body);
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const completeSurveyFill = body => async (dispatch) => {
  try {
    const result = true;
    await apiMethods.surveyFill.completeSurveyFill(body);
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeSurveyFill = id => async (dispatch) => {
  try {
    const body = [id];
    await apiMethods.surveyFill.removeSurveyFill(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const completeClientWelcome = body => async (dispatch) => {
  try {
    await apiMethods.surveyFill.completeClientWelcome(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
