import { langs } from '../../../services/translationLangs';
const aspireIcon = 'https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogoName_600_600.jpg';

const TRAINER = {
  introduction: (user, appLang) => ({
    key: 'trainerIntroduction',
    thumbnail: { uri: 'https://thumbs-dev.aspire.co.il/360xAUTO/Users/USR-qUsgrynFX2Mqrkj/1616787212388_alice_3.jpg'},
    title: langs[appLang].welcomeToAspire(user.firstName),
    content: 'aspireTrainerIntroduction_sub',
    buttons: [
/*
      {
        text: 'aspireIntroductionVideoTrainer',
        funcName: 'handleMediaOption',
        media: {
          type: 'video',
          isPortrait: true,
          sourceHls: {
            master: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_playlist.m3u8',
            h360: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_360.m3u8',
            h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_480.m3u8',
            h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_720.m3u8',
            h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_1080.m3u8'
          }
        },
      },
*/
      {
        text: 'createAWProgram',
        funcName: 'goToPage',
        url: '/workouts/create/details'
      },
      {
        text: 'noThankYou',
        funcName: 'cancel',
        classBtnText: 'grayText'
      }
    ],
    // showCheckBox: true
  }),
  workoutProgram: (user) => ({
    key: 'trainerIntroduction',
    title: 'welcome',
    content: 'welcome_sub',
    options: [
      {
        title: 'welcomeAspireClip',
        type: 'video',
        icon: 'la-photo-video',
        isPortrait: true,
        sourceHls: {
          master: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_playlist.m3u8',
          h360: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_360.m3u8',
          h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_480.m3u8',
          h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_720.m3u8',
          h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_1080.m3u8'
        }
      },
      {
        title: 'profileOverview',
        type: 'video',
        icon: 'la-user',
        isPortrait: false,
        sourceHls: {
          master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/profile_overview_playlist.m3u8',
          h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/profile_overview_480.m3u8',
          h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/profile_overview_720.m3u8',
          h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/profile_overview_1080.m3u8',
        }
      },
      /*  {
            title: 'inviteClientOverview',
            icon: 'la-user-plus',
            type: 'video',
            source: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/cardio/Cardio+31531201+Side+Kick+Burpee.mp4'
          }, */
      {
        title: 'programOverview',
        icon: 'la-file-alt',
        type: 'video',
        isPortrait: false,
        sourceHls: {
          master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/program_overview_playlist.m3u8',
          h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/program_overview_480.m3u8',
          h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/program_overview_720.m3u8',
          h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/program_overview_1080.m3u8',
        }
      },
      /*    {
            title: 'planOverview',
            icon: 'la-calendar-day',
            type: 'video',
            source: 'https://videourl'
          }, */
      {
        title: 'achievementsOverview',
        icon: 'la-medal',
        type: 'video',
        isPortrait: false,
        sourceHls: {
          master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/achievements_overview_playlist.m3u8',
          h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/achievements_overview_480.m3u8',
          h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/achievements_overview_720.m3u8',
          h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/achievements_overview_1080.m3u8',
        }
      },
      {
        title: 'statisticsOverview',
        icon: 'la-chart-area',
        type: 'video',
        isPortrait: false,
        sourceHls: {
          master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/statistics_overview_playlist.m3u8',
          h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/statistics_overview_480.m3u8',
          h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/statistics_overview_720.m3u8',
          h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/statistics_overview_1080.m3u8',
        }
      },
      /*    {
            title: 'createMyExerciseOverview',
            icon: 'la-dumbbell',
            type: 'video',
            source: 'https://videourl'
          }, */
      {
        title: 'paperworkOverview',
        icon: 'la-signature',
        isPortrait: false,
        sourceHls: {
          master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/paperwork_overview_playlist.m3u8',
          h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/paperwork_overview_480.m3u8',
          h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/paperwork_overview_720.m3u8',
          h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/paperwork_overview_1080.m3u8',
        }
        // type: 'tutorial',
        // url: '/workouts/create/details?isTutorial=true'
      }
    ]
  })
};

const CLIENT = {
  introduction: (user, appLang) => {
    const { trainer } = user;
    let thumbnailURI = aspireIcon
    let content = 'aspireClientIntroduction_sub'
    if (trainer[0] && trainer[0].thumbnail && trainer[0].thumbnail.uri) {
      thumbnailURI = trainer[0].thumbnail.uri;
      content = 'aspireClientIntroductionTwo_sub'
    }
    return ({
      key: 'clientIntroduction',
      thumbnail: { uri: thumbnailURI },
      title: langs[appLang].welcomeToAspire(user.firstName),
      content,
      buttons: [
        {
          text: langs[appLang].aspireIntroductionVideoClient(user.firstName),
          funcName: 'handleMediaOption',
          media: {
            type: 'video',
            isPortrait: true,
            sourceHls: {
              master: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_clients_small_playlist.m3u8',
              h360: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_clients_small_360.m3u8',
              h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_clients_small_480.m3u8',
              h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_clients_small_720.m3u8',
              h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_clients_small_1080.m3u8'
            }
          },
        },
        {
          text: 'getAFreeProgramTemplate',
          funcName: 'introduceProgramToClient',
          url: '/workouts/create/details'
        },
        {
          text: 'noThankYou',
          funcName: 'cancel',
          classBtnText: 'grayText'
        }
      ],
      showCheckBox: true
    })
  }
}

const tutorials = {
  TRAINER,
  CLIENT
};

export default tutorials;
