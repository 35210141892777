/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { langs } from '../../services/translationLangs';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { showAlert3 } from '../../store/actions';
import PermissionsGate from '../UI/PermissionsGate';
import './MenuExpand.css';

class MenuExpand extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {

  }

  shouldComponentUpdate (nextProps, nextState) {
    return !!(nextProps.shouldUpdate || nextState.shouldUpdate);
  }

  expandMenu () {
    const previewDrawer = document.getElementById(this.props.menuId);
    if (previewDrawer && previewDrawer.classList.contains('expand')) {
      previewDrawer.classList.remove('expand');
    } else {
      previewDrawer.classList.add('expand');
    }
  }

  creatingContent (item) {
    if (item.title) {
      return (
        <span className="text-wordBreak"> {`${langs[this.props.appLang]['Are you sure you want to delete']} "${item.title}" ?`}</span>
      );
    }
    return (
      <span>
        <span>
          <Trans i18nKey="Are you sure you want to delete" />
        </span>
        <span className="text-wordBreak">
          <Trans i18nKey={item.otherTitle} />
        </span> ?
      </span>
    );
  }

  deleteItem (item) {
    let title = <Trans i18nKey="notice" />,
      content = this.creatingContent(item);
    const data = {
      isOpen: true,
      title,
      content,
      className: 'ModalPage',
      id: eventActions.ALERT_MODAL_3,
      closeAlertBtn: {
        className: 'button flex alertBtnBg',
        text: <Trans i18nKey="cancel" />
      },
      buttonRight: {
        text: <Trans i18nKey="confirm" />,
        onClick: () => {
          this.props.onRemove(item);
          this.props.hideAlert3();
        }
      }
    };
    this.props.showAlert3(data);
  }

  itemAction (item) {
    if (item.key === actionTypes.REMOVE) {
      this.deleteItem(item);
    } else if (this.props[item.key]) {
      this.props[item.key](item);
    }
    this.expandMenu();
  }

  render () {
    // topRight
    return (
      <div id={this.props.menuId} className={`flex MenuExpand layout-row layout-wrap layout-align-end-center zIndex10 positionRelative ${this.props.className ? this.props.className : ''}`}>
        <div className="flex-initial layout-row layout-wrap layout-align-start-start padd10 positionFixed menuIcon" onClick={() => this.expandMenu()}>
          { this.props.plusIcon ? (<i className="la la-plus font28" style={{ color: this.props.color || '#fff' }} />) : (<i className="la la-ellipsis-v font28" style={{ color: this.props.color || '#fff' }} />) }
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start menuContainer zIndex160">
          { this.props.menuList && this.props.menuList.length > 0 && this.props.menuList.map((item, index) => (
            <div key={item.text + index} className="flex-100 layout-row layout-align-start-start link">
              { item && item.show ? (
                <div className="flex-100 layout-row layout-wrap layout-align-start-start ">
                  { item && item.key === actionTypes.GO_TO_PAGE ? (
                    <PermissionsGate dismiss permission={item.permission} primaryText="access_denied_pro_up" secondaryText="access_denied_update_sub" restrictedClass="opacity-50" appLang={this.props.appLang}>
                      <Link to={`${item.url}${item.public_id ? `/${item.public_id}` : ''}`} className={`flex-100 layout-row layout-align-start-start ${item.className || ''}`}>
                        <Trans i18nKey={item.text} />
                      </Link>
                    </PermissionsGate>
                  ) : (
                    <PermissionsGate dismiss permission={item.permission} primaryText="access_denied_pro_up" secondaryText="access_denied_update_sub" restrictedClass="opacity-50" appLang={this.props.appLang}>
                      <button
                        type="button"
                        className={`btn-transparent layout-row layout-wrap layout-align-start-start ${item.className || ''}`}
                        onClick={() => { this.itemAction(item); }}
                        disabled={item.isDisabled}>
                        <span className={item.textClassName}><Trans i18nKey={item.text} /></span>
                      </button>
                    </PermissionsGate>
                  )}
                </div>
              ) : null }
            </div>
          ))}
        </div>
        {this.props.menuList && this.props.menuList.length > 0 && (
          <div className="black_back" onClick={() => this.expandMenu()} />
        )}
      </div>
    );
  }
}

MenuExpand.defaultProps = {
  menuId: 'menuId',
  shouldUpdate: false,
  plusIcon: false,
  menuList: [],
  appLang: 'en'
};

MenuExpand.propTypes = {
  menuId: PropTypes.string,
  menuList: PropTypes.arrayOf(PropTypes.object),
  shouldUpdate: PropTypes.bool,
  plusIcon: PropTypes.bool,
  color: PropTypes.string,
  appLang: PropTypes.string,
  onRemove: PropTypes.func,
  changeCurrentWeek: PropTypes.func
};

const mapStateToProps = state => ({
  alertProps: state.appR.alertProps,
  appLang: state.appR.appLang
});

const mapDispatchToProps = dispatch => ({
  showAlert3: data => dispatch(showAlert3(data)),
  hideAlert3: () => dispatch({ type: actionTypes.HIDE_ALERT3 })
});
export default connect(mapStateToProps, mapDispatchToProps)(MenuExpand);
