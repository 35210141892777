/* eslint-disable max-len, object-curly-newline */
import nanoid from 'nanoid';
import { cloneDeep } from 'lodash-es';
import {
  categoryGroups,
  workoutLogTypes,
  durationType,
  selectUnits,
  units,
  language,
  repeatEventConditions,
} from './enums';

export const formConsts = {
  // login page forms
  login: 'userLoginInputs',
  clientInvite: 'clientInviteInputs',
  forgotPassword: 'forgotPasswordInputs',
  changePassword: 'changePasswordInputs',
  profileDetails: 'profileDetailsInputs',
  // other forms
  inviteClient: 'inviteClientInputs',
  notes: 'notesInputs',
  workoutProgram: 'workoutProgramInputs',
  workoutLog: 'workoutLogInputs',
  achievement: 'achievementInputs',
  logExercises: 'logExercisesInputs',
  exerciseModel: 'exerciseModelInputs',
  categoryModel: 'categoryModelInputs',
  createPlan: 'createPlanInputs',
  createExercise: 'createExerciseInputs',
  createExerciseAdmin: 'createExerciseAdminInputs',
  createCategory: 'createCategoryInputs',
  settings: 'settingsInputs',
  imageUploadDetails: 'imageUploadDetailsInputs',
  createDocument: 'documentInputs',
};
export const bodyStatsInputsDesc = (distanceUnits, weightUnits) => ({
  weight: weightUnits === 'KG' ? 'type in kg' : 'type in lbs',
  height: distanceUnits === 'CM' ? 'type in cm' : 'type in inch',
  muscleMass: 'type in kg',
  fat: 'type in %',
  calories: 'type in Kcal',
  bmi: '',
  water: 'type in %',
});

export const stopConditions = [
  {
    containerClass: 'flex-100 marginBottom5px',
    name: 'stopConditions1',
    group: 'stopConditions',
    elementType: 'radio',
    value: false,
    classes: 'flex-initial paddEnd5px',
    text: 'neverStop',
    textInfo: 'neverStop_info',
    conditionType: repeatEventConditions.never,
  },
  {
    containerClass: 'flex-100 marginBottom5px',
    name: 'stopConditions2',
    group: 'stopConditions',
    elementType: 'radio',
    value: false,
    classes: 'flex-initial paddEnd5px',
    text: 'untilDate',
    textInfo: 'untilDate_info',
    textDateInput: true,
    conditionType: repeatEventConditions.untilDate,
  },
  {
    containerClass: 'flex-100 marginBottom5px',
    name: 'stopConditions3',
    group: 'stopConditions',
    elementType: 'radio',
    value: false,
    classes: 'flex-initial paddEnd5px',
    text: 'untilNumber',
    text2: 'times',
    textInfo: 'untilNumber_info',
    textNumberInput: true,
    conditionType: repeatEventConditions.numOfTimes,
  },
];

export const categoryModelInputs = [
  {
    name: 'group',
    description: 'select category groups',
    options: categoryGroups,
    initValue: 1,
    elementType: 'select',
    validation: {
      required: true,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'public_id',
    description: 'category public_id example: CAT-ABC231',
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddStart10px',
  },
  {
    name: 'uri',
    description: 'category uri for link - required',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'order',
    description: 'order - category order',
    initValue: '0',
    type: 'number',
    elementType: 'input',
    classes: 'flex-50 paddStart10px',
  },
  {
    name: 'title.he',
    description: 'title.he - category name {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'title.en',
    description: 'title.en - category name {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    name: 'subtitle.he',
    description: 'subtitle.he - category subtitle {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'subtitle.en',
    description: 'subtitle.en - category subtitle {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    name: 'description.he',
    description: 'description.he - category description {HE}',
    type: 'text',
    elementType: 'textarea',
    config: {
      rows: 4,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'description.en',
    description: 'description.en - category description {EN}',
    type: 'text',
    elementType: 'textarea',
    config: {
      rows: 4,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    name: 'thumbnail.uri',
    description: 'Category thumbnail',
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'backgroundImage.uri',
    description: 'Category background Image',
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddStart10px',
  },
  {
    name: 'readOnly',
    description: 'Category readOnly',
    initValue: false,
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'muscleId',
    label: 'Muscle Name',
    description: 'muscleId - muscleId for SVG image',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 2,
    },
    classes: 'flex-50 paddStart10px',
  },
];
export const createExerciseInputs = [
  {
    label: 'title',
    name: 'title.en',
    placeholder: 'type in exercise title',
    description: 'title.he - exercise title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
      required: true,
    },
    classes: 'flex-100',
  },
  {
    label: 'description',
    name: 'description.en',
    description: 'description.he - exercise description {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 2,
    },
    classes: 'flex-100',
  },
  {
    label: 'execution',
    name: 'execution.en',
    description: 'execution.he - exercise execution {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3,
    },
    classes: 'flex-100',
  },
];
export const createExerciseAdminInputs = [
  {
    label: 'public_id',
    name: 'public_id',
    description: 'public_id - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-100',
  },
  {
    label: 'title_en',
    name: 'title.en',
    description: 'title.en - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    label: 'title_he',
    name: 'title.he',
    placeholder: 'type in exercise title',
    description: 'title.he - exercise title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    label: 'description en',
    name: 'description.en',
    description: 'description.he - exercise description {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 2,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    label: 'description',
    name: 'description.he',
    description: 'description.he - exercise description {HE}',
    elementType: 'textarea', // textarea
    config: {
      rows: 2,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    label: 'preparation en',
    name: 'preparation.en',
    description: 'description.he - exercise preparation {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    label: 'preparation',
    name: 'preparation.he',
    description: 'description.he - exercise preparation {HE}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    label: 'execution en',
    name: 'execution.en',
    description: 'description.he - exercise execution {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    label: 'execution',
    name: 'execution.he',
    description: 'description.he - exercise execution {HE}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3,
    },
    classes: 'flex-50 paddStart10px',
  },
];
export const createCategoryInputs = [
  {
    label: 'public_id',
    name: 'public_id',
    description: 'public_id - Category public_id',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    label: 'uri',
    name: 'uri',
    description: 'uri - Category uri',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    label: 'title_en',
    name: 'title.en',
    description: 'title.en - Category title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    label: 'title_he',
    name: 'title.he',
    placeholder: 'type in Category title',
    description: 'title.he - Category title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    label: 'order',
    name: 'order',
    description: 'order - Category order',
    type: 'number',
    elementType: 'input',
    config: {
      rows: 2,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    label: 'muscleId',
    name: 'muscleId',
    placeholder: 'muscleId',
    description: 'muscleId - Category muscleId',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 2,
    },
    classes: 'flex-50 paddStart10px',
  },
  {
    label: 'group',
    name: 'group',
    placeholder: 'group',
    description: 'group - Category group',
    options: categoryGroups,
    elementType: 'select',
    validation: {
      required: true,
    },
    classes: 'flex-100',
  },
];
export const exerciseModelInputs = [
  {
    name: 'title.he',
    description: 'title.he - exercise title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-100',
  },
  {
    name: 'title.en',
    description: 'title.en - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-100',
  },
  {
    name: 'description.he',
    description: 'description.he - exercise description {HE}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4,
    },
    classes: 'flex-100',
  },
  {
    name: 'description.en',
    description: 'description.en - exercise description {EN}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4,
    },
    classes: 'flex-100',
  },
  {
    name: 'preparation.he',
    description: 'description.he - exercise preparation {HE}',

    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4,
    },
    classes: 'flex-100',
  },
  {
    name: 'preparation.en',
    description: 'description.he - exercise preparation {HE}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4,
    },
    classes: 'flex-100',
  },
  {
    name: 'execution.he',
    description: 'description.en - exercise execution {HE}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4,
    },
    classes: 'flex-100',
  },
  {
    name: 'execution.en',
    description: 'description.en - exercise execution {EN}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4,
    },
    classes: 'flex-100',
  },
  {
    label: 'searchKeywords',
    name: 'searchKeywords',
    description: 'exercise search Keywords',
    type: 'text',
    elementType: 'input',
    classes: 'flex-100',
  },
  {
    name: 'thumbnail.className',
    description: 'className - exercises musckeId classes',
    type: 'text',
    elementType: 'input',
    classes: 'flex-100',
  },
  {
    name: 'thumbnail.previewImg',
    description: 'SVG image 1 / 2',
    type: 'Number',
    elementType: 'input',
    classes: 'flex-100',
  },
];
export const workoutProgramInputs = [
  {
    label: 'title',
    name: 'title',
    description: 'workoutProgram title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-100',
  },
  /* {
    label: 'description',
    name: 'description',
    description: 'workoutProgram description',
    elementType: 'textarea',
    config: {
      rows: 4
    },
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  } */
];
export const workoutLogInputs = [
  {
    label: 'type',
    name: 'type',
    description: 'select workoutLog groups',
    options: workoutLogTypes,
    elementType: 'select',
    validation: {
      required: true,
    },
    classes: 'flex-100',
  },
  {
    label: 'exercise',
    name: 'exercise',
    description: 'exercise Select async!',
    isMulti: true,
    loadOptions: 'exerciseList',
    type: 'multiSelect',
    elementType: 'multiSelectAsync',
    classes: 'flex-100',
  },
];
export const logExercisesInputs = [
  {
    label: 'sets',
    name: 'sets',
    description: 'exercise - sets',
    initValue: 3,
    type: 'number',
    elementType: 'input',
    classes: 'flex paddStart10px',
  },
  {
    label: 'reps',
    name: 'reps',
    description: 'exercise - reps',
    initValue: 8,
    type: 'number',
    elementType: 'input',
    classes: 'flex paddStart10px',
  },
  {
    label: 'weight',
    name: 'weight',
    description: 'exercise - weight',
    initValue: 60,
    type: 'number',
    elementType: 'input',
    classes: 'flex paddStart10px',
  },
  /*     {
            name: 'feels',
            description: 'exercise - feels',
            value: 1, // 1 - 10
            type: 'number',
            elementType: 'input'
        },
        {
            name: 'time',
            description: 'exercise - time (milliseconds)',
            value: 1000,
            type: 'number',
            elementType: 'input'
        } */
  /* need to add - exercise select , notes */
];
export const bodyStatsInputs = {
  height: {
    label: 'height',
    name: 'height',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 30,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  weight: {
    label: 'weight',
    name: 'weight',
    description: 'type in kg',
    type: 'number', // in kg or lbs
    validation: {
      min: 5,
      step: 0.1,
    },
    measurements: units.weightUnits.KG,
  },
  muscleMass: {
    label: 'muscleMass',
    name: 'muscleMass',
    description: 'type in kg',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 5,
      step: 0.1,
    },
    measurements: units.weightUnits.KG,
  },
  fat: {
    label: 'fat',
    name: 'fat',
    description: 'Type in %',
    type: 'number', // in %
    validation: {
      min: 1,
      max: 100,
    },
    measurements: units.other.PERCENT,
  },
  water: {
    label: 'water',
    name: 'water',
    description: 'Type in %',
    type: 'number', // in %
    validation: {
      min: 5,
      max: 100,
    },
    measurements: units.other.PERCENT,
  },
  calories: {
    label: 'calories',
    name: 'calories',
    description: 'Type in Kcal',
    type: 'number', // in num
    elementType: 'input',
    validation: {
      min: 5,
    },
    measurements: units.other.KCAL,
  },
  bmi: {
    name: 'bmi',
    label: 'bmi',
    type: 'number', // will calc on its own
    validation: {
      min: 0,
    },
    measurements: units.other.BLANK,
  },
};
export const bodyMeasurementsBasicInputs = {
  shoulders: {
    label: 'shoulders',
    name: 'shoulders',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  arms: {
    label: 'arms',
    name: 'arms',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  chest: {
    label: 'chest',
    name: 'chest',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  waist: {
    label: 'waist',
    name: 'waist',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  hips: {
    label: 'hips',
    name: 'hips',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  thighs: {
    label: 'thighs',
    name: 'thighs',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  lowerThighs: {
    label: 'lowerThighs',
    name: 'lowerThighs',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  calves: {
    label: 'calves',
    name: 'calves',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
};
export const bodyMeasurementsAdvancedInputs = {
  shoulders: {
    label: 'shoulders',
    name: 'shoulders',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  rightArm: {
    label: 'rightArm',
    name: 'rightArm',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  leftArm: {
    label: 'leftArm',
    name: 'leftArm',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  chest: {
    label: 'chest',
    name: 'chest',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  waist: {
    label: 'waist',
    name: 'waist',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  hips: {
    label: 'hips',
    name: 'hips',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  leftThigh: {
    label: 'leftThigh',
    name: 'leftThigh',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  rightThigh: {
    label: 'rightThigh',
    name: 'rightThigh',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  leftLowerThigh: {
    label: 'leftLowerThigh',
    name: 'leftLowerThigh',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  rightLowerThigh: {
    label: 'rightLowerThigh',
    name: 'rightLowerThigh',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  leftCalf: {
    label: 'leftCalf',
    name: 'leftCalf',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
  rightCalf: {
    label: 'rightCalf',
    name: 'rightCalf',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 1,
      step: 0.1,
    },
    measurements: units.distanceUnits.CM,
  },
};

export const userLoginInputs = [
  {
    name: 'email',
    label: 'email',
    description: 'User Email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-100',
  },
  {
    name: 'password',
    label: 'password',
    description: 'User password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6,
    },
    classes: 'flex-100',
  },
];
export const forgotPasswordInputs = [
  {
    name: 'email',
    label: 'email',
    description: 'User Email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-100',
  },
];

export const userRegisterInputsNew = [
  {
    name: 'firstName',
    label: 'firstName',
    description: 'User - First Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'lastName',
    label: 'lastName',
    description: 'User - Last Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2,
    },
    classes: 'flex-50 paddStart5px',
  },
];

export const userRegisterInputsEmail = [
  {
    name: 'password',
    label: 'password',
    description: 'User password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 7,
    },
    classes: 'flex-100',
  },
  {
    name: 'confirmPassword',
    label: 'confirmPassword',
    description: 'User confirm password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 7,
    },
    classes: 'flex-100',
  },
];

export const profileDetailsInputs = [
  {
    name: 'firstName',
    label: 'firstName',
    description: 'User - First Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2,
    },
    classes: 'flex-100 paddStart5px marginBottom5px',
  },
  {
    name: 'lastName',
    label: 'lastName',
    description: 'User - Last Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2,
    },
    classes: 'flex-100 paddStart5px marginBottom5px',
  },
  {
    name: 'birthDate',
    label: 'birthDate',
    description: 'User - birthDate',
    elementType: 'datePicker',
    config: {
      placement: 'bottom',
    },
    classes: 'flex-100 paddStart5px marginBottom5px',
  },
  {
    label: 'aboutMe',
    name: 'about',
    description: 'aboutMe description',
    elementType: 'textarea',
    config: {
      rows: 3,
    },
    validation: {
      minLength: 3,
      maxLength: 500,
    },
    classes: 'flex-100',
  },
  /* {
    label: 'bio',
    name: 'biography',
    description: 'user biography',
    elementType: 'quill-text',
    config: {
      rows: 6
    },
    validation: {
      minLength: 3,
      maxLength: 2000
    },
    classes: 'flex-100'
  } */
];
export const socialAccountsInputs = [
  {
    name: 'social.facebook',
    label: 'facebook',
    description: 'social facebook',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-100',
  },
  {
    name: 'social.instegram',
    label: 'instegram',
    description: 'social instegram',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-100',
  },
  {
    name: 'social.youtube',
    label: 'youtube',
    description: 'social youtube',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-100',
  },
];

export const inviteClientInputs = [
  {
    name: 'firstName',
    label: 'firstName',
    description: 'User - First Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2,
    },
    classes: 'flex-50 paddEnd10px',
  },
  {
    name: 'lastName',
    label: 'lastName',
    description: 'User - Last Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2,
    },
    classes: 'flex-50 paddStart5px',
  },
];

export const inviteClientInputsOld = {
  step1: [
    {
      name: 'firstName',
      label: 'firstName',
      description: 'User - First Name',
      type: 'text',
      elementType: 'input',
      validation: {
        required: true,
        minLength: 2,
      },
      classes: 'flex-50 paddEnd10px',
    },
    {
      name: 'lastName',
      label: 'lastName',
      description: 'User - Last Name',
      type: 'text',
      elementType: 'input',
      validation: {
        required: true,
        minLength: 2,
      },
      classes: 'flex-50 paddStart5px',
    },
    /* {
      name: 'birthDate',
      label: 'birthDate',
      description: 'User - birthDate',
      elementType: 'datePicker',
      config: {
        placement: 'bottom'
      },
      classes: 'flex-100'
    } */
    // email
    // gender
  ],
  step2: [
    {
      name: 'weightUnits',
      label: 'weightUnits',
      description: 'User - weight Units settings',
      options: selectUnits.weightUnits,
      initValue: 'KG',
      elementType: 'select',
      validation: {
        required: true,
      },
      classes: 'flex-50 paddEnd5px',
    },
    {
      name: 'distanceUnits',
      label: 'distanceUnits',
      description: 'User - Distance Units settings',
      elementType: 'select',
      initValue: units.distanceUnits.CM,
      options: selectUnits.distanceUnits,
      validation: {
        required: true,
      },
      classes: 'flex-50 paddStart5px',
    },
  ],
  step3: [],
};

export const clientInviteInputs = [
  {
    label: 'email',
    name: 'email',
    description: 'User Email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-100',
  },
  {
    label: 'inviteCode',
    name: 'inviteCode',
    description: 'client inviteCode',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6,
    },
    classes: 'flex-100',
  },
];
export const changePasswordInputs = [
  {
    name: 'oldPassword',
    label: 'oldPassword',
    placeholder: 'typeOldPassword',
    description: 'User oldPassword',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 7,
    },
    classes: 'flex-100',
  },
  {
    name: 'newPassword',
    label: 'newPassword',
    placeholder: 'typeNewPassword',
    description: 'User password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 7,
    },
    classes: 'flex-100',
  },
  {
    name: 'confirmPassword',
    label: 'confirmPassword',
    description: 'User confirm password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 7,
    },
    classes: 'flex-100',
  },
];
export const createPlanInputs = [
  {
    label: 'duration',
    name: 'duration.value',
    comment: 'numOfWeeks',
    placeholder: '4',
    labelClasses: 'font15 text-center',
    type: 'number',
    elementType: 'input',
    validation: {
      required: true,
      min: 0,
      max: 106,
    },
    labelBottom: true,
    classes:
      'flex-50 layout-column layout-align-center-center bigInputContainer text-center sidePadd5px',
  },
  {
    label: 'workoutPerWeek',
    name: 'workoutPerWeek.value',
    placeholder: '3',
    comment: 'numOfTimes',
    labelClasses: 'font15 text-center',
    type: 'number',
    elementType: 'input',
    validation: {
      required: true,
      min: 0,
      max: 30,
    },
    labelBottom: true,
    // comment: 'Times',
    classes:
      'flex-50 layout-column layout-align-center-center text-center bigInputContainer sidePadd5px',
  },
  {
    label: 'startDate',
    name: 'startDate',
    description: 'Plan - startDate',
    elementType: 'datePicker',
    // initValue: '2019-09-29',
    config: {
      placement: 'bottom',
    },
    validation: {
      required: true,
    },
    classes: 'flex-100',
  },
  {
    label: 'title',
    name: 'title',
    description: 'Plan title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-100',
  },
  {
    label: 'description',
    name: 'description',
    description: 'Plan description',
    elementType: 'textarea',
    config: {
      rows: 4,
    },
    validation: {
      minLength: 3,
    },
    classes: 'flex-100',
  },
  /*
        {
          name: 'workoutDuration.value',
          config: {
            placeholder: '#minutes'
          },
          initValue: 60,
          type: 'number',
          elementType: 'input',
          validation: {
            min: 0,
            max: 360
          },
          label: 'Workout Duration',
          comment: 'Minutes',
          classes: 'flex-33 layout-column layout-align-center-center text-center'
        }
    */
  // thumbnail
  // reviews
  // category
  // users
];
export const notesInputs = [
  {
    label: 'order',
    name: 'order',
    description: 'note - order',
    initValue: 1,
    type: 'number',
    elementType: 'input',
    validation: {
      step: 0.5,
      max: 99,
      min: 0,
      required: true,
    },
    classes: 'flex-30 paddEnd10px',
  },
  {
    label: 'content',
    name: 'content',
    description: 'note - content',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-70 paddStart10px',
  },
];
export const exNotesInputs = [
  {
    label: 'content',
    name: 'content',
    description: 'note - content',
    placeholder: 'please write your notes here',
    type: 'text',
    elementType: 'textarea',
    validation: {
      required: true,
      minLength: 3,
    },
    config: {
      id: 'exNotesInputsContent',
      rows: 2,
    },
    classes: 'flex-100',
  },
];
export const exNotesInputsNoneRequired = [
  {
    label: 'content',
    name: 'content',
    description: 'note - content',
    placeholder: 'please write your notes here',
    type: 'text',
    elementType: 'textarea',
    validation: {
      minLength: 3,
    },
    config: {
      id: 'exNotesInputsContent',
      rows: 2,
    },
    classes: 'flex-100',
  },
];
export const achievementInputs = [
  {
    label: 'title',
    name: 'title',
    description: 'achievement title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-100',
  },
  {
    label: 'description',
    name: 'description',
    description: 'achievement description',
    elementType: 'textarea',
    config: {
      rows: 3,
      maxLength: 80,
    },
    validation: {
      minLength: 3,
    },
    classes: 'flex-100',
  },
  {
    label: 'startDate',
    name: 'startDate',
    description: 'achievement - startDate',
    elementType: 'datePicker',
    // initValue: '2019-09-29',
    config: {
      placement: 'bottom',
    },
    validation: {
      required: true,
    },
    classes: 'flex-100',
  },
  /* {
    label: 'endDate',
    name: 'endDate',
    description: 'achievement - endDate',
    elementType: 'datePicker',
    // initValue: '2019-09-29',
    config: {
      placement: 'bottom'
    },
    classes: 'flex-50 paddStart5px'
  },
  {
    name: 'none',
    elementType: 'none',
    classes: 'flex-50 paddStart5px'
  }, */
  {
    label: 'duration',
    name: 'duration.value',
    config: {
      placeholder: 'duration',
    },
    initValue: 4,
    type: 'number',
    elementType: 'input',
    validation: {
      min: 0,
      max: 100,
      required: true,
    },
    // comment: 'Weeks',
    classes: 'flex-50 paddEnd5px',
  },
  {
    label: ' ',
    name: 'duration.durationType',
    options: durationType,
    initValue: 'weeks',
    elementType: 'select',
    validation: {
      required: true,
    },
    classes: 'flex-50 paddStart5px',
  },
];
export const goalBestTimeInputs = [
  {
    label: 'minutes',
    name: 'minutes',
    placeholder: '00',
    description: 'minutes',
    type: 'number', // in kg or lbs
    validation: {
      min: 0,
      step: 1,
      max: 999,
      maxLength: 3,
      pattern: '.{0}|.{1,3}',
      required: true,
    },
    measurements: 'KG',
    classes: 'flex-50',
  },
  {
    label: 'seconds',
    name: 'seconds',
    placeholder: '00',
    type: 'number', // in kg or lbs
    validation: {
      min: 0,
      step: 1,
      max: 59,
      pattern: '.{0}|.{1,2}',
      maxLength: 2,
      required: true,
    },
    classes: 'flex-50',
  },
];
export const achievementsFormInputs = {
  reps: {
    label: 'reps',
    name: 'reps',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 1,
      step: 0,
      required: true,
    },
    classes: 'flex paddEnd5px',
  },
  weight: {
    label: 'weight',
    name: 'weight',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 0,
      required: true,
    },
    measurements: 'KG',
    classes: 'flex paddStart5px',
  },
  value: {
    name: 'value',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 1,
      required: true,
    },
    measurements: 'KG',
    classes: 'flex-100',
  },
  timeDone: {
    label: 'time',
    name: 'timeDone',
    description: 'timeDone timer',
    elementType: 'timeInput',
    classes: 'flex-100',
  },
  distance: {
    label: 'distance',
    name: 'distance',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 0,
      required: true,
    },
    measurements: 'M',
    classes: 'flex-100',
  },
  setX: {
    name: 'setX',
    elementType: 'setX',
    classes: 'flex-initial',
  },
};
export const settingsInputs = [
  {
    name: 'unitsSettings',
    label: 'weightUnits',
    description: 'User - Units settings',
    options: selectUnits,
    initValue: 'KG_CM',
    elementType: 'select',
    validation: {
      required: true,
    },
    classes: 'flex-100',
  },
];
export const changePasswordModalInputs = [
  {
    name: 'newPassword',
    label: 'newPassword',
    description: 'User password',
    placeholder: 'password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 7,
    },
    classes: 'flex-100 marginBottom10px',
  },
  {
    name: 'confirmPassword',
    label: 'confirmPassword',
    placeholder: 'confirmPassword',
    description: 'User confirm password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 7,
    },
    classes: 'flex-100',
  },
];
export const imageUploadDetailsInputs = [
  {
    name: 'title',
    label: 'title',
    description: 'image - title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2,
    },
    classes: 'flex-100',
  },
  {
    label: 'description',
    name: 'description',
    description: 'image description',
    elementType: 'textarea',
    config: {
      rows: 3,
      maxLength: 500,
    },
    validation: {
      minLength: 3,
    },
    classes: 'flex-100',
  },
];
export const equipmentCatInputs = [
  {
    label: 'title',
    name: 'title.he',
    description: 'muscle - title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-33 paddStart10px',
  },
  {
    label: 'public_id',
    name: 'public_id',
    description: 'muscle - public_id',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-33 sidePadd10',
  },
  {
    label: 'video',
    name: 'video',
    description: 'video',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
    },
    classes: 'flex-33',
  },
];
export const performanceCubes = {
  totalTonnage: {
    label: 'tonnageLifted',
    name: 'totalTonnage',
    measurements: 'KG',
    classNames: 'flex-50 padd2 fadeInBottom-anime',
  },
  totalWorkouts: {
    label: 'workoutsCompleted',
    name: 'totalWorkouts',
    classNames: 'flex-50 padd2 fadeInBottom-anime',
  },
  totalReps: {
    label: 'totalReps',
    name: 'totalReps',
    classNames: 'flex-50 padd2 fadeInBottom-anime',
  },
  totalSets: {
    label: 'totalSets',
    name: 'totalSets',
    classNames: 'flex-50 padd2 fadeInBottom-anime',
  },
  /*
  liftTime: {
    label: 'liftingTime',
    name: 'liftTime',
    measurements: 'h/min',
    classNames: 'flex-33 padd2 fadeInBottom-anime'
  },
  avgSessionDuration: {
    label: 'avgWorkoutTime',
    name: 'avgSessionDuration',
    measurements: 'min',
    classNames: 'flex-33 padd2 fadeInBottom-anime'
  },
  avgRestTime: {
    label: 'avgRestTime',
    name: 'avgRestTime',
    measurements: 'min',
    classNames: 'flex-33 padd2 fadeInBottom-anime'
  }
   */
};
export const paperWorkCubes = {
  documentsFilled: {
    label: 'documents',
    name: 'documentsNum',
    classNames: 'flex-50 padd2 fadeInBottom-anime',
  },
  documentsTemplates: {
    label: 'templates',
    name: 'templates',
    classNames: 'flex-50 padd2 fadeInBottom-anime',
  },
  /*
  avgFillDuration: {
    label: 'avgFillDuration',
    name: 'fillDuration',
    measurements: 'min',
    classNames: 'flex-50 padd2 fadeInBottom-anime'
  }
  */
};
export const documentInputs = [
  {
    name: 'title',
    label: 'title',
    description: 'Document - title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3,
    },
    classes: 'flex-65 paddEnd10px',
  },
  {
    name: 'language',
    label: 'language',
    description: 'Document language',
    options: language,
    initValue: 'en',
    elementType: 'select',
    validation: {
      required: true,
    },
    classes: 'flex-35',
  },
  {
    label: 'content',
    name: 'content',
    description: 'Document content',
    elementType: 'quill-text',
    config: {
      rows: 3,
    },
    validation: {
      required: true,
      minLength: 3,
      maxLength: 500,
    },
    classes: 'flex-100',
  },
  {
    name: 'isShowAgreeCheckBox',
    label: 'isShowAgreeCheckBox',
    description: 'Document - Show Agree Check Box',
    elementType: 'checkBox',
    clickText: true,
    text: 'addCheckboxToConfirmAgreement',
    isSelected: true,
    classes: 'flex-100',
  },
  {
    name: 'isAgreeText',
    label: 'isAgreeText',
    description: 'Document - isAgreeText',
    placeholder: 'I agree to the terms/confirm...',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3,
    },
    classes: 'flex-100 borderBottomGrey marginBottom10px paddBottom10px',
  },
  {
    name: 'isClientCanModify',
    label: 'isClientCanModify',
    description: 'Document - Client Can Modify Doc',
    elementType: 'checkBox',
    clickText: true,
    text: 'isClientCanModifyDoc',
    isSelected: false,
    classes: 'flex-100 ',
  },
];
export const storeDoll = {
  categoryModelInputs,
  exerciseModelInputs,
  workoutProgramInputs,
  workoutLogInputs,
  logExercisesInputs,
  bodyStatsInputsDesc,
  bodyStatsInputs,
  notesInputs,
  userLoginInputs,
  inviteClientInputs,
  clientInviteInputs,
  createPlanInputs,
  createExerciseInputs,
  createExerciseAdminInputs,
  createCategoryInputs,
  achievementInputs,
  forgotPasswordInputs,
  changePasswordInputs,
  profileDetailsInputs,
  imageUploadDetailsInputs,
  documentInputs,
};
export const newLogObj = (settings) => ({
  straight: {
    order: 1,
    remove: false,
    reps: 8,
    weight: settings && settings.weightUnits === units.weightUnits.LBS ? 20.41 : 20,
    rest: 90000,
    distance: 0,
  },
  superset: {
    order: 1,
    remove: false,
    reps: 8,
    weight: settings && settings.weightUnits === units.weightUnits.LBS ? 20.41 : 20,
    rest: 90000,
    distance: 0,
  },
  aerobic: {
    order: 1,
    remove: false,
    time: 60000, // milliseconds
    speed: 1,
    intensity: 1,
    distance: settings && settings.distanceUnits === units.distanceUnits.INCH ? 91.43 : 100, // need to be checked -> inches or feet?
    rest: 90000,
  },
  static: {
    order: 1,
    remove: false,
    time: 60000, // milliseconds
    weight: 0,
    rest: 90000,
  },
  custom: {
    order: 1,
    remove: false,
    reps: 8,
    time: 60000, // milliseconds
    weight: 20,
    speed: 1,
    intensity: 1,
    distance: settings && settings.distanceUnits === units.distanceUnits.INCH ? 91.43 : 100, // need to be checked -> inches or feet?
    rest: 90000,
  },
});

export const serverStatus = {
  100: 'Continue',
  101: 'Switching Protocols',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Time-out',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Large',
  415: 'Unsupported Media Type',
  416: 'Requested Range not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Time-out',
  505: 'HTTP Version not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  SWITCH_PROXY: 306,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  REQUEST_ENTITY_TOO_LARGE: 413,
  REQUEST_URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};
export const nanoidOption = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export const nanoidOptionAbc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export const professionsOptions = [
  { value: 'Personal Trainer', label: 'Personal Trainer'},
  { value: 'Yoga Instructor', label: 'Yoga Instructor' },
  { value: 'Pilates Instructor', label: 'Pilates Instructor' },
  { value: 'TRX Instructor', label: 'TRX Instructor' },
  { value: 'Nutritionist', label: 'Nutritionist' },
  { value: 'Boxing', label: 'Boxing' },
  { value: 'Weight Lifting', label: 'Weight Lifting'},
  { value: 'Elder Training', label: 'Elder Training' },
  { value: 'Training Kids', label: 'Training Kids' },
  { value: 'Pregnancy Training Expert', label: 'Pregnancy Training Expert' },
  { value: 'TRX', label: 'TRX' },
  { value: 'מאמנ/ת כושר', label: 'מאמנ/ת כושר'},
  { value: 'מדריכ/ת יוגה', label: 'יוגה' },
  { value: 'מדריכ/ת פילאטס', label: 'מדריכ/ת פילאטיס' },
  { value: 'מאמנ/ת TRX', label: 'מאמנ/ת TRX' },
  { value: 'תזונאי/ת', label: 'תזונאי/ת' },
  { value: 'איגרוף', label: 'איגרוף' },
  { value: 'הרמות כוח', label: 'הרמות כוח' },
  { value: 'אימון גיל הזהב', label: 'אימון גיל הזהב' },
  { value: 'אימון ילדים', label: 'אימון ילדים' },
  { value: 'אימון נשים בהריון', label: 'אימון נשים בהריון' },
  { value: 'אימון אנשים בעלי מוגבלות', label: 'אימון אנשים בעלי מוגבלות' },
];
const nano = nanoid(6);
export const uploadSvgIcon = 'https://aspire123.s3.eu-central-1.amazonaws.com/assets/upload_image_simple.svg';
export const initialOption = {
  id: nanoid(6),
  order: 1,
  isSelected: false,
  elementType: 'input',
  type: 'text',
  label: { en: '', he: '' },
  value: null, // mixed
  group: `optionGroup_${nano}`,
  validation: {
    minLength: 2,
    maxLength: 500,
    rows: 3,
  },
  condition: false,
  // content: null
};
export const questionInitial = {
  id: nano,
  order: 1,
  question: { en: '', he: '' },
  // description: { en: null, he: null },
  // comment: { en: null, he: null },
  isRequired: false,
  group: `group_${nano}`,
  options: [cloneDeep(initialOption)],
};
export const imageExamplesList = [
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_34.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_29.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_10.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_8.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_36.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_31.jpg',
];

export const mediaProps = {
  carousel: {
    shouldUpdate: false,
    title: 'media',
    cardSize: { height: 170 },
    isCarousel: true,
    data: [],
  },
};
