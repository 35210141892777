import React from 'react';
import ReactSVG from 'react-svg';
import LoadingDots from './LoadingDots';

const ThumbnailUI = (props) => {
  let isSvg = false;
  if (props.src.indexOf('.svg') !== -1) {
      isSvg = true;
  }
  return (
    <div
      className="ThumbnailUI width100 layout-row layout-wrap layout-align-start-start"
      onClick={() => (props.clicked ? props.clicked() : null)}>
      <div className="width100 layout-row layout-wrap layout-align-center-center">
        { isSvg ? (
          <ReactSVG
            className={props.className}
            src={props.src}
            fallback={() => <span />}
            loading={() => (
              <LoadingDots active />
            )} />
        ) : (
          <img src={props.src} alt="" className={props.className} />
        )}
      </div>
    </div>
  );
};

export default ThumbnailUI;
