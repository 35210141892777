import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import Input from './Input';

const AddDescriptionToggle = props => {

  const [toggle, setToggle] = useState(!!((props && props.initValue) || props.alwaysOpen));

  const toggleDesc = () => {
    setToggle(!toggle);
    // props.setColor(color)
  }

  return (
    <div className={`flex-100 layout-row layout-wrap layout-align-start-start ${props.className}`}>
      { !toggle && (
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <button className="btn-transparent primary fontWeight600 text-capitalize font14 sidePadd5px" onClick={() => {toggleDesc()}}><Trans i18nKey={props.addLabel || "addDescription"} /> + </button>
        </div>
      )}
      { toggle && (
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className="flex-100 layout-row layout-align-space-between-start">
            <label className={`flex-initial formBoxInput primary text-capitalize rendered valued ${props.labelClass}`}>
              <Trans i18nKey={props.label || 'description'} />
            </label>
            <div className={`paddStart5px flex-initial layout-row layout-wrap layout-align-start-start ${props.alwaysOpen ? 'displayNone' : ''}`}>
              <div className="flex-initial" onClick={() => {toggleDesc()}}>
                <label className="flex-initial formBoxInput text-muted text-capitalize rendered fontWeight400 valued">
                  <Trans i18nKey="hide" />
                </label>
              </div>
            </div>
          </div>
          <Input
            noLabel
            name="description"
            label={props.label || 'description'}
            placeholder={props && props.placeholder  || 'description'}
            initValue={props && props.initValue  || ''}
            type="text"
            elementType="textarea"
            validation={{ minLength: 3 }}
            config={{rows: props.rows || 3}}
            classes="flex-100"
            appLang={props.appLang}
            className="width100"
            handleChange={(event) => { props.handleChange(event, (props.keyProp || 'description')); }} />
        </div>
      )}
    </div>
  )
};

export default AddDescriptionToggle;
