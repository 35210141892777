import { actionTypes } from '../actions/actionTypes';

const initialState = {
  isTimeBoxActive: false,
  timeRecorded: null, // number
  percentage: null, // number
  activeTimer: false,
  logTime: null,
  timerIsCompleted: false,
  isStopWatch: false,
  itemId: null,
  workoutLogId: null
};

const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_PLAYED_PROP:
      return {
        ...state,
        activeTimer: true,
        percentage: action.payload && action.payload.percentage ? action.payload.percentage : state.percentage,
        isTimeBoxActive: true,
        logTime: action.payload && action.payload.logTime ? action.payload.logTime : state.logTime,
        itemId: action.payload && action.payload.itemId ? action.payload.itemId : state.itemId,
        workoutLogId: action.payload && action.payload.workoutLogId ? action.payload.workoutLogId : state.workoutLogId
      };
    case actionTypes.CLEAR_PLAYED_PROP:
      return {
        ...state,
        activeTimer: false,
        timeRecorded: action.payload && action.payload.timeRecorded ? action.payload.timeRecorded : state.timeRecorded,
        percentage: action.payload && action.payload.percentage ? action.payload.percentage : state.percentage,
        timerIsCompleted: action.payload && action.payload.timerIsCompleted ? action.payload.timerIsCompleted : false
      };
    case actionTypes.RECORD_TIME_DONE:
      return {
        ...state,
        timeRecorded: action.payload.timeRecorded,
        percentage: action.payload.percentage,
        timerIsCompleted: action.payload && action.payload.timerIsCompleted ? action.payload.timerIsCompleted : false,
        isStopWatch: action.payload.isStopWatch
      };
    case actionTypes.RESET_TIMER_PROPS:
      return {
        ...state,
        isTimeBoxActive: false,
        timeRecorded: null, // number
        percentage: null, // number
        activeTimer: false,
        logTime: null,
        timerIsCompleted: false,
        isStopWatch: false,
        itemId: null,
        workoutLogId: null
      };
    default:
      return state;
  }
};

export default timerReducer;
