import { actionTypes } from '../actions/actionTypes';
import { utils } from '../../services/utilsService';
import { units } from '../../services/enums';
import subscriptionAccessControl from '../../appConsts/accessControl_subscription';
import userAccessControl  from '../../appConsts/accessControl_userType';

const initialState = {
  userInfo: {},
  settings: {
    weightUnits: units.weightUnits.KG,
    distanceUnits: units.distanceUnits.CM
  },
  clientsListKV: {},
  profileInfo: {},
  // trainerInfo: {}
  authenticated: true,
  response: null,
  // userType
  chosenClientsKV: {},
  chosenClientsIds: [],
  userCalendarSettings: {},
  myCalendarSettings: {},
  accessControl: {
    allAccess: true
  },
  userTypeAccess: {
    allAccess: true
  },
  subscription: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_USER_CREDENTIALS: {
      let clientsList = {};
      if (action.payload.user.clients && action.payload.user.clients.length > 0) clientsList = utils.arrayToKeyValue(action.payload.user.clients, 'public_id');
      // for Client Type purposes => SET_CHOSEN_CLIENT_BY_ID
      else {
        clientsList.keyValueObj = { public_id: action.payload.user.public_id, name: action.payload.user.name, thumbnail: action.payload.user.thumbnail, order: 0 };
      }
      const { user } = action.payload
      return {
        ...state,
        userInfo: user,
        settings: user.settings,
        profileInfo: user,
        authenticated: true,
        auth: user.token || action.payload.token,
        userType: user.userType,
        clientsListKV: clientsList.keyValueObj,
        trainerInfo: user && user.trainer ? user.trainer[0] : null,
        myCalendarSettings: user && user.settings && user.settings.calendarSettings || {},
        accessControl: subscriptionAccessControl.trial, // ['trial' || user.subscriptionAccess],
        // userTypeAccess: userAccessControl[user.userType]
      };
    }
    case actionTypes.SAVE_USER_PROFILE_INFO:
      return {
        ...state, profileInfo: action.payload
      };
    case actionTypes.SET_CHOSEN_CLIENT:
      return { ...state, chosenClientsKV: action.payload.keyValueObj, chosenClientsIds: action.payload.dataIds };
    case actionTypes.SET_CHOSEN_CLIENT_BY_ID: {
      const obj = {};
      if (state.clientsListKV && state.clientsListKV[action.payload]) {
        obj[action.payload] = { ...state.clientsListKV[action.payload] };
      } else {
        obj[action.payload] = {
          public_id: action.payload, name: state.userInfo.name, thumbnail: state.userInfo.backgroundImage, order: 0
        };
      }
      return { ...state, chosenClientsKV: obj, chosenClientsIds: [action.payload] };
    }
    case actionTypes.CLEAR_CHOSEN_CLIENT:
      return { ...state, chosenClientsKV: {}, chosenClientsIds: [] };
    case actionTypes.SET_DEFAULT_CHOSEN_USER: {
      const obj = {};
      if (!(state.chosenClientsIds && state.chosenClientsIds.length > 0) || !(state.chosenClientsKV && Object.keys(state.chosenClientsKV).length > 0)) {
        obj[state.userInfo.public_id] = {
          public_id: state.userInfo.public_id, name: state.userInfo.firstName, thumbnail: state.userInfo.backgroundImage, order: 0
        };
      }
      return { ...state, chosenClientsKV: obj, chosenClientsIds: [state.userInfo.public_id] };
    }
    case actionTypes.HANDLE_ERROR_RESPONSE:
      return { ...state, response: action.payload };
    case actionTypes.CLEAR_ERROR:
      return { ...state, response: null };
    case actionTypes.UPDATE_CLIENT_LIST: {
      const user = state.userInfo;
      user.clients = action.payload;
      let clientsList = {};
      if (user.clients && user.clients.length > 0) clientsList = utils.arrayToKeyValue(user.clients, 'public_id');
      else {
        clientsList.keyValueObj = {
          public_id: state.userInfo.public_id, name: state.userInfo.name, thumbnail: state.userInfo.thumbnail, order: 0
        };
      }
      return { ...state, userInfo: user, clientsListKV: clientsList.keyValueObj };
    }
    case actionTypes.SAVE_MY_CALENDAR_SETTINGS:
      return { ...state, myCalendarSettings: action.payload.calendarSettings };
    case actionTypes.SAVE_USER_CALENDAR_SETTINGS: {
      const { userCalendarSettings } = state;
      action.payload.forEach((user) => {
        userCalendarSettings[user.public_id] = user.calendarSettings
      })
      return { ...state, userCalendarSettings };
    }
    case actionTypes.SET_USER_SUBSCRIPTION_DETAILS: {
      return { ...state, subscription: action.payload.subscription, accessControl: action.payload.accessControl };
    }
    default:
      return state;
  }
};

export default userReducer;
