import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import {
  refreshPageHeader,
  getProfileInfo,
  getAllWorkoutPageData,
  showAlert,
  getCompletedAchievements,
  resendClientInvitation,
  refreshPageHeaderInfo,
} from '../../store/actions';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { categoryColor, userTypes } from '../../services/enums';
import { utils, securePostMsgUtils, getParams, checkAppOverallHeight } from '../../services/utilsService';
import { postMsgTypes } from '../../services/postMsgTypes';
import ProfileDetailsCard from '../../Components/ProfileCards/ProfileDetailsCard';
import { langs } from '../../services/translationLangs';
import LoadingDots from '../../Components/UI/LoadingDots';
import TutorialGuideBtn from '../../Components/TutorialGuide/TutorialGuideBtn';
import ActivateGuideCheck from '../../Components/TutorialGuide/ActivateGuideCheck';
import UserBg from '../../Components/UserBg/UserBg';
import SessionCard from '../WorkoutsPages/Components/RecentSessionCard/RecentSessionCard';
import SquareCard from '../../Components/SquareCard/SquareCard';
import LocationCard from '../../Components/SquareCard/LocationCard';
import startUploadFilesSVG from '../../assets/onBoarding/start_upload_files.svg';
import SwiperCarousel from '../../Components/SwiperCarousel/SwiperCarousel';
import MiniCalendarCmp from '../../Components/MiniCalendarCmp/MiniCalendarCmp';
import './ProfilePage.css';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      public_id: null,
      introMode: false,
      showInviteBtn: false,
      carouselOptions: {
        slidesPerView: 2.5,
        pagination: {
          el: '#location_swiper .swiper-pagination',
          dynamicBullets: true,
        },
      },
      carouselOptions_2: {
        slidesPerView: 1.8,
        pagination: {
          el: '#sessions_swiper .swiper-pagination',
          dynamicBullets: true,
        },
      },
      isPageReady: false,
    };
    const storeProps = { ...this.props };
    storeProps.refreshPageHeader('profile');
    storeProps.disableTopNavPlx(false);
  }

  async getProfileInfo() {
    // noinspection JSAnnotator
    try {
      let showInviteBtn = false;
      if (this.props.match && this.props.match.params && this.props.match.params.public_id) {
        this.state.public_id = this.props.match.params.public_id;
        window.scrollTo(0, 0);
        const profileInfo = await this.props.getProfileInfo(this.state.public_id);
        if (profileInfo && profileInfo.public_id) {
          const headerInfo = {
            title: `${profileInfo.firstName} ${profileInfo.lastName}`,
            subtitle: '',
          };
          this.props.refreshPageHeaderInfo(headerInfo);
        } else {
          this.props.history.replace('/');
        }
      }
      if (
        this.props.user.userType === userTypes.TRAINER &&
        this.props.profileInfo.userType === userTypes.CLIENT &&
        !this.props.profileInfo.isAccountVerified
      ) {
        showInviteBtn = true;
      }
      let query = { isBasic: true, limit: 6 };
      if (
        this.props.trainerInfo &&
        this.props.trainerInfo.public_id === this.props.match.params.public_id
      ) {
        query = {
          ...query,
          trainer: this.props.trainerInfo.public_id,
          user: this.props.match.params.public_id,
        };
      } else query = { ...query, user: this.props.match.params.public_id };
      await this.props.getAllWorkoutPageData(query);
      this.setState({ isPageReady: true, showInviteBtn });
    } catch (e) {
      console.log('handle error');
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getProfileInfo();
    this.handleParams();
    /*
    alert('pageREADY ' + securePostMsgUtils.listenToValidMessage() + `
        || domainWhiteList: ${!!domainWhiteList.indexOf(window.location.host) !== -1}
        || detectIsMobile: ${!!securePostMsgUtils.detectIsMobile()}
        || detectUserAgent: ${securePostMsgUtils.detectUserAgent()}
        || data: ${window && window.aspire ? JSON.stringify(window.aspire) : 'no window'}`);
        */
    if (securePostMsgUtils.listenToValidMessage()) {
      window.addEventListener('message', this.receiveProfilePostMessage, false);
      this.timeOut = setTimeout(() => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.PAGE_IS_READY }));
        clearTimeout(this.timeOut);
      }, 200);
    }
  }

  // it moved between users!!
  componentDidUpdate() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.public_id &&
      (this.state.public_id || this.props.profileInfo.public_id) !==
        this.props.match.params.public_id
    ) {
      this.state.isPageReady = false;
      this.getProfileInfo();
    }
    this.props.disableTopNavPlx(checkAppOverallHeight(850));
  }

  componentWillUnmount() {
    this.props.disableTutorial();
    if (securePostMsgUtils.listenToValidMessage()) {
      window.removeEventListener('message', this.receiveProfilePostMessage);
    }
  }

  receiveProfilePostMessage = (event) => {
    /*
    alert('receive Profile PostMessage , listenToValidMessage: ' + securePostMsgUtils.listenToValidMessage() + `
      || domainWhiteList: ${!!domainWhiteList.indexOf(window.location.host) !== -1}
      || detectIsMobile: ${!!securePostMsgUtils.detectIsMobile()}
      || detectUserAgent: ${securePostMsgUtils.detectUserAgent()}
      || event: ${JSON.stringify(event)}
      || window.aspire: ${JSON.stringify(window.aspire)}`);
*/
    if (window.aspire && window.aspire.url) {
      const redirectTo = window.aspire.url;
      window.aspire.url = null;
      window.location = redirectTo;
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ key: postMsgTypes.CLEAR_NOTIFICATION_URL }),
      );
    }
  };

  sendMsgRN() {
    if (securePostMsgUtils.listenToValidMessage()) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.GET_GCM_TOKEN }));
    }
  }

  openCongratsModal(achievements) {
    const data = {
      isOpen: true,
      isModal: true,
      id: eventActions.CONGRATS_PAGE,
      className: 'PageModal CongratsModal',
      achievements, // can be one...can be 3
      buttonRight: {
        text: <i className="la la-arrow-left iconFontSize30 goBackIconColor iconShadow" />,
        onClick: () => {
          this.props.hideAlert();
        },
      },
    };
    this.props.showAlert(data);
  }

  async handleParams() {
    const achievements = getParams('achievements', true);
    if (achievements) {
      const results = await this.props.getCompletedAchievements({ achievements });
      if (results && results.length > 0) {
        this.openCongratsModal(results);
      }
    }
  }

  getCarouselRef(carousel) {
    this.state.carouselRef = carousel;
  }

  goToIntro() {
    this.setState((prevState) => ({ introMode: !prevState.introMode }));
  }

  goToPage(page, publicId) {
    this.props.history.push(`${page}/${publicId}`);
  }

  renderSessionsSlider() {
    const sessions = utils.keyValueToArray(this.props.sessionsListKV) || [];
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-center sidePadd15px">
          <h3 className="text-uppercase fontWeight600" style={{ color: categoryColor.blue }}>
            <Trans i18nKey="sessionsHistory" />
          </h3>
          <Link to="/session/archive" className="flex-initial layout-row layout-align-start-start">
            <p className="flex-initial colorGrey margin0 font15 sidePadd2px">
              <i className="las la-arrow-circle-right font24 text-muted rotateOnDirection" />
            </p>
          </Link>
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start fadeIn-anime">
          <SwiperCarousel
            id="sessions_swiper"
            swiperIndex={0}
            options={this.state.carouselOptions_2}
            pagination
            className="width100"
            classNameWrapper="marginBottom30px width100 paddStart10px"
            navigationClasses="bottom text">
            { sessions.map((session) => {
              return (
                <div
                  key={session.public_id}
                  id={`session_${session.public_id}`}
                  className="width100 layout-row layout-wrap swiper-slide layout-align-start-start sidePadd5px"
                  onClick={() => {
                    this.goToPage('/session', session.public_id);
                  }}>
                  <SessionCard
                    className="padd15px"
                    data={session}
                    appLang={this.props.appLang}
                    settings={this.props.settings}
                    clientsListKV={this.props.clientsListKV ? this.props.clientsListKV : {}}
                    user={this.props.user ? this.props.user : {}}
                  />
                </div>
              );
            })}
          </SwiperCarousel>
        </div>
      </div>
    );
  }

  renderCalendarCmp() {
    const { user } = this.props;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        <MiniCalendarCmp
          toUpdate={this.props.match && this.props.match.params && this.props.match.params.public_id && (this.state.public_id || this.props.profileInfo.public_id) !== this.props.match.params.public_id}
          currentUser={this.props.match.params.public_id}
          isTrainer={user && user.userType === userTypes.TRAINER} />
      </div>
    );
  }

  renderLocationCarousel(profileInfo) {
    if (profileInfo && profileInfo.locations && profileInfo.locations.length > 0) {
      const locations = [];
      profileInfo.locations.forEach((locationItem, index) => {
        if (locationItem && locationItem.media && locationItem.media[0] && locationItem && locationItem.media && locationItem.media[0].source) {
          locations.push(
            <div
              key={`${locationItem.title}_${index}`}
              className={`swiper-slide flex-initial layout-row layout-wrap layout-align-start-start p-1 ${index === 0 ? '' : ''}`}>
              <LocationCard
                key={locationItem.place_id}
                item={locationItem}
                title={locationItem.title}
                thumbnail={locationItem.media[0].source}
                description={locationItem.description}
                locale={this.props.locale}
              />
            </div>,
          );
        }
      });
      return locations;
    }
    return null;
  }

  renderStartCreatingPrograms() {
    const { profileInfo, appLang } = this.props;
    return (
      <Link
        to={this.props.user.userType === userTypes.TRAINER ? '/workouts/create/details?isTutorial=true' : '#'}
        className="startUploadFilesSVG flex-100 layout-row layout-align-center-start marginTop30px">
        <div className="width100 layout-column layout-wrap layout-align-center-center text-center">
          <ReactSVG src={startUploadFilesSVG} className="startUploadFilesSVG_svg" />
          <div className="width100 layout-row layout-wrap layout-align-center-center padd5px15px">
            <p className="font18 fontWeight600">
              { langs[appLang].NoWorkoutsToShowFor(profileInfo.firstName)}
            </p>
          </div>
          {this.props.user.userType === userTypes.TRAINER ? (
            <div className="width100 layout-row layout-wrap layout-align-center-center sidePadd15px">
              <button
                type="button"
                className="btn-aspire-primary whiteBg boxShadowAround flex-100 layout-row layout-align-center-center borderRadius5 addWorkoutBtn fontWeight600 marginBottom10px">
                <p className="margin0 font15 text-capitalize aspire-text"><Trans i18nKey="clickHereToCreateProgram" /></p>
              </button>
            </div>
          ) : null}
        </div>
      </Link>
    );
  }

  render() {
    const { introMode, isPageReady, carouselOptions } = this.state;
    const { profileInfo, user } = this.props;
    const locationsSwiper = this.renderLocationCarousel(profileInfo);
    const renderCalendar = this.renderCalendarCmp(profileInfo);
    const renderSessions = this.props.sessionsListKV && Object.keys(this.props.sessionsListKV).length > 0 ? this.renderSessionsSlider() : null;
    const programs = this.props.workoutProgramsKV && Object.keys(this.props.workoutProgramsKV) ? utils.keyValueToArray(this.props.workoutProgramsKV) : null;
    return (
      <div className={`ProfilePage flex-100 layout-row layout-wrap layout-align-start-start marginBottom30px ${(profileInfo && profileInfo.userType) || ''}`}>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start height100vh content-start">
          <UserBg
            introMode={introMode}
            user={profileInfo}
            isCurrentUser={this.props.user.public_id === profileInfo.public_id}
            bgBottomColor={profileInfo.thumbnail && profileInfo.thumbnail.source ? 'rgba(25, 25, 25, 1)' : 'rgba(23, 22, 54, 0)'}
            bgMidColor="rgba(25, 25, 25, 0)"
            bgTopColor="rgba(25, 25, 25, 0)"
            goToIntro={() => this.goToIntro()}
          />
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start">
              <ProfileDetailsCard
                publicId={this.props.match.params.public_id}
                profileData={profileInfo}
                userType={user.userType}
                appLang={this.props.appLang}
                editIcon={user.public_id === profileInfo.public_id}
                settings={this.props.user.settings}
                showInviteBtn={this.state.showInviteBtn}
              />
            </div>
            {profileInfo.locations && profileInfo.locations.length > 0 && (
              <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10px15px">
                  <h3 className="text-uppercase primary fontWeight600">
                    <Trans i18nKey="trainingLocations" />
                  </h3>
                </div>
                <SwiperCarousel
                  id="location_swiper"
                  swiperIndex={0}
                  options={carouselOptions}
                  pagination
                  className="width100"
                  classNameWrapper="marginBottom20px width100 sidePadd5px"
                  navigationClasses="bottom text"
                  carouselRef={(carousel) => this.getCarouselRef(carousel)}>
                  {locationsSwiper}
                </SwiperCarousel>
              </div>
            )}
            {renderCalendar}
            {renderSessions}
            {programs && programs.length > 0 && (
              <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10px15px">
                  <h3 className="text-uppercase gradientText">
                    <Trans i18nKey="programs" />
                  </h3>
                </div>
                <div className="flex-100 layout-row layout-wrap layout-align-start-start sidePadd15px">
                  {programs.map((programs, index) => (
                    <div
                      key={programs.public_id}
                      className={`flex-50 layout-row layout-wrap layout-align-start-start ${index % 2 === 0 ? 'paddEnd5px' : 'paddStart5px'}`}
                      onClick={() => {this.goToPage('/program', programs.public_id);}}>
                      <SquareCard data={programs} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {isPageReady && !(this.props.sessionsListKV && Object.keys(this.props.sessionsListKV).length > 0) && !(programs && programs.length > 0) && this.renderStartCreatingPrograms()}
          </div>
          {/* <div className="flex-100 layout-row layout-wrap layout-align-space-between-center ">
            <TutorialGuideBtn dataKey="trainerIntroduction" iconClass="la la-star font34" history={this.props.history} />
          </div> */}
          <div className="flex-100 layout-row layout-wrap layout-align-start-start height60px" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userR.userInfo,
  profileInfo: state.userR.profileInfo,
  trainerInfo: state.userR.trainerInfo,
  error: state.userR.response,
  workoutProgramsKV: state.workoutR.workoutProgramsKV,
  sessionsListKV: state.sessionR.sessionsListKV,
  navBottom: state.appR.navBottom,
  appLang: state.appR.appLang,
});

const mapDispatchToProps = (dispatch) => ({
  showAlert: (data) => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  refreshPageHeader: (page) => dispatch(refreshPageHeader(page)),
  getProfileInfo: (publicId) => dispatch(getProfileInfo(publicId)),
  refreshPageHeaderInfo: (headerInfo) => dispatch(refreshPageHeaderInfo(headerInfo)),
  resendClientInvitation: (publicId) => dispatch(resendClientInvitation(publicId)),
  getAllWorkoutPageData: (query) => dispatch(getAllWorkoutPageData(query, true)),
  getCompletedAchievements: (body) => dispatch(getCompletedAchievements(body)),
  disableTopNavPlx: (isDisable) =>
    dispatch({ type: actionTypes.DISABLE_TOP_NAV_PLX, payload: isDisable }),
  disableTutorial: () => dispatch({ type: actionTypes.DISABLE_TUTORIAL_MODE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
