import React, { useState } from 'react';
import { Trans } from 'react-i18next';

const CredentialTypeSelector = (props) => {
  const [isPhone, setPhone] = useState(!!(props && props.isPhone));
  const toggleDesc = (isPhoneChange) => {
    if (isPhone === isPhoneChange) return;
    setPhone(isPhoneChange);
    props.onChange(isPhoneChange);
  };

  return (
    <div className={`flex-100 layout-row layout-wrap layout-align-start-center ${props.className}`}>
      <div className="flex-100 layout-row layout-wrap layout-align-start-center padd5px title">
        <p className="font15 primary fontWeight600 paddEnd5px margin0">
          <Trans i18nKey={props.title} />:
        </p>
      </div>
      <div className="flex layout-row layout-wrap layout-align-start-start phone paddEnd5px displayNone">
        <button onClick={() => toggleDesc(true)}
                type="button"
                className="btn-aspire-primary whiteBg flex-100 layout-row layout-align-center-center height50px borderRadius5 fontWeight600">
          <span className={`${isPhone ? 'aspire-text' : 'text-muted'}`}>
              <p className="margin0 font15 text-capitalize fontWeight600 text-nowrap"><Trans i18nKey="phone" /></p>
          </span>
        </button>
      </div>
      <div className="flex layout-row layout-wrap layout-align-start-start email">
        <button onClick={() => toggleDesc(false)}
                type="button"
                className="btn-aspire-primary whiteBg flex-100 layout-row layout-align-center-center height50px borderRadius5 fontWeight600">
          <span className={`${!isPhone ? 'aspire-text' : 'text-muted'}`}>
              <p className="margin0 font15 text-capitalize fontWeight600"><Trans i18nKey="email" /></p>
          </span>
        </button>
      </div>
    </div>
  );
};

export default CredentialTypeSelector;
