import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import LoadingDots from './LoadingDots';
import './UIStyle.css';

const AspireBtnLoader = (props) => {
  const { id, containerClassName, className, classNameText, initialValue } = props;
  const [isLoading, setIsLoading] = useState(initialValue || false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const onClickBtn = async () => {
    setIsLoading(true);
    const time = setTimeout(() => {
      setIsLoading(false);
      clearTimeout(time)
    }, 5000)
    const res = props.btnClicked ? await props.btnClicked() : null;
    if (res && res.error) {
      if (!(typeof res.error === 'string' || res.error instanceof String)) res.error = 'error'
      setSuccess(null);
      setError(res.error);
    }
    if (res && res.success) {
      setSuccess(res.success);
      setError(null);
    }
    setIsLoading(false);
  }

  return (
    <div id={id || 'BtnLoader'} className={`BtnLoader ${isLoading ? 'isLoading' : ''} ${containerClassName || 'flex-initial layout-row layout-wrap layout-align-center-center'} positionRelative`}>
      <button type={props.type || 'button'} onClick={() => onClickBtn()} className={`${props.btnBaseClass || 'btn-aspire-primary width100'} layout-align-center-center text-center ${ className || '' } ${error ? 'shake active error' : ''}`}>
        <span className={`text ${classNameText || ''}`}>
          {props.children ? props.children : (
            <p className={`margin0 ${classNameText || ''}`}><Trans i18nKey={success || error || props.btnText || 'next'} defaults={success || error || props.btnText || 'next'} /></p>
          )}
        </span>
        <div className="flex-initial positionAbsoluteTopLeft isLoadingDots padd10 width100">
          <LoadingDots />
        </div>
      </button>
    </div>
  );
}


export default AspireBtnLoader;
