import { paperworkTypes } from '../actions/actionTypes';
import { storeUtils } from '../../services/utilsService';

const initialState = {
  documents: {},
  documentData: {},
  docTemplates: []
  // newDocument: null
  // documentsKV {}
  // surveyKV {}
  // surveyIds []
  // documentsIds []
  // documentData {}
  // surveyFillData {}
  // surveyData {}
  // lastId
};

const paperworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case paperworkTypes.SET_NEW_DOCUMENT:
      return { ...state, newDocument: action.payload };
    case paperworkTypes.SET_DOCUMENTS:
      return { ...state, ...action.payload };
    case paperworkTypes.SET_DOCUMENT_DATA:
      return {
        ...state,
        documentData: action.payload
      };
    case paperworkTypes.CLEAR_DOCUMENT_DATA:
      return {
        ...state,
        newDocument: null,
        documentData: null
      };
    case paperworkTypes.REMOVE_DOCUMENT: {
      const documentsKV = storeUtils.removeFromObjectInStore(state.documentsKV, action.payload);
      const documentsIds = storeUtils.removeFromArrayInStore(state.documentsIds, action.payload);
      return { ...state, documentsIds, documentsKV };
    }
    case paperworkTypes.SET_NEW_SURVEY:
      return { ...state, newSurvey: action.payload };
    case paperworkTypes.SET_SURVEYS:
      return { ...state, ...action.payload };
    case paperworkTypes.SET_SURVEY_DATA:
      return {
        ...state,
        surveyData: action.payload
      };
    case paperworkTypes.CLEAR_SURVEY_DATA:
      return {
        ...state,
        newSurvey: null,
        surveyData: null
      };
    case paperworkTypes.REMOVE_SURVEY: {
      const surveysKV = storeUtils.removeFromObjectInStore(state.surveysKV, action.payload);
      const surveysIds = storeUtils.removeFromArrayInStore(state.surveysIds, action.payload);
      return { ...state, surveysIds, surveysKV };
    }
    case paperworkTypes.SET_SURVEY_FILL_DATA:
      return {
        ...state,
        surveyData: action.payload.surveyData,
        surveyFillData: action.payload.surveyFillData
      };
    case paperworkTypes.CLEAR_SURVEY_LIST:
      return {
        ...state, surveyKV: null, surveyIds: null, surveyTemplates: null, surveyForReview: null
      };
    case paperworkTypes.CLEAR_FILL_SURVEY:
      return {
        ...state, surveyData: null, surveyFillData: null
      };
    default:
      return state;
  }
};

export default paperworkReducer;
