/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import Collapse from 'react-css-collapse';
import DateTime from './DateTime';
import { configCalendarSettingsDateTime } from '../../services/utilsService'
import './DateTimeInputs_new.css';

const DateRangeInputs = (props) => {
  const { onDatesChange, showRange, appLang, min, timeMin, timeMax, errors, showDatePicker, showTimePicker, timegrid, isIOS } = props;
  const [startDate, setStartDate] = useState((props && props.initStartDate) || new Date());
  const [endDate, setEndDate] = useState((props && props.initEndDate) || new Date());
  const invalid = configCalendarSettingsDateTime(props.calendarSettings)
  const [invalidDateRange, setInvalidDateRange] = useState([{
    start: moment().subtract(50,'years'),
    end: moment().subtract(1,'day').endOf('day')
  }, ...invalid]);
  const [invalidTimeRange, setInvalidTimeRange] = useState([]);

  const onDateSelect = (date, key) => {
    if (!date) return;
    let startDateTemp = startDate;
    let endDateTemp = endDate;
    if (key === 'endDate') {
      setEndDate(date);
      endDateTemp = date;
      const startTime = moment(startDate, 'HH:mm');
      const endTime = moment(date, 'HH:mm');
      const isSameDay = moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD');
      const isBeforeDate = moment(date).isBefore(moment(startDate), 'day')
      const isBeforeTime = endTime.isBefore(startTime)
      if (isBeforeDate || (isSameDay && isBeforeTime)) {
        setEndDate(startDate);
        endDateTemp = startDate;
      }
    }
    else { // (key === 'startDate')
      setStartDate(date);
      startDateTemp = date;
      setInvalidDateRange([{
        start: moment().subtract(50, 'years'),
        end: moment(date).subtract(1, 'day').endOf('day')
      }, ...invalid])
      setInvalidTimeRange([{
        start: '00:00',
        end: moment(date).format('HH:mm'),
      }])
      const startTime = moment(date, 'HH:mm');
      const endTime = moment(endDate, 'HH:mm');
      const isSameDay = moment(date).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD');
      const isBeforeDate = moment(date).isBefore(moment(endDate), 'day')
      const isBeforeTime = endTime.isBefore(startTime)
      if (!isBeforeDate || (isSameDay && !isBeforeTime)) {
        setEndDate(date);
        endDateTemp = date;
      }
    }
    onDatesChange(startDateTemp, endDateTemp)
  }

  return (
    <div className={`DateRangeInputs flex-100 layout-row layout-wrap layout-align-start-start ${props.className}`}>
      <div className="flex-100 layout-row layout-align-space-between-start">
        <label className={`text-capitalize fontWeight600 margin0 ${errors && errors.startDate ? 'colorRed' : 'primary'}`}>
          <Trans i18nKey={errors && errors.startDate || 'startDate'} defaults={errors && errors.startDate || 'startDate'} />
        </label>
      </div>
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        <DateTime
          appLang={appLang}
          initValue={startDate}
          showDatePicker={showDatePicker}
          showTimePicker={showTimePicker}
          isIOS={isIOS}
          timegrid={timegrid}
          min={min}
          invalidDate={invalid}
          timeMin={timeMin}
          timeMax={timeMax}
          calendarSettings={props.calendarSettings}
          onChange={(date) => { onDateSelect(date,'startDate')}} />
      </div>
      <Collapse
        isOpen={showRange}
        transition="height 250ms cubic-bezier(.4, 0, .2, 1)">
        <div className="flex-100 width100 layout-row layout-wrap layout-align-start-center">
          <div className="flex-100 layout-row layout-align-space-between-start">
            <label className={`text-capitalize fontWeight600 margin0 ${errors && errors.endDate ? 'colorRed' : 'primary'}`}>
              <Trans i18nKey={errors && errors.endDate || 'endDate'} defaults={errors && errors.endDate || 'endDate'} />
            </label>
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <DateTime
              isIOS={isIOS}
              appLang={appLang}
              startDate={startDate}
              initValue={endDate}
              showDatePicker={showDatePicker}
              showTimePicker={showTimePicker}
              timegrid={timegrid}
              invalidDate={invalidDateRange}
              invalidTime={invalidTimeRange}
              timeMin={timeMin}
              timeMax={timeMax}
              calendarSettings={props.calendarSettings}
              onChange={(date) => { onDateSelect(date,'endDate')}} />
          </div>
        </div>
      </Collapse>
    </div>
  );
};
export default DateRangeInputs;
