import React, { Component } from 'react';
import nanoid from 'nanoid';
import PropTypes from 'prop-types';
import { modifyThumbnailSize, calcRequiredImageHeight, styleUtils } from '../../services/utilsService';

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: modifyThumbnailSize(this.props.src, this.props.height || 1080, 'AUTO'),
      id: nanoid(8),
      errored: false,
      height: calcRequiredImageHeight(this.props.height) || 1080,
      colors: {
        color1: props.color1 || null,
        color2: props.color2 || null,
        color3: props.color3 || null
      }
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.src && nextProps.src.length > 5) {
      this.state.src = modifyThumbnailSize(nextProps.src, nextProps.height || 1080, 'AUTO');
    } else this.state.src = nextProps.fallbackSrc;
    return true;
  }

  onError = () => {
    if (!this.state.errored) {
      this.setState({ src: this.props.fallbackSrc, errored: true }, () => {
        const imageElm = document.getElementById(`image_${this.props.id || this.state.id}`)
        if (imageElm && imageElm.src) imageElm.src = this.props.fallbackSrc;
      });
    }
  }

  render() {
    const { src, colors, id } = this.state;
    const { fallbackSrc, alt, backgroundImage, className, backgroundProps, degree, ...props } = this.props;
    const hasSrc = src && src.length > 3;
    if (hasSrc && backgroundImage) {
      return (
        <div id={`image_${props.id || id}`} className={className} style={{...styleUtils.imageBackground(src, colors.color1, colors.color2, colors.color3,degree || 0, fallbackSrc), ...backgroundProps}}>
          {props.children}
        </div>
      )
    } if (hasSrc) {
      return (
        <img id={`image_${props.id || id}`} alt={alt || ''} src={src} className={className} onError={this.onError} {...props} />
      );
    }
    return null;
  }
}

Image.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
};

export default Image;
