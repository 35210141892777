import React from 'react';
import ThumbnailUI from '../UI/ThumbnailUI';
import VideoBg from '../UI/VideoBg';

const VideoSection = props => (
  <div
    key={`section_${props.id}`}
    className={`flex-100 layout-row layout-wrap content-start ${props.isSwiperCarousel ? 'height100vh swiper-slide layout-align-space-between-start text-center' : 'layout-align-start-start'} ${props.className ? props.className : ''}`}
    style={{ backgroundColor: props.backgroundColor || '#fff' }}>
    <div className="width100 positionAbsoluteTopLeft height100vh layout-row layout-wrap layout-align-start-start">
      <VideoBg video={props.video} backgroundImage={props.backgroundImage} overlay={props.videoOverlay} />
    </div>
    <div className="positionRelative zIndex5 height100vh flex-100 layout-row layout-wrap layout-align-space-between-start" style={{ backgroundColor: props.backgroundOverlay || 'transparent' }}>
      { props.isSwiperCarousel && <div className="width100 layout-row layout-wrap layout-align-center-start height20px marginBottom30px" />}
      {props.thumbnail && (
        <ThumbnailUI
          src={props.thumbnail}
          className={`marginBottom30px svg90p ReactSVG height220px text-center ${props.thumbnailClass ? props.thumbnailClass : 'width100'}`} />
      )}
      { !props.thumbnail && props.isSwiperCarousel && <div className="width100 layout-row layout-wrap layout-align-center-start height250px marginBottom30px" />}
        <div className={`width100 layout-row layout-wrap sidePadd15px ${props.titleClassName ? props.titleClassName : 'layout-align-center-start'}`}>
        <div className="flex-initial width100 layout-row layout-wrap ">
          {props.title && (
            <div className={`flex-100 width100 layout-row layout-wrap marginBottom10px ${props.titleClassName ? props.titleClassName : 'layout-align-center-start'}`}>
              <label className="text-capitalize font22 fontWeight600 colorWhite">{props.title}</label>
            </div>
          )}
          {props.description && (
            <div className={`flex-100 width100 layout-row layout-wrap paddBottom10px ${props.descClassName ? props.descClassName : 'layout-align-center-start'}`}>
              <p className="marginBottom10px font16 lineHeight18 colorWhite">{props.description}</p>
            </div>
          )}
          {props.btns && (
            <div className={`flex-100 width100 layout-row layout-wrap paddBottom10px ${props.ClassNameBtns ? props.ClassNameBtns : 'layout-align-center-start'}`}>
              {props.btns}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default VideoSection;
