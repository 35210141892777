const clientInviteGuide = {
  key: 'clientInviteGuide',
  title: 'Client Invitation',
  content: 'what would you like to know?',
  type: 'newPage',
  options: [
    {
      title: 'How\'s invitation to aspire works?',
      type: 'video',
      source: 'https://videourl'
    },
    {
      title: 'Invite a client walk-through',
      type: 'tutorial',
      url: '/workouts/create/details?isTutorial=true'
    }
    /* {
      title: 'how\'s to upload my own welcome video for a client?',
      type: 'video',
      source: 'https://videourl'
    } */
  ]
};

const programGuide = {
  key: 'programGuide',
  title: 'workout programs',
  type: 'newPage',
  content: 'click on a subject you want to know about.',
  options: [
    {
      title: 'workout programs',
      content: 'what tutorial would you like to do/watch?',
      options: [
        {
          title: 'create an program walk-through',
          type: 'tutorial',
          url: '/workouts/create/details?isTutorial=true'
        },
        {
          title: 'How to create an program?',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'How to make super-set in a program?',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'How to start a session with a client?',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'How to update a past session of a client/yourself?',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'more help on programs features',
          className: 'text-muted font12',
          type: 'url',
          url: '/info'
        }
      ]
    }
  ]
};

const myStatsGuide = {
  key: 'myStatsGuide',
  title: 'Aspire statistics',
  content: 'Choose a subject',
  options: [
    {
      key: 'bodyStatsPage',
      title: 'Body Stats',
      content: 'what would you like to know about?',
      type: 'newPage',
      options: [
        {
          title: 'Aspire body stats overview',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'Updating body stats',
          type: 'tutorial',
          url: '/workouts/create/details?isTutorial=true'
        }
      ]
    },
    {
      key: 'performanceStatsPage',
      title: 'Performance Statistics',
      content: 'what would you like to know about?',
      type: 'newPage',
      options: [
        {
          title: 'Performance Stats overview',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'How it works?',
          type: 'video',
          source: 'https://videourl'
        }
      ]
    }
  ]
};

const userProfileGuide = {
  key: 'userProfileGuide',
  title: 'User profile',
  content: 'What would you like to know about your profile?',
  type: 'newPage',
  options: [
    {
      title: 'Profile overview',
      type: 'video',
      source: 'https://videourl'
    },
    {
      title: 'User profile walk-through',
      type: 'tutorial',
      url: '/workouts/create/details?isTutorial=true'
    }
  ]
};

const goalsAchievementsGuide = {
  key: 'goalsAchievementsGuide',
  title: 'Goals & achievements',
  content: 'what would you like to learn about?',
  options: [
    {
      title: 'Goal creation & use overview',
      type: 'video',
      source: 'https://videourl'
    },
    {
      title: 'Create a goal walk-through',
      type: 'tutorial',
      url: '/program/create?isTutorial=true'
    }
  ]
};

const planGuide = {
  key: 'planGuide',
  title: 'workout plans',
  type: 'newPage',
  options: [
    {
      title: 'workout plans',
      content: 'what tutorial would you like to do/watch?',
      options: [
        {
          title: 'Plan creation & use overview',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'Create a plan walk-through',
          type: 'tutorial',
          url: '/program/create?isTutorial=true'
        },
        {
          title: 'How to update update plan progress each week?',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'more help on plan features',
          className: 'text-muted font12',
          type: 'url',
          url: '/info'
        }
      ]
    }
  ]
};

const myExercisesGuide = {
  key: 'myExercisesGuide',
  title: 'My exercises',
  content: 'what would you like to know about?',
  type: 'newPage',
  options: [
    {
      title: 'exercise creation & use overview',
      type: 'video',
      source: 'https://videourl'
    },
    {
      title: 'Exercise creation walk-through',
      type: 'tutorial',
      url: '/workouts/create/details?isTutorial=true'
    }
  ]
};

const paperworkGuide = {
  key: 'paperworkGuide',
  title: 'Documents & surveys',
  content: 'Choose a subject',
  type: 'newPage',
  options: [
    {
      title: 'Documents',
      content: 'what would you like to know about?',
      type: 'newPage',
      options: [
        {
          title: 'Documents overview',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'Document creation walk-through',
          type: 'tutorial',
          url: '/workouts/create/details?isTutorial=true'
        }
      ]
    },
    {
      title: 'surveys',
      content: 'what would you like to know about?',
      type: 'newPage',
      options: [
        {
          title: 'Surveys overview',
          type: 'video',
          source: 'https://videourl'
        },
        {
          title: 'Survey creation walk-through',
          type: 'tutorial',
          url: '/workouts/create/details?isTutorial=true'
        }
      ]
    }
  ]
};

const trainerIntroduction = {
  key: 'trainerIntroduction',
  activeTime: { num: 4, unit: 'days' }, // active for 4 days
  title: 'welcome',
  content: 'welcome_sub',
  options: [
    {
      title: 'welcomeAspireClip',
      type: 'video',
      icon: 'la-photo-video',
      isPortrait: true,
      sourceHls: {
        master: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_playlist.m3u8',
        h360: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_360.m3u8',
        h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_480.m3u8',
        h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_720.m3u8',
        h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/intro_trainers_small_1080.m3u8'
      }
    },
    {
      title: 'profileOverview',
      type: 'video',
      icon: 'la-user',
      isPortrait: false,
      sourceHls: {
        master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/profile_overview_playlist.m3u8',
        h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/profile_overview_480.m3u8',
        h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/profile_overview_720.m3u8',
        h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/profile_overview_1080.m3u8',
      }
    },
/*  {
      title: 'inviteClientOverview',
      icon: 'la-user-plus',
      type: 'video',
      source: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/cardio/Cardio+31531201+Side+Kick+Burpee.mp4'
    }, */
    {
      title: 'programOverview',
      icon: 'la-file-alt',
      type: 'video',
      isPortrait: false,
      sourceHls: {
        master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/program_overview_playlist.m3u8',
        h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/program_overview_480.m3u8',
        h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/program_overview_720.m3u8',
        h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/program_overview_1080.m3u8',
      }
    },
/*    {
      title: 'planOverview',
      icon: 'la-calendar-day',
      type: 'video',
      source: 'https://videourl'
    }, */
    {
      title: 'achievementsOverview',
      icon: 'la-medal',
      type: 'video',
      isPortrait: false,
      sourceHls: {
        master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/achievements_overview_playlist.m3u8',
        h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/achievements_overview_480.m3u8',
        h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/achievements_overview_720.m3u8',
        h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/achievements_overview_1080.m3u8',
      }
    },
    {
      title: 'statisticsOverview',
      icon: 'la-chart-area',
      type: 'video',
      isPortrait: false,
      sourceHls: {
        master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/statistics_overview_playlist.m3u8',
        h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/statistics_overview_480.m3u8',
        h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/statistics_overview_720.m3u8',
        h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/statistics_overview_1080.m3u8',
      }
    },
/*    {
      title: 'createMyExerciseOverview',
      icon: 'la-dumbbell',
      type: 'video',
      source: 'https://videourl'
    }, */
    {
      title: 'paperworkOverview',
      icon: 'la-signature',
      isPortrait: false,
      sourceHls: {
        master: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/paperwork_overview_playlist.m3u8',
        h480: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/paperwork_overview_480.m3u8',
        h720: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/paperwork_overview_720.m3u8',
        h1080: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/overviews/paperwork_overview_1080.m3u8',
      }
      // type: 'tutorial',
      // url: '/workouts/create/details?isTutorial=true'
    }
  ],
  buttons: [
    {
      text: 'hide',
      funcName: 'toggleTutorial'
    },
    {
      text: 'dontShowAgain',
      funcName: 'toggleTutorial',
      data: true
    }
  ]
};

const tutorials = {
  trainerIntroduction,
  clientInviteGuide,
  programGuide,
  myStatsGuide,
  userProfileGuide,
  goalsAchievementsGuide,
  planGuide,
  myExercisesGuide,
  paperworkGuide
};

export default tutorials;

/**
 * Paste one or more documents here
 */
const media = {
  title: 'Chest 12731201 Clap Push Up',
  source: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/chest/Chest+12731201+Clap+Push+Up.mp4',
  sourceId: 'chest/Chest+12731201+Clap+Push+Up.mp4',
  thumbnail: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/previews/Chest+12731201+Clap+Push+Up.jpg',
  public_id: 'VID-391091533',
  type: 'video',
  sourceType: 'amazonS3',
  mediaGroup: 'aspireExercise',
  duration: 5000,
  creationDate: {
    $date: '2020-02-12T22:06:36.000Z'
  },
  searchKeywords: 'chest 12731201 clap push up',
  lastUpdated: {
    $date: '2020-07-12T19:46:25.675Z'
  },
  removed: false,
  sourceHls: {
    master: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/HLS/Chest/Chest+12731201+Clap+Push+Up_playlist.m3u8',
    h360: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/HLS/Chest/Chest+12731201+Clap+Push+Up_360.m3u8',
    h480: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/HLS/Chest/Chest+12731201+Clap+Push+Up_480.m3u8',
    h720: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/HLS/Chest/Chest+12731201+Clap+Push+Up_720.m3u8',
    h1080: 'https://exercisesdb.s3.eu-central-1.amazonaws.com/HLS/Chest/Chest+12731201+Clap+Push+Up_1080.m3u8'
  }
};
