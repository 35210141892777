import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Collapse from 'react-css-collapse';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  checkEmailExist,
  resendClientInvitation,
  updateSMSInvitation,
  updateEmailInvitation,
} from '../../store/actions';
import handleRequest from '../../services/controlledRequest';
import AspireBtnLoader from '../UI/AspireBtnLoader';
import EmailChecker from '../UI/EmailChecker';
import { requestTypes } from '../../services/enums';
import PhoneInput from '../PhoneInput/PhoneInput';
import CredentialTypeSelector from '../UI/CredentialTypeSelector';
import { langs } from '../../services/translationLangs';
import { removeClass } from '../../services/utilsService';

class CredentialUpdater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      updateToggle: false,
      updatePhoneToggle: true,
      newEmail: '',
      inviteByPhone: !!this.props.phone,
      email: this.props.email || null,
      phone: this.props.phone || null,
    };
  }

  handleChangeEmail(value) {
    this.state.emailApproved = false;
    this.state.newEmail = value;
  }

  handlePhoneChange(value) {
    this.state.phoneNumber = value.value;
    this.state.phoneNumberInt = value.international;
  }

  async checkEmailExist(value) {
    const api = '/api/users/checkEmailExist';
    const query = { email: value };
    const data = await handleRequest(api, requestTypes.GET, query);
    if (data && data.status === 200) this.state.emailApproved = true;
    return data;
  }

  async updateUserEmailAndResend() {
    if (!this.state.emailApproved) return;
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const data = await this.props.updateEmailInvitation(this.props.publicId, this.state.newEmail);
      console.log(data);
      this.setState({ email: this.state.newEmail });
      this.isLoading = true;
      return data;
    } catch (e) {
      this.isLoading = false;
      console.log('handle error');
    }
  }

  async updateSMSInvitation() {
    if (this.isLoading) return;
    this.isLoading = true;
    const { inviteByPhone, phoneNumber } = this.state;
    try {
      if (inviteByPhone && phoneNumber && isValidPhoneNumber(phoneNumber)) {
        // login by phone
        const data = await this.props.updateSMSInvitation(
          this.props.publicId,
          this.state.phoneNumber,
        );
        console.log(data);
        this.setState({ phone: this.state.phoneNumberInt });
        this.isLoading = false;
        return data;
      }
      this.isLoading = false;
      return { error: 'invalidPhoneNumber' };
    } catch (e) {
      this.isLoading = false;
      console.log('handle error');
      return { error: 'error' };
    }
  }

  async resendClientInvitation() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { inviteByPhone } = this.state;
      const body = { publicId: this.props.publicId, inviteByPhone };
      const data = await this.props.resendClientInvitation(body);
      if (data && data.text) {
        this.isLoading = false;
        return { error: data && data.text ? data.text : null };
      }
      console.log('resendClientInvitation', data);
      return { success: 'invitationSent' };
    } catch (e) {
      this.isLoading = false;
      console.log('handle error');
      return { error: 'error' };
    }
  }

  inviteBy(isPhone) {
    this.isLoading = false;
    this.setState({ inviteByPhone: isPhone });
  }

  updateToggle() {
    this.isLoading = false;
    this.setState((prevState) => ({ updateToggle: !prevState.updateToggle }));
  }

  render() {
    const { email, phone, inviteByPhone, updateToggle } = this.state;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
        <CredentialTypeSelector
          className="marginBottom10px CredentialTypeSelector"
          title="inviteBy"
          onChange={(value) => this.inviteBy(value)}
        />
        {!inviteByPhone && (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
              <p className="width100 text-muted font13">
                <Trans i18nKey="email" />
              </p>
              <p className="lineHeight18 layout-row layout-wrap layout-align-start-center">
                <span className="directionLtr"> {email || <Trans i18nKey="emailNotExist" />}</span>
                <button
                  type="button"
                  className="btn-link sideMargin10px primary fontWeight600"
                  onClick={() => {
                    this.updateToggle();
                  }}
                >
                  <Trans i18nKey="change" defaults="change" />
                </button>
              </p>
              <Collapse
                isOpen={updateToggle}
                className="width100"
                transition="height 250ms cubic-bezier(.4, 0, .2, 1)"
              >
                <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
                  <EmailChecker
                    inputId="emailUpdater"
                    emailStatusId="emailStatus"
                    required
                    showTooltip
                    noLabel
                    appLang={this.props.appLang}
                    initValue={email}
                    checkEmailExistOnChange
                    handleChangeEmail={(value) => {
                      this.handleChangeEmail(value);
                    }}
                    handleCheckEmail={async (value) => this.checkEmailExist(value)}
                  />
                </div>
                <AspireBtnLoader
                  btnText="updateResendInvitation"
                  containerClassName="flex-100 layout-row layout-wrap layout-align-center-center"
                  className="flex-100 whiteBg text-theme-1"
                  classNameText="fontWeight600 text-capitalize"
                  btnClicked={() => this.updateUserEmailAndResend()}
                />
              </Collapse>
            </div>
            <Collapse
              isOpen={!updateToggle}
              className="width100"
              transition="height 150ms cubic-bezier(.4, 0, .2, 1)">
              <AspireBtnLoader
                btnText="resendInvitation"
                containerClassName="flex-100 layout-row layout-wrap layout-align-center-center"
                className="flex-100 whiteBg text-theme-1"
                classNameText="fontWeight600 text-capitalize"
                btnClicked={() => this.resendClientInvitation()}
              />
            </Collapse>
          </div>
        )}
        {inviteByPhone && (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
              <p className="width100 text-muted font13">
                <Trans i18nKey="phone" />
              </p>
              <p className="lineHeight18 layout-row layout-wrap layout-align-start-center">
                <span className="directionLtr"> {phone || <Trans i18nKey="phoneNotExist" />}</span>
                <button
                  type="button"
                  className="btn-link sideMargin10px primary fontWeight600"
                  onClick={() => {
                    this.updateToggle();
                  }}
                >
                  <Trans i18nKey="change" defaults="change" />
                </button>
              </p>
              <Collapse
                isOpen={updateToggle}
                className="width100"
                transition="height 250ms cubic-bezier(.4, 0, .2, 1)"
              >
                <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
                  <PhoneInput
                    isRequired
                    name="phone_number"
                    defaultCountry="US"
                    label="phone"
                    internationalValue
                    countryCallingCodeEditable
                    international
                    onChange={(value) => this.handlePhoneChange(value)}
                  />
                </div>
                <AspireBtnLoader
                  btnText="updateResendInvitation"
                  containerClassName="flex-100 layout-row layout-wrap layout-align-center-center"
                  className="flex-100 whiteBg text-theme-1"
                  classNameText="fontWeight600 text-capitalize"
                  btnClicked={() => this.updateSMSInvitation()}
                />
              </Collapse>
            </div>
            <Collapse
              isOpen={!updateToggle}
              className="width100"
              transition="height 150ms cubic-bezier(.4, 0, .2, 1)"
            >
              <div className="flex-100 layout-row layout-wrap layout-align-center-center">
                <AspireBtnLoader
                  btnText="resendInvitation"
                  containerClassName="flex-100 layout-row layout-wrap layout-align-center-center"
                  className="flex-100 whiteBg text-theme-1"
                  classNameText="fontWeight600 text-capitalize"
                  btnClicked={() => this.resendClientInvitation()}
                />
              </div>
            </Collapse>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appLang: state.appR.appLang,
});

const mapDispatchToProps = (dispatch) => ({
  resendClientInvitation: (publicId) => dispatch(resendClientInvitation(publicId)),
  updateEmailInvitation: (publicId, email) => dispatch(updateEmailInvitation(publicId, email)),
  updateSMSInvitation: (publicId, number) => dispatch(updateSMSInvitation(publicId, number)),
  checkEmailExist: (email) => dispatch(checkEmailExist(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CredentialUpdater);
