/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import ReactSVG from 'react-svg';
import localStorage from 'local-storage';
import nanoid from 'nanoid/generate';
import { getExerciseDetails, refreshPageHeader } from '../../store/actions';
import onBoarding from '../../appConsts/onBoarding';
import { actionTypes } from '../../store/actions/actionTypes';
import PhoneDisplayUI from '../../Components/UI/PhoneDisplayUI';
import LoadingDots from '../../Components/UI/LoadingDots';
import SwiperCarousel from '../../Components/SwiperCarousel/SwiperCarousel';
import { addClass } from '../../services/utilsService';
import { nanoidOption } from '../../services/storeDoll';
import './MediaLibraryPage.css';
import './OnBoardingStyle.css';
import ReactGA from 'react-ga';

class OnBoardingPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      sliderNum: 0,
      onBoardingData: onBoarding[this.props.data],
      config: onBoarding[this.props.data].slides[0].config,
      defaultPoster: 'https://res.cloudinary.com/hxecphixw/image/upload/onboardingAssets/logoScreen_kohzov.png',
      carouselOptions: {
        // Optional parameters
        // Navigation arrows
        centeredSlides: true,
        nested: true,
        // And if we need scrollbar
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  }

  componentDidMount () {
    try {
      this.state.isPageReady = true;
      localStorage.set(`onBoarding-${this.props.data}`, true);
      this.renderChangeSection(0);
    } catch (e) {
      console.log('handle error', e);
    }
  }

  onSlideChange (value, isEnd) {
    console.log('value ', value)
    const hasTutorialBtns = this.state.onBoardingData && this.state.onBoardingData.slides && this.state.onBoardingData.slides[value] && this.state.onBoardingData.slides[value].showTutorialBtns;
    if (isEnd && !hasTutorialBtns) {
      addClass('.OnBoardingPage .doneBtn', 'show');
    }
  }

  getCarouselRef (carousel) {
    this.state.carouselRef = carousel;
  }

  closePage () {
    this.props.hideAlert();
  }

  goDoTutorial() {
    ReactGA.event({ category: 'OnBoarding', action: 'Go Do Tutorial' });
    this.props.alertProps.goDoTutorial.onClick();
  }

  clickBack () {
    if (this.state.sliderNum === 0) return;
    this.state.carouselRef.slidePrev(600);
    this.renderChangeSection(this.state.sliderNum - 1);
  }

  clickNext () {
    if (this.state.sliderNum === (this.props.slidesLength - 1)) return;
    this.state.carouselRef.slideNext(600);
    this.renderChangeSection(this.state.sliderNum + 1);
  }

  renderNavTopForModal () {
    const title = this.state.onBoardingData && this.state.onBoardingData.title ? this.state.onBoardingData.title : '';
    return (
      <div className="content-header flex-100 layout-row layout-wrap layout-align-start-start positionAbsoluteTop width100 zIndex10">
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-end ">
          <div className="flex-100 layout-row layout-wrap layout-align-space-between-center topNav height50px ">
            <div className="flex-initial">
              { this.props.alertProps.buttonRight && this.props.alertProps.buttonRight.text && (
                <button
                  type="button"
                  className="flex-initial textIcon btn-transparent paddEnd10px"
                  onClick={() => this.closePage()}>
                  { this.props.alertProps.buttonRight.text }
                </button>
              )}
            </div>
            <div className="flex layout-row layout-align-start-center sidePadd15px NavTop-label-direction">
              <label className="primary text-capitalize font20 fontWeight600 margin0"><Trans i18nKey={title} /></label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChangeSection (sliderNum) {
    this.state.sliderNum = sliderNum;
    this.state.config = this.state.onBoardingData.slides[sliderNum].config;
    this.setState({ sliderNum });
  }

  renderDefaultTemplate (section) {
    return (
      <div key={`section_${nanoid(nanoidOption, 4)}`}
        className="onBoardingPage swiper-slide flex-100 layout-column layout-align-start-start"
        style={{ backgroundColor: section.backgroundColor }}>
        { section.svg && (
          <div className="flex1 width100 layout-row layout-wrap layout-align-center-center margin30px">
            <ReactSVG
              className="marginBottom15px width100 svg90p ReactSVG height220px text-center "
              src={section.svg}
              fallback={() => <span>Error!</span>}
              loading={() => (
                <LoadingDots active />
              )} />
          </div>
        )}
        { section.videoUrl && (
          <div className="flex1 width100 layout-row layout-wrap layout-align-center-center margin30px">
            <PhoneDisplayUI
              uiId={`phone_${nanoid(nanoidOption, 4)}`}
              isIphone
              videoUrl={section.videoUrl}
              videoPoster={section.videoPoster || this.state.defaultPoster} />
          </div>
        )}
        <div className={`flex4 width100 layout-row layout-wrap layout-align-center-start text-${section.direction || 'center'} sidePadd15px`}>
          <div className="flex-initial width100 layout-row layout-wrap layout-align-center-start marginBottom10px">
            { section.title && (
              <div className="titleSection flex-100 width100 layout-row layout-wrap layout-align-center-start marginBottom10px">
                <label className="gradientText text-capitalize font20 fontWeight600"><Trans i18nKey={section.title} /></label>
              </div>
            )}
            <div className={`descriptionSection flex-100 width100 layout-row layout-wrap layout-align-${section.direction || 'center'}-start paddBottom10px`}>
              { section.isTemplateDescription ? (section.templateDescription(this.props.appLang)) : (
                <p className="margin0 lineHeight18"><Trans i18nKey={section.description} /></p>
              )}
            </div>
            { section.showTutorialBtns && (
              <div className={`tutorialSection flex-100 width100 layout-row layout-wrap layout-align-center-start paddBottom10px`}>
                <button type="button" onClick={() => this.goDoTutorial()} className="aspireLibraryBtn aspireBg flex-100 layout-align-center-center text-center marginBottom15px">
                  <i className="las la-hands-helping font30"></i>
                  <p className="margin0 paddStart10px fontWeight600"><Trans i18nKey="programCreationTutorial" /></p>
                </button>
                <button type="button" onClick={() => this.closePage()} className="uploadFilesBtn flex-100 layout-align-center-center text-center">
                  <i className="las la-meh font30"></i>
                  <p className="margin0 paddStart10px fontWeight600"><Trans i18nKey="noThankUTutorial" /></p>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderSliderSections () {
    if (!this.state.onBoardingData) this.state.onBoardingData = onBoarding[this.props.data];
    return this.state.onBoardingData.slides.map((section, index) => {
      if (section.isTemplate) {
        return (
          <div key={`section_${nanoid(nanoidOption, 4)}`} className="onBoardingPage swiper-slide flex-100 layout-column layout-align-start-start">
            {section.template(this.props.appLang)}
          </div>
        );
      }
      return this.renderDefaultTemplate(section, index);
    });
  }

  render () {
    const isModalPage = this.renderNavTopForModal();
    const sections = this.props.data && this.renderSliderSections();
    return (
      <div className="OnBoardingPage flex-100 layout-row layout-wrap layout-align-start-start fullSizeWindow content-start"
        style={{ backgroundColor: 'rgb(221, 228, 255)' }}>
        { isModalPage }
        <div className="flex-100 layout-row layout-align-start-start whiteBackground positionRelative tabs-container slide_0 directionChangeLtr">
          <div id="slider" />
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className="flex-100 layout-row layout-wrap layout-align-center-start">
            <SwiperCarousel
              id="onBoardingSwiper"
              swiperIndex={0}
              options={this.state.carouselOptions}
              pagination
              navigation
              className="onBoardingClass"
              /* navigationClasses="arrow" */
              navigationClasses="bottom text"
              carouselRef={carousel => this.getCarouselRef(carousel)}
              onSlideChange={(value, isEnd) => this.onSlideChange(value, isEnd)}>
              {sections}
            </SwiperCarousel>
            <div className="bottomSection positionRelative zIndex10 flex-100 layout-row layout-wrap layout-align-space-between-center directionLtr">
              <div className="width100 layout-row layout-wrap layout-align-center-center doneBtn">
                <button
                  type="button"
                  className="width100 textIcon btn-purple padd15 text-center"
                  onClick={() => this.closePage()}>
                  <p className="margin0"><Trans i18nKey="done" /></p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  exerciseDetails: state.exerciseR.exerciseDetails,
  appLang: state.appR.appLang,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  refreshPageHeader: page => dispatch(refreshPageHeader(page)),
  getExerciseDetails: publicId => dispatch(getExerciseDetails(publicId)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});
export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingPage);
