import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { orderBy } from 'lodash-es';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { showAlert, getCountryCodes } from '../../store/actions';
import CountryResult from '../ListWrappers/CountryResult';
/*
contact Object = {
  familyName: 'טיפולים',
  middleName: '',
  displayName: 'סבטה טיפולים', // android prop
  givenName: 'ראובן דדון', // ios prop
  'emailAddresses': [{ 'label': 'home', 'email': 'Orna@or-nadlan.co.il' }], // ios prop
  'phoneNumbers': [{ 'label': 'mobile', 'number': '0524639008' }], // ios prop
  'phoneNumbers': [{ 'id': '4', 'label': 'mobile', 'number': '+972 52-831-5073' }] // android
}
* */
class PhoneCountryInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: null,
      selectedCountry: null,
    };
    this.isLoading = false;
  }

  async componentDidMount() {
    try {
      const countries = await this.props.getCountryCodes();
      this.setState({ countries });
    } catch (e) {
      console.log('handle error');
    }
  }


  handleSelectedCountry(country) {
    this.props.handleSelectedCountry(country)
    this.setState({selectedCountry: country})
    if (this.props.hideAlert) this.props.hideAlert();
  }

  handleCountrySearch() {
    const list = orderBy(this.state.countries, ['name'], ['asc']);
    const data = {
      isOpen: true,
      className: 'PageModal',
      id: eventActions.DYNAMIC_SEARCH_LIST,
      title: 'selectCountry',
      itemsList: list || [],
      config: {
        propBy: '_id',
        showSearch: true,
        dynamicResultCard: CountryResult,
        makeSearchApiCall: false,
        itemSize: 45,
        selectedItems: [],
      },
      filterResults: () => {},
      onSelect: (country) => {
        this.handleSelectedCountry(country);
      },
      closeSearchList: () => {
      },
    };
    this.props.showAlert(data);
  }

  render() {
    const { selectedCountry } = this.state;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-center-start">
        <div className="flex-100 layout-row layout-align-start-start">
          <label className={`text-capitalize fontWeight600 ${this.props.errorText ? 'colorRed' : 'primary'}`}>
            <Trans i18nKey={this.props.errorText || 'selectCountry'} />
          </label>
        </div>
        <div className="flex-100 layout-row layout-align-start-start">
          <div className="layout-row layout-wrap layout-align-start-start form-control" onClick={() => this.handleCountrySearch()}>
            <div className="flex-100 layout-row layout-wrap layout-align-space-between-center">
              <div className="flex-initial layout-row layout-align-start-start">
                <p className="font15 margin0">{selectedCountry && selectedCountry.name || ''}</p>
              </div>
              <div className="flex-initial layout-row layout-align-start-start directionLtr">
                <p className="font15 margin0">{selectedCountry && selectedCountry.dialCode || ''}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appLang: state.appR.appLang,
});

const mapDispatchToProps = (dispatch) => ({
  showAlert: (data) => dispatch(showAlert(data)),
  getCountryCodes: () => dispatch(getCountryCodes()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhoneCountryInput);
