import React , {Fragment} from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import UserInfo from '../UserInfo/UserInfo';
import './SquareCard.css';
import { aspirePlaceholder } from '../../services/enums';
import Image from '../UI/Image';

const SquareCard = (props) => {
  const data = props.data;
  const thumbnail = (data && data.thumbnail && data.thumbnail.thumbnail) || (data && data.thumbnail && data.thumbnail.uri) || aspirePlaceholder[1];
  const workoutLogsLength = !props.isPlan ? (data.workoutLogs && Array.isArray(data.workoutLogs) ? data.workoutLogs.length : data.workoutLogs) : null;
  const workoutLogsLength2 = props.isPlan && data.programs && data.programs.length === 1 ? (data.programs && data.programs[0] && data.programs[0].workoutLogs || '1') : null;
  const multiPrograms = props.isPlan && data.programs && data.programs.length > 1 ? data.programs.length : null;
  return (
    <div className="flex-100 layout-row layout-wrap layout-align-start-center SquareCard marginBottom10px">
      <Image
        backgroundImage
        src={thumbnail}
        fallbackSrc={(data.thumbnail && data.thumbnail.uri) || aspirePlaceholder[0]}
        height={100}
        className="mediaImg backgroundImage flex-100 layout-column layout-align-start-start positionRelative"
        color1="rgba(0, 0, 0, 0.1)"
        color2="rgba(0, 0, 0, 0)"
        color3="rgba(0, 0, 0, 0.1)">
        <div className="width100 layout-row layout-wrap layout-align-start-start">
          { multiPrograms && (
            <div className="marginBottom5px mr-1 badge badge-primary badge-pill" style={{ position: 'relative', left: `${props.appLang === 'he' ? 10 : -10}px`, top: '10px' }}> multi-routine </div>
          )}
        </div>
      </Image>
      <div className="flex-100 layout-column layout-wrap layout-align-space-between padd10 infoContent">
        <div className="flex-initial layout-row layout-wrap layout-align-start-start marginBottom5px">
          <p className="flex-100 font18 textLimitHeight title text-capitalize marginBottom5px" style={{ maxHeight: `${props.appLang === 'he' ? 45 : 45}px` }}>{(data && data.title) || ''}</p>
          { !multiPrograms && (workoutLogsLength || workoutLogsLength2) && (
            <span className="flex-100 font14 exercisesNum colorGrey text-capitalize">{( workoutLogsLength || workoutLogsLength2 )} <span><Trans i18nKey="exercises" /> </span></span>
          )}
          { multiPrograms && (
            <Fragment>
              <span className="flex-100 font14 exercisesNum colorGrey text-capitalize marginBottom5px">{ multiPrograms } <span><Trans i18nKey="programs" /> </span></span>
            </Fragment>
          )}
        </div>
        <div className="flex-initial width100 layout-row layout-align-start-start layout-wrap">
          <div className="flex-100 layout-row layout-align-start-start layout-wrap">
            <div className="flex-initial layout-row layout-align-center-center layout-wrap">
              { data.user && (
                <div className="flex-100 layout-row layout-wrap layout-align-start-center">
                  <UserInfo users={data.user} inProps="public_id" text=" " />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SquareCard.propTypes = {
  data: PropTypes.shape({}),
  appLang: PropTypes.string
};

export default SquareCard;
