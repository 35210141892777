import { actionTypes, storeTypes } from '../actions/actionTypes';
import { storeUtils } from '../../services/utilsService';

const initialState = {
  mainPagePrograms: {},
  mainPageArticles: {},
  articleCategories: [],
  storeCategories: [],
  categories: {},
  archive: [],
  // article: {}
  // moreArticles: []
  // articleArchive: []
  // mainPageData: null
  // mainPageData: null
  // programCategories []
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeTypes.SET_STORE_MAIN_PAGE_PROGRAMS:
      return { ...state, featuredPrograms: action.payload.featuredPrograms, programCategories: action.payload.categories };
    case storeTypes.SET_STORE_MAIN_PAGE_ARTICLES:
      return { ...state, mainPageArticles: action.payload };
    case storeTypes.SET_ARTICLES_CATEGORIES:
      return { ...state, articleCategories: action.payload };
    case storeTypes.SET_STORE_CATEGORIES:
      return { ...state, categories: action.payload };
    case storeTypes.SET_STORE_ARCHIVE: {
      return { ...state, archive: action.nextPage ? [...state.archive, ...action.payload.programs ] : action.payload.programs }
    }
    case storeTypes.CLEAR_STORE_ARCHIVE: {
      return { ...state, archive: [] }
    }
    default:
      return state;
  }
};

export default storeReducer;
