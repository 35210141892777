import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {actionTypes} from '../../store/actions/actionTypes';
import { postMsgTypes } from '../../services/postMsgTypes';
import { securePostMsgUtils } from '../../services/utilsService';
import './MsgModal.css';

class Modal extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    if (securePostMsgUtils.listenToValidMessage()) {
      window.addEventListener('message', this.receiveModalPostMessage, false);
      window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.DISABLE_BACK_BTN }));
    }
  }

  componentWillUnmount () {
    if (securePostMsgUtils.listenToValidMessage()) {
      window.removeEventListener('message', this.receiveModalPostMessage);
      if (!this.props.alertProps.dontDisableListener) window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.ENABLE_BACK_BTN }));
      window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.LOCK_ORIENTATION_PORTRAIT }));
    }
  }

  receiveModalPostMessage = (event) => {
    if (securePostMsgUtils.isValidMessage(event)) {
      if (event.data.key === postMsgTypes.GO_BACK && !this.props.disableCurtain && !this.props.isBackButtonDisabled) {
        if (!this.props.modalAlert2) window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.LOCK_ORIENTATION_PORTRAIT }));
        this.closeModal();
      }
    }
  };

  closeModal () {
    const { alertProps}  = this.props;
    if (alertProps && alertProps.closeAlertBtn && alertProps.closeAlertBtn.onClick && !alertProps.disableCurtain){
      alertProps.closeAlertBtn.onClick()
    }
    if (this.props.hideAlertBtn && (alertProps && !alertProps.disableCurtain && !this.props.isBackButtonDisabled)) {
      this.props.hideAlertBtn();
    }
  }

  render () {
    return (
      <div className={`width100 ${this.props.className}`}>
        <div className={`modalMobile ${this.props.isOpen ? 'openModal animeDelay03' : ''} ${this.props.modalClassName}`}>
          { this.props.children }
        </div>
        <div className="black_back" onClick={() => this.closeModal()} />
      </div>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  isModal: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.object,
  model: PropTypes.string,
  modelId: PropTypes.string,
  buttonRight: PropTypes.object,
  buttonLeft: PropTypes.object
};

const mapStateToProps = state => ({
  isBackButtonDisabled: state.appR.isBackButtonDisabled,
});

const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
  hideAlert3: () => dispatch({ type: actionTypes.HIDE_ALERT3 })
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
