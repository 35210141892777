/* eslint-disable no-useless-escape,max-len,no-use-before-define */

import React from 'react';
import { calcUtils } from './utilsService';
import { allowedFieldsList } from './enums';

const buildNotification = (setNum, log, lang) => {
  const props = 'time reps weight distance speed intensity rest'.split(' ');
  let sentence = `${langs[lang].set} ${setNum}: `;
  props.forEach((prop) => {
    if ((log[prop] && prop === allowedFieldsList.weight) || log[prop] === 0) {
      sentence += `${
        log[prop] && log[prop].value > 0
          ? `${log[prop].value} ${log[prop].display || 'Kg'}`
          : langs[lang].bodyWeight
      } `;
    } else if ((log[prop] && prop === allowedFieldsList.distance) || log[prop] === 0) {
      sentence += `${
        log[prop] && log[prop].value > 0
          ? `${langs[lang].distance}: ${log[prop].value}${log[prop].display || 'M'}, `
          : ''
      } `;
    } else if (log[prop] && prop === allowedFieldsList.reps) {
      sentence += `${log[prop]} ${langs[lang].reps} ${log.weight || log.weight === 0 ? 'X' : ''} `;
    } else if (log[prop] && prop === allowedFieldsList.time) {
      sentence += `${langs[lang][prop]}: ${calcUtils.calcMillisToMinutesAndSeconds(log[prop])} `;
    } else if (log[prop]) {
      sentence += `${langs[lang][prop]}: ${log[prop]} `;
    }
  });
  return sentence;
};

export const he = {
  intro: 'קצת עליי',
  media: 'מדיה',
  reviews: 'חוות דעת',
  location: 'מיקום',
  categories: 'קטגוריות',
  category: 'קטגוריה',
  achievements: 'מטרות והישגים',
  myAchievements: 'מטרותי והישגי',
  inviteClient: 'הזמנת לקוח',
  invite: 'הזמן לקוח',
  resend: 'הזמן לקוח',
  'fill clients details': 'מלא את פרטי הלקוח',
  workouts: 'אימונים',
  myWorkouts: 'האימונים שלי',
  certifiedPersonalTrainer: 'מאמן אישי מוסמך',
  workoutsArchive: 'תוכניות ואימונים אישיים',
  workoutsArchive_2: 'ארכיון תוכניות אימונים',
  sessionsArchive: 'ארכיון תוכניות אימונים',
  workoutPlanArchive: 'ארכיון אימונים',
  createWorkout: 'עידכון אימון חדש',
  createPlan: 'יצירת תוכנית אימונים',
  myExercises: 'התרגילים שלי',
  myExercises_sub: 'רשימת תרגילים פרטיים',
  inquiriesAnswers: 'שאלות ותשובות',
  inquiriesAnswers_sub: 'כל מה שתרצו לדעת על ASPIRE',
  createInquiry: 'יש לך שאלה?',
  createInquiry_sub: 'שאל ואנו נענה בהקדם האפשרי',
  createSolution: 'פיתרון אפשרי',
  createSolution_sub: 'פיתרון לשאלה',
  replayInquiry: 'תגובה',
  replayInquiry_sub: 'השאר לנו תגובה, ונחזור אליכם בהקדם!',
  exerciseStats_title: 'תרגיל: ',
  programsStats_title: 'תוכנית: ',
  createWorkout_sub: 'מלא את פרטי התוכנית',
  chooseExercises: 'בחירת תרגילים',
  chooseExercises_sub: 'בחר תרגילים מן הרשימה',
  chooseExercise: 'בחר תרגיל',
  chooseExercise_sub: 'בחר תרגיל מן הרשימה',
  workoutLoggerHeader: 'עריכת אימון',
  workoutLoggerHeader_sub: 'עריכת סטים, חזרות ומשקלים',
  routineUpdateDetails: 'עריכת פרטי אימון',
  routineUpdateDetails_sub: 'עריכת כותרת והגדרות אימון',
  createAProgram: 'יצירת תוכנית אימונים',
  createAWProgram: 'יצירת תוכנית אימונים',
  getAFreeProgramTemplate: 'קבל תוכנית אימונים חינמית',
  aspireIntroductionVideoTrainer: 'סרטון מיוחד למאמן',
  aspireIntroductionVideoClient: (name) => `סרטון מיוחד ל${name || 'לקוח'}`,
  programDetails: 'מלא את פרטי התוכנית',
  addPrograms: 'הוספת תוכנית אימונים',
  modifyProgress: 'בניית התקדמות שבועית',
  modifyProgress_sub: 'בניית התקדמות אוטומטית לכל תוכנית לפי שבוע.',
  modifyProgramsProgressPage_info:
    'לחץ על התוכנית הרצויה, ערוך לכל שבוע את המדדים המתאימים, ומדדים אלו יתעדכנו אצל הלקוח בכל אימון.',
  'Select Programs for your plan': 'בחר תוכניות הכלולים בלו"ז זה',
  sessionStart: 'תחילת האימון',
  'Session Started At': 'האימון התחיל ב: ',
  clientsList: 'הלקוחות שלי',
  statistics: 'מדדי גוף וביצועים',
  MyStatistics: 'מדדי גוף וביצועים שלי',
  settings: 'הגדרות',
  applicationSettings: 'הגדרות משתמש',
  sessionsHistory: 'היסטוריית אימונים',
  workoutPrograms: 'תוכניות אימונים',
  workoutPlans: 'תוכניות תקופתיות',
  calendar: 'יומן',
  calendar_sub: 'יומן אימונים שבועי',
  programs: 'תוכניות אימונים',
  routines: 'אימונים',
  minutes: 'דקות',
  hours: 'שעות',
  hour: 'שעה',
  and: 'ו',
  days: 'ימים',
  day: 'יום',
  today: 'היום',
  weeks: 'שבועות',
  week: 'שבוע',
  month: 'חודש',
  months: 'חודשים',
  daily: 'יומי',
  weekly: 'שבועי',
  monthly: 'חודשי',
  times: 'פעמים',
  of: 'מתוך',
  min: "דק'",
  sec: "שנ'",
  startTime: 'שעת התחלה',
  endTime: 'שעת סיום',
  assignToClient: 'שייך ללקוח',
  sendDocToClients: 'שלח מסמך זה ללקוחות',
  copyDocToMe: 'העתק מסמך זה ושלח ללקוחותי',
  sendSurveyToClients: 'שלח שאלון זה ללקוחות',
  copySurveyToMe: 'העתק שאלון זה ושלח ללקוחותי',
  UsingThisProgram: 'משתמשים בתוכנית',
  exercises: 'תרגילים',
  description: 'הסבר קצר',
  addDescription: 'הוסף הסבר קצר',
  activityHistory: 'היסטוריית פעילות',
  currentProgram: 'שם התוכנית',
  default: 'ברירת מחדל',
  chooseEquipment: 'בחר ציוד לתרגיל',
  choose: 'בחר',
  workoutsPerWeek: 'אימונים בשבוע',
  currentWeek: 'שבוע נוכחי',
  history: 'היסטוריה',
  createBodyStats: 'הוספת מדדים חדשים',
  updateBodyStats: 'עידכון מדדים קיימים',
  loading: 'טוען...',
  bodyStatsInfo_title: 'פרטי המדדים',
  bodyStatsInfo_sub: 'תאריך:',
  UpdateYourCurrentBodyStats: 'עדכן את מדדי הגוף הנוכחיים',
  startSession: 'התחל אימון',
  createPastSession: 'הוספת אימון שהתקיים',
  updatePastSessions: 'הוספת אימון שהתקיים',
  copyProgramToClient: 'העתק תוכנית ללקוח אחר',
  copyProgram: 'העתק תוכנית',
  copyProgramToProfile: 'העתק אימון לפרופיל שלי',
  updatePastSessionsFP: 'עידכון אימון שהתקיים בעבר בתוכנית זו',
  workoutsCompleted: 'אימונים נערכו',
  tonnageLifted: 'משקל הורם',
  exercisesDone: 'תרגילים הושלמו',
  totalSets: 'סטים נעשו',
  totalReps: 'חזרות נעשו',
  active: 'פעיל',
  completed: 'הושלם',
  programsTitle: 'שם התוכנית',
  eventsTitle: 'כותרת האירוע',
  // body stats labels
  height: 'גובה',
  weight: 'משקל',
  muscleMass: 'מסת שריר',
  fat: 'אחוזי שומן',
  calories: 'קלוריות',
  water: 'אחוז נוזלים',
  bmi: 'BMI',
  BMI: 'BMI',

  shoulders: 'כתפיים',
  arms: 'היקף זרוע',
  leftArm: 'היקף זרוע שמאל',
  rightArm: 'היקף זרוע ימין',
  chest: 'היקף חזה',
  waist: 'היקף מתניים',
  hips: 'היקף אגן',
  thighs: 'היקף ירך',
  leftThigh: 'היקף ירך שמאל',
  rightThigh: 'היקף ירך ימין',
  lowerThighs: 'היקף ירך תחתון',
  leftLowerThigh: 'היקף ירך תחתון שמאל',
  rightLowerThigh: 'היקף ירך תחתון ימין',
  calves: 'היקף שוק',
  leftCalf: 'היקף שוק שמאל',
  rightCalf: 'היקף שוק ימין',
  isAdvancedMeasurements: 'מדדי היקף מתקדם',
  addBodyMeasurements: 'הוסף מדדי היקפים',
  bodyMeasurements: 'מדדי היקפים',
  bodyStats: 'מדדי גוף',
  bodyStats_sub: 'מדדים המעודכנים לפי לקוח',
  bodyStats_sub_client: 'המדדים המעודכנים שלך',
  performance: 'מדדי ביצועים',
  performance_sub: 'מדדים המעודכנים לפי לקוח',
  performance_sub_client: 'המדדים המעודכנים שלך',
  createCategory: 'יצירת קטגוריה',
  categories_sub: 'רשימת כל הקטגוריות',
  straight: 'רגיל',
  superset: 'סופר סט',
  super_set: 'סופר-סט',
  pyramid: 'פירמידה',
  interval: 'אינטרבלים',
  aerobic: 'אירובי',
  strength: 'כוח',
  static: 'סטטי',
  kilograms: 'קילוגרם',
  pounds: 'ליברות',
  meter: 'מטר / ס"מ',
  inch: "אינץ'",
  yard: 'יַרְד',
  mile: 'מייל',
  group_option: 'לקבוצה',
  personal_option: 'לאדם',
  public_option: 'פומבי',
  store_option: 'חנות',
  other_option: 'אחר',
  public_id: "מס' זיהוי",
  uri: 'URL',
  order: 'סדר',
  content: 'תוכן',
  email: 'דוא"ל',
  title: 'כותרת',
  password: 'סיסמא',
  confirmPassword: 'אשר סיסמא',
  oldPassword: 'סיסמא נוכחית',
  newPassword: 'סיסמא חדשה',
  passwordOrInvite: 'סיסמא / קוד הזמנה',
  resetCode: 'קוד שחזור סיסמא',
  forgotPassword: 'שכחתי סיסמא',
  alreadyHaveTheCode: 'קיבלתי קוד בדוא"ל',
  inviteCode: 'קוד הזמנה',
  firstName: 'שם פרטי',
  aboutMe: 'קצת עליי',
  phone: 'טלפון',
  lastName: 'שם משפחה',
  birthDate: 'יום הולדת',
  weightUnits: "יח' משקל",
  distanceUnits: "יח' מרחק",
  language: 'שפה',
  chooseLanguage: 'בחר שפה',
  startDate: 'תאריך התחלה',
  endDate: 'תאריך סיום',
  date: 'תאריך',
  eventsHour: 'שעת האירוע',
  allDay: 'כל היום',
  duration: 'משך זמן',
  numOfWeeks: "הערה: רשום מס' שבועות",
  workoutPerWeek: 'אימונים בשבוע',
  numOfTimes: "הערה: רשום מס' פעמים",
  updateStats: 'עדכן מדדים',
  type: 'סוג',
  exercise: 'תרגיל',
  round: 'סבב',
  set: 'סט',
  sets: 'סטים',
  reps: 'חזרות',
  time: 'זמן',
  speed: 'מהירות',
  distance: 'מרחק',
  intensity: 'עצימות',
  rest: 'זמן מנוחה',
  timeDone: 'זמן הושלם',
  totalTimeDone: 'זמן כולל',
  totalDistance: 'מרחק כולל',
  chooseImage: 'בחר תמונה',
  uploadMedia: 'העלאת מדיה',
  uploadFile: 'העלאת קובץ',
  mediaLibrary: 'ספריית תמונות',
  uploadImage: 'העלאת תמונה',
  uploadVideo: 'העלאת וידאו',
  uploadProfileImage: 'העלאת תמונת פרופיל',
  uploadBackgroundImage: 'העלאת תמונת רקע',
  files: 'קבצים',
  gallery: 'גלריה',
  myMedia: 'מדיה שלי',
  thisMedia: 'מדיה זה',
  male: 'זכר',
  female: 'נקבה',
  next: 'הבא',
  prev: 'הקודם',
  save: 'שמור',
  leave: 'צא',
  send: 'שלח',
  skip: 'דלג',
  cancel: 'ביטול',
  clear: 'נקה',
  confirm: 'אישור',
  updatePassword: 'עדכן סיסמא',
  done: 'סיום',
  assignAsDone: 'סמן כבוצע',
  markAsCompleted: 'סמן כבוצע',
  finishSession: 'סיים אימון',
  finishSession_desc: 'לחץ כאן לסיים אימון זה',
  back: 'חזור',
  add: 'הוספה',
  remove: 'הסרה',
  addSet: 'הוספת סט',
  removeSet: 'הסרת סט',
  setAdd: 'סט נוסף',
  moreDetails: 'פרטים נוספים',
  details: 'פרטים',
  edit: 'עריכה',
  modifyDoc: 'עריכת המסמך',
  delete: 'מחיקה',
  expand: 'הרחבה',
  changeRepsWeight: 'שינוי משקל/חזרות',
  backToCards: 'חזרה לכרטיסים',
  resetChanges: 'מחק שינויים',
  exitEditMode: 'צא מעריכה',
  register_login: 'התחבר / הרשם',
  register: 'הרשם',
  submit: 'שלח',
  login: 'התחבר',
  signin: 'התחבר',
  signup: 'הרשם',
  invitedClient: 'לקוח מוזמן',
  createAccount: 'צור חשבון מאמן',
  dontHaveAnAccount: `היי מאמנ/ת , אין לך חשבון?`,
  muscleId: 'קוד שריר',
  title_he: 'כותרת בעברית',
  title_en: 'כותרת באנגלית',
  description_he: 'הסבר בעברית',
  description_en: 'הסבר באנגלית',

  moreOptions: 'אפשרויות נוספות',
  exerciseNotes: 'הערות לתרגיל',
  clickHereFullScreen: 'לחץ כאן לצפייה במסך מלא',
  close: 'סגור',
  notes: 'הערות',
  addToList: 'הוסף לרשימה',
  exerciseName: 'שם התרגיל',
  editExerciseName: 'ערוך את שם התרגיל',
  changeExerciseFields: 'שינוי שדות מדידה לתרגיל',
  changeExerciseOrder: 'שינוי סדר תרגילים',
  addExerciseToSuperSet: 'הוסף תרגיל לסופר-סט',
  deleteExercise: 'מחק תרגיל',
  clientInvite: 'לקוח מוזמן',
  saveChanges: 'שמור שינויים',
  info: 'מידע',
  information: 'מידע',
  support: 'תמיכה',
  myInquiries: 'שאלות שלי',
  exerciseInfo: 'מידע נוסף',
  enter: 'כניסה',
  logout: 'התנתק',
  myProfile: 'הפרופיל שלי',
  store: 'חנות',
  notice: 'שים לב',
  alert: 'הודעה',
  thisModalCantBeOpened: 'איזור זה לא יפתח עד להשלמת העלאת הקבצים, נסה שנית מאוחר יותר.',
  chooseClient: 'בחר לקוח',
  chooseTrainer: 'בחר מאמן',
  chooseClients: 'בחר לקוחות',
  clientsParticipates: 'משתתפים',
  chooseAchievementType: 'בחר סוג מטרה',
  your: '',
  invitationCode: 'קוד ההזמנה',
  inviteSentInfoByEmail: 'בדקות הקרובות הלקוח יקבל את קוד ההזמנה בכתובת הדוא"ל',
  inviteSentInfoSpam: "אנא בדוק גם את תיבת ה'קידומי מכירות' אם לא נמצא המייל.",
  inviteSentInfoBySMS: 'ההזמנה נשלחה אל הלקוח בהודעת SMS.',
  inviteSentInfoBySMS_sub: (name) =>
    `אם מסיבה מסויימת ${name || 'הלקוח'} לא קיבל/ה את ההזמנה בדקות הקרובות, קוד ההזמנה הינו:`,
  NoWorkoutsToShow: 'לא נמצאו תוכניות אימונים',
  NoWorkoutsToShowFor:(name) => `לא נמצאו תוכניות אימונים ל${name}.`,
  clickHereToCreateProgram: 'צור את תוכנית האימונים הראשונה שלי!',
  NoActivitiesToShow: 'לא נמצאו פעילויות חדשות',
  NoGoalsToShow: 'לא נמצאו מטרות אימונים',
  NoStatsToShow: 'לא נמצאו סטטיסטיקות',
  NoStatsToShow_sub: 'יש לקיים אימון עם הלקוח על מנת שדף זה יתעדכן',
  clickHereToCreateGoal: 'צור מטרת אימונים לך/ללקוחותך כאן',
  NoAchievementToShow: 'לא נמצאו הישגים, עדיין',
  ChangeLogToCompleted: 'סט זה שמור כ"לא הושלם", האם לעדכן ל"הושלם"?',
  CLIENT: 'לקוח',
  TRAINER: 'מאמן אישי',
  NUTRITIONIST: 'תזונאי',
  english: 'אנגלית',
  hebrew: 'עברית',
  selectProgramType: 'בחר סוג תוכנית',
  selectProgramView: 'בחר תצוגת תוכנית',
  exercisesSelected: 'תרגילים נבחרו',
  addExercise: 'הוספת תרגיל לתוכנית',
  addExerciseToSession: 'הוספת תרגיל לאימון',
  addExerciseInfo: 'לחץ כאן כדי להוסיף תרגיל חדש',
  reorderExercises: 'שינוי סדר תרגילים',
  reorderExercises_sub: 'שינוי סדר ומחיקת תרגילים',
  addSuperSet: 'הוסף סופר סט',
  selectedExercises: 'תרגילים נבחרים',
  addExercises: 'הוספת תרגילים',
  selectExercises: 'בחר בתרגילים',
  selectExercise: 'בחר בתרגיל',
  moveExercisesInfo: 'גרור את התרגיל הנבחר על מנת לשנות את סדר התרגילים בתוכנית.',
  bodyWeightInfo: `רישום 0 בשדה המשקל יחשב כ'משקל גוף'.`,
  aerobicFieldsInfo: `.רישום 0 בשדה יחשב כהתעלמות מהשדה בתוכנית.`,
  programsSelected: 'תוכניות נבחרו',
  noticeChangingCurrentProgram:
    "הוספה/הסרה של תרגיל מהתוכנית תבטל את מצב 'העתקה' והיא תחשב כתוכנית חדשה, האם הנך מעונין להמשיך?",
  noticeUnsavedChanges: (numbers) =>
    `תרגילים ${numbers} נמצאים עדיין בעריכה והשינויים שבוצעו בהם לא ישמרו, האם ברצונך להמשיך?`,
  assignAsAchieved: 'המטרה הושגה',
  assignAsAchieved_notice: 'האם המטרה הושגה?',
  assignAsAnswered: 'השאלה נענתה',
  assignAsAnswered_notice: 'האם קיבלת את המענה לשאלתך?',
  resetProgressToThisWeek_notice: 'הנך בטוח/ה שברצונך להסיר את השינויים שנעשו בשבוע זה?',
  goBackWithoutSave_notice: "חזרה לאחור ללא שמירת שינויים תגרום למחיקת השינויים שנעשו, האם את/ה בטוח/ה?",
  more: 'נוספים',
  liftingTime: 'זמן מושקע באימונים',
  avgWorkoutTime: 'ממצוע זמן אימון',
  avgRestTime: 'ממוצע זמן מנוחה',
  showMore: 'הצג עוד',
  showLess: 'הצג פחות',
  archive: 'ארכיון',
  saySomething: 'כמה מילים...',
  howWasSession: 'איך הרגיש האימון היום?',
  sessionTime: 'משך זמן האימון',
  preparation: 'הכנה לתרגיל',
  execution: 'ביצוע התרגיל',
  muscles: 'שרירים',
  equipment: 'ציוד',
  difficulty: 'רמת אימון',
  defaultEquipment: 'ציוד ברירת מחדל',
  chooseDefaultEquipment: 'בחר ציוד ברירת מחדל',
  exerciseType: 'סוג תרגיל',
  filter: 'סינון',
  /*  target: 'אגוניסט',
    synergist: 'סינרגיסט',
    stabilizers: 'מייצבים',
    antagonist: 'אנטגוניסט', */
  target: 'שרירי מטרה',
  synergist: 'שרירי עזר',
  stabilizers: 'מייצבים',
  antagonist: 'שרירי ניגוד',
  removeExercise: 'הסרת תרגיל',
  removeExerciseDesc: 'האם את/ה בטוח/ה שברצונך להסיר התרגילים הנבחרים?',
  clickToDelete: 'לחץ כאן למחיקה',
  hi: 'היי',
  sessionSection1: 'למי נעשה אימון היום?',
  sessionSection2: 'אנא בחר את התוכנית הרלוונטית',
  'Choose Client & Program': 'בחר/י לקוח ותוכנית אימונים',
  chooseProgram: 'בחר/י תוכנית אימונים',
  'Are you sure you want to delete': 'האם את/ה בטוח/ה שברצונך להסיר את',
  areYouSureFinishSession: 'האם את/ה בטוח/ה שברצונך לסיים אימון זה?',
  areYouSureRemoveEquipment: 'האם את/ה בטוח/ה שברצונך להסיר את המדיה מהציוד הנבחר?',
  areYouSureUnSubscription: 'האם את/ה בטוח/ה שברצונך לבטל את המנוי?',
  cancelSubscription: 'ביטול המנוי',
  theseBodyStats: ' מדדים אלו',
  me: 'אני',
  isPersonalTrainingSession: 'אימון זה הינו חלק מחבילת אימונים אישיים',
  sessionSummary: 'סיכום אימון',
  bestSets: 'סטים מובחרים',
  current_state: 'מיקום נוכחי',
  target_state: 'מטרה',
  initial_state: 'מדידה ראשונה',
  initialvalue: 'מדידה ראשונה',
  targetvalue: 'מטרה',
  kg: 'ק"ג',
  KG: 'ק"ג',
  lbs: "ליב'",
  LBS: "ליב'",
  Ft: 'רגל',
  FT: 'רגל',
  Yd: 'יַרְד',
  YD: 'יַרְד',
  T: 'טון',
  cm: 'ס"מ',
  CM: 'ס"מ',
  INCH: "אינץ'",
  YARD: 'יַרְד',
  MILE: 'מייל',
  km: "קמ'",
  KM: "קמ'",
  m: "מ'",
  M: "מ'",
  kcal: 'קק"ל',
  Kcal: 'קק"ל',
  KCAL: 'קק"ל',
  percent: '%',
  PERCENT: '%',
  M_KM: 'ס"מ / מ\' / ק"מ',
  YARD_MILE: "אינץ' / יַרְד / מייל",
  KG_CM: 'ק"ג / ס"מ',
  LBS_INCH: "ליברות / אינץ'",
  NoStatsRecorded: 'לא נמצאו נתונים',
  activeAchievements: 'מטרות פעילות',
  completedAchievements: 'רשימת הישגים',
  'type in kg': 'רשום בק"ג',
  'type in cm': 'רשום בס"מ',
  'type in %': 'רשום באחוזים',
  'type in Kcal': "רשום מס' קלוריות",
  'type in lbs': 'רשום בליברות',
  'type in inch': 'רשום באינץ',
  searchKeywords: 'מילות חיפוש',
  '': '',
  blank: '',
  EN: 'English',
  HE: 'עיברית',
  ES: 'Español',
  EN_short: 'Eng',
  HE_short: 'עב',
  ES_short: 'Es',
  image: 'תמונה',
  video: 'וידאו',
  search: 'חיפוש',
  session_of: 'תוצאות האימון של',
  chooseGoal: 'בחירת מטרה ללקוח',
  chooseGoalSub: 'בחירת מטרה לפי מדדי גוף או ביצוע',
  goalDetails: 'פרטי המטרה',
  achievementDetails: 'טוען פרטים...',
  goalDetailsSub: 'מלא את הפרטים הבאים',
  createGoal: 'יצירת מטרה',
  againstEquipment: 'כנגד',
  lastUpdatedIn: 'עודכן לאחרונה ב',
  bestSet: 'הסט הטוב ביותר באימון',
  bestTime: 'הזמן הטוב ביותר לפי מרחק',
  tonnage: "טונאז'",
  mostReps: "מס' החזרות הגבוהה ביותר",
  highestWeight: 'המשקל הגבוהה ביותר',
  longestTime: 'הזמן הארוך ביותר',
  longestDistance: 'המרחק הארוך ביותר',
  bestByRM: 'המשקל הגבוה ביותר לפי RM',

  BARBELL: 'מוט',
  DUMBBELL: 'משקולות יד',
  MACHINE: 'מכונה',
  SMITH_MACHINE: "מכונת סמית'",
  CABLE: 'כבלים',
  BODY_WEIGHT: 'משקל גוף',
  KETTELE_BELL: 'קטלבל',
  TRX: 'TRX',
  FREE_WEIGHTS: 'משקולות חופשי',
  PAUNCH_MITTS: 'לפות אגרוף',
  WEIGHT_BAG: 'שק משקולת',
  bodyWeightShort: 'מ. גוף',
  bodyWeight: 'משקל גוף',
  showInGraph: 'הצג בגרף:',
  nextExercise: 'תרגיל הבא',
  prevExercise: 'תרגיל קודם',
  currentExercise: 'תרגיל נוכחי',
  skipped: 'לא הושלם',
  later: 'יותר מאוחר',
  seeAchievement: 'ראה הישג',
  congratulations_sub: 'השגת את מטרה זו! ההישג יועבר לדף ההישגים',
  wellDone: 'כל הכבוד!',
  'please write your notes here': 'אנא רשום את הערותך כאן...',
  changePassword: 'שינוי סיסמא',
  changePassword_sub: 'אנא עדכן את סיסמתך',
  updateUserCredentials: 'עידכון פרטי התחברות',
  updateUserCredentials_sub: "עדכנו את סיסמתכם או הוסיפו מס' טלפון.",
  resendInvitation: 'שלח הזמנה נוספת ללקוח',
  resendInvitationAgain: 'שלח הזמנה שוב',
  updateResendInvitation: 'עדכן ושלח הזמנה נוספת ללקוח',
  inviteAnotherClient: 'הזמן לקוח נוסף',
  emailSent: 'הודעה נשלחה לדוא"ל',
  invitationSent: 'ההזמנה נשלחה',
  invitationSentError: 'בעיה בשליחת ההזמנה',
  profileDetails: 'פרטי חשבון',
  restDay: 'יום מנוחה',
  createExercise: 'יצירת תרגיל',
  createExerciseAdmin: 'יצירת תרגיל ניהול',
  createExercise_sub: 'לחץ כאן כדי ליצור תרגיל חדש',
  'fill exercise details': 'מלא את פרטי התרגיל',
  typeOldPassword: 'רשום סיסמא ישנה',
  typeNewPassword: 'רשום סיסמא חדשה',
  'Application Settings': 'הגדרות מערכת',
  onlyEnglishLetters: 'רשום אך ורק באותיות אנגלית',
  'type in exercise title': 'שם התרגיל',
  myActivities: 'פעילות',
  myActivities_sub: 'כל העדכונים באפליקציה ירשמו כאן',
  editName: 'עריכת שם ',
  top: 'עליון',
  center: 'מרכז',
  bottom: 'תחתון',
  processingImage: 'מכווץ תמונה',
  connect: 'מתחבר',
  uploading: 'מעלה תמונה...',
  errorUploadingFile: 'העלאה נכשלה',
  addMedia: 'הוספת מדיה',
  upToImgs: (number) => `עד ${number} העלאות`,
  fullScreen: 'מסך מלא',
  skipEx: 'דלג תרגיל',
  change: 'שינוי',
  nextSet: 'סט הבא',
  timer: 'טיימר',
  stopwatch: 'שעון עצר',
  noExercisesAdded: 'לא נוספו תרגילים לתוכנית',
  pleaseGoBack: 'אנא חזור אחורה',
  session_has_ended: 'האימון הסתיים',
  changeCurrentWeek: 'שינוי שבוע נוכחי',
  changeCurrentSessionNum: "שינוי מס' אימון נוכחי",
  statistics_of: (name) => `סטטיסטיקות של ${name || ''}`,
  history_of: (name) => `המדדים של ${name || ''}`,
  workouts_of: (name) => `האימונים של ${name || ''}`,
  activeAchievements_of: (name) => `המטרות של ${name || ''}`,
  completedAchievements_of: (name) => `ההישגים של ${name || ''}`,
  bodyStats_of: (name) => `המדדים של ${name || ''}`,
  bodyStatsDetails_of: (name) => `המדדים של ${name || ''}`,
  bodyStatsDetails_sub: (name) => `המדדים של ${name || ''}`,
  bodyStatsDetails: 'טוען מדדים...',
  performanceDetails: 'טוען מדדים...',
  performance_of: (name) => `הביצועים של ${name || ''}`,
  performanceDetails_of: (name) => `הביצועים של ${name}`,
  performanceDetails_sub: () => 'טוען מדדים...',
  performanceDetails_exerciseStats_sub: (name) => `מדדי התרגיל של ${name || ''}`,
  performanceDetails_programsStats_sub: (name) => `מדדי התוכנית של ${name || ''}`,
  achievementDetails_sub: (name) => `המדדים של ${name || ''}`,
  lastRegisteredStat: 'מדד אחרון שנרשם: ',
  activeSessionInfo_primary: (setNum, log, lang) => buildNotification(setNum, log, lang || 'he'),
  activeSessionInfo_sub: (setNum, log, execution, lang) => {
    let sentance = buildNotification(setNum, log, lang || 'he');
    sentance += '\nלעדכון הסט לחץ כאן.';
    sentance += `${execution ? `\nביצוע התרגיל: \n${execution}` : ''}`;
    return sentance;
  },
  aspireCommentTitle: () => `Aspire Says`,
  HALF_TIME_DOME: (timeLeft, log) => `עברת כבר מחצית מהזמן, זמן שנישאר: ${timeLeft}`,
  TIME_DOME_75: (timeLeft, log) => `אנו מתקרבים אל הסוף, זמן שנישאר: ${timeLeft}`,
  TIME_DOME_90: (timeLeft, log) => `כמעט סיימנו, זמן שנישאר: ${timeLeft}`,
  TIMER_TIME_DOME: (timeLeft, log) => `הזמן נגמר! עבודה טובה!`,
  usingThisProgram: (title, endDate) => `פעילה ע"י ${title} עד תאריך ${endDate}`,
  addProgramToPlan: 'הוספת תוכנית זו לרשימה',
  myClientsList: 'רשימת המתאמנים שלי',
  myClientsList_sub: 'עדכון שינוי וניתוק מתאמן מהרשימה',
  refreshInfo: 'עדכן פרטים',
  refreshClientsInfo: 'עדכן פרטי לקוחות',
  unlink: 'נתק',
  clientDetails: 'פרטי הלקוח',
  unlink_question: (clientName) =>
    `האם את/ה בטוח/ה שברצונך לנתק את '${clientName}' מרשימת הלקוחות שלך? `,
  subscription: 'הרשמה לשרותי Aspire',
  subscription_sub: 'עדכון מסלול השירותים לAspire',



  /* validation */
  invalidDate: 'תאריך אינו תקין',
  missingDate: 'אנא בחר תאריך',
  pleaseChooseClient: 'אנא בחר לקוח',
  pleaseSelectRoutine: 'אנא בחר אימון',
  isRequired: (name) => `שדה ${name} הינו חובה`,
  tooShort: (name) => `השדה ${name} קצר מידי`,
  tooLong: (name) => `השדה ${name} ארוך מידי`,
  typeMismatch: (name) => `בשדה ${name} ישנה אי-התאמה`,
  rangeUnderflow: (name, value) => `${name} חייב להיות גדול\/שווה ל ${value}`,
  rangeOverflow: (name, value) => `${name} חייב להיות קטן\/שווה ל ${value}`,
  passwordsMismatch: 'ישנה אי-התאמה בין הסיסמאות',
  "Please include an '@' in the email address": 'אנא הוסף @ לשדה הדוא"ל',
  typeMismatchReps: `בשדות 'חזרות' ישנה אי-התאמה`,
  typeMismatchReps_sub: `בסוג מטרה זה שדות 'חזרות' מחוייב לאותו ערך`,
  typeMismatchWeights: `בשדות 'משקל' ישנה אי-התאמה`,
  typeMismatchWeights_sub: `שדות 'משקל' אינם יכולים להיות 0 ביחד`,
  termsAgreement_1: 'על ידי לחיצה על "הרשם", אני מסכים ל',
  termsOfService: 'תנאי השימוש',
  termsAgreement_2: ' של Aspire וכי קראתי את ',
  policy: 'מדיניות הפרטיות.',
  privacyPolicy: 'מדיניות פרטיות',
  termsAgreement_3: ' ',
  askMeAgain: 'שאל אותי שוב בכניסה הבאה לAspire.',
  noThankYou: 'לא תודה',
  letMyTry: 'אנסה בעצמי',
  checkTermsAgreement: 'אנא הסכם למדיניות Aspire',

  // error handler text
  error: 'שגיאה',
  emailAlreadyInUse: 'דוא"ל נמצא בשימוש',
  noUserFound: 'לא נמצא משתמש זה במערכת',
  badCredentials: 'אנא בדוק את הסיסמא/דוא"ל שוב',
  emailNotVerified: 'דוא"ל לא אומת',
  checkEmailFirst: 'המתן לבדיקת כתובת הדוא"ל ואז לחץ כאן',
  accessDenied: 'גישה נדחתה',
  unhandledError: 'אופס שגיאה, נסה שנית',
  wrongPassword: 'סיסמא שגויה, נסה שנית',
  'Missing Credentials': 'נתונים חסרים',
  lockForHour: 'החשבון ננעל לשעה',
  emailAlreadyExist: 'דוא"ל קיים במערכת',
  emailIsGood: 'מצויין! אפשר להמשיך',
  emailNotExist: 'דוא"ל לא קיים',
  invalidName: 'שם שגוי',
  invalidEmail: 'כתובת שגויה',
  invalidPhoneNumber: "מס' טלפון אינו תקין",
  invalidForm: 'טופס שגוי',
  invalidCode: 'קוד שגוי',
  userAlreadyExist: 'משתמש קיים במערכת',
  phoneAlreadyExist: 'המספר קיים במערכת',
  phoneNotExist: 'המספר לא קיים במערכת',
  userSuspended: 'יוזר מושהה',
  alreadyLoggedIn: 'יוזר מחובר למערכת',
  limitReached: 'השהייה קלה, נסה שוב מאוחר יותר',
  progress: 'התקדמות',
  program: 'תוכנית',
  /* onBoarding */
  yourBusinessPlatform: 'הסביבה העסקית שלך',
  yourBusinessPlatform_desc:
    'הראה את יכולותיך ומקצועיותך על ידי שיתוף מדיה, תוכניות אימונים ותוצאות לקוחותיך אשר יוצגו ללקוחות פוטנציאלים בקרוב.',
  inviteClientsOnly: 'בלעדי ללקוחות אישיים',
  inviteClientsOnly_desc:
    'הזמן את לקוחותיך האישיים לקהילה בילעדית המיועדת ליצירת מסגרת מקצועית ומקיפה ומחוייבות גבוה.',
  // create workout
  fillWorkoutDetails: 'מילוי פרטי התוכנית',
  fillWorkoutDetails_desc:
    'ליצירת תוכנית אימנים על המאמן להשלים 3 צעדים פשוטים, הצעד הראשון הוא למלא את פירטי התוכנית:',
  fillWorkoutDetails_list: [
    'בחר לקוח מרשימת הלקוחות.',
    'רשום שם לתוכנית.',
    'בחר תמונה מספריית התמונות של ASPIRE או מהנייד',
  ],
  selectTheExercises: 'בחירת תרגילים',
  selectTheExercises_desc:
    'בחר את התרגילים הרצויים לתוכנית, ' +
    'ניתן לבחור סוג אימון: רגיל או סופר-סט\n' +
    'למצוא תרגילים לפי קטגוריה, שם שריר, וציוד.',
  customizeYourProgram: 'עריכת תרגילים',
  customizeYourProgram_list: [
    'בחירת ציוד לכל תרגיל.',
    'שינוי/הוספת סטים, חזרות, ומשקל.',
    'הוספת הערות לכל תרגיל',
    'שינוי שם התרגיל - להבנה טובה יותר מצד הלקוח.',
    'מחיקת תרגילים מיותרים',
  ],
  // create a goal
  creatingGoal: 'יצירת מטרה',
  creatingGoal_desc:
    'ליצירת מטרה חדשה יש לעבור שני שלבים, הראשון הוא לבחור את סוג המטרה ולמי משוייכת.',
  creatingGoal_list: [
    'בחר לקוח מרשימת הלקוחות.',
    'בחר את סוג המטרה: מדד גוף/ביצועים.',
    'בחר את המדד הרצוי מהרשימה',
  ],
  fillGoalsDetails: 'מילוי פרטי המטרה',
  fillGoalsDetails_list: [
    'מילוי שם, תאריך התחלתי לתחילת מדידה, משך הזמן להשיג את מטרה זו',
    'רישום מדד התחלתי (בחלקו התחתון של הדף יופיע העידכון האחרון של המדד במידה ועודכן במערכת)',
    'רישום מדד סופי אליו נרצה להגיע',
    'שים לב: במידה ונבחרה מטרה למדידת ביצועים יש לבחור ציוד לתרגיל וסוג מדידה (משקל הכי גבוה, חזרות...)',
  ],
  fillExerciseDetails: 'מילוי פרטי התרגיל',
  fillExerciseDetails_desc:
    'ליצירת תרגיל עליך לבחור את סוג התרגיל, למלא את שם התרגיל והוראות ביצוע',
  uploadEquipmentMedia: 'העלה תמונה/סירטון לכל ציוד',
  uploadEquipmentMedia_desc: 'אפשרות העלאת מדיה לכל ציוד של התרגיל\n' + '*העלאת מדיה איננה חובה',
  helpful: 'עזר',
  unhelpful: 'לא עזר',
  steps: 'צעדים',
  solutions: 'פיתרונות',
  replay: 'השב',
  chooseMediaType: 'בחר סוג מדיה',
  uploadImages: 'העלאת תמונות',
  uploadImages_desc: 'אנא בחר/י את התמונות אשר ברצונך להעלות, אחרת לחץ על החץ לחזרה.',
  uploadVideos: 'העלאת וידאו',
  uploadVideos_desc: 'אנא בחר/י את הסרטונים שברצונך להעלות, אחרת לחץ על החץ לחזרה.',
  uploadCertificates: 'העלאת תעודות הכשרה',
  uploadCertificates_desc:
    'אנא צלם והעלה את התעודות המאשרות את הכשרתך המקצועית בתחומים שבחרת.\n' +
    '(אפשר לדלג על שלב זה)',
  orSkipAndUploadLater: "או דלג בלחיצה על 'הבא' לאישור מאוחר יותר.",
  congratulations: 'מזל טוב!',
  dummyUserAppended:
    'הרשמתך לAspire הושלמה! \n' +
    'הכירו את Alice, הלקוחה הראשונה שלך במערכת!.\n' +
    'בעזרתה תוכל/י להבין את יכולות המערכת ולממש את יכולות אלו על לקוחותיך האישיים.',
  goodLuck: 'אנחנו כאן לכל שאלה ובקשה,\nבהצלחה!',
  welcomeToAspire: (name) => `שלום ${name}!`,
  welcomeToAspire_desc:
    'הנך עכשיו חלק מקהילת Aspire!\n' +
    'זה הזמן לבנות את הסביבה המקצועית שלך כאן, להזמין לקוחות, ולהנות ממגוון יכולות אשר יעזרו לך ליצור מסגרת חזקה יותר בינך לבין לקוחותך.',
  welcomeToAspireClient_desc:
    'ברוך הבא אל ASPIRE, מאמנך האישי הזמין אותך אל סביבה אקסקלוסיבית ללקוחותיו אשר יהנו מיחס אישי,' +
    'שיתוף תוכן ואימונים אישיים.',
  smsOnItsWay: 'הקוד בדרך אלייך!',
  smsWillBeReceived: 'ברגעים הקרובים תשלח אלייך הודעת SMS עם קוד בן 5 ספרות, אנא המתן.',
  resendSMS: 'שלח SMS בשנית',
  insertTrainerInvite: 'קוד הזמנה לASPIRE',
  insertTrainerInvite_sub: 'אנא הכנס/י את קוד ההזמנה אשר קיבלת בהודעת SMS או ממאמנך.',
  clickNextOrSwipe: "לחץ על 'הבא' או החלק ימינה.",
  noResultsFound: 'לא נמצאו תוצאות תואמות לחיפוש',
  pleaseTryAgain: 'אנא נסה שוב',
  noSessionFound: 'לא נמצא מידע על אימון זה',
  documents: 'מסמכים',
  myDocuments: 'המסמכים שלי',
  surveys: 'שאלונים',
  documents_of: (name) => `המסמכים של ${name || ''}`,
  surveys_of: (name) => `השאלונים של ${name || ''}`,
  documentArchive: 'ארכיון מסמכים',
  surveyArchive: 'ארכיון שאלונים',
  createDocument: 'יצירת מסמך חדש',
  createDocument_sub: 'מלא את פרטי המסמך',
  updateDocument: 'עידכון מסמך',
  updateDocument_sub: 'עדכן את פרטי המסמך',
  createSurvey: 'יצירת שאלון חדש',
  createSurvey_sub: 'יצירת שאלון חדש',
  surveyFillDetails: 'השלמת שאלון',
  surveyFillDetails_sub: 'ענה על השאלות בכנות מלאה',
  updateSurvey: 'עדכון שאלון',
  updateSurvey_sub: 'עדכון שינוי או הוספת שאלות חדשות לשאלון',
  filled: 'הושלמו',
  templates: 'תבניות',
  template: 'תבנית',
  forReviewSurvey: 'השלמת שאלון',
  forReviewDoc: 'השלמת מסמכים',
  chooseClients_doc: 'בחר לקוחות לשליחת המסמך לחשבונם',
  addCheckboxToConfirmAgreement: `הוסף צ'ק-בוקס למסמך כדי לאשר שהלקוח קרא ומסכים לתנאים.`,
  isAgreeText: "רשום טקסט בהתאמה אישית לצ'ק-בוקס",
  IAgreeToTerms: 'קראתי את המסמך ואני מסכים לתנאים הרשומים בו.',
  isClientCanModifyDoc: `אפשר ללקוח לשנות/לתקן את הכתוב במסמך.`,
  forClient: 'ללקוח',
  addQuestion: '+ הוסף שאלה',
  requiredAnswer: 'חובה לענות',
  question: 'שאלה',
  options: 'אפשרויות',
  addOption: 'הוסף אפשרות',
  // inputOption
  inputOption: 'שדה טקסט',
  numberOption: 'שדה מספר',
  longInputOption: 'שדה טקסט ארוך',
  checkboxOption: 'בחירה מרובה',
  radioOption: 'בחירה אחת',
  dateOption: 'שדה תאריך',
  optionsPlaceHolderText: 'כאן תירשם תשובת הלקוח...',
  optionsPlaceHolderLabel: 'רשום כאן את האפשרות',
  answer: 'תשובה',
  surveyTitle: 'כותרת השאלון',
  surveyDescription: 'הסבר קצר על השאלון',
  surveyIntro: 'כאן תוכלו לרשום הסבר קצר על השאלון שלקוחותיכם ימלאו...',
  noQuestion: 'הוסף לפחות שאלה 1 לשאלון',
  noAnswers: 'מלא את שדות החובה בשאלון',
  fillRequiredAnswers: 'מלא את שדות החובה בשאלון.',
  missingSurveyTitle: 'כותרת לשאלון הינה שדה חובה',
  noQuestionText: (index) => `חסרה שאלה לשאלה: ${index}`,
  noLabelOption: (name) => `חסר טקסט לאפשרות בשאלה: ${name}`,
  starRequiredFields: '* שדות חובה',
  copySurvey: 'העתק שאלון זה לחשבוני',
  assignDocumentSuccessfully: 'המסמכים נשלחו ללקוחות בהצלחה',
  assignSurveySuccessfully: 'השאלונים נשלחו ללקוחות בהצלחה',
  unfinishedSession_sub: 'האימון האחרון נסגר ללא אישור, שנחזור להתאמן?',
  potentialClients: 'לקוחות פוטנציאלים',
  contactsList: 'רשימת אנשי קשר',
  contactDetails: 'פרטי איש הקשר',
  contactYouFrom: 'בקשה ליצירת קשר דרך ',
  aspireWebsite: 'אתר ASPIRE',
  by: ' ע"י ',
  hereAListOfContactWillAppear: 'כאן תופיע רשימה של לקוחות אשר מנסים ליצור קשר דרך אתר ASPIRE.',
  phoneCopied: "מס' הטלפון הועתק",
  misMatchPasswords: 'הסיסמאות אינן תואמות',
  misMatchCode: 'הקוד אינו תואם',
  // tutorial overview
  profileOverview: 'פרופיל המאמן',
  inviteClientOverview: 'הזמנת לקוח',
  programOverview: 'תוכנית אימונים',
  planOverview: 'תוכניות אימונים',
  achievementsOverview: 'מטרות והישגים',
  statisticsOverview: 'סטטיסטיקות ומדדים',
  paperworkOverview: 'מסמכים ושאלונים',
  createMyExerciseOverview: 'יצירת תרגילים אישיים',
  welcomeAspireClip: 'סירטון ברוכים הבאים',
  welcome: 'ברוכים הבאים!',
  welcome_sub:
    'תודה שבחרת להתנסות עם ASPIRE, אנו מבטיחים לכם לעשות כל שנוכל כדי שחווית האימונים איתכם תיהיה בלתי נשכחת!.\n' +
    'אך קודם תנו לנו להציג בפינכם כמה מיכולותנו, מה תרצו לדעת?',
  // profileCard
  addCertificates: 'הוסף הכשרה',
  addLocation: 'הוסף מיקום',
  socialDetails: 'רשתות חברתיות',
  socialDetails_sub: 'הוסף לינקים לפרופילים חברתיים אשר ברשותך',
  userDetails: 'פרטי המאמן',
  userDetails_sub: 'מלא את השדות הרשומים והעלה תמונת פרופיל',
  introductionVideo: 'סרטון קצר על המאמן',
  introductionVideo_sub: 'הוסף סרטון קצר אשר מסביר ומציג את עצמך למען לקוחות פוטנציאליים',
  trainingLocations: 'מיקום אימונים',
  trainingLocations_sub: 'הוסף מקומות המשמשים אותך לאימונים',
  professionCertificates: 'הסמכות והכשרות',
  professionCertificates_sub: 'העלה תעודות הכשרה שברשותך להוכחת מקצועיותך',
  imagesAndVideos: 'תמונות וסרטונים',
  SearchAPlace: 'חפש מקום/איזור:',
  bio: 'ביוגרפיה',
  myGallery: 'הגלריה שלי',
  userBiography: 'ביוגרפיה',
  userBiography_sub: 'רשום כאן על עצמך ועל הניסיון האישי שצברת בתחום.',
  city: 'עיר',
  expertise: 'מומחיות',
  facebook: 'פייסבוק',
  instagram: 'אינסטגרם',
  youtube: 'יוטוב',
  twitter: 'טוויטר',
  linkedin: 'לינקדאין',
  selectMyMedia: 'בחר מהמדיה שלי',
  selectFilesToUpload: 'בחר קבצים מהמכשיר',
  wouldYouLikeAWalkThru: 'האם תרצה הדרכה מהירה לבניית תוכנית?',
  programCreationTutorial: 'בניית תוכנית עם הדרכה',
  noThankUTutorial: 'לא תודה, אמשיך לבד',
  // tutorials
  chooseClient_sub: 'בחר בך או בלקוח מהרשימה לשייך את תוכנית האימונים אליו.',
  groupClients_sub: "בחירה במספר לקוחות התוכנית תהפוך ל'תוכנית אימונים קבוצתית'.",

  fillProgramTitle: 'תן כותרת לתוכנית האימונים.',
  afterFillForm: 'לאחר השלמת הפרטים ',
  clickHereToAddExercises: "לחץ על הוספת תרגילים לאימון.",
  saveToProceed: "לחץ על 'שמור' לסיום.",
  nextToProceed: "לחץ על 'הבא' להמשך.",
  nextToProceed2: "לאחר סיום בחירת התרגילים ,לחץ על 'הבא' להמשך.",
  doneToProceed: "לחץ על 'סיום' לשמירת השינויים שנעשו בדף.",
  chooseExercisesTutorial_sub: 'בחר ב3 תרגילים להוספה לתוכנית האימונים.',
  selectedExercisesTutorial_sub:
    'כאן יופיעו התרגילים אשר בחרתם, בלחיצה על התגית תיפתח רשימת התרגילים.',
  exerciseType_categories: 'בחירת סוג תרגיל וקטגוריות',
  filterTutorial_sub: 'כאן ניתן לבחור בתרגיל אחד או לקבץ מספר תרגילים ביחד (סופר-סט).',
  clickOnAlice_sub: "ברשימת הלקוחות לחץ על 'אליס' כדי לצפות במדדיה.",

  // 'ה"קטגוריות" מיועדות לסינון מהיר יותר למציאת התרגילים אותם תרצו לבחור לתוכנית.',
  filterOptions: 'אפשרויות נוספות',
  filterOptions_sub: 'ניתן למצוא תרגילים בחיפוש חופשי, לפי ציוד, שריר מטרה או קטגורית אימונים.',
  aspireTrainerIntroduction_sub:
    '"כלקוחה הראשונה שלך בAspire, הרשה לי לתת לך סיור קצר, אני מבטיחה לך שתבין מהר יותר את המערכת איתי לצדך."\n\nנתחיל ב:',
  aspireClientIntroduction_sub:
    '"בביקורך הראשון בAspire, הרשה לי לתת לך סיור קצר, אני מבטיח לך שתבינ/י מהר יותר את המערכת איתי לצדך."\n\nנתחיל ב:',
  aspireClientIntroductionTwo_sub:
    '"כמאמן האישי שלך, אשמח לתת לך סיור קצר באפליקציה, כדי שנוכל להתמצא בה טוב יותר בעתיד."\n\nנתחיל ב:',

  editBtnTutorial_sub: 'בלחיצה על הכפתור תוכל להוסיף/למחוק סטים ולשנות את השדות לפי יכולות הלקוח.',
  exerciseOptionsTutorial_sub:
    'אפשרויות נוספות לכל תרגיל כגון:\nהוספת הערות, שינוי מדדי התרגיל, עריכת שם וכו...',
  exerciseEquipmentTutorial_sub: 'שינוי ציוד התרגיל במקרה הצורך.',
  squareInfo_hint: 'כאן יוצג כמות אימונים הנעשו בעזרת התוכנית + כמות המשקל שהורם סך הכל.',
  sessionUpdateBtn_hint: 'בלחיצה כאן תוכל להתחיל אימון ולעדכן אותו בזמן-אמת.',
  exerciseDescription_hint:
    'בלחיצה על שם התרגיל יפתח דף סבר ביצוע התרגילת ציוד אפשרי, ומפת שרירים.',
  superset_hint: 'הצגת סופר-סט, החלק ימינה/שמאלה למעבר בין התרגילים.',
  clickToCheck_hint: "בלחיצה על שם הסט כ'הושלם' ויפעיל את הסט הבא.",
  modifyExerciseBtn_hint:
    'בלחיצה על הכפתור הכרטיס יפתח למצב עריכה ותוכל/י לשנות מדדים וסטים לפי הצורך.',
  fillClientInfo: 'מילוי פרטי הלקוח',
  fillClientInfo_sub: 'מלא את פרטי הלקוח לשליחת ההזמנה',
  changeClientsAppSettings: 'הגדרות חשבון הלקוח',
  changeClientsAppSettings_sub: "במידת הצורך שנה את הגדרות חשבון הלקוח, אם לא לחץ על 'הבא'.",
  editProfile: 'עריכת פרופיל',
  editProfile_sub: 'התחל/י לבנות את הפרופיל שלך כאן.',
  uploadImage_sub: 'כאן תופיע תמונת הפרופיל שלך, שנתחיל לבנות?',
  trainersProfile: 'פרופיל המאמן',
  trainersProfile_sub: 'בפינה הימנית נמצא פרופיל המאמן שלך, בלחיצה עליו תוכל/י לבקר בו.',
  notifyUserToVerifyAccount_desc:
    'חשבונך לא אומת עדיין, אם הודעת האימות אינה התקבלה לדוא"ל אנא לחץ "שלח שוב" ' +
    "(יש לבדוק את תיבת 'קידומי מכירות' ליתר ביטחון)",
  sendAgain: 'שלח שוב',
  cantFindVerificationMail: 'הודעת אימות החשבון לא נמצאה בדוא"ל?',
  cantFindVerificationMail_comment: "(אנא בדוק את תיבת 'קידומי מכירות' ליתר ביטחון)",
  verificationSent: 'הודעת אימות נשלחה',
  hide: 'הסתר',
  dontShowAgain: 'אל תציג אותי שוב',
  personalProgram: 'תוכנית אישית - לקוח אחד',
  groupProgram: "תוכנית לקבוצה - מס' לקוחות",
  pickAColor: 'בחר צבע',
  chooseDate: 'בחר תאריך',
  selectDate: 'בחר תאריך',
  selectDates: 'בחר תאריכים',
  chooseDateRange: 'בחר טווח תאריכים',
  makeAllDayEvent: 'אירוע זה פעיל לכל אורך היום.',
  createAnEvent: 'יצירת אירוע',
  bookASession: 'קבע אימון חדש',
  editAnEvent: 'עריכת פרטי האימון',
  rescheduleAnEvent: 'דחיית אימון לזמן אחר',
  writeATitle: 'רשום כותרת...',
  participants: 'משתתפים',
  advancedOptions: 'אפשרויות מתקדמות',
  recurringEvent: 'אפשרויות אירוע חוזר',
  openRecurringOptions: 'פתח אפשרויות אירוע חוזר',
  repeatEvery: 'חזרה כל',
  repeatEvery_day_info: 'האירוע יחזור על עצמו בכל יום או בכל X ימים.',
  repeatEvery_week_info: 'האירוע יחזור על עצמו כל שבוע או x שבועות בימים ספציפיים.',
  repeatEvery_month_info: 'האירוע יחזור על עצמו בכל x חודש ביום ספציפי בחודש.',
  stopConditions: 'אפשרויות עצירה',
  neverStop: 'ללא עצירה',
  neverStop_info: 'אירוע זה יחזור על עצמו לעולם.',
  untilDate: 'חזור עד לתאריך',
  untilDate_info: 'אירוע זה יחזור על עצמו עד שיגיע לתאריך מסוים.',
  untilNumber: 'חזור עד ',
  occurrences: 'אירועים',
  untilNumber_info: 'אירוע זה יחזור על עצמו עד שיופיע מספר פעמים.',
  eventDates: 'זמן האירוע',
  trainer: 'מאמן',
  confirmArrival: 'אשר הגעה',
  postpone: 'לא אגיע',
  cancelArrival: 'לא אגיע',
  reschedule: 'שנה לזמן אחר',
  repeatedEvent: 'אירוע חוזר',
  thisEventRepeats: 'אירוע זה חוזר על עצמו ',
  for: ' למשך ',
  until: ' עד ',
  weekly2: ' כל שבוע ',
  daily2: ' כל יום ',
  inDays: ' בימים ',
  editRecurringEventSelect: 'בעריכת אירוע חוזר זה ערוך...',
  deleteRecurringEventSelect: 'במחיקת אירוע חוזר זה מחק...',
  thisEventOnly: 'אירוע זה בלבד.',
  thisEventAndFollowing: 'אירוע זה והבאים אחריו.',
  allEvents: 'כל האירועים.',
  pending: 'ממתין לאישור',
  confirmed: 'מגיע',
  canceled: 'לא מגיע',
  noDatesSelected: 'לא נבחרו תאריכים חדשים, אין אפשרות לשנות את זמן האירוע.',
  onGoing: 'מתמשך',
  beginner: 'מתחיל',
  trained: 'מאומן',
  wellTrained: 'מאומן מנוסה',
  athlete: 'אתלט',
  copyProgramSuccessfully: 'התוכנית הועתקה לחשבונך בהצלחה',
  success: 'הושלם בהצלחה',
  successfullyUpdated: 'העידכון הושלם בהצלחה',
  successfullyUpdatedPhone: 'המספר התעדכן בהצלחה במערכת!',
  successfullyUpdatedPhone2: 'מהיום תוכל/י להתחבר אלינו בהזנת מספר הטלפון בלבד.',
  successfullyUpdatedPassword: 'סיסמתך התעדכן בהצלחה במערכת!',

  somethingWentWrong: 'שגיאה קלה',
  stay: 'השאר',
  articles: 'מאמרים',
  helloUserStore: (name) => `שלום ${name}`,
  helloUserStore_desc: 'ברוכים הבאים לחנות ASPIRE',
  storePrograms: 'ארכיון תוכניות',
  storePrograms_desc:
    'הנכם מוזמנים להעתיק, לעדכן ולשתף את תוכניות האימונים של אספייר עם לקוחותיכם.',
  personal_info:
    'תוכנית אימונים אישית המיודעת ללקוח אחד, ניתן לשייך את התוכנית ללקוחות נוספים לאחר שהכנתה הושלמה.',
  group_info:
    'תוכנית זו נועדה לאימוני קבוצות, בחר מספר לקוחות לתוכנית זו וצפה ביכולותיהם במקום אחד.',
  public_info:
    'זוהי תוכנית אימונים חינמית המיועדת לצפייה ותרגול ע"י לקוחותיך או מבקרים בפרופיל המאמן שלך, כדי להתנסות ולהבין את כישוריך.',
  store_info:
    'זוהי תוכנית אימונים המיועדת לבדיקה ע"י ASPIRE, אם תאושר, התוכנית תעלה לחנות עם שמך ותג מיוחד (בהמשך תוכל לבנות תוכניות בתשלום).',
  circuitTraining: 'אימון מחזורי',
  SET_option: 'סטים',
  CIRCUIT_option: 'מחזורי',
  SET_info:
    'תצוגת ברירת מחדל, מאפשרת למאמן לבנות תוכנית הבנויה לפי סטים, סופר-סטים וכו...',
  CIRCUIT_info: 'תצוגת אימון מחזורי, מאפשרת למאמן לציין מספר סבבים לתרגילים הנבחרים.',
  optionsAreDisabled: 'אין אפשרות לשנות את הבחירה הקיימת, הנך נמצא בהדרכה או במצב עריכה.',
  original: 'המקור',
  newUpdates: 'עידכונים חדשים',
  whatsNew: `מה חדש?!`,
  signUpBy: 'הרשם בעזרת',
  signinBy: 'התחבר בעזרת',
  inviteBy: 'הזמן בעזרת',
  updateDetails_2: 'עדכן פרטי',
  updateDetails: 'עדכן פרטים',
  signupByPhone: 'הרשם בעזרת קוד טלפוני',
  signupByEmail: 'הרשם בעזרת חשבון דוא"ל',
  insertYourNumberToVerify: 'לקבלת SMS לאימות המכשיר, אנא רשום את מספר טלפונך.',
  verifyEmailBeSent: 'לאחר ההרשמה ישלח אימייל לאימות חשבונך, אנא בדוק גם את איזור ה"קידומי מכירות".',
  sendVerificationCode: 'שלח לי קוד אימות',
  aspireIntro_title_1: 'עידן חדש למאמנים אישיים',
  aspireIntro_desc_1:
    'ASPIRE שמה כמטרה להעצים את המאמן האישי אשר נמצא בכל שלב מקצועי, מכל בחינה, עם כל לקוח אישי.',
  aspireIntro_title_2: 'השארו מחוברים 24/7',
  aspireIntro_desc_2: 'צור סביבה מקצועית ואקסקלוסיבית בשבילך ובשביל לקוחותיך, ועקוב אחר התקדמותם.',
  aspireIntro_title_3: 'חוויה אישית וייחודית',
  aspireIntro_desc_3:
    'כל יכולת שפיתחנו נבחנה בדיקדוק כדי לעזור לך להפוך לגרסה הטובה ביותר שלך: יותר מקצועית ,יותר אישית, יותר עצמך.',
  copied: 'הועתק',
  certainField: 'השדה',
  addWorkoutRoutine: 'הוספת אימון לתוכנית',
  workoutRoutine: 'אימון',
  addExercisesToFirstCreatedProgram: (title) => `ל'${title}' לא התווספו תרגילים, אנא בנה את '${title}' קודם.`,
  addAProgram: 'הוספת תוכנית אימונים',
  saveProgram: 'שמור שינויים בתוכנית',
  clickHereAddExercises: 'לחץ כאן להוספת תרגילים לתוכנית',
  removeFromPlan: 'הסר אימון מהתוכנית',
  display: 'תצוגה',
  removeRoutine: 'הסר אימון זה',
  areYouSureRemoveRoutine: 'האם את/ה בטוח/ה שברצונך להסיר את האימון מהתוכנית?',
  routineDetails: 'פרטי האימון',
  editRoutineDetails: 'עריכת פרטי האימון',
  editProgramDetails: 'עריכת פרטי התוכנית',
  modifyExercises: 'עידכון תרגילים',
  programAdvanceOptions: 'אפשרויות מתקדמות לתוכנית',
  routineAdvanceOptions: 'אפשרויות מתקדמות לאימון',
  attention: 'הודעה חשובה!',
  goBackNotice: 'חזרה לדף הקודם תמחק את השינויים אשר נעשו בדף זה, האם הנך בטוח/ה שברצונך לחזור לדף הקודם?',
  processingMedia: 'העלאה בתהליכי השלמה,\nיתכן והתהליך יקח כמה דקות.\nהנך יכול/ה להמשיך בעבודתך.',
  clientProfileIsPrivate: 'פרופיל זה הינו פרטי ואינו ניתן לצפייה ע"י איש פרט למאמנך האישי.',
  openContactsList: 'פתח רשימת אנשי קשר',
  countryCodes: 'קוד ארצות',
  selectCountry: 'בחר מדינה',
  selectCountryCode: 'אנא בחר קוד מדינה',
  noCountryCodeAttached: 'קוד המדינה לא נמצא במספר הטלפון הנבחר',
  unverifiedClientAccountInfoEmail: () => (
    <span>חשבון לקוח נוצר תחת כתובת זו <strong>אך אינו אומת עדיין.</strong>
      <br />
      אנא חפש בחשבון הדוא"ל הודעה מAspire <strong>בתיבת 'ראשי' או 'קדומי מכירות'</strong>  וקבל את קוד ההזמנה.
    </span>
  ),
  unverifiedClientPhoneInfo: () => (
    <span>חשבון לקוח נוצר תחת כתובת זו <strong>אך אינו אומת עדיין.</strong>
      <br />
       אנא בדוק את ההודעות הנכנסות במכשיר<strong> ומצא הודעה מAspire בעלת קוד ההזמנה</strong>, העתק את הקוד ולחץ על &apos;התחבר&apos;.
    </span>
  ),
  resendSMStoUnverifiedClientPhone: () => (
    <span>בעוד כמה רגעים תישלח אלייך הודעת SMS עם קוד ההזמנה.
      <br />
      אנא בדוק את ההודעות הנכנסות במכשיר <strong> ומצא הודעה מAspire בעלת קוד ההזמנה</strong>, העתק את הקוד ולחץ על &apos;התחבר&apos;.
    </span>
  ),
  calendarSettings: 'הגדרות היומן שלי',
  rescheduleReason: 'סיבת השינוי',
  sundayToSunday: 'ראשון עד ראשון',
  mondayToMonday: 'שני עד שני',
  firstDayOfTheWeek: 'יום ראשון בשבוע',
  firstDayOfTheWeek_sub: 'בחר את היום הרצוי שיתחיל השבוע ביומנך:',
  restrictAvailableHours: 'הגדרת שעות פעילות',
  restrictAvailableHours_sub: 'הגדרת שעות פעילות תאפשר ללקוחותך לקבוע אימונים רק בזמנים המותרים בין שני הערכים.',
  timeInputSettings: 'הגדרות שדה זמן',
  timeInputSettings_sub: 'הגדר את שדה הזמן בצורה הנוחה לך ביותר',
  daysOff: 'ימים חופשיים',
  daysOff_sub: 'הגדר ימים חופשיים אשר לקוחותך לא יוכלו קבוע אימונים בהם. לדוגמא: יום שבת',
  timeInputExample: 'לחץ כאן כדי לראות את השינויים על שדה הזמן.',
  timegrid: 'משבצות זמנים',
  timeWheel: 'גלגל זמנים',
  fiveMinutes: '5 דקות',
  tenMinutes: '10 דקות',
  fifteenMinutes:'15 דקות',
  thirtyMinutes: '30 דקות',
  watchYoutubeVideo: 'צפה בסרטון הסברה',
  bookSession: 'קבע אימון',
  waitingForResponse: 'ממתין לתשובה',
  confirmedArrival: 'אישרו הגעה',

  access_denied_pro_up: 'פעולה זו אפשרית למאמנים במסלול Pro ומעלה',
  access_denied_gold_up: 'פעולה זו אפשרית למאמנים במסלול Gold ומעלה',
  access_denied_update_sub: ' ,לעדכון מסלול עבור להגדרות > שרותי Aspire.',
  clickHere: 'לחץ כאן.',

  latestStatClientProgress_info: (name) => `לצפייה בנתוני האימון האחרון של ${name}, `,
  latestStatClientWorkout_info: (name) => `גלול למטה לצפייה בנתוני האימון האחרון של ${name}.`,
  NoSessionsOnWorkout_info: (name) => `${name} עדיין לא עידכנ/ה נתונים לאימון זה.`,

  discount: '50% הנחה',
  basicService: 'מאמן מתחיל',
  proService: 'מאמן מקצועי',
  upTo10Clients: 'עד 10 לקוחות',
  proPriceMonthly: '$4 / month',

  /*

  discount: '50% Discount',
  basicService: 'Basic Trainer',
  proService: 'Basic Trainer',
  upTo10Clients: 'Up To 10 Clients',

  inviteClients: 'Invite Clients',
  basicProgramBuild: 'Basic Program Build',

  */


};

export const en = {
  intro: 'Intro',
  media: 'Media',
  reviews: 'Reviews',
  location: 'Location',
  calendar: 'Calendar',
  calendar_sub: 'A weekly calendar',
  categories: 'Categories',
  category: 'Category',
  achievements: 'Achievements',
  myAchievements: 'My Achievements',
  // NavTop
  inviteClient: 'Invite client',
  invite: 'Invite client',
  resend: 'Resend',
  'fill clients details': "Step 1: Fill client's details",
  workouts: 'Workouts',
  myWorkouts: 'My Workouts',
  workoutsArchive: 'Workouts archive',
  workoutsArchive_2: 'Workouts archive',
  sessionsArchive: 'Sessions archive',
  workoutPlanArchive: 'Workout plans archive',
  createWorkout: 'Create a workout',
  createWorkout_sub: 'Fill workout program details',
  chooseExercises: 'Select exercises',
  chooseExercises_sub: 'Select exercises from the list',
  chooseExercise: 'Select exercise',
  chooseExercise_sub: 'Select exercise from the list',
  workoutLoggerHeader: 'Edit Workout Menu',
  workoutLoggerHeader_sub: 'Build exercise sets, reps & weight',
  routineUpdateDetails: 'Edit routine details',
  routineUpdateDetails_sub: 'Update routine title & settings',
  createAProgram: 'Create a program',
  createAWProgram: 'Create a workout',
  getAFreeProgramTemplate: 'Get A Free Program',
  aspireIntroductionVideoTrainer: 'Aspire Introduction Video',
  aspireIntroductionVideoClient: () => 'Aspire Introduction Video',
  programDetails: 'Program details',
  createPlan_sub: 'Fill program details',
  addPrograms: 'Add programs',
  modifyProgress: 'Modify Weekly Progress',
  modifyProgress_sub: 'build an automated progress per week.',
  modifyProgramsProgressPage_info:
    'Choose a program, modify variables by desired week (reps, weights, rest...).\nThese variables will be updated for the client at the desired week when they start a session with ASPIRE.',
  'Select Programs to Add': 'Select programs to add',
  sessionStart: 'Session start',
  'Session Started At': 'Session started at: ',
  clientsList: 'Clients list',
  statistics: 'Statistics',
  MyStatistics: 'My Statistics',
  history: 'Data',
  createBodyStats: 'Create Body Stats',
  updateBodyStats: 'Update Body Stats',
  loading: 'Loading...',
  bodyStatsInfo_title: 'Stats Information',
  bodyStatsInfo_sub: 'Date:',
  UpdateYourCurrentBodyStats: 'Update your Current Body Stats',
  startSession: 'Start Session',
  createPastSession: 'Create a past session',
  updatePastSessions: 'Update a past session',
  copyProgramToClient: 'Copy program to another client',
  copyProgram: 'Copy program',
  copyProgramToProfile: 'Copy program to my profile',
  updatePastSessionsFP: 'Update a session for this program',
  workoutsCompleted: 'Workouts Completed',
  tonnageLifted: 'Tonnage Lifted',
  totalSets: 'Total Sets',
  totalReps: 'Total Reps',
  exercisesDone: 'Exercises Done',
  active: 'Active',
  completed: 'Completed',
  programsTitle: "Program's Title",
  eventsTitle: "Event's Title",
  settings: 'Settings',
  applicationSettings: 'Application Settings',
  sessionsHistory: "Session's History",
  workoutPrograms: 'Workout programs',
  workoutPlans: 'Plans',
  workoutsPerWeek: 'Workouts per week',
  currentWeek: 'Current week',
  assignToClient: 'Assign to client',
  sendDocToClients: 'Send document to clients',
  sendSurveyToClients: 'Send survey to clients',
  copySurveyToMe: 'Copy survey & send to clients',
  copyDocToMe: 'Copy Document to my account',
  of: 'of',
  programs: 'Programs',
  routines: 'Workout Routines',
  minutes: 'Minutes',
  hours: 'Hours',
  hour: '1 Hour',
  and: 'and',
  days: 'Days',
  day: 'Day',
  today: 'Today',
  weeks: 'Weeks',
  week: 'Week',
  month: 'Month',
  months: 'Months',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  times: 'Times',
  startTime: 'Start',
  endTime: 'End',
  chooseTrainer: 'Choose trainer',
  chooseClient: 'Select a client',
  chooseClients: 'Select clients',
  clientsParticipates: 'Participants',
  chooseAchievementType: 'Select achievement type',
  UsingThisProgram: 'Using this program',
  exercises: 'Exercises',
  description: 'Description',
  addDescription: 'Add description',
  activityHistory: 'Activity History',
  currentProgram: 'Current Program',
  default: 'Default',
  chooseEquipment: 'Select equipment',
  choose: 'Choose',
  // body stats labels
  height: 'Height',
  weight: 'Weight',
  muscleMass: 'Muscle Mass',
  fat: 'Fat',
  calories: 'Calories',
  water: 'Water',
  bmi: 'BMI',
  BMI: 'BMI',

  shoulders: 'Shoulders',
  arms: 'Arms',
  leftArm: 'Left arm',
  rightArm: 'Right arm',
  chest: 'Chest',
  waist: 'Waist',
  hips: 'Hips',
  thighs: 'Thighs',
  leftThigh: 'Left thigh',
  rightThigh: 'Right thigh',
  lowerThighs: 'Lower thighs',
  leftLowerThigh: 'Left Lower thigh',
  rightLowerThigh: 'Right Lower thigh',
  calves: 'Calves',
  leftCalf: 'Left Calf',
  rightCalf: 'Right Calf',

  isAdvancedMeasurements: 'Advanced measurements',
  addBodyMeasurements: 'add body measurements',
  bodyMeasurements: 'Body measurements',
  bodyStats: 'Body stats',
  bodyStats_sub: 'Latest stats by client',
  bodyStats_sub_client: 'Your latest stats',
  performance: 'Performance',
  performance_sub: 'Latest stats by client',
  performance_sub_client: 'Your latest stats',
  certifiedPersonalTrainer: 'Certified Personal Trainer',
  straight: 'Straight',
  superset: 'Superset',
  super_set: 'Superset',
  pyramid: 'Pyramid',
  interval: 'Interval',
  aerobic: 'Aerobic',
  strength: 'Strength',
  static: 'Static',
  kilograms: 'Kg / T',
  pounds: 'Lbs / Pound',
  meter: 'Cm / M', // M
  male: 'Male',
  female: 'Female',
  group_option: 'Group',
  personal_option: 'Personal',
  public_option: 'Public',
  store_option: 'Store',
  other_option: 'Other',
  public_id: 'Public ID',
  uri: 'URL',
  order: 'Order',
  content: 'Content',
  email: 'Email',
  title: 'Title',
  password: 'Password',
  confirmPassword: 'Confirm password',
  oldPassword: 'Current password',
  newPassword: 'New password',
  passwordOrInvite: 'Password / Invite code',
  resetCode: 'Reset password code',
  forgotPassword: 'Forgot password?',
  alreadyHaveTheCode: 'I already have a code',
  inviteCode: 'Invitation code',
  firstName: 'First name',
  lastName: 'Last name',
  BirthDate: 'Birth date',
  aboutMe: 'About me',
  phone: 'Phone Number',
  weightUnits: 'Weight units',
  distanceUnits: 'Distance units',
  language: 'Language',
  chooseLanguage: 'Choose language',
  startDate: 'Starts on',
  endDate: 'Ends on',
  date: 'Date',
  eventsHour: "Event's time",
  allDay: 'All day',
  duration: 'Duration',
  workoutPerWeek: 'Workout per week',
  numOfWeeks: 'Note: Count in weeks',
  numOfTimes: 'Note: num. of times',
  updateStats: 'Update stats',
  chooseImage: 'Select image',
  uploadMedia: 'Upload media',
  uploadFile: 'Upload file',
  mediaLibrary: 'Media library',
  uploadImage: 'Upload image',
  uploadVideo: 'Upload video',
  files: 'Files',
  gallery: 'Gallery',
  myMedia: 'My media',
  thisMedia: 'This media',
  type: 'Type',
  exercise: 'Exercise',
  round: 'Round',
  set: 'Set',
  sets: 'Sets',
  reps: 'Reps',
  time: 'Time',
  speed: 'Speed',
  distance: 'Distance',
  intensity: 'Intensity',
  rest: 'Rest time',
  timeDone: 'Time done',
  totalTimeDone: 'Total time',
  totalDistance: 'Total distance',
  cancel: 'Cancel',
  clear: 'Clear',
  confirm: 'Confirm',
  updatePassword: 'Update Password',
  save: 'Save',
  leave: 'Leave',
  send: 'Send',
  done: 'Done',
  assignAsDone: 'Completed',
  markAsCompleted: 'Mark as completed',
  finishSession: 'Finish Session',
  finishSession_desc: 'Click here to finish current session.',
  edit: 'Edit',
  modifyDoc: 'Modify document',
  delete: 'Delete',
  expand: 'Expand',
  changeRepsWeight: 'Change Reps/Weight',
  backToCards: 'Back to cards',
  back: 'Back',
  skip: 'Skip',
  add: 'Add',
  remove: 'Remove',
  addSet: 'Add set',
  removeSet: 'Remove set',
  setAdd: 'Set Added',
  moreDetails: 'More details',
  skipped: 'Skipped',
  details: 'Details',
  submit: 'Submit',
  register: 'register',
  register_login: 'Sign up / Login',
  login: 'Login',
  invitedClient: 'Invited client',
  signin: 'Sign in',
  signup: 'Sign up',
  createAccount: 'Create trainer account',
  resetChanges: 'Reset Changes',
  exitEditMode: 'Exit edit mode',
  clientInvite: "Client's invite",
  saveChanges: 'Save Changes',
  enter: 'Enter',
  info: 'Info',
  information: 'Information',
  support: 'Support',
  myInquiries: 'My inquiries',
  exerciseInfo: 'Exercise info',
  logout: 'Logout',
  dontHaveAnAccount: `Hey, don't have an account yet?`,
  muscleId: 'Muscle ID',
  title_he: 'Title HEB',
  title_en: 'Title ENG',
  description_he: 'Description HEB',
  description_en: 'Description ENG',
  moreOptions: 'More options',
  exerciseNotes: 'Exercise notes',
  clickHereFullScreen: 'Click here to view in full-screen',
  close: 'Close',
  notes: 'Notes',
  exerciseName: 'Exercise name',
  editExerciseName: 'Change exercise name',
  changeExerciseFields: 'Change exercise fields',
  changeExerciseOrder: 'Change exercise order',
  addExerciseToSuperSet: 'Add exercise to superset',
  deleteExercise: 'Delete exercise',
  myProfile: 'My profile',
  store: 'Store',
  notice: 'Notice',
  alert: 'Alert',
  thisModalCantBeOpened: "This popup can't be opened while uploading, please try again later.",
  your: 'Your ',
  invitationCode: 'Invitation code',

  inviteSentInfoByEmail: 'In a few minutes, your client will be invited to Aspire via email.',
  inviteSentInfoSpam: "(Please check the 'Promotions' inbox as well)",
  inviteSentInfoBySMS: 'Your client will receive an invite to Aspire via SMS.',
  inviteSentInfoBySMS_sub: (name) =>
    `If ${
      name || 'your client'
    } don't get an SMS from us in the next few minutes, invitation code is:`,

  NoWorkoutsToShow: 'No Registered Workout Programs',
  NoWorkoutsToShowFor: (name) => `No registered workout programs for ${name}`,
  clickHereToCreateProgram: 'Create your first workout program here!',
  NoActivitiesToShow: "Couldn't find any activities",
  NoGoalsToShow: 'No goals recorded',
  NoStatsToShow: 'No performance stats recorded.',
  NoStatsToShow_sub: 'A training session needs to be recorded in order to update performance stats',
  clickHereToCreateGoal: 'Create a goal for you / your client',
  NoAchievementToShow: 'No goals have been achieved, yet!',
  ChangeLogToCompleted:
    'Current set has been declared as "Skipped", should it be updated to "Completed"?',
  CLIENT: 'Client',
  TRAINER: 'Trainer',
  NUTRITIONIST: 'Nutritionist',
  english: 'English',
  hebrew: 'Hebrew',
  selectProgramType: 'Select Program type',
  selectProgramView: 'Select Program View',
  exercisesSelected: 'Exercises selected',
  againstEquipment: 'with',
  addExercise: 'Add exercise',
  addExerciseToSession: 'Add exercise',
  addExerciseInfo: 'Click here to add a new exercise',
  addSuperSet: 'Add super-set',
  selectExercises: 'Select exercises',
  selectExercise: 'Select exercise',
  selectedExercises: 'Selected exercises',
  addExercises: 'Add exercises',
  moveExercisesInfo: 'Drag your exercises into the order you want for your workout.',
  bodyWeightInfo: `Insert '0' in the weight field will be consider as 'body weight'.`,
  aerobicFieldsInfo: `Insert '0' in a field will be considered as ignored.`,
  reorderExercises: 'Reorder Exercises',
  reorderExercises_sub: 'Reorder or remove Exercises',
  programsSelected: 'Programs selected',
  noticeChangingCurrentProgram:
    "Changing current Program will exit 'Assign Mode' and create a new Program instead, are you sure?",
  noticeUnsavedChanges: (numbers) =>
    `workouts ${numbers} have not been saved and still are in 'Edit Mode', are you sure you want to continue?`,
  assignAsAchieved: 'Assign target as achieved',
  assignAsAchieved_notice: 'Are you sure you want to mark this target as achieved?',
  assignAsAnswered: 'Mark as answered',
  assignAsAnswered_notice: 'Was our replay helpful?',
  resetProgressToThisWeek_notice: "Are you sure you want to remove all changes made to this week's progress?",
  goBackWithoutSave_notice: "Going back without saving will reset all your changes, are you sure you want to go back?",
  total: 'Total',
  more: 'More',
  liftingTime: 'Lifting time spent',
  avgWorkoutTime: 'Avg. workout time',
  avgRestTime: 'Avg. rest time',
  showMore: 'Show more',
  showLess: 'Show less',
  archive: 'Archive',
  saySomething: 'A few words...',
  howWasSession: 'Rate your training session',
  sessionTime: 'Session time',
  preparation: 'Preparation',
  execution: 'Execution',
  muscles: 'Muscles',
  equipment: 'Equipment',
  difficulty: 'Difficulty',
  defaultEquipment: 'Default equipment',
  chooseDefaultEquipment: 'Select default equipment',
  exerciseType: 'Exercise type',
  target: 'Target',
  synergist: 'Synergist',
  stabilizers: 'Stabilizers',
  antagonist: 'Antagonist',
  removeExercise: 'Remove Exercise',
  removeExerciseDesc: "Are you sure you want to remove selected exercise's?",
  clickToDelete: 'Delete exercises',
  filter: 'Filter',
  hi: 'Hi',
  sessionSection1: 'Who are we training today?',
  sessionSection2: 'Please Select a workout Program',
  'Choose Client & Program': 'Select client & Program',
  chooseProgram: 'Select a Program',
  'Are you sure you want to delete': 'Are you sure you want to remove',
  areYouSureFinishSession: 'Are you sure you want to finish current training session?',
  areYouSureRemoveEquipment: 'Are you sure you want to remove selected media from the equipment?',
  areYouSureUnSubscription: 'Are you sure you want to unsubscribe?',
  cancelSubscription: 'Cancel Subscription',
  theseBodyStats: 'these body stats',
  me: 'Me',
  isPersonalTrainingSession: 'Current session is a part of the "personal training" package',
  sessionSummary: 'Session summary',
  bestSets: 'Best sets',
  current_state: 'Current',
  target_state: 'Target',
  initial_state: 'Initial',
  initialvalue: 'Initial',
  targetvalue: 'Target',
  kg: 'kg',
  KG: 'kg',
  Lbs: 'lbs',
  LBS: 'lbs',
  Ft: 'Ft',
  FT: 'Ft',
  Yd: 'Yd',
  YD: 'Yd',
  T: 'T',
  cm: 'cm',
  CM: 'cm',
  inch: 'Inches',
  INCH: 'Inches',
  FEET: 'Feet',
  yard: 'Yard',
  YARD: 'Yard',
  mile: 'Mile',
  MILE: 'Mile',
  km: 'km',
  KM: 'km',
  m: 'm',
  M: 'M',
  kcal: 'kcal',
  Kcal: 'kcal',
  KCAL: 'kcal',
  percent: '%',
  PERCENT: '%',
  '%': '%',
  M_KM: 'Cm / M / Km',
  YARD_MILE: 'Inches / Yards / Miles',
  KG_CM: 'Kg / Cm',
  LBS_INCH: 'Lbs / Inch',
  NoStatsRecorded: 'No stats recorded',
  activeAchievements: 'Active goals',
  completedAchievements: 'Achievements',
  createPlan: 'Create a workout program',
  myExercises: 'My exercises',
  myExercises_sub: 'My exercises list',
  inquiriesAnswers: 'Inquiries & Answers',
  inquiriesAnswers_sub: 'All you need to know about ASPIRE.',
  createInquiry: 'Ask us an Inquiry',
  createInquiry_sub: "And we'll respond as soon as possible.",
  createSolution: 'Solution',
  createSolution_sub: 'Write your solution.',
  replayInquiry: 'Replay',
  replayInquiry_sub: 'Write your replay to us.',
  exerciseStats_title: 'Exercise: ',
  programsStats_title: 'Program: ',
  'type in kg': 'Type in Kg',
  'type in cm': 'Type in Cm',
  typeInCm: 'Type in Cm',
  'type in %': 'Type in %',
  'type in Kcal': 'Type in Kcal',
  'type in lbs': 'Type in LBS',
  'type in inch': 'Type in inches',
  '': '',
  blank: '',
  EN: 'English',
  HE: 'עברית',
  ES: 'Español',
  EN_short: 'Eng',
  HE_short: 'עב',
  ES_short: 'Es',
  image: 'Image',
  video: 'Video',
  search: 'Search',
  session_of: 'session of',
  chooseGoal: 'Choose one goal',
  chooseGoalSub: 'Choose a performance/body stats goal',
  goalDetails: 'Goal Details',
  goalDetailsSub: 'Fill your goal details',
  createGoal: 'Create a Goal',
  min: 'min',
  sec: 'sec',
  lastUpdatedIn: 'Updated in',
  showInGraph: 'Show graph for',
  nextExercise: 'Next Ex.',
  prevExercise: 'Prev Ex.',
  currentExercise: 'Current Exercise',
  next: 'Next',
  prev: 'Previous',
  later: 'Later',
  seeAchievement: 'See Achievement',
  congratulations_sub: "Congratulations! you achieved a Goal you've set!",
  wellDone: 'well Done!',
  'please write your notes here': 'Please write your notes here...',
  addToList: 'Add to list',
  changePassword: 'Change password',
  changePassword_sub: 'Please update your password',
  updateUserCredentials: 'Update user credentials',
  updateUserCredentials_sub: 'Update your login credentials',

  createCategory: 'Create Category',
  categories_sub: `Aspire's Category List`,
  resendInvitation: 'Resend Invitation',
  resendInvitationAgain: 'Send Again',
  updateResendInvitation: 'Update & resend Invite',
  inviteAnotherClient: 'Invite another client',

  emailSent: 'Email sent',
  invitationSent: 'Invitation sent',
  invitationSentError: 'Error sending invite',
  profileDetails: 'My Profile Details',
  restDay: 'Rest day',
  typeOldPassword: 'Type old password',
  typeNewPassword: 'Type new password',
  onlyEnglishLetters: 'Should only contain english letters',
  'type in exercise title': 'Type in exercise title',
  createExercise: 'Create exercise',
  createExerciseAdmin: 'Create exercise',
  createExercise_sub: 'Create your own exercises here.',
  'fill exercise details': 'Fill exercise details',
  achievementDetails: 'Achievement details',
  achievementDetails_loading: 'Load achievement...',
  myActivities: 'My Activities',
  myActivities_sub: 'All your updates are listed here',
  bestSet: 'Best set',
  tonnage: 'Tonnage',
  bestTime: 'Best time',
  mostReps: 'Most reps',
  highestWeight: 'Highest weight',
  longestTime: 'Longest time',
  longestDistance: 'Longest distance',
  bestByRM: 'Best by RM',
  editName: 'Edit ex. name ',
  top: 'Top',
  center: 'Center',
  bottom: 'Bottom',
  bodyWeight: 'Body Weight',
  bodyWeightShort: 'body w.',
  processingImage: 'Processing Image',
  connect: 'Connect',
  uploading: 'Uploading...',
  errorUploadingFile: 'Could not upload image',
  addMedia: 'Add media',
  upToImgs: (number) => `Up to ${number} uploads`,
  fullScreen: 'Full Screen',
  skipEx: 'Skip Ex.',
  change: 'Change',
  nextSet: 'Next set',
  timer: 'Timer',
  stopwatch: 'Stopwatch',
  noExercisesAdded: 'No exercises added',
  pleaseGoBack: 'Please go back',
  session_has_ended: 'Session has ended',
  changeCurrentWeek: 'Change current week',
  changeCurrentSessionNum: 'Change current session num',
  statistics_of: (name) => `${name || ''}'s statistics`,
  history_of: (name) => `${name || ''}'s history`,
  workouts_of: (name) => `${name || ''}'s workouts`,
  activeAchievements_of: (name) => `${name || ''}'s goals`,
  completedAchievements_of: (name) => `${name || ''}'s achievements`,
  bodyStats_of: (name) => `${name || ''}'s body stats`,
  bodyStatsDetails: 'Loading...',
  bodyStatsDetails_of: (name) => `${name || ''}'s body stats`,
  bodyStatsDetails_sub: (name, stat) => `${name || ''}'s ${stat || ''} history`,
  performance_of: (name) => `${name}'s performance`,
  performanceDetails: `Performance history`,
  performanceDetails_of: (name) => `${name || ''}'s performance`,
  performanceDetails_sub: (name, stat) => `${name || ''}'s ${stat || ''} performance`,
  performanceDetails_exerciseStats_sub: (name) => `${name || ''}'s exercise performance`,
  performanceDetails_programsStats_sub: (name) => `${name || ''}'s Program performance`,
  achievementDetails_sub: (name) => `Accomplishments by ${name || ''}`,
  lastRegisteredStat: `Last registered stats: `,
  /* session translations */
  activeSessionInfo_primary: (setNum, log, lang) => buildNotification(setNum, log, lang || 'en'),
  activeSessionInfo_sub: (setNum, log, execution, lang) => {
    let sentance = buildNotification(setNum, log, lang || 'en');
    sentance += '\nFor changes click here.';
    sentance += `${execution ? `\nExecution: \n${execution}` : ''}`;
    return sentance;
  },
  aspireCommentTitle: () => `Aspire Says`,
  HALF_TIME_DOME: (timeLeft, log) => `You're half way there, time left : ${timeLeft}`,
  TIME_DOME_75: (timeLeft, log) => `We're getting closer, time left : ${timeLeft}`,
  TIME_DOME_90: (timeLeft, log) => `Almost Done, time left : ${timeLeft}`,
  TIMER_TIME_DOME: (timeLeft, log) => `Done! Good job!`,
  usingThisProgram: (title, endDate) => `used by '${title}' until ${endDate}`,
  addProgramToPlan: 'Add this program to your plan',
  myClientsList: 'My clients list',
  myClientsList_sub: 'update/unlink a client from your list',
  refreshInfo: 'Refresh info',
  refreshClientsInfo: 'Refresh clients info',
  unlink: 'Unlink',
  clientDetails: "Client's details",
  unlink_question: (clientName) =>
    `are you sure you want to unlink '${clientName}' from your client List?`,
  subscription: 'Aspire Subscription',
  subscription_sub: 'Update service subscription for Aspire',


  /* validation */
  pleaseChooseClient: 'Please select a client',
  pleaseSelectRoutine: 'Please select a routine',
  invalidDate: 'Invalid date',
  missingDate: 'Date is required',
  isRequired: (name) => `${name} is required`,
  tooShort: (name) => `${name} is too short`,
  tooLong: (name) => `${name} is too long`,
  typeMismatch: (name) => `${name} has a mismatch value`,
  rangeUnderflow: (name, value) => `${name} must be greater/equal than ${value}`,
  rangeOverflow: (name, value) => `${name} must be less/equal than ${value}`,
  passwordsMismatch: 'passwords do not match',
  "Please include an '@' in the email address": `Please include '@' in the email field`,
  typeMismatchReps: `'Reps' has a mismatch values`,
  typeMismatchReps_sub: `Current goal requires equal 'reps' value`,
  typeMismatchWeights: `'Weight' has a mismatch values`,
  typeMismatchWeights_sub: `'Weight' values cannot be equal to 0`,
  termsAgreement_1: 'By clicking Sign Up, you agree to our ',
  termsOfService: 'Terms of Service',
  termsAgreement_2: ' and that you have read our Data Use ',
  policy: 'Policy.',
  termsAgreement_3: ' ',
  privacyPolicy: 'Privacy policy',
  askMeAgain: 'Ask me again next time.',
  noThankYou: 'No thank you',
  letMyTry: 'Let me try on my own',
  checkTermsAgreement: 'Agree to aspire terms please',
  // error handler text
  error: 'error',
  emailAlreadyInUse: 'Email already in use',
  noUserFound: 'User not in the system',
  badCredentials: 'Please check your Password/email',
  emailNotVerified: 'Email is not verified',
  checkEmailFirst: 'Check your email before submit',
  accessDenied: 'Access Denied',
  unhandledError: 'Something went wrong :(',
  wrongPassword: 'Wrong password, try again',
  'Missing Credentials': 'Missing Credentials',
  lockForHour: 'Lock for 1 hour',
  emailAlreadyExist: 'Email already exist',
  emailIsGood: 'Email is good',
  invalidName: 'Invalid name',
  invalidEmail: 'Invalid email',
  emailNotExist: 'Email doesnt exist',
  invalidPhoneNumber: 'Invalid phone number',
  limitReached: 'Limit reached',
  invalidForm: 'Invalid form',
  invalidCode: 'Invalid code',
  userAlreadyExist: 'User already exist',
  phoneAlreadyExist: 'Phone number already exist',
  phoneNotExist: 'Phone doesnt exist',
  userSuspended: 'User is suspended',
  alreadyLoggedIn: 'User already logged in',
  progress: 'Progress',
  program: 'Program',
  /* onBoarding */
  // profile
  yourBusinessPlatform: 'Your business platform',
  yourBusinessPlatform_desc:
    'show your abilities and mindset by sharing media, Programs, and clients results.\n soon to be showcase to potential clients.',
  inviteClients: 'Exclusive to your clients',
  inviteClients_desc:
    'Invite your personal clients for an exclusive community made for you and them and stay engaged 24/7.',
  // create workout
  fillWorkoutDetails: "Fill Program's Details",
  fillWorkoutDetails_desc:
    'For program creation, there are 3 stages a trainer needs to complete.\n' +
    'The fist one is filling in the details on the program:',
  fillWorkoutDetails_list: [
    'Select a client / yourself from the clients list.',
    'Give your workout a title',
    'Choose an Image from ASPIRE library or your mobile',
  ],
  selectTheExercises: 'Select Exercises To Add',
  selectTheExercises_desc:
    'Select the exercises you want in your program, switch between normal / superset exercise, find exercises by muscle, equipment or search.',
  customizeYourProgram: 'Customize your program',
  customizeYourProgram_list: [
    'Select equipment for each exercise.',
    'Add / edit number of sets, reps and weight.',
    'Add your own notes to each exercise',
    "Change exercise name - for client's better understanding.",
    'Remove any exercise.',
  ],
  creatingGoal: 'Create a goal',
  creatingGoal_desc:
    'There are 2 steps for goal creation. \n' +
    'Firstly, select a client and a certain body stat/exercise.',

  creatingGoal_list: [
    'Choose a client / yourself from the clients list.',
    'Select goal type: Body/Performance.',
    'Select a certain goal from the list.',
  ],
  fillGoalsDetails: 'Fill Goal Details',
  fillGoalsDetails_desc:
    "Fill the goal's details including title, description, time frame for the goal.\n" +
    "insert an initial & target states to measure your client's progress.\n" +
    "* The app will show you the client's latest stats recorded on the chosen exercise / body stats (depends if there is a record in the app)",
  fillGoalsDetails_list: [
    "Fill the goal's details: title, description, time frame for the goal.",
    "insert an initial & target states to measure your client's progress.",
    "The app will show you the client's latest stats recorded on the chosen exercise / body stats (depends if there is a record in the app)",
    'Attention: if a performance goal has been selected, you need to Select equipment and performance measure type. \n' +
      'for example: best set, most reps, highest weight etc...',
  ],
  fillExerciseDetails: "Fill Exercise's Details",
  fillExerciseDetails_desc: 'Give your exercise a name & write instructions for execution.',
  uploadEquipmentMedia: 'Upload equipment media',
  uploadEquipmentMedia_desc: 'Upload a media to each equipment\n*It is not a required step.',

  helpful: 'helpful',
  unhelpful: 'unhelpful',
  steps: 'steps',
  solutions: 'solutions',
  replay: 'replay',
  chooseMediaType: 'Choose media type',
  uploadCertificates: 'Upload certificates',
  uploadCertificates_desc:
    "Please upload documents that verify your training in the fields you've checked.\n" +
    '(you can also skip this stage by swiping)',
  congratulations: 'Congratulations!',
  dummyUserAppended:
    'Your registration is complete!\n' +
    'Meet Alice, your first client at Aspire!\n' +
    "With Alice, you'll get to experience everything that Aspire has to offer!",
  goodLuck: 'Welcome to Aspire - Become the Best You Can Be!',
  orSkipAndUploadLater: "Or skip by clicking 'Next' & upload later.",
  uploadImages: 'Upload Images',
  uploadImages_desc: 'Select & upload your images or press the back button on top',
  uploadVideos: 'Upload videos',
  uploadVideos_desc: 'Select & upload your videos or press the back button on top',
  uploadFiles: 'Upload Files',
  uploadFiles_desc: 'Select & upload your files or press the back button on top',
  uploadProfileImage: 'Upload profile image',
  uploadBackgroundImage: 'Upload background image',
  welcomeToAspire: (name) => `Welcome ${name}!`,
  welcomeToAspire_desc:
    'You are now a part of our community!\n' +
    'its time to create your own professional environment, and build yourself as a brand.',
  welcomeToAspireClient_desc:
    'Your personal trainer has invited you to an exclusive environment to enjoy a more personal & professional experience.',
  clickNextOrSwipe: "click 'Next' or swipe to continue.",
  smsOnItsWay: 'SMS sent',
  smsWillBeReceived: "In a few seconds you'll receive an SMS with a 5-digit code to enter.",
  resendSMS: 'Resent SMS',
  insertTrainerInvite: 'Invitation code',
  insertTrainerInvite_sub:
    'Please insert your invitation code you got from our SMS or from your trainer.',

  noResultsFound: 'No Results Found',
  pleaseTryAgain: 'Please try again',
  noSessionFound: 'No information found about current session',
  documents: 'Documents',
  myDocuments: 'My Documents',
  documents_of: (name) => `${name || ''}'s documents`,
  surveys_of: (name) => `${name || ''}'s surveys`,
  surveys: 'Surveys',
  documentArchive: 'Document archive',
  surveyArchive: 'Survey archive',
  createDocument: 'Create new document',
  createDocument_sub: 'Fill document details',
  updateSurvey: 'Update survey',
  updateSurvey_sub: 'Change/add questions to current survey',
  updateDocument: 'Update document',
  updateDocument_sub: 'Update document details',
  createSurvey: 'Create new survey',
  createSurvey_sub: 'Fill survey details',
  surveyFillDetails: 'Fill survey',
  surveyFillDetails_sub: 'Answer the following questions.',
  documentsFilled: 'Documents filled',
  filled: 'Filled',
  templates: 'Templates',
  template: 'Template',
  forReviewSurvey: 'unreviewed surveys',
  forReviewDoc: 'unreviewed Documents',
  chooseClients_doc: 'Select clients to send them the document',
  addCheckboxToConfirmAgreement: `Add a check-box to the document to confirm the client has read and agrees to the terms.`,
  isAgreeText: 'add costume text to check-box',
  IAgreeToTerms: 'I have read and agree with the terms and conditions of this document.',
  isClientCanModifyDoc: `Allow the client to modify/change document's content.`,
  forClient: 'For client',
  addQuestion: 'Add a question',
  requiredAnswer: 'Required Answer',
  question: 'Question',
  options: 'Options',
  addOption: 'Add an option',
  // inputOption
  inputOption: 'Text field',
  numberOption: 'Number field',
  longInputOption: 'Long Text field',
  checkboxOption: 'Multi check',
  radioOption: 'Single check',
  dateOption: 'Date field',
  optionsPlaceHolderText: 'The answer will be written here...',
  optionsPlaceHolderLabel: 'Option label',
  answer: 'Answer',
  surveyTitle: 'Survey Title',
  surveyDescription: 'Survey Description',
  surveyIntro: 'Here you can write a short introduction to your survey...',
  noQuestion: 'Add at least 1 question to survey.',
  noAnswers: 'Fill required questions in survey.',
  fillRequiredAnswers: 'Fill required questions in survey.',
  missingSurveyTitle: 'Missing title to survey.',
  noQuestionText: (index) => `Missing question in section ${index}`,
  noLabelOption: (name) => `Missing label to option in the question: ${name}`,
  starRequiredFields: '* requiredFields',
  copySurvey: 'Copy survey to my account',
  assignDocumentSuccessfully: 'All documents have been sent successfully',
  assignSurveySuccessfully: 'All surveys have been sent successfully',
  unfinishedSession_sub: 'Your recent session ended unexpectedly, should we resume it?',
  potentialClients: 'Potential clients',
  contactsList: 'Contacts list',
  contactDetails: 'Contact details',
  contactYouFrom: 'A contact request from ',
  aspireWebsite: 'ASPIRE website',
  by: ' by ',
  hereAListOfContactWillAppear:
    'Potential clients who will contact you thru ASPIRE website will be enlisted here.',
  phoneCopied: 'Phone Copied',
  misMatchPasswords: 'Miss match Passwords',
  misMatchCode: 'Miss match code',
  // tutorial overview
  welcome: 'Welcome!',
  welcome_sub:
    'Thank you for giving us a chance to be better trainers with ASPIRE. \nLet us introduce to you out capabilities, what would you like to know first?',
  welcomeAspireClip: 'welcome To Aspire - clip',
  profileOverview: 'Profile overview',
  inviteClientOverview: 'Client invitation overview',
  programOverview: 'Program overview',
  planOverview: 'Plan creation & use',
  achievementsOverview: 'Goals & Achievements',
  statisticsOverview: 'Statistics overview',
  paperworkOverview: 'Paperwork overview',
  createMyExerciseOverview: 'My exercises overview',

  // profileCard
  addCertificates: 'Add certificate',
  addLocation: 'Add Location',
  socialDetails: 'User Social Details',
  socialDetails_sub: 'Add your social links',
  userDetails_sub: 'Fill in your details',
  introductionVideo: 'User introduction video',
  introductionVideo_sub: 'Add introduction video to boost your engagement',
  trainingLocations: 'Training locations',
  trainingLocations_sub: 'Add places you train with your clients',
  professionCertificates: 'Profession certificates',
  professionCertificates_sub: 'Upload your professional certificates',
  SearchAPlace: 'Search for a place',
  imagesAndVideos: 'Images & videos',
  myGallery: 'My Gallery',
  bio: 'Biography',
  userBiography: 'User biography',
  userBiography_sub: 'Write here about yourself and your personal experience in fitness.',

  city: 'City',
  expertise: 'Expertise',
  facebook: 'facebook',
  instagram: 'instagram',
  youtube: 'youtube',
  twitter: 'twitter',
  linkedin: 'linkedin',
  selectMyMedia: 'Select from my media',
  selectFilesToUpload: 'Select & Upload files',
  wouldYouLikeAWalkThru: 'Would you like a quick walk-thru the process?',
  programCreationTutorial: 'yes! guide me thru!',
  noThankUTutorial: "I'll do it myself",

  chooseClient_sub: 'Select a client / yourself to assign this program to.',
  groupClients_sub: "Selecting a number of clients will create a 'Group Program'.",
  fillProgramTitle: 'Give your program a title.',
  afterFillForm: 'After completing the form, ',
  clickHereToAddExercises: "Click in add exercises",
  saveToProceed: "Click 'Save' to proceed",
  nextToProceed: "Click 'Next' to proceed",
  doneToProceed: "Click 'Done' to save all the changes you've made.",
  nextToProceed2: "when you're done choosing the exercises you need, click 'Next' to proceed.",
  chooseExercisesTutorial_sub: 'Select 3 exercises you wish to add to your program.',
  selectedExercisesTutorial_sub: "Here you can see the exercises you've chosen for this program.",
  filterTutorial_sub: 'You can choose between making a super-set / a regular exercise here.',
  clickOnAlice_sub: "Click on 'Alice' in your clients list to view her stats.",
  //    'With "Categories" you can filter the exercises to find ones you\'re looking for.',
  exerciseType_categories: 'Change exercise type',
  filterOptions: 'Search options',
  filterOptions_sub: 'You can find exercises by text or filter by muscle, equipment or categories.',
  aspireTrainerIntroduction_sub:
    "\"As your first client allow me to guide you, I guarantee you'll understand 'Aspire' faster with me by your side.\"\n\nLet's start with:",
  aspireClientIntroduction_sub:
    "\"On your first visit, allow me to guide you, I guarantee you'll understand 'Aspire' faster with me by your side.\"\n\nLet's start with:",
  aspireClientIntroductionTwo_sub:
    "\"As your trainer allow me to guide you through 'Aspire', so we can train better in the future.\"\n\nLet's start with:",
  editBtnTutorial_sub: "Press 'Edit' and you'll be able to modify exercise's sets & fields.",
  exerciseOptionsTutorial_sub:
    'Extended exercise options:\nAdding your comments, changing exercise fields, modify name... ',
  exerciseEquipmentTutorial_sub: "You can also change exercise's selected equipment from here.",
  squareInfo_hint: 'Program Stats on num. of workouts made + overall tonnage lifted.',
  sessionUpdateBtn_hint:
    'Click here will start your workout & update your current state in real-time.',
  exerciseDescription_hint:
    'Click here to view exercise description, muscle map & possible equipments.',
  superset_hint: 'Super-set exercise view, swipe left/right to view them.',
  clickToCheck_hint: 'Click here to update the set to "Completed" and activate the next one.',
  modifyExerciseBtn_hint: 'Click here to modify the sets and update the fields if needed.',

  fillClientInfo_sub: "Fill your client's information and click 'Next'.",
  changeClientsAppSettings_sub: "In case needed, change your client's basic settings.",
  editProfile: 'Build your profile',
  editProfile_sub: 'Click here to create your profile.',
  uploadImage_sub: 'Your profile picture will be displayed here, shall we begin?',
  trainersProfile: "Trainer's profile",
  trainersProfile_sub:
    "You can click on your trainer's profile icon (right corner) if you want to visit their profile page.",
  notifyUserToVerifyAccount_desc:
    'your account has not been verified, if you haven\'t received our verification mail, click on "send again" ' +
    "(please check your 'promotions' inbox as well).",
  sendAgain: 'Send again',
  cantFindVerificationMail: 'Cant find your verification mail?',
  cantFindVerificationMail_comment: "(please check your 'promotions' inbox as well)",
  verificationSent: 'Verification massage sent',
  hide: 'Hide',
  dontShowAgain: 'Dont show me again',
  personalProgram: 'Personal program - one client',
  groupProgram: 'Group program - num. of clients',
  pickAColor: 'Pick a color',
  selectDate: 'Select date',
  selectDates: 'Select dates',
  chooseDateRange: 'Select date range',
  makeAllDayEvent: 'This event is active all day.',
  createAnEvent: 'Create an event',
  bookASession: 'Book a session',
  editAnEvent: 'Update session details',
  rescheduleAnEvent: 'Reschedule session',
  writeATitle: 'Write a title...',
  participants: 'Participants',
  advancedOptions: 'Advanced options',
  recurringEvent: 'Repeated event options',
  openRecurringOptions: 'Open repeated event options',
  repeatEvery: 'Repeat every',
  repeatEvery_day_info:
    'The event will be repeated every day or every x days, depending on the value',
  repeatEvery_week_info: 'The event will be repeated every x weeks on specific weekdays',
  repeatEvery_month_info: 'The event will be repeated every x month on specific day of the month',
  stopConditions: 'Stop conditions',
  neverStop: 'Never stop.',
  neverStop_info: 'The event will repeat indefinitely.',
  untilDate: 'Run until a specific date.',
  untilDate_info: 'The event will run until it reaches a specific date.',
  untilNumber: 'Run event',
  untilNumber_info: 'The event will repeat until it reaches a certain amount of times.',
  eventDates: "event's date",
  trainer: 'Trainer',
  confirmArrival: 'Confirm Arrival',
  cancelArrival: 'Cancel',
  postpone: 'Postpone',
  reschedule: 'Reschedule',
  repeatedEvent: 'Repeated event',
  thisEventRepeats: 'This event repeats',
  for: ' for ',
  until: ' until ',
  weekly2: ' every week ',
  daily2: ' every day ',
  inDays: ' on ',
  editRecurringEventSelect: 'Edit recurring event:',
  deleteRecurringEventSelect: 'Delete recurring event:',
  thisEventOnly: 'This event only.',
  thisEventAndFollowing: 'This and following events.',
  allEvents: 'All events',
  pending: 'Pending',
  confirmed: 'Confirmed',
  canceled: 'Cancelled',
  noDatesSelected: 'No dates have been selected for reschedule.',
  onGoing: 'Ongoing',
  beginner: 'Beginner',
  trained: 'Trained',
  wellTrained: 'Well Trained',
  athlete: 'Athlete',
  copyProgramSuccessfully: 'Program has been copied to your account successfully',
  success: 'Success',
  successfullyUpdated: 'successfully Updated',
  successfullyUpdatedPassword: 'Your password has been successfully updated to the system!',
  successfullyUpdatedPhone: 'Your phone number has been successfully updated to the system!',
  successfullyUpdatedPhone2: 'From now on you can login with your phone number.',
  somethingWentWrong: 'Something went wrong',
  stay: 'Stay',
  articles: 'Articles',
  helloUserStore: (name) => `Hello ${name}`,
  helloUserStore_desc:
    'Welcome to our store, feel free to use and share our templates with your clients.',
  storePrograms: 'Programs archive',
  storePrograms_desc: 'You can copy, change & share our workout templates with your clients.',

  personal_info:
    'A personal training programs, Select ONE client to assign this program to, you can assign this program to others later.',
  group_info: 'A group training program, Select multiple clients to assign this program to.',
  public_info:
    'This Program will serve as a FREE template for your clients and visitors to check out & try from your profile page.',
  store_info:
    'this program is to be reviewed by ASPIRE, if accepted, will be uploaded to the aspire store with your name & a badge.',
  circuitTraining: 'Circuit training',
  SET_option: 'Sets',
  CIRCUIT_option: 'Circuit',
  SET_info: 'Default Program view, enable user to view exercises as sets, supersets etc...',
  CIRCUIT_info: 'Circuit training view, enable user to determine number of rounds for chosen exercises.',
  optionsAreDisabled: 'Selecting an option is disabled.\nYou are either in tutorial or edit mode.',
  original: 'Origin',
  newUpdates: 'New Updates',
  whatsNew: `What’s New`,
  signUpBy: 'Sign up by',
  signinBy: 'Sign in by',
  inviteBy: 'Invite by',
  updateDetails: 'Update details',
  updateDetails_2: 'Update',
  signupByPhone: 'Signup by phone',
  signupByEmail: 'Signup by Email',
  insertYourNumberToVerify:
    'To receive a verification code via SMS, please insert your phone number.',
  verifyEmailBeSent:
    'You\'ll receive an email to verify your account, please check your "promotions" tab as well.',
  sendVerificationCode: 'Send Verification Code',
  aspireIntro_title_1: 'A new era for personal training.',
  aspireIntro_desc_1:
    'We empower trainers on every level, giving your clients 100% focus and devotion to their cause.',
  aspireIntro_title_2: 'Stay connected 24/7',
  aspireIntro_desc_2:
    'Create your own professional environment and invite your clients to an exclusive platform, made for progress.',
  aspireIntro_title_3: 'Unique Experience',
  aspireIntro_desc_3:
    'Every feature we develop helps you to become more professional, more personal, more yourself.',
  copied: 'Copied',
  certainField: 'Field',
  addWorkoutRoutine: 'Add a workout routine',
  workoutRoutine: 'Workout Routine',
  addExercisesToFirstCreatedProgram: (title) => `'${title}' has no exercises, please select exercises to '${title}' first.`,
  addAProgram: 'Add a program',
  saveProgram: 'save a program',
  clickHereAddExercises: 'Click here to add exercises',
  removeFromPlan: 'Remove from program',
  display: 'Display',
  removeRoutine: 'Remove routine',
  areYouSureRemoveRoutine: 'Are you sure you want to remove this routine?',
  routineDetails: 'Routine details',
  editRoutineDetails: 'Edit routine details',
  editProgramDetails: 'Edit program details',
  modifyExercises: 'Modify exercises',
  routineAdvanceOptions: 'Routine advanced options',
  programAdvanceOptions: 'Program advanced options',
  attention: 'Attention',
  goBackNotice: 'Going back will delete all changes you\'ve made on current page.\n Are you sure you want to go back?',
  processingMedia: 'Your upload is being processed,\nit might take a few minutes.\nfeel free to continue.',
  clientProfileIsPrivate: 'Client\'s profile is private.\nOnly your trainer can see it.',
  openContactsList: 'Open contacts list',
  countryCodes: 'Country codes',
  selectCountry: 'Select country',
  selectCountryCode: 'Please select country code',
  noCountryCodeAttached: 'No country code attached to phone number',
  unverifiedClientAccountInfoEmail: () => (
    <span>A client account created under this address but <strong>has yet to be verified.</strong>
      <br />
      Please check your email <strong>&apos;Inbox&apos; or &apos;Promotions&apos; tab</strong> & get your invitation code.
    </span>
  ),
  unverifiedClientPhoneInfo: () => (
    <span>A client account created under this phone number but <strong>has yet to be verified.</strong>
      <br />
      Please check your SMS inbox & get your <strong>5-digits code</strong>, then click &apos;Sign In&apos;.
    </span>
  ),
  resendSMStoUnverifiedClientPhone: () => (
    <span>In a few moments you&apos;ll receive an SMS with a new invitation code.
      <br />
      Please check your SMS & get your <strong>5-digits code</strong>, then click &apos;Sign In&apos;.
    </span>
  ),
  calendarSettings: 'Calendar settings',
  rescheduleReason: 'Reschedule reason',
  sundayToSunday: 'Sunday to sunday',
  mondayToMonday: 'Monday to monday',
  firstDayOfTheWeek: 'First day of the week',
  firstDayOfTheWeek_sub: 'Set your week to start from Sunday or Monday.',
  restrictAvailableHours: 'Set operating hours',
  restrictAvailableHours_sub: 'Setting operating hours will allow your Clients to schedule workouts only at the time allowed between the two values.',
  timeInputSettings: 'Time Input Settings',
  timeInputSettings_sub: 'Configure the way you want your time input to look and feel.',
  timeInputExample: 'Click here to see the changes you\'ve made on the input.',
  daysOff: 'Days off',
  daysOff_sub: 'Configure days your clients wont be able to schedule workouts. For example: Saturday, Sunday.',
  timegrid: 'Time grid',
  timeWheel: 'Time wheel',
  fiveMinutes: '5 Minutes',
  tenMinutes: '10 Minutes',
  fifteenMinutes: '15 Minutes',
  thirtyMinutes: '30 Minutes',
  watchYoutubeVideo: 'Watch Youtube video',
  bookSession: 'Book',
  waitingForResponse: 'waiting for response',
  confirmedArrival: 'Confirmed arrival',

  access_denied_pro_up: `Access available to 'Pro Trainer' and up`,
  access_denied_gold_up: `Access available to 'Gold Trainer' and up`,
  access_denied_update_sub: `, to update subscription go to 'Settings'.`,
  clickHere: 'click here.',
  latestStatClientProgress_info: (name) => `To view ${name}'s latest session stats on current workout, `,
  latestStatClientWorkout_info: (name) => `Scroll down to see ${name}'s latest session stats on current workout.`,
  NoSessionsOnWorkout_info: (name) => `No Session by ${name} were made yet.`
};

export const es = {
  intro: 'Introducción',
  media: 'Multimedia',
  reviews: 'Reseñas',
  location: 'Ubicación',
  calendar: 'Calendario',
  calendar_sub: 'Calendario semanal',
  categories: 'Categorías',
  category: 'Categoría',
  achievements: 'Logros',
  myAchievements: 'Mis logros',

  // Navigation Top

  inviteClient: 'Invitar al cliente',
  invite: 'Invitar cliente',
  'fill clients details': 'Paso 1: Rellenar los datos del cliente',
  workouts: 'Entrenamientos',
  myWorkouts: 'Mis entrenamientos',
  workoutsArchive: '', // 'Archivo de entrenamientos',
  workoutsArchive_2: 'Archivo de entrenamientos',
  sessionsArchive: 'Archivo de sesiones',
  workoutPlanArchive: 'Archivo de planes de entrenamiento',
  createWorkout: 'Crear una rutina',
  createWorkout_sub: 'Rellenar los detalles del programa de entrenamiento',
  chooseExercises: 'Seleccionar ejercicios',
  chooseExercises_sub: 'Seleccionar ejercicios de la lista',
  chooseExercise: 'Seleccionar ejercicio',
  chooseExercise_sub: 'Seleccionar ejercicio de la lista',

  workoutLoggerHeader: 'Editar ejercicios', // Editar menú de rutina
  workoutLoggerHeader_sub: 'Crear series de ejercicios, repeticiones y peso',
  createAProgram: 'Crear un programa',
  createAWProgram: 'Crear una rutina',
  getAFreeProgramTemplate: 'Obtener un modelo de programa gratuito',
  aspireIntroductionVideoTrainer: 'Video de introducción de Aspire',
  aspireIntroductionVideoClient: () => 'Video de introducción de Aspire',
  programDetails: 'Detalles del programa',
  createPlan_sub: 'Rellenar detalles del programa',
  addPrograms: 'Añadir programas',
  modifyProgress: 'Modificar el progreso del programa',
  modifyProgress_sub: 'Construir un progreso automatizado por semana.',
  modifyProgramsProgressPage_info:
    'Elija un programa, modifique las variables por semana deseada (repeticiones, pesos, descanso...). Estas variables se actualizarán para el cliente en la semana deseada cuando inicie una sesión con ASPIRE.',
  'Select Programs to Add': 'Seleccione los programas a añadir',
  sessionStart: 'Inicio de sesión',
  'Session Started At': 'Sesión iniciada en: ',
  clientsList: 'Mis clientas',
  statistics: 'Estadísticas',
  MyStatistics: 'Mis estadísticas',
  history: 'Historial de datos',
  createBodyStats: 'Crear estadísticas corporales',
  updateBodyStats: 'Actualizar estadísticas corporales',
  loading: 'Cargando...',
  bodyStatsInfo_title: 'Información de las estadísticas',
  bodyStatsInfo_sub: 'Fecha:',
  UpdateYourCurrentBodyStats: 'Actualice sus estadísticas corporales actuales',
  startSession: 'Iniciar sesión',
  createPastSession: 'Crear una sesión pasada',
  updatePastSessions: 'Actualizar una sesión pasada',
  copyProgramToClient: 'Copiar programa a otro cliente',
  copyProgramToProfile: 'Copiar programa a mis perfil',
  updatePastSessionsFP: 'Actualizar una sesión de este programa',
  workoutsCompleted: 'Entrenamientos completados',
  tonnageLifted: 'Tonelaje levantado',
  totalSets: 'Total de series',
  totalReps: 'Total de repeticiones',
  exercisesDone: 'Ejercicios realizados',
  active: 'Activo',
  completed: 'Completado',
  programsTitle: 'Título del programa',
  eventsTitle: 'Título del evento',
  settings: 'Ajustes',
  applicationSettings: 'Ajustes de la aplicación',
  sessionsHistory: 'Historial de la sesión',
  workoutPrograms: 'Programas de entrenamiento',
  workoutPlans: 'Planes',
  workoutsPerWeek: 'Entrenamientos por semana',
  currentWeek: 'Semana actual',
  assignToClient: 'Asignar al cliente',
  sendDocToClients: 'Enviar documento a clientes',
  sendSurveyToClients: 'Enviar encuesta a clientes',
  copySurveyToMe: 'Copiar encuesta y enviar a clientes',
  copyDocToMe: 'Copiar documento a mi cuenta',
  of: 'de',
  programs: 'Programas',
  minutes: 'Minutos',
  hours: 'Horas',
  hour: '1 Hora',
  and: 'y',
  days: 'Días',
  day: 'Día',
  today: 'Hoy',
  weeks: 'Semanas',
  week: 'Semana',
  month: 'Mes',
  months: 'Meses',
  daily: 'Diario',
  weekly: 'Semanal',
  monthly: 'Mensual',
  times: 'Tiempos',
  startTime: 'Inicio',
  endTime: 'Fin',
  chooseTrainer: 'Elegir entrenador',
  chooseClient: 'Seleccionar un cliente',
  chooseClients: 'Seleccionar clientes',
  clientsParticipates: 'Participantes',
  chooseAchievementType: 'Seleccionar tipo de logro',
  UsingThisProgram: 'Uso de este programa',
  exercises: 'Ejercicios',
  description: 'Descripción',
  addDescription: 'Añadir descripción',
  activityHistory: 'Historial de actividades',
  currentProgram: 'Programa actual',
  default: 'Por defecto',
  chooseEquipment: 'Seleccionar equipamiento',
  choose: 'Elegir',

  // body stats labels

  height: 'Altura',
  weight: 'Peso',
  muscleMass: 'Masa muscular',
  fat: 'Grasa',
  calories: 'Calorías',
  water: 'Agua',
  bmi: 'imc',
  BMI: 'IMC',

  shoulders: 'Hombros',
  arms: 'Brazos',
  leftArm: 'Brazo izquierdo',
  rightArm: 'Brazo derecho',
  chest: 'Pecho',
  waist: 'Cintura',
  hips: 'Caderas',
  thighs: 'Muslos',
  leftThigh: 'Muslo izquierdo',
  rightThigh: 'Muslo derecho',
  lowerThighs: 'Muslos inferiores',
  leftLowerThigh: 'Muslo inferior izquierdo',
  rightLowerThigh: 'Muslo inferior derecho',
  calves: 'Pantorrillas',
  leftCalf: 'Pantorrilla izquierda',
  rightCalf: 'Pantorrilla derecha',

  isAdvancedMeasurements: 'Medidas avanzadas',
  addBodyMeasurements: 'Añadir medidas corporales',
  bodyMeasurements: 'Medidas corporales',
  bodyStats: 'Estadísticas corporales',
  bodyStats_sub: 'Estadísticas del cliente',
  bodyStats_sub_client: 'Sus estadísticas recientes',
  performance: 'Rendimiento',
  performance_sub: 'Estadísticas del cliente',
  performance_sub_client: 'Sus estadísticas recientes',
  certifiedPersonalTrainer: 'Entrenador personal certificado',
  straight: 'Estiramiento',
  superset: 'Superserie',
  super_set: 'Superserie',
  pyramid: 'Pirámide',
  interval: 'Intervalo',
  aerobic: 'Aeróbico',
  strength: 'Resistencia',
  static: 'Estático',
  kilograms: 'Kg / T',
  pounds: 'Lbs / Libra',
  meter: 'Cm / M',
  male: 'Hombre',
  female: 'Mujer',
  group_option: 'Grupo',
  personal_option: 'Personal',
  public_option: 'Público',
  store_option: 'Tienda',
  other_option: 'Otros',
  public_id: 'ID público',
  uri: 'URL',
  order: 'Orden',
  content: 'Contenido',
  email: 'Correo electrónico',
  title: 'Título',
  password: 'Contraseña',
  confirmPassword: 'Confirmar contraseña',
  oldPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  passwordOrInvite: 'Contraseña / Código de invitación',
  resetCode: 'Restablecer código de contraseña',
  forgotPassword: 'Olvidó la contraseña?',
  alreadyHaveTheCode: 'Ya tengo un código',
  inviteCode: 'Código de invitación',
  firstName: 'Nombre',
  lastName: 'Apellido',
  BirthDate: 'Fecha de nacimiento',
  aboutMe: 'Sobre mí',
  phone: 'Núm. de teléfono',
  weightUnits: 'Unidades de peso',
  distanceUnits: 'Unidades de distancia',
  language: 'Idioma',
  chooseLanguage: 'Elegir idioma',
  startDate: 'Comienza el',
  endDate: 'Finaliza el',
  date: 'Fecha',
  eventsHour: 'Hora del evento',
  allDay: 'Todo el día',
  duration: 'Duración',
  workoutPerWeek: 'Entrenamiento por semana',
  numOfWeeks: 'Nota: Conteo en semanas',
  numOfTimes: 'Nota: número de veces',
  updateStats: 'Actualizar estadísticas',
  chooseImage: 'Seleccionar imagen',
  uploadMedia: 'Cargar mēdēə',
  uploadFile: 'Subir archivo',
  mediaLibrary: 'Biblioteca mēdēə',
  uploadImage: 'Subir imagen',
  uploadVideo: 'Subir video',
  files: 'Archivos',
  gallery: 'Galería',
  myMedia: 'Mis archivos', // 'Mi mēdēə',
  thisMedia: 'Este archivo mēdēə',
  type: 'Tipo',
  exercise: 'Ejercicio',
  round: 'Ronda',
  set: 'Serie',
  sets: 'Series',
  reps: 'Reps', // Repeticiones
  time: 'Tiempo',
  speed: 'Velocidad',
  distance: 'Distancia',
  intensity: 'Intensidad',
  rest: 'Tiempo de descanso',
  totalTimeDone: 'Tiempo total',
  totalDistance: 'Distancia total',
  cancel: 'Cancelar',
  clear: 'Borrar',
  confirm: 'Confirmar',
  updatePassword: 'Actualizar contraseña',
  save: 'Guardar',
  send: 'Enviar',
  done: 'Realizado',
  assignAsDone: 'Completado',
  finishSession: 'Finalizar sesión',
  finishSession_desc: 'clic aquí para finalizar la sesión actual.',
  edit: 'Editar',
  modifyDoc: 'Modificar documento',
  delete: 'Borrar',
  expand: 'Expandir',
  changeRepsWeight: 'Cambiar repeticiones/peso',
  backToCards: 'Volver a las fichas',
  back: 'Volver',
  skip: 'Saltar',
  add: 'Añadir',
  remove: 'Quitar',
  addSet: 'Añadir serie',
  removeSet: 'Quitar serie',
  setAdd: 'Serie añadida',
  moreDetails: 'Más detalles',
  skipped: 'Omitir',
  details: 'Detalles',
  submit: 'Enviar',
  register: 'registrarse',
  register_login: 'Registrarse / Iniciar sesión',
  login: 'Iniciar sesión',
  invitedClient: 'Cliente invitado',
  signin: 'Iniciar sesión',
  signup: 'Registrarse',
  createAccount: 'Crear cuenta de entrenador',
  resetChanges: 'Restablecer cambios',
  exitEditMode: 'Salir del modo de edición',
  clientInvite: 'Invitación del cliente',
  saveChanges: 'Guardar cambios',
  enter: 'Intro',
  info: 'Datos',
  information: 'Información',
  support: 'Soporte',
  myInquiries: 'Mis consultas',
  exerciseInfo: 'Información sobre el ejercicio',
  logout: 'Cerrar sesión',
  dontHaveAnAccount: `Hey, ¿todavía no se ha hecho una cuenta?`,
  muscleId: 'ID del músculo',
  title_he: 'Título HEB',
  title_en: 'Título ENG',
  description_he: 'Descripción HEB',
  description_en: 'Descripción ENG',
  moreOptions: 'Más opciones',
  exerciseNotes: 'Notas del ejercicio',
  clickHereFullScreen: 'clic aquí para ver en pantalla completa',
  close: 'Cerrar',
  notes: 'Notas',
  exerciseName: 'Nombre del ejercicio',
  editExerciseName: 'Cambiar el nombre del ejercicio',
  changeExerciseFields: 'Cambiar los campos del ejercicio',
  changeExerciseOrder: 'Cambiar el orden del ejercicio',
  addExerciseToSuperSet: 'Añadir ejercicio a la superserie',
  deleteExercise: 'Eliminar ejercicio',
  myProfile: 'Mi perfil',
  store: 'Tienda',
  notice: 'Aviso',
  alert: 'Alerta',
  thisModalCantBeOpened:
    'Esta ventana emergente no puede abrirse mientras se carga, por favor inténtelo más tarde.',
  your: 'Su ',
  invitationCode: 'Código de invitación',

  inviteSentInfoByEmail:
    'En unos minutos, su cliente será invitado a Aspire por correo electrónico.',
  inviteSentInfoSpam: "(Por favor, compruebe también la bandeja de entrada de 'Promociones')",
  inviteSentInfoBySMS: 'Su cliente recibirá una invitación a Aspire por SMS.',
  inviteSentInfoBySMS_sub: (name) =>
    `Si ${
      name || 'su cliente'
    } no recibe un SMS nuestro en los próximos minutos, el código de invitación es:`,

  NoWorkoutsToShow: 'No hay programas de entrenamiento registrados',
  NoWorkoutsToShowFor: (name) => `No hay programas registrados para ${name}`,
  clickHereToCreateProgram: '¡Cree su primer programa de entrenamiento aquí!',
  NoActivitiesToShow: 'No se ha podido encontrar ninguna actividad',
  NoGoalsToShow: 'No hay objetivos registrados',
  NoStatsToShow: 'No hay estadísticas de rendimiento registradas.',
  NoStatsToShow_sub:
    'Es necesario registrar una sesión de entrenamiento para actualizar las estadísticas de rendimiento',
  clickHereToCreateGoal: 'Crear un objetivo para usted / su cliente',
  NoAchievementToShow: '¡No se ha alcanzado ningún objetivo todavía!',
  ChangeLogToCompleted:
    'La serie actual ha sido declarada como "Omitida", ¿debe ser modificada a "Completada"?',
  CLIENT: 'Cliente',
  TRAINER: 'Entrenador',
  NUTRITIONIST: 'Nutricionista',
  english: 'Inglés',
  hebrew: 'Hebreo',
  spanish: 'Español',
  selectProgramType: 'Seleccionar tipo de programa',
  selectProgramView: 'Seleccionar la vista del programa',
  exercisesSelected: 'Ejercicios seleccionados',
  againstEquipment: 'con',
  addExercise: 'Añadir ejercicio',
  addExerciseToSession: 'Añadir ejercicio',
  addExerciseInfo: 'clic aquí para añadir un nuevo ejercicio',
  addSuperSet: 'Añadir superserie',
  selectExercises: 'Seleccionar ejercicios',
  selectExercise: 'Seleccionar ejercicio',
  selectedExercises: 'Ejercicios elegidos',
  addExercises: 'Añadir ejercicios',
  moveExercisesInfo: 'Arrastre sus ejercicios en el orden que desee para su entrenamiento.',
  bodyWeightInfo: `Insertar '0' en la casilla de peso se considerará como 'peso corporal'.`,
  aerobicFieldsInfo: `Insertar '0' en una casilla será considerado como ignorado.`,
  reorderExercises: 'Reordenar Ejercicios',
  reorderExercises_sub: 'Reordenar o eliminar Ejercicios',
  programsSelected: 'Programas seleccionados',
  noticeChangingCurrentProgram:
    "Cambiar el Programa actual cerrará el 'Modo de Asignación' y creará un nuevo Programa en su lugar, ¿está seguro?",
  noticeUnsavedChanges: (numbers) =>
    `Los entrenamientos ${numbers} no se han guardado y todavía están en 'Modo de Edición', ¿está seguro de que desea continuar?`,
  assignAsAchieved: 'Asignar objetivo como alcanzado',
  assignAsAchieved_notice: '¿Está seguro de que quiere marcar este objetivo como alcanzado?',
  assignAsAnswered: 'Marcar como respondido',
  assignAsAnswered_notice: '¿Le ha servido de algo nuestra respuesta?',
  resetProgressToThisWeek_notice:
    '¿Seguro que quiere eliminar todos los cambios realizados en el progreso de esta semana?',
  total: 'Total',
  more: 'Más',
  liftingTime: 'Tiempo de levantamiento invertido',
  avgWorkoutTime: 'Tiempo promedio de entrenamiento',
  avgRestTime: 'Tiempo promedio de descanso',
  showMore: 'Mostrar más',
  archive: 'Archivo',
  saySomething: 'Unas palabras...',
  howWasSession: 'Califique su sesión de entrenamiento',
  sessionTime: 'Duración de la sesión',
  preparation: 'Preparación',
  execution: 'Ejecución',
  muscles: 'Músculos',
  equipment: 'Equipamiento',
  difficulty: 'Dificultad',
  defaultEquipment: 'Equipamiento por defecto',
  chooseDefaultEquipment: 'Seleccionar equipamiento por defecto',
  exerciseType: 'Tipo de ejercicio',
  target: 'Objetivo',
  synergist: 'Sinergista',
  stabilizers: 'Estabilizadores',
  antagonist: 'Antagonista',
  removeExercise: 'Eliminar ejercicio',
  removeExerciseDesc: '¿Seguro que quiere eliminar los ejercicios seleccionados?',
  clickToDelete: 'Eliminar ejercicios',
  filter: 'Filtro',
  hi: 'Hola',
  sessionSection1: '¿A quién vamos a entrenar hoy?',
  sessionSection2: 'Por favor, seleccione un programa de entrenamiento',
  'Choose Client & Program': 'Seleccione el cliente y el Programa',
  chooseProgram: 'Seleccione un Programa',
  'Are you sure you want to delete': '¿Está seguro de que quiere eliminar?',
  areYouSureFinishSession: '¿Está seguro de que quiere terminar la sesión de entrenamiento actual?',
  areYouSureRemoveEquipment:
    '¿Está seguro de que quiere eliminar la mēdēə seleccionada del equipamiento?',
  theseBodyStats: 'Estas estadísticas corporales',
  me: 'Yo',
  isPersonalTrainingSession: 'La sesión actual forma parte del paquete de "entrenamiento personal"',
  sessionSummary: 'Resumen de la sesión',
  bestSets: 'Mejores series',
  current_state: 'Actual',
  target_state: 'Objetivo',
  initial_state: 'Inicial',
  initialvalue: 'Inicial',
  targetvalue: 'Objetivo',
  kg: 'kg', // for kilograms
  KG: 'kg',
  Lbs: 'lbs', // for pound
  LBS: 'lbs',
  Ft: 'Ft', // for feet
  FT: 'Ft',
  Yd: 'Yd',
  YD: 'Yd', // for yards
  T: 'T',
  cm: 'cm', // for centimeters
  CM: 'cm',
  inch: 'pulgadas',
  INCH: 'Pulgadas',
  FEET: 'Pies',
  yard: 'Yarda',
  YARD: 'Yarda',
  mile: 'Milla',
  MILE: 'Milla',
  km: 'km',
  KM: 'km',
  m: 'm', // for meters
  M: 'M',
  kcal: 'kcal', // for kilocalories
  Kcal: 'kcal',
  KCAL: 'kcal',
  percent: '%',
  PERCENT: '%',
  '%': '%',
  M_KM: 'Cm / M / Km',
  YARD_MILE: 'Pulgadas / Yardas / Millas',
  KG_CM: 'Kg / Cm',
  LBS_INCH: 'Lbs / Pulgadas',
  NoStatsRecorded: 'No hay estadísticas registradas',
  activeAchievements: 'Metas activas',
  completedAchievements: 'Logros',
  createPlan: 'Crear un Programa',
  myExercises: 'Mis ejercicios',
  myExercises_sub: 'Mi lista de ejercicios',
  inquiriesAnswers: 'Preguntas y respuestas',
  inquiriesAnswers_sub: 'Todo lo que necesita saber sobre ASPIRE.',
  createInquiry: 'Háganos una pregunta',
  createInquiry_sub: 'Y le responderemos lo antes posible.',
  createSolution: 'Solución',
  createSolution_sub: 'Escriba su solución.',
  replayInquiry: 'Respuesta',
  replayInquiry_sub: 'Escríbanos su respuesta.',
  exerciseStats_title: 'Ejercicio: ',
  programsStats_title: 'Programa: ',

  // text placeholders

  'type in kg': 'Escribir en Kg',
  'type in cm': 'Escribir en cm',
  typeInCm: 'Escribir en Cm',
  'type in %': 'Escribir en %',
  'type in Kcal': 'Escribir en Kcal',
  'type in lbs': 'Escribir en LBS',
  'type in inch': 'Escribir en pulgadas',
  '': '', // no need to touch here
  blank: '', // no need to touch here

  EN: 'English',
  HE: 'עיברית',
  ES: 'Español',
  EN_short: 'Eng',
  HE_short: 'עב',
  ES_short: 'Es',
  image: 'Imagen',
  video: 'Video',
  search: 'Búsqueda',
  session_of: 'sesión de',
  chooseGoal: 'Elija un objetivo',
  chooseGoalSub: 'Elija un objetivo de rendimiento/estadística corporal',
  goalDetails: 'Detalles del objetivo',
  goalDetailsSub: 'Rellene los detalles de su objetivo',
  createGoal: 'Crear un objetivo',
  min: 'min',
  sec: 'seg',
  lastUpdatedIn: 'Actualizado en',
  showInGraph: 'Mostrar gráfico para',
  nextExercise: 'Siguiente Ej.',
  prevExercise: 'Anterior Ej.',
  currentExercise: 'Ejercicio actual',
  next: 'Siguiente',
  prev: 'Anterior',
  later: 'Posterior',
  seeAchievement: 'Ver Logro',
  congratulations_sub: '¡Felicidades! ¡Ha conseguido un objetivo que se había propuesto!',
  wellDone: '¡Bien hecho!',
  'please write your notes here': 'Por favor, escriba sus notas aquí...',
  addToList: 'Añadir a la lista',
  changePassword: 'Cambiar la contraseña',
  changePassword_sub: 'Por favor, actualice su contraseña',
  updateUserCredentials: 'Actualice las credenciales de usuario',
  updateUserCredentials_sub: 'Actualice sus credenciales de acceso',

  createCategory: 'Crear categoría',
  categories_sub: `Lista de categorías de Aspire`,
  resendInvitation: 'Reenviar invitación',
  updateResendInvitation: 'Actualizar y reenviar invitación',
  inviteAnotherClient: 'Invitar a otro cliente',

  emailSent: 'Correo electrónico enviado',
  invitationSent: 'Invitación enviada',
  invitationSentError: 'Error al enviar la invitación',
  profileDetails: 'Datos de mi perfil',
  restDay: 'Día de descanso',
  typeOldPassword: 'Escriba la antigua contraseña',
  typeNewPassword: 'Escriba la nueva contraseña',
  onlyEnglishLetters: 'Sólo debe contener palabras en inglés',
  'type in exercise title': 'Escriba el título del ejercicio',
  createExercise: 'Crear ejercicio',
  createExerciseAdmin: 'Crear ejercicio',
  createExercise_sub: 'Cree sus propios ejercicios aquí.',
  'fill exercise details': 'Rellene los detalles del ejercicio',
  achievementDetails: 'Detalles del logro',
  achievementDetails_loading: 'Cargar logro...',
  myActivities: 'Mis actividades',
  myActivities_sub: 'Todas sus actualizaciones aparecen aquí',
  bestSet: 'Mejor serie',
  tonnage: 'Toneladas',
  bestTime: 'Mejor tiempo',
  mostReps: 'Mayor número de repeticiones',
  highestWeight: 'Peso más alto',
  longestTime: 'Tiempo más largo',
  longestDistance: 'Distancia más larga',
  bestByRM: 'Mejor por RM',
  editName: 'Editar nombre del ej. ',
  top: 'Superior',
  center: 'Centro',
  bottom: 'Abajo',
  bodyWeight: 'Peso Corp.', // 'Peso corporal',
  bodyWeightShort: 'Peso corp.',
  processingImage: 'Procesamiento de imágenes',
  connect: 'Conectar',
  uploading: 'Subiendo...',
  errorUploadingFile: 'No se pudo subir la imagen',
  addMedia: 'Añadir multimedia',
  upToImgs: (number) => `Un máximo de ${number} archivos subidos`,
  fullScreen: 'Pantalla completa',
  skipEx: 'Omitir Ej.',
  change: 'Cambiar',
  nextSet: 'Siguiente serie',
  timer: 'Temporizador',
  stopwatch: 'Cronómetro',
  noExercisesAdded: 'No hay ejercicios añadidos',
  pleaseGoBack: 'Por favor, vuelva atrás',
  session_has_ended: 'La sesión ha terminado',
  changeCurrentWeek: 'Cambiar la semana actual',
  changeCurrentSessionNum: 'Cambiar el número de sesión',

  statistics_of: (name) => `Estadísticas de ${name || ''}`,
  history_of: (name) => `Historial de ${name || ''}`,
  workouts_of: (name) => `Entrenamientos de ${name || ''}`,
  activeAchievements_of: (name) => `Objetivos de ${name || ''}`,
  completedAchievements_of: (name) => `Logros de ${name || ''}`,
  bodyStats_of: (name) => `Estadísticas corporales de ${name || ''}`,
  bodyStatsDetails: 'Cargando...',
  bodyStatsDetails_of: (name) => `Estadísticas corporales de ${name || ''}`,
  bodyStatsDetails_sub: (name, stat) => `Historial de estadísticas de ${name || ''} ${stat || ''}`,
  performance_of: (name) => `Rendimiento de ${name}`,
  performanceDetails: `Historial del rendimiento`,
  performanceDetails_of: (name) => `Rendimiento de ${name || ''}`,
  performanceDetails_sub: (name, stat) =>
    `Estadísticas del rendimiento de ${name || ''} ${stat || ''}`,
  performanceDetails_exerciseStats_sub: (name) => `Rendimiento del ejercicio de ${name || ''}`,
  performanceDetails_programsStats_sub: (name) => `Rendimiento del programa de ${name || ''}`,
  achievementDetails_sub: (name) => `Logros de ${name || ''}`,
  lastRegisteredStat: `Últimas estadísticas registradas: `,
  Execution: 'Ejecución',
  'For changes click here': 'Para ver los cambios haga clic aquí',
  aspireCommentTitle: () => `Aspire dice`,
  HALF_TIME_DOME: (timeLeft, log) => `Estamos a mitad de camino, tiempo restante: ${timeLeft}`,
  TIME_DOME_75: (timeLeft, log) => `Nos estamos acercando, tiempo restante: ${timeLeft}`,
  TIME_DOME_90: (timeLeft, log) => `Casi hemos terminado, tiempo restante: ${timeLeft}`,
  TIMER_TIME_DOME: (timeLeft, log) => `¡Listo! ¡Buen trabajo!`,
  usingThisProgram: (name, date) => `utilizado por '${name}' hasta ${date}`,
  addProgramToPlan: 'Agregar este programa a su plan',
  myClientsList: 'Mi lista de clientes',
  myClientsList_sub: 'Actualizar/desvincular un cliente de su lista',
  refreshInfo: 'Actualizar información',
  refreshClientsInfo: 'Actualizar la información de los clientes',
  unlink: 'Desvincular',
  clientDetails: 'Datos del cliente',
  unlink_question: (clientName) =>
    `¿Está seguro de que quiere desvincular a '${clientName}' de su lista de clientes?`,

  // validation
  pleaseChooseClient: 'Seleccione un cliente',
  pleaseSelectRoutine: 'Seleccione un rutina',
  invalidDate: 'Fecha invalida',
  missingDate: 'Fecha es obligatorio',
  isRequired: (name) => `${name} es obligatorio`,
  tooShort: (name) => `${name} es demasiado corto`,
  tooLong: (name) => `${name} es demasiado largo`,
  typeMismatch: (name) => `${name} tiene un valor incorrecto`,
  rangeUnderflow: (name, value) => `${name} debe ser mayor/igual ${value}`,
  rangeOverflow: (name, value) => `${name} debe ser menor/igual que ${value}`,
  passwordsMismatch: 'las contraseñas no coinciden',
  "Please include an '@' in the email address": `Por favor, incluya un '@' en la casilla del correo electrónico`,
  typeMismatchReps: `'Repeticiones' tiene un valor erróneo`,
  typeMismatchReps_sub: `El objetivo actual requiere un valor de 'repeticiones' igual`,
  typeMismatchWeights: `El 'Peso' tiene un valor erróneo`,
  typeMismatchWeights_sub: `Los valores de 'Peso' no pueden ser igual a 0`,
  termsAgreement_1: 'Al hacer clic en Registrarse, acepta nuestros ',
  termsOfService: 'Términos de Servicio',
  termsAgreement_2: ' y haber leído nuestra ',
  policy: 'Política de Uso de Datos.',
  termsAgreement_3: ' ',
  privacyPolicy: 'Política de privacidad',
  askMeAgain: 'Pregúnteme de nuevo la próxima vez.',
  noThankYou: 'No, gracias',
  letMyTry: 'Déjeme probar por mi cuenta',
  checkTermsAgreement: 'Por favor, acepte las condiciones de Aspire',

  // error handler text

  error: 'error',
  emailAlreadyInUse: 'El correo electrónico ya está en uso',
  noUserFound: 'El usuario no está en el sistema',
  badCredentials: 'Por favor, compruebe su Contraseña/correo electrónico',
  emailNotVerified: 'El correo electrónico no está verificado',
  checkEmailFirst: 'Compruebe su correo electrónico antes de enviar',
  accessDenied: 'Acceso denegado',
  unhandledError: 'Algo salió mal :(',
  wrongPassword: 'Contraseña incorrecta, inténtelo de nuevo',
  lockForHour: 'Bloquear durante 1 hora',
  emailAlreadyExist: 'El correo electrónico ya existe',
  emailIsGood: 'El correo electrónico es correcto',
  invalidName: 'Nombre inválido',
  invalidEmail: 'Correo electrónico inválido',
  emailNotExist: 'El correo electrónico no existe',
  invalidPhoneNumber: 'Número de teléfono inválido',
  limitReached: 'Límite alcanzado',
  invalidForm: 'Formulario inválido',
  invalidCode: 'Código inválido',
  userAlreadyExist: 'El usuario ya existe',
  phoneAlreadyExist: 'El número de teléfono ya existe',
  phoneNotExist: 'El teléfono no existe',
  userSuspended: 'El usuario está suspendido',
  alreadyLoggedIn: 'El usuario ya ha iniciado sesión',

  // onBoarding
  // profile
  yourBusinessPlatform: 'Su plataforma de negocios',
  yourBusinessPlatform_desc:
    'Demuestre sus habilidades y su mentalidad compartiendo multimedia, programas y resultados de los clientes. Próximamente se mostrará a los clientes potenciales.',
  inviteClients: 'Exclusivo para sus clientes',
  inviteClients_desc:
    'Invite a sus clientes personales a una comunidad exclusiva hecha para sí mismo así como para ellos y permanezca involucrado 24/7.',

  // create workout
  fillWorkoutDetails: 'Rellene los detalles del programa',
  fillWorkoutDetails_desc:
    'Para la creación del programa, hay 3 etapas que un entrenador necesita completar. La primera es rellenar los detalles del mismo:',
  fillWorkoutDetails_list: [
    'Seleccione un cliente / usted mismo de la lista de clientes.',
    'Ponga un título a su entrenamiento',
    'Elija una imagen de la biblioteca de ASPIRE o de su teléfono móvil.',
  ],
  selectTheExercises: 'Seleccione los ejercicios a añadir',
  selectTheExercises_desc:
    'Seleccione los ejercicios que desea incluir en su programa.\n' +
    'Puede cambiar entre ejercicios normales o superseries, encontrar ejercicios por categoría, músculo y equipamiento.\n' +
    '* Para cambiar el orden de los ejercicios seleccionados puede arrastrarlos y moverlos hacia arriba/abajo en la lista.',
  customizeYourProgram: 'Personalice su programa',
  customizeYourProgram_list: [
    'Seleccione el equipamiento para cada ejercicio.',
    'Añada / edite número de series, repeticiones y peso.',
    'Añada sus propias notas a cada ejercicio',
    'Cambie el nombre del ejercicio - para que el cliente lo entienda mejor.',
    'Elimine cualquier ejercicio.',
  ],
  creatingGoal: 'Crear un objetivo',
  creatingGoal_desc:
    'Hay 2 pasos para la creación de un objetivo.\n' +
    'En primer lugar, seleccione un cliente y un determinado estado corporal/ejercicio.',
  creatingGoal_list: [
    'Elija un cliente/ usted mismo de la lista de clientes.',
    'Seleccione el tipo de objetivo: Cuerpo/Ejercicio.',
    'Seleccione un objetivo determinado de la lista.',
  ],
  fillGoalsDetails: 'Rellene los detalles del objetivo',
  fillGoalsDetails_desc:
    'Rellene los detalles del objetivo, incluyendo el título, la descripción, el marco de tiempo para el objetivo. Inserte un estado inicial y objetivo para medir el progreso de su cliente.* La aplicación le mostrará las últimas estadísticas del cliente registradas en el ejercicio elegido / estadísticas corporales (depende de si hay un registro en la aplicación).',
  fillGoalsDetails_list: [
    'Rellene los detalles del objetivo: título, descripción, plazo de tiempo para el objetivo.',
    'Inserte un estado inicial y objetivo para medir el progreso de su cliente.',
    'La aplicación le mostrará las últimas estadísticas del cliente registradas en el ejercicio elegido / estadísticas corporales (depende de si hay un registro en la app)',
    'Atención: si se ha seleccionado un objetivo de rendimiento, debe seleccionar el equipamiento y el tipo de medida de rendimiento. \n' +
      'p.ej. mejor serie, mayor número de repeticiones, mayor peso, entre otros...',
  ],
  fillExerciseDetails: 'Rellene los detalles del ejercicio',
  fillExerciseDetails_desc:
    'Ponga un nombre a su ejercicio y escriba las instrucciones de ejecución.',
  uploadEquipmentMedia: 'Subir material multimedia del equipamiento',
  uploadEquipmentMedia_desc: 'Subir un mēdēə para explicar el ejercicio\n*Subir mēdēə no es un paso obligatorio.',
  helpful: 'útil',
  unhelpful: 'inútil',
  steps: 'pasos',
  solutions: 'resoluciones',
  replay: 'reproducir',
  chooseMediaType: 'Elegir tipo de mēdēə',
  uploadCertificates: 'Subir certificados',
  uploadCertificates_desc:
    'Por favor, suba documentos que verifiquen su formación en los campos que ha marcado. (también puede saltarse esta etapa al deslizar)',
  congratulations: '¡Felicidades!',
  dummyUserAppended:
    '¡Su registro está completado! ¡Conozca a Alice, su primer cliente en Aspire! ¡Con Alice, usted podrá experimentar todo lo que Aspire le ofrece!',
  goodLuck: 'Bienvenido a Aspire - ¡Conviértase en lo mejor que puede ser!',
  orSkipAndUploadLater: "O salte haciendo clic en 'Siguiente' y cargue más tarde.",
  uploadImages: 'Subir imágenes',
  uploadImages_desc: 'Seleccione y suba sus imágenes o pulse el botón Volver en la parte superior',
  uploadVideos: 'Subir vídeos',
  uploadVideos_desc: 'Seleccione y suba sus vídeos o pulse el botón Volver en la parte superior',
  uploadFiles: 'Subir archivos',
  uploadFiles_desc: 'Seleccione y suba sus archivos o pulse el botón Volver en la parte superior',
  uploadProfileImage: 'Subir imagen de perfil',
  uploadBackgroundImage: 'Subir imagen de fondo',
  welcomeToAspire: (name) => `¡Bienvenido ${name}!`,
  welcomeToAspire_desc:
    '¡Ahora es parte de nuestra comunidad!\n' +
    'Es hora de crear su propio, entorno profesional y construirse como marca',
  welcomeToAspireClient_desc:
    'Su entrenador le ha invitado a un entorno exclusivo para disfrutar de una experiencia más personal y profesional.',
  clickNextOrSwipe: "haga clic en 'Siguiente' o deslice para continuar'.",
  smsOnItsWay: 'SMS enviado',
  smsWillBeReceived: 'En unos segundos recibirá un SMS con un código de 5 dígitos para ingresar.',
  resendSMS: 'SMS reenviado',
  insertTrainerInvite: 'Código de invitación',
  insertTrainerInvite_sub:
    'Por favor, introduzca el código de invitación que ha recibido de nuestro SMS o de su entrenador.',

  noResultsFound: 'No se han encontrado resultados',
  pleaseTryAgain: 'Por favor, inténtelo de nuevo',
  noSessionFound: 'No se ha encontrado información sobre la sesión actual',
  documents: 'Documentos',
  myDocuments: 'Mis documentos',
  documents_of: (name) => `Documentos de ${name || ''}`,
  surveys_of: (name) => `Encuestas de ${name || ''}`,
  surveys: 'Encuestas',
  documentArchive: 'Archivo de documentos',
  surveyArchive: 'Archivo de encuestas',
  createDocument: 'Crear nuevo documento',
  createDocument_sub: 'Rellenar datos del documento',
  updateSurvey: 'Actualizar encuesta',
  updateSurvey_sub: 'Cambiar/añadir preguntas a la encuesta actual',
  updateDocument: 'Actualizar documento',
  updateDocument_sub: 'Actualizar datos del documento',
  createSurvey: 'Crear una nueva encuesta',
  createSurvey_sub: 'Rellenar los datos de la encuesta',
  surveyFillDetails: 'Rellenar encuesta',
  surveyFillDetails_sub: 'Responder las siguientes preguntas.',
  documentsFilled: 'Documentos rellenados',
  filled: 'Rellenado',
  templates: 'Plantillas',
  template: 'Plantillas',
  forReviewSurvey: 'Encuestas no revisadas',
  forReviewDoc: 'Documentos no revisados',
  chooseClients_doc: 'Seleccionar clientes para enviarles el documento',
  addCheckboxToConfirmAgreement: `Añadir una casilla de verificación al documento para confirmar que el cliente ha leído y está de acuerdo con las condiciones.`,
  isAgreeText: 'añadir texto personalizado a la casilla de verificación',
  IAgreeToTerms: 'He leído y estoy de acuerdo con los términos y condiciones de este documento.',
  isClientCanModifyDoc: `Permitir al cliente modificar/cambiar el contenido del documento.`,
  forClient: 'Para el cliente',
  addQuestion: 'Añadir una pregunta',
  requiredAnswer: 'Respuesta requerida',
  question: 'Pregunta',
  options: 'Opciones',
  addOption: 'Añadir una opción',

  // inputOption

  inputOption: 'Campo de texto',
  numberOption: 'Campo de números',
  longInputOption: 'Campo de texto largo',
  checkboxOption: 'Verificación multiple',
  radioOption: 'Verificación individual',
  dateOption: 'Campo de fecha',
  optionsPlaceHolderText: 'La respuesta se escribirá aquíThe answer will be written here...',
  optionsPlaceHolderLabel: 'Etiqueta opcional',
  answer: 'Respuesta',
  surveyTitle: 'Título de la encuesta',
  surveyDescription: 'Descripción de la encuesta',
  surveyIntro: 'Aquí puede escribir una breve introducción a su encuesta...',
  noQuestion: 'Añada al menos una pregunta a la encuesta.',
  noAnswers: 'Rellene las preguntas requeridas en la encuesta.',
  fillRequiredAnswers: 'Rellene las preguntas requeridas en la encuesta.',
  missingSurveyTitle: 'Falta el título de la encuesta.',
  noQuestionText: (index) => `Falta una pregunta en la sección ${index}`,
  noLabelOption: (name) => `Falta la etiqueta de la opción en la pregunta: ${name}`,
  starRequiredFields: '* CamposRequeridos',
  copySurvey: 'Copiar encuesta a mi cuenta',
  assignDocumentSuccessfully: 'Todos los documentos han sido enviados con éxito',
  assignSurveySuccessfully: 'Todas las encuestas han sido enviadas con éxito',
  unfinishedSession_sub: 'Su sesión reciente terminó inesperadamente, ¿debemos reanudarla?',
  potentialClients: 'Clientes potenciales',
  contactsList: 'Lista de contactos',
  contactDetails: 'Datos de contacto',
  contactYouFrom: 'Una solicitud de contacto de',
  aspireWebsite: 'Sitio web de ASPIRE',
  by: ' por ',
  hereAListOfContactWillAppear:
    'Los clientes potenciales que se pongan en contacto con usted a través de la página web de ASPIRE se alistarán aquí.',
  phoneCopied: 'Teléfono copiado',
  misMatchPasswords: 'Contraseñas no coincidentes',
  misMatchCode: 'Código no coincidente',

  // tutorial overview

  welcome: '¡Bienvenido!',
  welcome_sub:
    'Gracias por darnos la oportunidad de ser mejores entrenadores con ASPIRE.  Permítanos presentarle nuestras capacidades, ¿qué le gustaría saber primero?',
  welcomeAspireClip: 'La bienvenida a Aspire - clip',
  profileOverview: 'Resumen del perfil',
  inviteClientOverview: 'Resumen de la invitación al cliente',
  programOverview: 'Resumen del programa',
  planOverview: 'Creación y uso del plan',
  achievementsOverview: 'Objetivos y Logros',
  statisticsOverview: 'Resumen de estadísticas',
  paperworkOverview: 'Resumen del papeleo',
  createMyExerciseOverview: 'Resumen de mis ejercicios',

  // profileCard

  addCertificates: 'Añadir certificado',
  addLocation: 'Añadir ubicación',
  socialDetails: 'Datos sociales del usuario',
  socialDetails_sub: 'Añada sus vínculos sociales',
  userDetails_sub: 'Rellene sus datos',
  introductionVideo: 'Vídeo de presentación del usuario',
  introductionVideo_sub: 'Añada un vídeo de presentación para aumentar su compromiso',
  trainingLocations: 'Lugares de entrenamiento',
  trainingLocations_sub: 'Añada los lugares donde entrene con sus clientes',
  professionCertificates: 'Certificados profesionales',
  professionCertificates_sub: 'Suba sus certificados profesionales',
  SearchAPlace: 'Buscar un lugar',
  imagesAndVideos: 'Imágenes y vídeos',
  myGallery: 'Mi Galería',
  bio: 'Biografía',
  userBiography: 'Biografía del usuario',
  userBiography_sub: 'Escriba aquí sobre usted y su experiencia personal en el fitness.',

  city: 'Ciudad',
  expertise: 'Experiencia',
  facebook: 'facebook',
  instagram: 'instagram',
  youtube: 'youtube',
  twitter: 'twitter',
  linkedin: 'linkedin',
  selectMyMedia: 'Seleccionar de mi mēdēə',
  selectFilesToUpload: 'Seleccionar y Subir', // Seleccionar y Subir archivos
  wouldYouLikeAWalkThru: '¿Quiere que le guíen rápidamente en el proceso?',
  programCreationTutorial: '¡Si, guíame!',
  noThankUTutorial: 'Lo haré yo mismo',

  chooseClient_sub: 'Seleccione un cliente / usted mismo para asignar este programa.',
  groupClients_sub: "Seleccionando una cantidad de clientes se creará un 'Programa de Grupo'.",
  fillProgramTitle: 'Ponga un título a su programa.',
  afterFillForm: 'Después de completar el formulario, ',
  saveToProceed: "clic en 'Guardar' para continuar",
  nextToProceed: "clic en 'Siguiente' para continuar",
  doneToProceed: "clic en 'Hecho' para guardar todos los cambios que ha realizado.",
  nextToProceed2:
    "Cuando haya terminado de elegir los ejercicios que necesita, haga clic en 'Siguiente' para continuar.",
  chooseExercisesTutorial_sub: 'Seleccione 3 ejercicios que desee añadir a su programa.',
  selectedExercisesTutorial_sub: 'Aquí puede ver los ejercicios que ha elegido para este programa.',
  filterTutorial_sub: 'Aquí puede elegir entre hacer una súper serie / un ejercicio regular',
  clickOnAlice_sub: "clic en 'Alice' en su lista de clientes para ver sus estadísticas.",

  exerciseType_categories: 'Cambiar el tipo de ejercicio',
  filterOptions: 'Opciones de búsqueda',
  filterOptions_sub:
    'Puede encontrar ejercicios por texto o filtrar por músculo, equipamiento o categorías.',
  aspireTrainerIntroduction_sub:
    '"Como primer cliente, permítame guiarlo, le garantizo que entenderá \'Aspire\' más rápido conmigo a su lado". Empecemos por:',
  aspireClientIntroduction_sub:
    '"En su primera visita, permítame guiarle, le garantizo que entenderá \'Aspire\' más rápido conmigo a su lado." Empecemos con:',
  aspireClientIntroductionTwo_sub:
    '"Como su entrenador, permítame guiarlo a través de \'Aspire\', para que podamos entrenar mejor en el futuro." Empecemos con:',
  editBtnTutorial_sub: "Pulse 'Editar' y podrá modificar las series y áreas de los ejercicios.",
  exerciseOptionsTutorial_sub:
    'Opciones de ejercicio ampliadas: Añada sus comentarios, cambie las áreas del ejercicio, modifique el nombre...',
  exerciseEquipmentTutorial_sub:
    'También puede cambiar el equipamiento seleccionado del ejercicio desde aquí.',
  squareInfo_hint:
    'Programar las estadísticas sobre el número de entrenamientos realizados + el tonelaje total levantado.',
  sessionUpdateBtn_hint:
    'clic aquí para iniciar su entrenamiento y actualizar su estado en tiempo real.',
  exerciseDescription_hint:
    'clic aquí para ver la descripción del ejercicio, el mapa muscular y los posibles equipamientos.',
  superset_hint:
    'Vista de los ejercicios de la superserie, deslice a la izquierda/derecha para verlos.',
  clickToCheck_hint: 'clic aquí para actualizar la serie a "Completada" y activar la siguiente.',
  modifyExerciseBtn_hint:
    'clic aquí para modificar las series y actualizar las áreas si es necesario',

  fillClientInfo_sub: "Rellene los datos de su cliente y haga clic en 'Siguiente'.",
  changeClientsAppSettings_sub:
    'En caso de que sea necesario, cambie la configuración básica de su cliente',
  editProfile: 'Cree su perfil',
  editProfile_sub: 'clic aquí para crear su perfil',
  uploadImage_sub: 'Su foto de perfil se mostrará aquí, ¿empezamos?',
  trainersProfile: 'Perfil del entrenador',
  trainersProfile_sub:
    'Puede hacer clic en el icono del perfil de su entrenador (esquina derecha) si desea visitar su página de perfil.',
  notifyUserToVerifyAccount_desc:
    'Su cuenta no ha sido verificada, si no ha recibido nuestro correo de verificación, haga clic en "enviar de nuevo" (por favor, compruebe también su bandeja de entrada de \'promociones\').',
  sendAgain: 'Enviar de nuevo',
  cantFindVerificationMail: '¿No puede encontrar su correo de verificación?',
  cantFindVerificationMail_comment:
    '(por favor, compruebe también su bandeja de entrada de promociones)',
  verificationSent: 'Mensaje de verificación enviado',
  hide: 'Ocultar',
  dontShowAgain: 'No volver a mostrarlo',
  personalProgram: 'Programa personal - un cliente',
  groupProgram: 'Programa de grupo - cantidad de clientes',
  pickAColor: 'Elija un color',
  selectDate: 'Seleccionar fecha',
  selectDates: 'Seleccionar fechas',
  chooseDateRange: 'Seleccionar rango de fecha',
  makeAllDayEvent: 'Este evento está activo todo el día.',
  createAnEvent: 'Crear un evento',
  rescheduleAnEvent: 'Reprogramar evento',
  writeATitle: 'Escribir un título...',
  participants: 'Participantes',
  advancedOptions: 'Opciones avanzadas',
  recurringEvent: 'Opciones de eventos repetidos',
  openRecurringOptions: 'Abrir opciones de eventos repetidos',
  repeatEvery: 'Repetir cada',
  repeatEvery_day_info: 'El evento se repetirá cada día o cada x días, según el valor',
  repeatEvery_week_info: 'El evento se repetirá cada x semanas en días concretos de la misma',
  repeatEvery_month_info: 'El evento se repetirá cada x meses en un día específico del mismo',
  stopConditions: 'Condiciones de detención',
  neverStop: 'No detener nunca.',
  neverStop_info: 'El evento se repetirá indefinidamente.',
  untilDate: 'Ejecutar hasta una fecha específica.',
  untilDate_info: 'El evento se ejecutará hasta una fecha específica.',
  untilNumber: 'Ejecutar el evento',
  untilNumber_info: 'El evento se repetirá hasta que llegue a una cantidad determinada de veces.',
  eventDates: 'Fecha del evento',
  trainer: 'Entrenador',
  confirmArrival: 'Confirmar llegada',
  postpone: 'Posponer',
  reschedule: 'Reprogramar',
  repeatedEvent: 'Evento repetido',
  thisEventRepeats: 'Este evento se repite',
  for: ' para ',
  until: ' hasta ',
  weekly2: ' cada semana ',
  daily2: ' cada día ',
  inDays: ' en ',
  editRecurringEventSelect: 'Editar evento recurrente:',
  deleteRecurringEventSelect: 'Eliminar evento recurrente:',
  thisEventOnly: 'Sólo este evento.',
  thisEventAndFollowing: 'Este evento y los siguientes.',
  allEvents: 'Todos los eventos',
  pending: 'Pendiente',
  confirmed: 'Confirmado',
  canceled: 'Cancelado',
  cancelArrival: 'Cancelado',
  noDatesSelected: 'No se han seleccionado fechas para reprogramar.',
  onGoing: 'En curso',

  beginner: 'Principiante',
  trained: 'Entrenado',
  wellTrained: 'Bien entrenado',
  athlete: 'Atleta',

  copyProgramSuccessfully: 'El programa se ha copiado en su cuenta con éxito',
  success: 'Éxito',
  successfullyUpdated: 'Actualizado con éxito',
  successfullyUpdatedPassword: '¡Su contraseña ha sido actualizada con éxito en el sistema!',
  successfullyUpdatedPhone: '¡Su número de teléfono ha sido actualizado con éxito en el sistema!',
  successfullyUpdatedPhone2: 'A partir de ahora puede iniciar sesión con su número de teléfono.',
  somethingWentWrong: 'Algo ha ido mal',
  stay: 'Quédese aquí',
  articles: 'Artículos',
  helloUserStore: (name) => `Hola ${name}`,
  helloUserStore_desc:
    'Bienvenido a nuestra tienda, siéntase libre de usar y compartir nuestras plantillas con sus clientes.',
  storePrograms: 'Archivo de programas',
  storePrograms_desc:
    'Puede copiar, cambiar y compartir nuestras plantillas de entrenamiento con sus clientes.',
  personal_info:
    'Un programa de entrenamiento personal, Selecciona UN cliente para asignarle este programa, puedes asignar a otros más adelante.',
  group_info:
    'Un programa de entrenamiento en grupo, Seleccione varios clientes para asignar este programa.',
  public_info:
    'Este Programa servirá como plantilla GRATUITA para que sus clientes y visitantes puedan revisarlo y probarlo desde su página de perfil.',
  store_info:
    'Este programa es para ser revisado por ASPIRE, si es aceptado, será subido a la tienda de aspire con su nombre y una insignia.',
  circuitTraining: 'Entrenamiento en circuito',
  SET_option: 'Series',
  CIRCUIT_option: 'Circuito',
  SET_info:
    'Vista del programa por defecto, permite al usuario ver los ejercicios como series, superseries, pirámides...',
  CIRCUIT_info:
    'Vista de entrenamiento en circuito, permite al usuario determinar el número de rondas para los ejercicios elegidos.',
  optionsAreDisabled:
    'La selección de una opción está desactivada. Usted se encuentra en el modo tutorial o en el modo de edición.',
  original: 'Origen',
  newUpdates: 'Nuevas actualizaciones',
  whatsNew: `Qué hay de nuevo`,
  signUpBy: 'Registrarse por',
  signinBy: 'Iniciar sesión por',
  inviteBy: 'Invitar por',
  updateDetails: 'Actualizar datos',
  updateDetails_2: 'Actualizar',
  signupByPhone: 'Registrarse por teléfono',
  signupByEmail: 'Registrarse por correo electrónico',
  insertYourNumberToVerify:
    'Para recibir un código de verificación por SMS, por favor, inserte su número de teléfono.',
  verifyEmailBeSent:
    'Recibirá un correo electrónico para verificar su cuenta, por favor, compruebe también su pestaña de "promociones".',
  sendVerificationCode: 'Enviar código de verificación',
  aspireIntro_title_1: 'Una nueva era para el entrenamiento personal.',
  aspireIntro_desc_1:
    'Potenciamos a los entrenadores en todos los niveles, dando a sus clientes el 100% de enfoque y devoción a su causa.',
  aspireIntro_title_2: 'Manténgase conectado 24/7',
  aspireIntro_desc_2:
    'Cree su propio entorno profesional e invite a sus clientes a una plataforma exclusiva, hecha para el progreso.',
  aspireIntro_title_3: 'Experiencia única',
  aspireIntro_desc_3:
    'Cada característica que desarrollamos le ayuda a ser más profesional, más personal, más usted mismo.',
  copied: 'Copiado',
  certainField: 'Campo',
  routineAdvanceOptions: 'Opciones avanzadas de rutina',
  routines: 'Rutinas',
  attention: 'Atención!',
  goBackNotice: 'Going back will delete all changes you\'ve made on current page.\n Are you sure you want to go back?',
  routineUpdateDetails: 'Edit routine details',
  routineUpdateDetails_sub: 'Update routine title & settings',
  processingMedia: 'Your upload is being processed,\nit might take a few minutes.\nfeel free to continue.',
  clientProfileIsPrivate: 'Client\'s profile is private.\nOnly your trainer can see it.',
  openContactsList: 'Open contacts list',
  countryCodes: 'Country codes',
  selectCountry: 'Select country',
  selectCountryCode: 'Please select country code',
  noCountryCodeAttached: 'No country code attached to phone number',
  clickHereToAddExercises: "Click on add exercises",
  saveProgram: 'save a program',
  resend: 'Resend',
  'Missing Credentials': 'Missing Credentials',
  resendInvitationAgain: 'Send Again',
  unverifiedClientAccountInfoEmail: () => (
    <span>A client account created under this address but <strong>has yet to be verified.</strong>
      <br />
      Please check your email <strong>&apos;Inbox&apos; or &apos;Promotions&apos; tab</strong> & get your invitation code.
    </span>
  ),
  unverifiedClientPhoneInfo: () => (
    <span>A client account created under this phone number but <strong>has yet to be verified.</strong>
      <br />
      Please check your SMS inbox & get your <strong>5-digits code</strong>, then click &apos;Sign In&apos;.
    </span>
  ),
  resendSMStoUnverifiedClientPhone: () => (
    <span>In a few moments you&apos;ll receive an SMS with a new invitation code.
      <br />
      Please check your SMS & get your <strong>5-digits code</strong>, then click &apos;Sign In&apos;.
    </span>
  ),
  calendarSettings: 'Calendar settings',
  editAnEvent: 'Update event details',
  rescheduleReason: 'Reschedule reason',
  sundayToSunday: 'Sunday to sunday',
  mondayToMonday: 'Monday to monday',
  firstDayOfTheWeek: 'First day of the week',
  firstDayOfTheWeek_sub: 'Set your week to start from Sunday or Monday.',
  restrictAvailableHours: 'Set operating hours',
  restrictAvailableHours_sub: 'Setting operating hours will allow your Clients to schedule workouts only at the time allowed between the two values.',
  timeInputSettings: 'Time Input Settings',
  timeInputSettings_sub: 'Configure the way you want your time input to look and feel.',
  timeInputExample: 'Click here to see the changes you\'ve made on the input.',
  daysOff: 'Days off',
  daysOff_sub: 'Configure days your clients wont be able to schedule workouts. For example: Saturday, Sunday.',
  timegrid: 'Time grid',
  timeWheel: 'Time wheel',
  fiveMinutes: '5 Minutes',
  tenMinutes: '10 Minutes',
  fifteenMinutes: '15 Minutes',
  thirtyMinutes: '30 Minutes',
  watchYoutubeVideo: 'Watch Youtube video',
  bookSession: 'Book',
  waitingForResponse: 'Waiting for response',
  confirmedArrival: 'Confirmar llegada',

  access_denied_pro_up: `Access available to 'Pro Trainer' and up`,
  access_denied_gold_up: `Access available to 'Gold Trainer' and up`,
  access_denied_update_sub: `, to update subscription go to 'Settings'.`,
  clickHere: 'click here.',
  latestStatClientProgress_info: (name) => `To view ${name}'s latest session stats on current workout, `,
  latestStatClientWorkout_info: (name) => `Scroll down to see ${name}'s latest session stats on current workout.`,
  NoSessionsOnWorkout_info: (name) => `No Session by ${name} were made yet.`,

  showLess: 'Show less',
  bookASession: 'Book a session',
  leave: 'Leave',
  progress: 'Progress',
  program: 'Program',
  goBackWithoutSave_notice: "Going back without saving will reset all your changes, are you sure you want to go back?",
  areYouSureUnSubscription: 'Are you sure you want to unsubscribe?',
  cancelSubscription: 'Cancel Subscription',
};

export const langs = { he, en, es };

const equipmentEN = {
  BARBELL: 'Barbell',
  DUMBBELL: 'Dumbbell',
  BODY_WEIGHT: 'Body weight',
  DUMBBEL: 'Dumbbell',
  KETTELE_BELL: 'Kettlebell',
  SAFETY_SQUAT_BAR: 'Safety Squat Bar',
  BICYCLE: 'Bicycle',
  ROPE: 'Rope',
  CABLE_VER_3: 'cable ver. 3',
  CABLE: 'cable',
  STAIR: 'Stair',
  MACHINE_VER_2: 'machine ver. 2',
  RECUMBENT_CYCLE: 'Cycle (machine)',
  OUTSIDE: 'Park (Outside)',
  RUBBER: 'Rubber',
  JUMP_ROPE: 'Jump rope',
  WEIGHT_BAG: 'Weight Bag',
  TRX: 'TRX',
  STEPMILL: 'Stepmill',
  MEDICINE_BALL: 'Medicine Ball',
  RINGS: 'Rings',
  SLED: 'Sled',
  FIT_BALL: 'fit ball',
  MASSAGE_BALL: 'Massage Ball',
  BOX: 'Box',
  SWISS_BAR: 'Swiss Bar',
  SKI_EGRGOMETER: 'Ski Ergometer',
  BOSU_BALL: 'BOSU Ball',
  TRAP_HEX_BAR: 'Trap (Hex) Bar',
  CLIMBMILL: 'Climb Mill / Tread Wall',
  FOAM_ROLL: 'Foam Roll',
  SMITH_MACHINE: 'smith machine',
  SKI_MACHINE: 'Ski machine',
  BENCH: 'Bench',
  CHAINS: 'Chains',
  LADDERMILL: "Ladder Mill (Jacob's Ladder)",
  RESISTANCE_BAND: 'resistance band',
  PILATE_RING: 'Pilate ring',
  PUNCHING_BAG: 'Paunch Bag',
  MACHINE: 'machine',
  CLIMBER: 'Climber (handle bars machine)',
  CYCLE_CROSS: 'Cycle Cross T.',
  BARBEL: 'barbell',
  EZ_CURL_BAR: 'EZ Curl Bar',
  TREADMILL: 'Treadmill',
  ROW_EGRGOMETER: 'Row Ergometer',
  TIRE: 'Tire',
  CABLE_VER_2: 'cable ver. 2',
  ELLIPTICAL_GLIDER: 'Elliptical Glider',
  MATTRESS: 'Mattress',
  FREE_WEIGHTS: 'Weights',
  PAUNCH_MITTS: 'Punch Mitts',
  MACHINE_VER_3: 'machine ver. 3',
  ASSISTED: 'Assisted',
};

const equipmentHE = {
  BARBELL: 'מוט',
  DUMBBELL: 'משקולות יד',
  BODY_WEIGHT: 'משקל גוף',
  DUMBBEL: 'משקולות יד',
  KETTELE_BELL: 'קטלבל',
  SAFETY_SQUAT_BAR: 'מוט בטיחות לסקווט',
  BICYCLE: 'אופנים',
  ROPE: 'חבל',
  CABLE_VER_3: 'כבלים 3',
  CABLE: 'כבלים',
  STAIR: 'מדרגה',
  MACHINE_VER_2: 'מכונה 2',
  RECUMBENT_CYCLE: 'אופנים בישיבה',
  OUTSIDE: 'פארק (בחוץ)',
  RUBBER: 'גומיות (ידיות)',
  JUMP_ROPE: 'חבל קפיצה',
  WEIGHT_BAG: 'שק משקולת',
  TRX: 'TRX',
  STEPMILL: 'מכונת מדרגות',
  MEDICINE_BALL: 'כדור כוח',
  RINGS: 'טבעות',
  SLED: 'מזחלת',
  FIT_BALL: 'כדור פיזיו',
  MASSAGE_BALL: 'כדור עיסוי',
  BOX: 'קופסאארגז',
  SWISS_BAR: 'מוט Swiss',
  SKI_EGRGOMETER: 'סקי ארגומטר',
  BOSU_BALL: 'כדור בוסו',
  TRAP_HEX_BAR: 'מוט Hex/Trap',
  CLIMBMILL: 'מכונת טיפוס (טיפוס דנאמי)',
  FOAM_ROLL: 'גליל עיסוי',
  SMITH_MACHINE: 'מכונת סמית',
  SKI_MACHINE: 'אליפטי (סקי)',
  BENCH: 'ספסל',
  CHAINS: 'שרשראות',
  LADDERMILL: 'מכונת טיפוס (סולם)',
  RESISTANCE_BAND: 'גומית התנגדות',
  PILATE_RING: 'טבעת פילאטיס',
  PUNCHING_BAG: 'שק אגרוף',
  MACHINE: 'מכונה',
  CLIMBER: 'מכונת טיפוס (ידיות)',
  CYCLE_CROSS: 'אופני קרוס',
  BARBEL: 'מוט',
  EZ_CURL_BAR: 'מוט W',
  TREADMILL: 'הליכון',
  ROW_EGRGOMETER: 'מכונת חתירה',
  TIRE: 'צמיג',
  CABLE_VER_2: 'כבלים 2',
  ELLIPTICAL_GLIDER: 'אליפטי',
  MATTRESS: 'מזרן',
  FREE_WEIGHTS: 'משקולת',
  PAUNCH_MITTS: 'לפות אגרוף',
  MACHINE_VER_3: 'מכונה 3',
  ASSISTED: 'בעזרת מאמן',
};

const equipmentES = {
  BARBELL: 'Haltera',
  DUMBBELL: 'Mancuerna',
  BODY_WEIGHT: 'Peso corporal',
  DUMBBEL: 'Mancuerna',
  KETTELE_BELL: 'Pesa rusa',
  SAFETY_SQUAT_BAR: 'Barra de seguridad para sentadillas',
  BICYCLE: 'Bicicleta',
  ROPE: 'Cuerda',
  CABLE_VER_3: 'cable ver. 3',
  CABLE: 'cable cruzado',
  STAIR: 'Escalera',
  MACHINE_VER_2: 'máquina ver. 2',
  RECUMBENT_CYCLE: 'Bicicleta reclinada (máquina)',
  OUTSIDE: 'Parque (Exterior)',
  RUBBER: 'Goma',
  JUMP_ROPE: 'Cuerda de saltar',
  WEIGHT_BAG: 'Bolsa de peso',
  TRX: 'TRX',
  STEPMILL: 'Caminadora',
  MEDICINE_BALL: 'Balón medicinal',
  RINGS: 'Anillos',
  SLED: 'Sled',
  FIT_BALL: 'Bola de gimnasia',
  MASSAGE_BALL: 'Bola de masaje',
  BOX: 'Box',
  SWISS_BAR: 'Barra suiza',
  SKI_EGRGOMETER: 'Ski Ergómetro',
  BOSU_BALL: 'Bola BOSU',
  TRAP_HEX_BAR: 'Barra de tracción (Hexagonal)',
  CLIMBMILL: 'Escaladora / Muro de escalada',
  FOAM_ROLL: 'Rodillo de espuma',
  SMITH_MACHINE: 'Máquina Smith',
  SKI_MACHINE: 'Máquina Ski',
  BENCH: 'Banco',
  CHAINS: 'Cadenas',
  LADDERMILL: 'Escalera vertical (Escalera de Jacob)',
  RESISTANCE_BAND: 'Banda de resistencia',
  PILATE_RING: 'Anillo de pilates',
  PUNCHING_BAG: 'Saco de boxeo',
  MACHINE: 'máquina',
  CLIMBER: 'Escaladora (máquina de paso a paso)',
  CYCLE_CROSS: 'Bicicleta de montaña T.',
  BARBEL: 'barra',
  EZ_CURL_BAR: 'Barra Z curva',
  TREADMILL: 'Cinta de correr',
  ROW_EGRGOMETER: 'Remo Ergómetro',
  TIRE: 'Neumático',
  CABLE_VER_2: 'cable ver. 2',
  ELLIPTICAL_GLIDER: 'Deslizador elíptico',
  MATTRESS: 'Colchoneta',
  FREE_WEIGHTS: 'Pesas',
  PAUNCH_MITTS: 'Guantes de boxeo',
  MACHINE_VER_3: 'máquina ver. 3',
  ASSISTED: 'Asistido',
};

export const equipmentLangs = { en: equipmentEN, he: equipmentHE, es: equipmentES };
