/* eslint-disable max-len */
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import tutorialsData from './tutorialJson';
import 'react-tippy/dist/tippy.css';
import './TutorialGuide.css';

class TutorialGuide extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: tutorialsData[this.props.dataKey],
      pagesArray: [this.props.dataKey],
      addBackBtn: false
    };
  }

  openVideoModal (option) {
    const media = {
      user: null,
      public_id: 'VID-ASPIRE_TUTORIAL',
      title: 'aspire tutorial',
      type: 'video',
      sourceType: 'amazonS3',
      mediaGroup: 'aspireInformation',
      source: option.sourceHls.master,
      sourceHls: option.sourceHls,
      thumbnail: null
    };
    if (this.props.handleVideoModal) this.props.handleVideoModal(media, option.isPortrait);
  }

  goToPage (option) {
    if (this.props.handleGoToPage) this.props.handleGoToPage(option.url);
  }

  goBack () {
    const { pagesArray } = this.state;
    const pagesArrayModified = pagesArray.pop();
    const backLocation = pagesArray[pagesArray.length - 1];
    this.setState({ pagesArray, data: tutorialsData[backLocation], addBackBtn: pagesArray.length !== 1 });
  }

  selectOption (option) {
    console.log(option);
    const { pagesArray } = this.state;
    switch (option.type) {
      case 'video':
        this.openVideoModal(option);
        break;
      case 'url':
        this.goToPage(option);
        break;
      case 'tutorial':
        this.goToPage(option);
        break;
      case 'newPage': {
        pagesArray.push(option.key);
        this.setState({ pagesArray, data: tutorialsData[option.key], addBackBtn: true });
        break;
      }
      default:
    }
  }

  renderPage () {
    const { data, addBackBtn } = this.state;
    return (
      <div className="TutorialGuide flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
        { addBackBtn && (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start fadeIn-anime positionAbsoluteTopLeft">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start sidePadd5px" onClick={() => { this.goBack() }}>
              <i className="la la-arrow-left iconFontSize28 primary" />
            </div>
          </div>
        )}
        <div className="flex-100 layout-row layout-align-center-center padd10">
          <p className="text-capitalize fontWeight600 margin0 font20 primary"><Trans i18nKey={data.title} /></p>
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <p className="lineHeight14 sidePadd15px"><Trans i18nKey={data.content} /></p>
          { data.options && data.options.length > 0 && (
            <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10px optionListContainer">
              { data.options.map(option => (
                <button key={option.title} type="button" className="btn btn-transparent width100 text-start" onClick={() => { this.selectOption(option); }}>
                  <p className="margin0 fontWeight600 text-capitalize layout-row layout-wrap layout-align-start-center">
                    { option.icon && (<i className={`las ${option.icon} iconFontSize28 paddEnd5px`}></i>)}
                    <Trans i18nKey={option.title} />
                  </p>
                </button>
              ))}
            </div>
          )}
        </div>
        { data.buttons && data.buttons.length > 0 && (
          <div className="flex-100 layout-row layout-wrap layout-align-start-center borderTopGrey">
            { data.buttons.map(button => (
              <button type="button" onClick={() => { this.props.initiateFunction(button.funcName, button.data); }} className="flex height50px btn-transparent layout-row layout-wrap layout-align-center-center fontWeight600 text-uppercase font14">
                <Trans i18nKey={button.text} />
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }

  render () {
    const { data } = this.state;
    const tooltipPage = data && data.title && this.renderPage();
    return (
      <div className="TutorialGuide flex-100 layout-row layout-wrap layout-align-start-start">
        { tooltipPage }
      </div>
    );
  }
}

TutorialGuide.defaultProps = {
  data: {}
};

TutorialGuide.propTypes = {
  data: PropTypes.shape({}),
  dataKey: PropTypes.string
};

export default TutorialGuide;
