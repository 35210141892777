/* eslint-disable no-trailing-spaces,max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import { langs } from '../../services/translationLangs';
import { chooseFilterCallByPage } from '../../services/enums';
import { addClass, cleanInput, removeClass } from '../../services/utilsService';
import {
  dispatchFilteredExercises,
  dispatchFilteredInfos,
  getFilteredExercises,
} from '../../store/actions';
import './Filter.css';
import filterIcon from '../../assets/icons/filter.svg';
import BtnLoader from '../UI/BtnLoader';
import SearchInput from './SearchInput';
import { actionTypes } from '../../store/actions/actionTypes';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muscles: [],
      equipments: [],
      categories: [],
      muscleTarget: [],
      searchInput: '',
      chosenCategories: [],
      filterIsOpen: false,
    };
  }

  componentDidMount() {
    this.state = {
      categories: [],
      muscles: [],
      equipments: [],
      searchInput: '',
    };
  }

  handleChange(value) {
    this.state.searchInput = cleanInput(value);
  }

  categoryClicked(prop, stateKey) {
    if (this.props.singleSelect) {
      this.state[stateKey] = [prop];
    } else if (
      !this.state[stateKey] ||
      (this.state[stateKey] && this.state[stateKey].indexOf(prop) === -1)
    ) {
      if (!this.state[stateKey]) this.state[stateKey] = [];
      this.state[stateKey].push(prop);
    } else {
      this.state[stateKey].splice(this.state[stateKey].indexOf(prop), 1);
    }
    const { equipments, muscles, categories } = this.state;
    this.state.chosenCategories = [...equipments, ...muscles, ...categories];
    // update Component
    this.setState({ ...this.state });
  }

  async filterResults() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { equipments, categories, searchInput } = this.state;
      const pageProps = chooseFilterCallByPage[this.props.currentPage];
      const body = { ...pageProps.query };
      // if (muscles && muscles.length > 0) body.muscles = muscles;
      if (equipments && equipments.length > 0) body.equipments = equipments;
      if (categories && categories.length > 0) body.category = categories;
      if (searchInput && searchInput.length > 0) body.searchInput = searchInput;
      this.props.saveSearchQuery(body);
      await this.props[pageProps.funcName](body);
      this.handleFilterMode();
      this.isLoading = false;
    } catch (e) {
      console.log('handle error');
      this.isLoading = false;
    }
  }

  handleFilterMode() {
    if (this.state.filterIsOpen) {
      removeClass('#filterContainerId', 'expand');
      removeClass('.filterChecker', 'filterIsOpen');
      this.state.filterIsOpen = false;
    } else {
      addClass('#filterContainerId', 'expand');
      addClass('.filterChecker', 'filterIsOpen');
      this.state.filterIsOpen = true;
    }
    removeClass('#filterContainerId #BtnLoader', 'isLoading');
  }

  dispatchResults(body, data) {
    try {
      const pageProps = chooseFilterCallByPage[this.props.currentPage];
      this.props[pageProps.dispatch](body, data);
    } catch (e) {
      console.error('error handle');
    }
  }

  renderFilter(categories, stateKey, byProp) {
    if (categories && categories.length > 0) {
      return categories.map((category) => (
        <button
          type="button"
          key={category._id}
          className={`${category.title.en} btn btn-primary btn-filter text-capitalize ${
            this.state[stateKey].indexOf(category[byProp]) !== -1 ? 'chosen' : ''
          }`}
          onClick={() => this.categoryClicked(category[byProp], stateKey)}
        >
          {category.title[this.props.appLang]
            ? category.title[this.props.appLang]
            : category.title.en}
        </button>
      ));
    }
    return null;
  }

  render() {
    const { equipmentFilter, exCategoriesFilter, muscleTargetFilter } = this.props;
    const muscleTargetFilters = this.renderFilter(muscleTargetFilter, 'categories', '_id');
    const equipmentFilters = this.renderFilter(equipmentFilter, 'equipments', '_id');
    const categoriesFilter = this.renderFilter(exCategoriesFilter, 'categories', '_id');
    const { muscles, equipments } = this.state;
    return (
      <div
        className={`Filter flex-100 layout-row layout-wrap layout-align-start-start sidePadd10px filterSectionContainer ${
          this.props.className ? this.props.className : ''
        }`}
      >
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className="flex layout-row layout-wrap layout-align-start-start flex-order-2">
            <div className="flex layout-row layout-align-start-start positionRelative">
              <SearchInput
                minLength={2}
                required
                className="whiteIcon"
                currentPage={this.props.currentPage}
                bodyProps={{
                  muscles: muscles && muscles.length > 0 ? muscles : null,
                  equipments: equipments && equipments.length > 0 ? equipments : null,
                }}
                inputValue={(value) => {this.handleChange(value)}}
                dispatchResults={(body, data) => {this.dispatchResults(body, data);}}
              />
            </div>
          </div>
          {this.props.filterIcon && (
            <div
              className="flex-initial layout-row layout-wrap layout-align-start-start filterIconCont"
              onClick={() => {
                this.handleFilterMode();
              }}
            >
              {this.state.chosenCategories.length > 0 && (
                <div className="chosenCategoriesIndicator layout-column layout-align-center-center">
                  <p className="margin0 font13 fontWeight600">
                    {this.state.chosenCategories.length}
                  </p>
                </div>
              )}
              <ReactSVG className="filterIcon colorWhite fadeIn-anime" src={filterIcon} />
              <i className="la la-arrow-left closeFilter iconFontSize30 goBackIconColor fadeInRight-anime" />
            </div>
          )}
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start filterSection">
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10px">
              <p className="flex-100 text-uppercase colorWhite font18 margin0">
                {' '}
                <Trans i18nKey="categories" />{' '}
              </p>
            </div>
            {categoriesFilter}
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10px">
              <p className="flex-100 text-uppercase colorWhite font18 margin0">
                {' '}
                <Trans i18nKey="muscles" />{' '}
              </p>
            </div>
            {muscleTargetFilters}
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10px">
              <p className="flex-100 text-uppercase colorWhite font18 margin0">
                {' '}
                <Trans i18nKey="equipment" />{' '}
              </p>
            </div>
            {equipmentFilters}
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-center-start padd20px">
            <BtnLoader
              btnText="filter"
              shouldUpdate
              addClassLoading
              className="btn-searchByFilter colorWhite flex-100 positionRelative"
              containerClassName="flex-100 layout-row layout-wrap layout-align-center-start"
              btnClicked={() => {
                this.filterResults();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

Filter.defaultProps = {
  filterIcon: false,
};

Filter.propTypes = {
  filterIcon: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  equipmentFilter: state.exerciseR.equipmentFilter,
  exCategoriesFilter: state.exerciseR.exCategoriesFilter,
  muscleTargetFilter: state.exerciseR.muscleTargetFilter,
  currentPage: state.appR.currentPage,
});

const mapDispatchToProps = (dispatch) => ({
  saveSearchQuery: (query) => dispatch({ type: actionTypes.SAVE_SEARCH_QUERY, payload: query }),
  dispatchFilteredExercises: (body, data) => dispatch(dispatchFilteredExercises(body, data)),
  dispatchFilteredInfos: (body, data) => dispatch(dispatchFilteredInfos(body, data)),
  getFilteredExercises: (body) => dispatch(getFilteredExercises(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
