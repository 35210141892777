/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import Plx from 'react-plx';
import { Link } from 'react-router-dom';
import localStorage from 'local-storage';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import {
  getBodyStatsHistory,
  getBodyStats,
  getStatistics,
  getExercisesStatistics,
  getProgramStatistics,
  getUserExerciseInfo,
  getAllWorkoutPageData,
  getAchievements,
  showAlert,
  getDocuments,
  getFilledSurveys
} from '../../store/actions';
import { utils, checkUserType } from '../../services/utilsService';
import { chooseClientCallByPage, userTypes } from '../../services/enums';
import onBoarding from '../../appConsts/onBoarding';
import { langs } from '../../services/translationLangs';
import NavDrawer from '../NavDrawer/NavDrawer';
import ClientsDrawer from '../ClientsDrawer/ClientsDrawer';
import ClientList from '../ClientList/ClientList';
import Filter from '../Filter/Filter';
import MenuExpand from '../MenuExpand/MenuExpand';
// import NotificationUI from '../UI/NotificationButtonUI';
import ClientIcon from '../ClientIcon/ClientIcon';
import './NavTop.css';

class NavTop extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isClientListOpen: false,
      currentPage: '',
      title: '',
      subtitle: '',
      chosenClientsId: null,
      trainerIcon: null,
      isTrainerProfile: false
    };
  }

  componentDidUpdate () {
    this.state.subtitle = this.props.pageHeader.subtitle;
    this.state.errorException = false;
    this.state.currentPage = this.props.pageHeader.title;
  }


  handleGoBackByStore (){
    if (this.props.workoutCreationPage > 1) {
      const value = this.props.workoutCreationPage - 1;
      this.props.setWorkoutCreationPage(value)
    } else {
      this.props.history.goBack();
    }
  }

  goBack (backOptions) {
    if (backOptions.isDisabled) return;
    if (backOptions.funcName) {
      this[backOptions.funcName]();
      return;
    }
    if (backOptions.link) {this.props.history.push(backOptions.link);
    } else if (this.props.history && this.props.history.length > 1) this.props.history.goBack();
    else this.props.history.push('/');
  }

  goToPage (page) {
    this.props.history.push(page);
  }

  startTutorial (key, tutorial) {
    if (onBoarding[tutorial]) onBoarding[tutorial](this.props.appLang, (funcName, value) => {
      this[funcName](value)
    });
    this.props.activateTutorial(key)
    this.props.hideAlert();
  }

  openOnBoarding (config) {
    if (this.props.aliceTutorialMode) return ;
    const boarding = config.onBoarding;
    const tutorial = config.tutorial;
    const seenTutorial = tutorial && tutorial.storage && localStorage.get(`tutorial-${tutorial.storage}`) || null;
    if (((boarding && boarding.isTutorial) || (tutorial && tutorial.show)) && !seenTutorial) {
      const tutorialKey = (boarding && boarding.isTutorial) || (tutorial && tutorial.key);
      if (onBoarding[tutorialKey]) {
        onBoarding[tutorialKey](this.props.appLang);
        tutorial && tutorial.storage && this.props.activateTutorial(tutorial.storage)
      }
    } else {
      const data = {
        isOpen: true,
        isModal: true,
        id: eventActions.ONBOARDING_PAGE,
        data: boarding.key,
        className: 'PageModal CongratsModal',
        buttonRight: {
          text: <i className="la la-arrow-left iconFontSize30 primary " />,
          onClick: () => {
            this.props.hideAlert();
          }
        },
        goDoTutorial: {
          onClick: () => {
            this.startTutorial(boarding.key, `${boarding.key}_tutorial`);
          }
        }
      };
      this.props.showAlert(data);
    }
  }

  checkOnTutorial(config) {
    const { tutorial } = config;
    try {
      const seenTutorial = tutorial && tutorial.storage ? localStorage.get(`tutorial-${tutorial.storage}`) : null;
      if (!seenTutorial && tutorial.autoPlayTutorial) {
        const tutorialKey = tutorial.key;
        if (onBoarding[tutorialKey]) {
          onBoarding[tutorialKey](this.props.appLang, (funcName, value) => {
            this[funcName](value)
          });
          tutorial && tutorial.storage && this.props.activateTutorial(tutorial.storage);
        }
        tutorial && tutorial.storage && localStorage.set(`tutorial-${tutorial.storage}`, true);
      }
    } catch (e) {
      tutorial && tutorial.storage && localStorage.remove(`tutorial-${tutorial.storage}`);
    }
  }

  checkOnBoarding (config) {
    try {
      const seenOnboarding = localStorage.get(`onBoarding-${config.onBoarding.key}`) || null;
      if (!seenOnboarding) {
        this.openOnBoarding(config);
      }
    } catch (e) {
      localStorage.remove(`onBoarding-${config.onBoarding.key}`);
    }
  }

  async chooseClientApiCall (publicId) {
    if (this.isLoading) return;
    this.isLoading = true;
    const pageProps = chooseClientCallByPage[this.props.currentPage];
    let query = pageProps.query;
    if (publicId) {
      query = { ...query, user: publicId };
    }
    const type = pageProps.type ? pageProps.type : null;
    await this.props[pageProps.funcName](query, type);
    this.isLoading = false;
  }

  chooseClient (userArray) {
    this.openClientList(false);
    const result = utils.arrayToKeyValue(userArray, 'public_id');
    if (this.props && this.props.chosenClientsIds && this.props.chosenClientsIds.indexOf(result.dataIds[0]) !== -1) return;
    this.props.setChosenClient(result); // result - sending userArray from ClientsList as KV & Ids
    this.chooseClientApiCall(result.dataIds[0]);
  }

  openClientList (isOpen) {
    this.setState(prevState => ({ isClientListOpen: (isOpen || !prevState.isClientListOpen) }));
  }

  trainerIconClicked (user) {
    if (user.public_id && !this.state.isTrainerProfile) {
      this.state.isTrainerProfile = true;
      this.props.history.push(`/feed/${user.public_id}`);
    } else {
      this.state.isTrainerProfile = false;
      this.props.history.replace(`/feed/${user.public_id}`);
    }
  }

  managePlxAnimation () {
    const { config } = this.props.pageHeader;
    const themeKey = localStorage.get('Aspire-theme');
    let color = 'rgba(30, 29, 68,1)';
    if (themeKey === 'dark') {
      color = 'rgba(53, 53, 53, 1)';
    }
    return {
      default: [
        {
          start: 0,
          end: 120,
          properties: [
            {
              startValue: (config && config.startValue) || 45, // 50
              endValue: (config && config.endValue) || 45, // 35
              property: 'height'
            }
          ]
        }
      ],
      defaultText: [
        {
          start: 45,
          end: 120,
          properties: [
            {
              startValue: 1,
              endValue: 0,
              property: 'opacity'
            }
          ]
        }
      ],
      opacity: [
        {
          start: (config && config.startValue) || '20vh',
          end: (config && config.endValue) || '25vh',
          properties: [
            {
              startValue: (config && config.bgColor && config.bgColor.start) || 'rgba(30, 29, 68,0)',
              endValue: (config && config.bgColor && config.bgColor.end) || color,
              property: 'backgroundColor'
            }
          ]
        }
      ],
      textOpacity: [
        {
          start: (config && config.startValue) || '20vh',
          end: (config && config.endValue) || '25vh',
          properties: [
            {
              startValue: 0,
              endValue: 1,
              property: 'opacity'
            }
          ]
        }
      ]
    };
  }

  renderClientsName (chosenClientId) {
    const {
      chosenClientsKV, chosenClientsIds, user, appLang, pageHeader
    } = this.props;
    let clientName = chosenClientsKV && chosenClientsKV[chosenClientId] && chosenClientsKV[chosenClientId].name ? chosenClientsKV[chosenClientId].name : null;
    if (!clientName) clientName = user.firstName;
    else clientName = chosenClientsKV[chosenClientId].name.split(' ')[0] ? chosenClientsKV[chosenClientId].name.split(' ')[0] : chosenClientsKV[chosenClientId].name;
    const title = langs[appLang][`${pageHeader.title}_of`](clientName);
    this.state.chosenClientsId = chosenClientsIds[0];
    this.state.title = title;
    return title;
  }

  renderSubtitle (subtitleParam) {
    let subtitle = null;
    const {
      chosenClientsKV, chosenClientsIds, user, appLang, pageHeader
    } = this.props;
    const chosenClientId = chosenClientsIds && chosenClientsIds[0] ? chosenClientsIds[0] : null;
    if (pageHeader.validationError && pageHeader.validationError.length > 1) {
      return `2. ${pageHeader.validationError[1]}`;
    }
    if (chosenClientId && !pageHeader.config.skipChosenClient) {
      let clientName = chosenClientsKV && chosenClientsKV[chosenClientId] && chosenClientsKV[chosenClientId].name ? chosenClientsKV[chosenClientId].name.split(' ')[0] : null;
      if (!clientName) clientName = user.firstName;
      else clientName = chosenClientsKV[chosenClientId].name.split(' ')[0] ? chosenClientsKV[chosenClientId].name.split(' ')[0] : chosenClientsKV[chosenClientId].name;
      let param = langs[appLang][subtitleParam];
      if (pageHeader.params.type) {
        param = langs[appLang][pageHeader.params.type];
      }
      if (pageHeader.params.subtitle) {
        this.state.subtitle = pageHeader.params.subtitle;
        return this.state.subtitle;
      }
      subtitle = langs[appLang][`${subtitleParam}${pageHeader.params.pageType ? `_${pageHeader.params.pageType}` : ''}_sub`](clientName, param);
      this.state.subtitle = subtitle;
      return subtitle;
    }
    if (pageHeader.params && pageHeader.params.user) {
      const userId = pageHeader.params && pageHeader.params.user;
      let userName = null;
      this.props && this.props.clients && this.props.clients.forEach((user) => {
        if (user.public_id === userId) userName = user.name.split(' ')[0] ? user.name.split(' ')[0] : user.name;
      });
      if (!userName) userName = user.firstName;
      let param = '';
      if (pageHeader.params.type) {
        param = langs[appLang][pageHeader.params.type];
      }
      subtitle = langs[appLang][`${subtitleParam}${pageHeader.params.pageType ? `_${pageHeader.params.pageType}` : ''}_sub`](userName, param);
      this.state.subtitle = subtitle;
      return subtitle;
    }
    if (pageHeader.params.subtitle) {
      subtitle = langs[appLang][pageHeader.params.subtitle] || pageHeader.params.subtitle;
      return subtitle;
    }

    return null;
  }

  renderTitleParams () {
    let title = '',
      translate = '';
    const { appLang, pageHeader } = this.props;
    if (pageHeader.params.title && typeof pageHeader.params.title === 'object') {
      title = pageHeader.params.title[appLang];
    } else title = langs[appLang][pageHeader.params.title] || pageHeader.params.title;
    if (pageHeader.params.type) {
      translate = `${langs[appLang][`${pageHeader.params.type}_title`]} `;
    }
    if (pageHeader.params.translate) {
      translate = `${langs[appLang][`${pageHeader.params.translate}`]} `;
    }
    return `${translate}${title}`;
  }

  renderTitle () {
    const { pageHeader, chosenClientsIds } = this.props;
    const title = this.state.title || pageHeader.title;
    if ((this.state.title !== pageHeader.title) || (this.state.subtitle !== pageHeader.subtitle) || (this.state.chosenClientsId !== (chosenClientsIds && chosenClientsIds[0]))) {
      // if title || subtitle || chosen client is not equal to the previous title || subtitle || chosen client
      const chosenClientId = chosenClientsIds && chosenClientsIds[0] ? chosenClientsIds[0] : null;
      if (pageHeader.config && pageHeader.config.insertClientsName && chosenClientId && !(pageHeader.params && pageHeader.params.title)) {
        return this.renderClientsName(chosenClientId);
      } if (pageHeader.validationError) {
        return `1. ${pageHeader.validationError[0]}`;
      } if (pageHeader.params && pageHeader.params.title) {
        return this.renderTitleParams(chosenClientId);
      } this.state.title = title;
      return <Trans i18nKey={pageHeader.title} />;
    }
    return <Trans i18nKey={pageHeader.title} />;
  }

  render () {
    const { config } = this.props.pageHeader;
    const errors = !!(this.props.pageHeader.validationError && this.props.pageHeader.validationError.length > 1);
    const title = this.renderTitle();
    const subtitle = ((config && config.insertSubtitle && this.props.pageHeader.params) || errors) && this.props.pageHeader.subtitle ? this.renderSubtitle(this.props.pageHeader.subtitle) : null;
    const parallaxData = this.managePlxAnimation();
    this.state.trainerIcon = !this.state.isTrainerProfile && this.props.user && this.props.user.trainer ? this.props.user.trainer[0] : this.props.user;
    const disablePlx = this.props.disableTopNavPlx;
    if ((this.state.currentPage !== this.props.pageHeader.title)) { // || (this.state.subtitle !== this.props.pageHeader.subtitle)
      this.state.isClientListOpen = false; // close clients list on change page
      if (config.onBoarding && config.onBoarding.show) this.checkOnBoarding(config);
      if (config.tutorial) this.checkOnTutorial(config);
    }
    return (
      <div className={`NavTop flex-100 layout-row layout-wrap layout-align-start-start ${disablePlx ? 'disablePlx' : ''} ${config && config.navClass ? config.navClass : ''} ${this.props.pageHeader.validationError ? 'errorException' : ''}`}>
        <div className="content-header flex-100 layout-row layout-wrap layout-align-space-between-end">
          <Plx
            className="flex-100 layout-row layout-wrap layout-align-space-between-center topNav width100 positionAbsoluteTopLeft zIndex10"
            animateWhenNotInViewport
            parallaxData={parallaxData.opacity}
            disabled={disablePlx || (config && config.parallax !== 'opacity')}>
            {/* Left side Top Nav Btns */}
            <div className="flex-initial minWidthIcon">
              {config && config.menuIcon && (
                <NavDrawer />
              )}
              {config && config.goBackIcon && config.goBackIcon.show && (
                <button
                  type="button"
                  className={`flex-initial goBackIcon btn-transparent ${config.goBackIcon.isDisabled ? 'disabled' : '' }`}
                  onClick={() => this.goBack(config.goBackIcon)}>
                  <i className="la la-arrow-left iconFontSize30 colorWhite " />
                </button>
              )}
            </div>
            {/* Middle section Top Nav */}
            <div className="flex layout-row layout-wrap layout-align-center-center text-center">
              {config && config.showTitle && (
                <Plx
                  className="width100 textOpacity directionRegular"
                  parallaxData={parallaxData.textOpacity}
                  disabled={disablePlx}
                  animateWhenNotInViewport>
                  { config.parallax === 'default' && (
                    <span className="flex-100 title-header font18 margin0 fontWeight600">{disablePlx ? '' : title }</span>
                  )}
                  { config.parallax === 'opacity' && this.props.pageHeaderInfo && this.props.pageHeaderInfo.title && (
                    <div className="flex-100 layout-row layout-wrap layout-align-center-center text-center">
                      <p className="flex-100 font16 margin0 textLimitHeight pageHeaderInfoTitle text-capitalize">{langs[this.props.appLang][this.props.pageHeaderInfo.title] || this.props.pageHeaderInfo.title }</p>
                      <span className="flex-100 font12 margin0 colorGrey">{langs[this.props.appLang][this.props.pageHeaderInfo.subtitle] || this.props.pageHeaderInfo.subtitle || ''}</span>
                    </div>
                  )}
                </Plx>
              )}
            </div>
            {/* Right section Top Nav */}
            <div className="flex-initial layout-row layout-align-end-start minWidthIcon">
              {config && config.textIcon && config.textIcon.show && (
                <button
                  type="button"
                  className="flex-initial textIcon btn-transparent "
                  onClick={() => this.props.textIconClick(config.textIcon.key, config.textIcon.url, config.textIcon.currentModel)}>
                  <p><Trans i18nKey={config.textIcon.text} /></p>
                </button>
              )}
{/*              {config && config.activitiesIcon && (
                <NotificationUI clicked={() => this.goToPage('/activities')} />
              )} */}
              {config && config.clientsIcon && checkUserType([userTypes.TRAINER, userTypes.GROUP_ADMIN]) && (
                <ClientsDrawer history={this.props.history} />
              )}
              {config && config.trainerIcon && checkUserType(userTypes.CLIENT) && this.state.trainerIcon && (
                <div className="trainerIconContainer flex-initial layout-row layout-align-start-start">
                  <ClientIcon
                    user={this.state.trainerIcon}
                    firstName
                    disableName
                    clicked={() => this.trainerIconClicked(this.state.trainerIcon)}
                    className="drawerClient"
                    color1={this.state.trainerIcon.thumbnail && this.state.trainerIcon.thumbnail.uri ? 'rgba(29, 28, 63, 0.2)' : 'rgba(29, 28, 63, 1)'}
                    color2={this.state.trainerIcon.thumbnail && this.state.trainerIcon.thumbnail.uri ? 'rgba(29, 28, 63, 0.2)' : 'rgba(29, 28, 63, 1)'}
                    color3={this.state.trainerIcon.thumbnail && this.state.trainerIcon.thumbnail.uri ? 'rgba(24, 23, 54, 0.2)' : 'rgba(24, 23, 54, 1)'}
                  />
                </div>
              )}
              {config && config.menuExpand && checkUserType([userTypes.TRAINER, userTypes.GROUP_ADMIN]) && (
                <MenuExpand menuId="navMenuExpand" shouldUpdate plusIcon={config.menuExpand.plusIcon} menuList={config.menuExpandLinks} />
              )}
              {config && config.plusIcon && config.plusIcon.show && (
                <Link to={config.plusIcon.url} className="flex-initial plusIcon btn-transparent">
                  <i className="la la-plus iconFontSize30 colorWhite" />
                </Link>
              )}
            </div>
          </Plx>
          <Plx
            className="flex-100 layout-row height45px"
            parallaxData={parallaxData.default}
            animateWhenNotInViewport
            disabled={disablePlx || (config && config.parallax !== 'default')} />
          <div className="flex-100 layout-row layout-align-space-between-end sidePadd10px">
            <div className={`flex-100 layout-row layout-wrap layout-align-start-start sidePadd5px ${(config && config.parallax === 'opacity') ? 'displayNone' : ''}`}>
              <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                <Plx
                  className={`flex-100 layout-row layout-wrap layout-align-start-start PlxTitle ${this.props.pageHeader && this.props.pageHeader.title ? '' : 'displayNone'}`}
                  animateWhenNotInViewport
                  parallaxData={parallaxData.defaultText}
                  disabled={disablePlx || (config && (config.parallax !== 'default' || !config.opacityText))}>
                  <h3 className="flex-100 title-header fadeInLeft-anime paddBottom10px text-capitalize">{title}
                    {config && (config.onBoarding && config.onBoarding.show || config.tutorial && config.tutorial.show) && (
                      <button
                        type="button"
                        className="flex-initial onBoardingIcon btn-transparent"
                        onClick={() => this.openOnBoarding(config)}>
                        <i className="la la-question-circle colorWhite " />
                      </button>
                    )}
                  </h3>
                </Plx>
              </div>
              <div className="flex-100 layout-row layout-wrap layout-align-space-between-end PlxSubtitle">
                <div className="flex-initial layout-column layout-wrap layout-align-start-start">
                  <Plx
                    className="flex-100 layout-row layout-wrap layout-align-start-start"
                    animateWhenNotInViewport
                    parallaxData={parallaxData.defaultText}
                    disabled={disablePlx || (config && (config.parallax !== 'default' || !config.opacityText))}>
                    {(subtitle || this.props.pageHeader.subtitle) && (
                      <p
                        className="fadeInLeft-anime subtitle-header margin0 textLimitWidth"
                        style={{ maxWidth: `calc(100vw - ${config && config.clientList && config.clientList.show ? '120px' : '15px'})` }}>
                        { subtitle || <Trans i18nKey={this.props.pageHeader.subtitle} />}
                      </p>
                    )}
                  </Plx>
                </div>
                <div className="flex-initial layout-row layout-wrap layout-align-end-start ">
                  {config && config.clientList && config.clientList.show && (
                    <button
                      type="button"
                      className="flex-initial btn-transparent clientsBtn"
                      onClick={() => this.openClientList()}>
                      <p className="clientListColor margin0"><Trans i18nKey={config.clientList.text} /></p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`flex-100 layout-row layout-wrap layout-align-space-between-end marginBottom10px ${config.filter && config.filter.show ? 'filterMargin' : ''}`} style={{ height: `auto` }}>
            {config && config.clientList && config.clientList.show && (
              <div
                className={`flex-100 layout-row layout-wrap layout-align-start-start clientList-container ${this.state.isClientListOpen ? 'open' : ''}`}>
                {this.props.clients && this.props.clients.length > 0 && this.state.isClientListOpen && (
                  <ClientList
                    className="padd10px"
                    canChooseDummyUser
                    topNavMode
                    singleSelect
                    showTrainer
                    horizontalBar
                    clientClicked={client => this.chooseClient(client)} />
                )}
              </div>
            )}
            {config && config.filter && config.filter.show && (
              <div id="filterContainerId" className="width100 layout-row layout-align-start-start FilterContainer">
                <Filter filterIcon={config.filter.filterIcon} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

NavTop.defaultProps = {
  pageHeader: {},
  currentPage: ''
};

NavTop.propTypes = {
  pageHeader: PropTypes.shape({
    showTopNav: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    config: PropTypes.shape({
      navClass: PropTypes.string,
      goBackIcon: PropTypes.shape({ show: PropTypes.bool, key: PropTypes.string }),
      textIcon: PropTypes.shape({ show: PropTypes.bool, text: PropTypes.string }),
      plusIcon: PropTypes.shape({ show: PropTypes.bool, url: PropTypes.string }),
      filter: PropTypes.shape({ show: PropTypes.bool, filterIcon: PropTypes.bool, search: PropTypes.bool }),
      menuExpand: PropTypes.shape({ show: PropTypes.bool, plusIcon: PropTypes.bool, onlyTrainer: PropTypes.bool }),
      menuExpandLinks: PropTypes.array,
      insertSubtitle: PropTypes.bool,
      skipChosenClient: PropTypes.bool,
      insertClientsName: PropTypes.bool,
      showTitle: PropTypes.bool,
      opacityText: PropTypes.bool,
      menuIcon: PropTypes.bool,
      // activitiesIcon: PropTypes.bool,
      parallax: PropTypes.oneOf(['default', 'opacity']),
      startValue: PropTypes.string,
      endValue: PropTypes.string,
      bgColor: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string
      })
    })
  }),
  currentPage: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  clients: state.userR.userInfo.clients,
  chosenClientsIds: state.userR.chosenClientsIds,
  chosenClientsKV: state.userR.chosenClientsKV,
  currentPage: state.appR.currentPage,
  pageHeader: state.appR.pageHeader, // all the permissions
  pageHeaderInfo: state.appR.pageHeaderInfo, // the data for refreshing title and subtitle
  extraTitle: state.appR.extraTitle,
  appLang: state.appR.appLang,
  disableTopNavPlx: state.appR.disableTopNavPlx,
  aliceTutorialMode: state.appR.aliceTutorialMode,
  workoutCreationPage: state.workoutR.workoutCreationPage
});

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  activateTutorial: key => dispatch({ type: actionTypes.ACTIVATE_TUTORIAL_MODE, payload: key }),
  setChosenClient: userObj => dispatch({ type: actionTypes.SET_CHOSEN_CLIENT, payload: userObj }),
  getBodyStats: query => dispatch(getBodyStats(query)),
  getStatistics: query => dispatch(getStatistics(query)),
  getExercisesStatistics: query => dispatch(getExercisesStatistics(query)),
  getProgramStatistics: query => dispatch(getProgramStatistics(query)),
  getBodyStatsHistory: query => dispatch(getBodyStatsHistory(query)),
  getUserExerciseInfo: query => dispatch(getUserExerciseInfo(query)),
  getAllWorkoutPageData: query => dispatch(getAllWorkoutPageData(query)),
  getAchievements: (query, type) => dispatch(getAchievements(query, type)),
  getDocuments: query => dispatch(getDocuments(query)),
  getFilledSurveys: query => dispatch(getFilledSurveys(query)),
  setWorkoutCreationPage: (value) => dispatch({ type: actionTypes.SET_WORKOUT_CREATION_PAGE, payload: value }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavTop);
