/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import Modal from '../../Components/Alerts/Modal';
import './ModalStyles.css'
import MsgModal from '../../Components/Alerts/MsgModal';
/* Lazy Modals */
const MediaLibraryPage = React.lazy(() => import('./MediaLibraryPage'));
const FileUploadPage = React.lazy(() => import('./FileUploadPage'));
const FullScreenVideoPage = React.lazy(() => import('./FullScreenVideoPage'));

class ModalsThirdLayerContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  openModal () {
    switch (this.props.alert3Props.id) {
      case eventActions.ALERT_MODAL_3:
        return <MsgModal {...this.props.alert3Props} hideAlertBtn={() => this.props.hideAlert3()} />;
      case eventActions.MEDIA_LIBRARY:
        return <MediaLibraryPage appLang={this.props.appLang} config={this.props.alert3Props.config} hideAlertBtn={() => this.props.hideAlert3()} />;
      case eventActions.FILE_UPLOAD:
        return <FileUploadPage appLang={this.props.appLang} data={this.props.alert3Props.data} alertProps={this.props.alert3Props} hideAlertBtn={() => this.props.hideAlert3()} />;
      case eventActions.FULL_SCREEN_PAGE:
        return <FullScreenVideoPage appLang={this.props.appLang} isModalPage alertProps={this.props.alert3Props} isPortrait={this.props.alert3Props.isPortrait} media={this.props.alert3Props.media} hideAlertBtn={() => this.props.hideAlert3()} />;
      default:
        return null;
    }
  }

  render () {
    return (
      <div className="ModalsThirdLayerContainer flex-100 layout-row layout-wrap layout-align-start-start">
        { this.props.alert3Props && this.props.alert3Props.isOpen && (
          <Modal modalAlert3
                 isOpen={this.props.alert3Props.isOpen}
                 className={this.props.alert3Props.className}
                 modalClassName={this.props.alert3Props.modalClassName || ''}
                 alertProps={this.props.alert3Props}
                 hideAlertBtn={() => this.props.hideAlert3()}>
            <Suspense fallback={<div className="gogo-loading" />}>
              { this.openModal() }
            </Suspense>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  alert3Props: state.appR.alert3Props
});


const mapDispatchToProps = dispatch => ({
  hideAlert3: () => dispatch({ type: actionTypes.HIDE_ALERT3 })
});


export default connect(mapStateToProps, mapDispatchToProps)(ModalsThirdLayerContainer);
