/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localStorage from 'local-storage';
import ReactGA from 'react-ga';
import Confetti from 'react-confetti';
import { showAlert3 } from '../../../store/actions';
import { handleModalFullScreen, addClass, linkingAppUrl } from '../../../services/utilsService';
import AspireMsgModal from '../../../Components/ProfileCards/AspireMsgModal';
import InviteUsersCmp from '../../../Components/InviteUsers/InviteUsersCmp';
import '../CongratulationsPage.css';
import './UserIntroductionPage.css';

class AspireUpdatesPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: this.props.data,
      components: {
        inviteUsersCmp: InviteUsersCmp
      }
    }
    this.windowSize = { height: window.outerHeight, width: window.innerWidth };
  }

  handleVideoModal (media) {
    const data = handleModalFullScreen(media, true, 'UserIntroduction');
    data.disableFSLogo = true;
    data.controls = true;
    // data.className = `${data.className}`;
    data.modalClassName = `flex-100 layout-row layout-wrap layout-align-start-center videoBlackBg`

    this.props.showAlert3(data);
  }

  handleMediaOption (option) {
    const data = option.media;
    const media = {
      user: null,
      public_id: 'VID-ASPIRE_UPDATES',
      title: 'aspire updates',
      type: 'video',
      sourceType: 'amazonS3',
      mediaGroup: 'aspireInformation',
      source: data.sourceHls.master,
      sourceHls: data.sourceHls,
      thumbnail: null
    };
    this.handleVideoModal(media, data.isPortrait);
  }

  handleLinkingApp(option) {
    const url = option.url || ''
    linkingAppUrl(url)
  }

  closeModal () {
    localStorage.set(`aspire-updates`, this.props.msgId);
    addClass('#CongratulationsPage', 'opacity0');
    ReactGA.event({ category: 'AspireUpdatesMsg', action: 'Confirm' });
    this.timeoutId = setTimeout(() => {
      this.props.hideAlertBtn();
      clearTimeout(this.timeoutId);
    }, 400);
  }

  goToPage (option) {
    ReactGA.event({ category: 'AspireUpdatesMsg', action: option.text });
    localStorage.set(`aspire-updates`, this.props.msgId)
    this.props.history.push(`${option.url}`);
  }

  renderComponent () {
    if (this.props.data.components && this.props.data.components.length > 0) {
      return (
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          { this.props.data.components.map((component, index) => {
            const ComponentItem = this.state.components[component.name];
            return (
              <div key={(component.data &&component.data.msgId) || `asd_${index}`} className="flex-100 layout-row layout-wrap layout-align-start-start">
                <ComponentItem data={component.data} />
              </div>
            )
          })}
        </div>
      )
    }
    return null;
  }

  render () {
    const confetti = this.props.confetti ? (
      <Confetti
        numberOfPieces={100}
        width={this.windowSize.width}
        height={this.windowSize.height}
        colors={['#384ab4', '#5855a0', '#824187', '#eb4b93', '#d74c3b', '#e1774a', '#eea04f']} />
    ) : null;
    const components = this.renderComponent()
    return (
      <div id="CongratulationsPage" className="CongratulationsPage UserIntroductionPage flex-100 layout-row layout-wrap layout-align-start-start fullSizeWindow content-start">
        <div className="width100 layout-row layout-wrap layout-align-start-start fullSizeWindow positionAbsoluteTopLeft content-start zIndex5">
          {confetti}
        </div>
        <div className="width100 layout-row layout-wrap layout-align-start-start zIndex10 confettiCardPosition sidePadd20px">
          <div className="flex-100 layout-row layout-wrap layout-align-center-center">
            <AspireMsgModal
              showImage
              isCard
              data={this.state.data}
              userData={this.props.user}
              appLang={this.props.appLang}
              isChecked={!this.state.dontShowAgain}
              cancel={() => this.closeModal()}
              handleMediaOption={(option) => this.handleMediaOption(option)}
              handleLinkingApp={(option) => this.handleLinkingApp(option)}
              goToPage={(option) => this.goToPage(option)}
              components={components}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  alertProps: state.appR.alertProps,
  aspireMsgs: state.appR.aspireMsgs
});

const mapDispatchToProps = dispatch => ({
  showAlert3: data => dispatch(showAlert3(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AspireUpdatesPage);
