import React, { useState } from 'react';
import './VideoBg.css';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { styleUtils } from '../../services/utilsService';

const VideoBg = props => {
  return (
    <div className={`VideoBg flex-100 layout-row layout-wrap layout-align-start-start ${props.className}`}>
      <div className="bg-video-wrap" style={{...styleUtils.imageBackground(props.backgroundImage, 'rgba(0,0,0,0)', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)')}}>
{/*        <VideoPlayer
          id="ascascsvsavs"
          height={window.innerHeight}
          width={window.innerWidth}
          media={{
            source: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/client-clip-1_playlist.m3u8',
            sourceHls: {
              master: 'https://aspire123.s3.eu-central-1.amazonaws.com/videos/HLS/client-clip-1_playlist.m3u8'
            }
          }}
          isAutoPlay={true}
          appLang="en" /> */}
        { props.video && (<video src={props.video} loop muted autoPlay playsInline />)}
        { props.overlay && <div className="overlay" /> }
      </div>
    </div>
  );
};

export default VideoBg;
