/* eslint-disable max-len */
import React from 'react';
import { Trans } from 'react-i18next';
import MinimalStrip from '../StripProgress/MinimalStrip';

function NotesUI(props) {
  const { notes } = props;
  const name = props.name || 'notes';
  if (!notes || notes.length === 0) return null;
  return (
    <div className="flex-100 layout-row layout-wrap layout-align-start-start">
      <div className="flex-100 layout-row layout-wrap layout-align-space-between-center">
        <p className="text-uppercase primary fontWeight600 font16">
          <Trans i18nKey={props.title || ''} />
        </p>
      </div>
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        {notes.map((note, index) => (
          <MinimalStrip
            key={`${name}_${index}`}
            itemClass="basicNone"
            {...note}
            multiLangListBox={props.multiLangListBox}
            appLang={props.appLang}
          />
        ))}
      </div>
    </div>
  );
}

export default NotesUI;
