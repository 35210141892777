/* eslint-disable react/destructuring-assignment */
import React from 'react';
import moment from 'moment';
import ClientIcon from '../../../../Components/ClientIcon/ClientIcon';
import './RecentSessionCard.css';
import Image from '../../../../Components/UI/Image';
import { aspirePlaceholder } from '../../../../services/enums';

const RecentSessionCard = (props) => {
  const { workoutProgramInfo, startDate } = props.data;
  const { clientsListKV, className } = props;
  const thumbnail = workoutProgramInfo && workoutProgramInfo.thumbnail
    ? (workoutProgramInfo.thumbnail.uri || workoutProgramInfo.thumbnail.source) : aspirePlaceholder[0];
  const hasUsers = !!(props.data.user && ((clientsListKV && clientsListKV[props.data.user]) || props.data.user === props.user.public_id)
  );
  const creationDate = startDate && moment(startDate).format('DD.MM.YY ');
  const time = props.data && startDate && moment(startDate).format('| HH:mm');
  const user = clientsListKV && clientsListKV[props.data.user] ? clientsListKV[props.data.user] : props.user;
  return (
    <div className={`flex-100 layout-row layout-titlewrap layout-align-start-start RecentSessionCard ${className || ''}`}>
      <div className="flex-100 layout-row layout-wrap layout-align-start-center">
        <Image
          backgroundImage
          src={thumbnail}
          fallbackSrc={thumbnail || aspirePlaceholder[0]}
          height={300}
          className="mediaImg backgroundImage flex-100 layout-column layout-wrap layout-align-center-center positionRelative"
          color1="rgba(9, 10, 16, 0.65)"
          color2="rgba(0, 0, 0, 0.7)"
          color3="rgba(7, 18, 113, 0.3)"
          degree={-15}>
          <div className="flex-100 layout-column layout-align-space-between layout-wrap width100 positionRelative zIndex5">
            <div className="flex-40 layout-column layout-align-center-center colorWhite text-center paddTop30px">
              {hasUsers && <ClientIcon firstName user={user} />}
            </div>
            <div className="flex-60 layout-row layout-align-center-center layout-wrap sessionDetails padd10">
              <div className="flex-100 layout-column layout-align-start-center colorWhite text-center">
                { workoutProgramInfo && workoutProgramInfo.title && (
                  <p className="font16 fontWeight600 marginBottom5px textLimitHeight title">
                    { workoutProgramInfo.title.length > 38 ? `${workoutProgramInfo.title.slice(0,35)}...` : workoutProgramInfo.title }
                  </p>
                )}
                { workoutProgramInfo && workoutProgramInfo.planTitle && (workoutProgramInfo.planTitle !==  workoutProgramInfo.title) && (
                  <p className="font13 fontWeight600 marginBottom5px planTitle">
                    { workoutProgramInfo.planTitle.length > 15 ? `${workoutProgramInfo.planTitle.slice(0,14)}...` : workoutProgramInfo.planTitle }
                  </p>
                )}
                <span className="time font12">
                {creationDate}<span className="hour">{time}</span>
              </span>
              </div>
            </div>
          </div>
          <div className="containerSkewed width100 displayNone">
            <div className="parallelogramSkewed" />
          </div>
        </Image>
      </div>
    </div>
  );
};

export default RecentSessionCard;
