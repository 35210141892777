import React from 'react';
import ThumbnailUI from '../UI/ThumbnailUI';

const DefaultSection = props => (
  <div
    key={`section_${props.id}`}
    className={`flex-100 layout-row layout-wrap content-start ${props.isSwiperCarousel ? 'height100vh swiper-slide layout-align-center-start text-center' : 'layout-align-start-start'} ${props.className ? props.className : ''}`}
    style={{ backgroundColor: props.backgroundColor || '#fff' }}>
    { props.isSwiperCarousel && <div className="width100 layout-row layout-wrap layout-align-center-start height50px marginBottom30px" />}
    {props.thumbnail && (
      <ThumbnailUI
        src={props.thumbnail}
        className={`marginBottom30px svg90p ReactSVG height220px text-center ${props.thumbnailClass ? props.thumbnailClass : 'width100'}`} />
    )}
    <div className={`width100 layout-row layout-wrap sidePadd15px ${props.titleClassName ? props.titleClassName : 'layout-align-center-start'}`}>
      <div className="width100 flex-initial width100 layout-row layout-wrap ">
        {props.title && (
          <div className={`flex-100 width100 layout-row layout-wrap marginBottom10px ${props.titleClassName ? props.titleClassName : 'layout-align-center-start'}`}>
            <label className="primary text-capitalize font20 fontWeight600">{props.title}</label>
          </div>
        )}
        {props.description && (
          <div className={`flex-100 width100 layout-row layout-wrap paddBottom10px ${props.descClassName ? props.descClassName : 'layout-align-center-start'}`}>
            <p className="width100 marginBottom10px font15 lineHeight18">{props.description}</p>
          </div>
        )}
        {props.comment && (
          <div className={`flex-100 width100 layout-row layout-wrap paddBottom10px ${props.commentClassName ? props.commentClassName : 'layout-align-center-start'}`}>
            <p className="width100 marginBottom10px colorGrey font15">{props.comment}</p>
          </div>
        )}
        { props.children }
      </div>
    </div>
  </div>
);

export default DefaultSection;
