import { actionTypes } from '../actions/actionTypes';

const initialState = {
  infoList: [],
  myInfoList: {},
  myInfoPublicId: null
  // solutionDetails: {}
  // categoriesFilter: []
  // infoDetails: []
  // infoDetailsKV: {},
  // myInfoList: {},
  // myInfoListIds: []
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INFOS_LIST:
      return { ...state, infoList: action.payload };
    case actionTypes.UPDATE_INFO_LIST:
      return { ...state, infoListKV: action.payload };
    case actionTypes.SET_INFO_CATEGORIES:
      return { ...state, categoriesFilter: action.payload };
    case actionTypes.SET_MY_INFOS:
      return { ...state, infoList: action.payload };
    case actionTypes.SET_INFO_DETAILS:
      return { ...state, infoDetails: action.payload };
    case actionTypes.SET_SOLUTION_DETAILS:
      return { ...state, solutionDetails: action.payload };
    case actionTypes.SET_MY_INFO_PUBLIC_ID:
      return { ...state, myInfoPublicId: action.payload };
    case actionTypes.CLEAR_INFO_DETAILS:
      return { ...state, infoDetails: null, myInfoPublicId: null, infoDetailsKV: null };
    case actionTypes.CLEAR_INFO_FILTERS:
      return { ...state, categoriesFilter: [] };
    default:
      return state;
  }
};

export default infoReducer;
