import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { actionTypes } from '../../store/actions/actionTypes';
import './MediaUpload.css';

class MinimizedUpload extends Component {
  constructor (props) {
    super(props);
    this.state = {
      total: 0,
      load: 0,
      percent: 0,
      isInfinite: false
    };
  }

  componentDidMount () {
    this.props.minimizeUpload();
    this.timeoutProgress = setTimeout(() => {
      clearTimeout(this.timeoutProgress);
      this.setState({ ...this.state });
    }, 500);
  }

  componentDidUpdate () {
    this.timeoutProgress = setTimeout(() => {
      clearTimeout(this.timeoutProgress);
      this.setState({ ...this.state, isInfinite: this.state.percent === 100 });
    }, 500);
  }

  componentWillUnmount () {
    this.props.extendUpload();
    if (this.timeoutProgress) clearTimeout(this.timeoutProgress);
  }

  calculateProgress () {
    if (Object.keys(this.props.progress) && Object.keys(this.props.progress).length > 0) {
      this.state.total = 0;
      this.state.load = 0;
      Object.keys(this.props.progress).forEach((id) => {
        this.state.total += this.props.progress[id].total;
        this.state.load += this.props.progress[id].load;
      });
      this.state.percent = (this.state.load * 100) / this.state.total;
    }
    return this.state.percent;
  }

  renderNavMinimizeForModal () {
    return (
      <div
        className="NavTopForModal content-header flex-100 layout-row layout-wrap layout-align-start-start positionAbsoluteTop width100 borderBottomGrey whiteBackground">
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-end ">
          <div className="flex-100 layout-row layout-wrap layout-align-space-between-center topNav height50px ">
            <div className="flex-initial">
              <button
                type="button"
                className="flex-initial textIcon btn-transparent sidePadd10px"
                onClick={() => this.props.expand()}>
                <i className="la la-sort-up font22 primary" />
              </button>
            </div>
            <div className="flex layout-row layout-align-start-center sidePadd15px NavTop-label-direction">
              <label className="primary text-capitalize font16 fontWeight600 margin0">{this.props.numberOfFiles} <Trans i18nKey="files" /></label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render () {
    const isMinimizeModalPage = this.renderNavMinimizeForModal();
    const percent = this.calculateProgress();
    return (
      <div className="MinimizedUpload flex-100 layout-row layout-wrap layout-align-center-center uppyMinimizeSection whiteBackground">
        {isMinimizeModalPage}
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-center">
          <div className="flex-initial layout-align-start-start paddEnd10px">
            <p>{percent.toFixed(0)}%</p>
          </div>
          <div className="flex Animation-progress">
            <div className={`progressUpload ${this.state.isInfinite ? 'infinite' : 'transition03'}`} style={{ width: `${percent}%` }} />
          </div>
        </div>
      </div>
    );
  }
}

MinimizedUpload.propTypes = {
  numberOfFiles: PropTypes.number,
  progress: PropTypes.shape({
    // id : {load: number, total: number }
  }),
  expand: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang
});

const mapDispatchToProps = dispatch => ({
  minimizeUpload: () => dispatch({ type: actionTypes.MINIMIZE_UPLOAD }),
  extendUpload: () => dispatch({ type: actionTypes.EXPAND_UPLOAD })
});

export default connect(mapStateToProps, mapDispatchToProps)(MinimizedUpload);
