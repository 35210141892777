import localStorage from 'local-storage';
import { apiMethods, aspireClientConfig } from '../../services/apiService';
import { actionTypes } from './actionTypes';
import { handleErrorResponse } from './index';
import momentTZ from 'moment-timezone';
import { securePostMsgUtils, utils } from '../../services/utilsService';
import { postMsgTypes } from '../../services/postMsgTypes';
import { setAspireServices } from './appActions';

export const saveUserToken = (res) => {
  if (res.token) {
    aspireClientConfig.headers.Authorization = res.token;
    localStorage.set('Aspire-auth', res.token);
  }
  if (res.clients) {
    return {
      type: actionTypes.UPDATE_CLIENT_LIST,
      payload: res.clients
    };
  }
  return {
    type: actionTypes.HANDLE_SUCCESS_RESPONSE,
    payload: 'Handled Successfully'
  };
};

export const saveUserCredentials = (res) => {
  if (res.token) {
    aspireClientConfig.headers.Authorization = res.token;
    localStorage.set('Aspire-auth', res.token);
  }
  document.body.classList = [];
  document.body.classList.add(res.user.userType);
  return {
    type: actionTypes.SAVE_USER_CREDENTIALS,
    payload: res
  };
};

export const saveUserProfileInfo = payload => ({
  type: actionTypes.SAVE_USER_PROFILE_INFO,
  payload
});

export const saveMyCalendarSettings = payload => ({
  type: actionTypes.SAVE_MY_CALENDAR_SETTINGS,
  payload
});

export const saveUserCalendarSettings = payload => ({
  type: actionTypes.SAVE_USER_CALENDAR_SETTINGS,
  payload
});

export const setUserSubscriptionDetails = payload => ({
  type: actionTypes.SET_USER_SUBSCRIPTION_DETAILS,
  payload
});

const receiveTokenMessage = (event) => {
  const data = event && event.data ? event.data : null;
  if (!window.aspire) window.aspire = {};
  if (data && data.token) {
    window.aspire.token = data && data.token
    window.aspire.os = data && data.os
  }
};

const updateUserToken = () => {
  try {
    if (securePostMsgUtils.listenToValidMessage()) {
      window.addEventListener('message', receiveTokenMessage, false);
      window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.GET_GCM_TOKEN }));
      const intervalId = setInterval(async () => {
        if (window && window.aspire && window.aspire.token) {
          const body = {
            token:  window.aspire.token,
            os:  window.aspire.os
          }
          await apiMethods.users.updateUserToken(body);
          window.removeEventListener('message', receiveTokenMessage);
          // window.aspire.token = null;
          clearInterval(intervalId);
        } else {
          window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.GET_GCM_TOKEN }));
        }
      }, 1000);
    } else {
      console.log('not on phone');
    }
  } catch (e) {
    console.log('error', e);
  }
};

export const checkIfLoggedIn = () => async (dispatch) => {
  try {
    const timezone = momentTZ.tz.guess();
    const query = `?timezone=${timezone}`;
    const data = await apiMethods.users.checkIfLoggedIn(query);
    dispatch(saveUserCredentials(data));
    if (data && !data.validToken) updateUserToken();
    return data;
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const getProfileInfo = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.users.getProfileInfo(publicId);
    // if (window && window.aspire) window.aspire.token = null;
    dispatch(saveUserProfileInfo(data));
    return data;
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const loginByEmail = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.loginByEmail(body);
    if (data.user.userType === 'TRAINER') {
      data.user.profession = true; // for now
    }
    dispatch(saveUserCredentials(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data || { error: 'error'};
  }
};

export const loginByPhone = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.loginByPhone(body);
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data || { error: 'error'};
  }
};

export const verifyUserAndLogin = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.verifyUserAndLogin(body);
    dispatch(saveUserCredentials(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data || { error: 'error'};  }
};

export const UserVerifyInvitationCode = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.verifyInvitationCode(body);
    return data;
  } catch (e) {
    if (e.response.data) return e.response.data;
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data || { error: 'error'};
  }
};

export const signUpUser = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.register(body);
    dispatch(saveUserCredentials(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const createClient = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.createClient(body);
    dispatch(saveUserToken(result));
    return result;
  } catch (e) {
    console.log(e.response.data);
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};


export const sendVerificationCode = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.sendVerificationCode(body);
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const sendVerificationCodeToLogin = body => async (dispatch) => {
  try {
    return await apiMethods.users.sendVerificationCodeToLogin(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const resendInvitationCode = body => async (dispatch) => {
  try {
    return await apiMethods.users.resendInvitationCode(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};


export const checkEmailExist = email => async () => {
  try {
    const query = `?email=${email}`;
    const data = await apiMethods.users.checkEmailExist(query);
    return data;
  } catch (e) {
    return false;
  }
};

export const checkAccountExistByEmail = email => async () => {
  try {
    const query = `?email=${email}`;
    const data = await apiMethods.users.checkAccountExistByEmail(query)
    console.log('checkAccountExistByEmail: ', data)
    return data;
  } catch (e) {
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const logoutUser = body => async (dispatch) => {
  try {
    await apiMethods.users.logout(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const saveSettings = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.saveSettings(body);
    dispatch(saveUserCredentials(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateUserPassword = body => async (dispatch) => {
  try {
    await apiMethods.users.updatePassword(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const updateClientPassword = body => async (dispatch) => {
  try {
    await apiMethods.users.updateClientPassword(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const sendResetPasswordEmail = body => async (dispatch) => {
  try {
    await apiMethods.users.sendResetPasswordEmail(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const resendClientInvitation = body => async (dispatch) => {
  try {
    const respond = await apiMethods.users.resendClientInvitation(body);
    return { success: 'invitationSent', ...respond };
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const updateSMSInvitation = (publicId, phoneNumber) => async (dispatch) => {
  try {
    const body = { publicId, phoneNumber };
    await apiMethods.users.updateSMSInvitation(body);
    return { success: 'invitationSent' };
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};
export const updateEmailInvitation = (publicId, email) => async (dispatch) => {
  try {
    const body = { publicId, email };
    await apiMethods.users.updateEmailInvitation(body);
    return { success: 'invitationSent' };
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const resetPassword = body => async (dispatch) => {
  try {
    await apiMethods.users.resetPassword(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateUserAccount = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.updateUserAccount(body);
    dispatch(saveUserToken(result));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateUserCredentials = body => async (dispatch) => {
  try {
    return await apiMethods.users.updateUserCredentials(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const unlinkClient = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.unlinkClient(body);
    dispatch(saveUserToken(result));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserDetailsForClients = body => async (dispatch) => {
  try {
    return await apiMethods.users.getUserDetailsForClients(body);
  } catch (e) {
    return false;
  }
};

export const saveUpdatedClientsList = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.saveUpdatedClientsList(body);
    dispatch(saveUserToken(result));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateTrainerProfession = body => async (dispatch) => {
  try {
    await apiMethods.users.updateTrainerProfession(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};


export const getCountryCodes = () => async () => {
  try {
    return await apiMethods.users.getCountryCodes();
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const getMyCalendarSettings = () => async (dispatch) => {
  try {
    const result = await apiMethods.users.getMyCalendarSettings();
    if (result && result.calendarSettings) dispatch(saveMyCalendarSettings(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateCalendarSettings = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.updateCalendarSettings(body);
    if (result && result.calendarSettings) dispatch(saveMyCalendarSettings(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserCalendarSettings = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const result = await apiMethods.users.getUserCalendarSettings(query);
    if (result && Array.isArray(result) && result.length > 0) {
      dispatch(saveUserCalendarSettings(result));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getMySubscription = () => async (dispatch) => {
  try {
    const result = await apiMethods.products.getMySubscription();
    dispatch(setUserSubscriptionDetails(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const cancelSubscription = () => async (dispatch) => {
  try {
    return await apiMethods.products.cancelSubscription();
    dispatch(setUserSubscriptionDetails({}));
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
