import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Tooltip } from 'react-tippy';
import { addClass, removeClass, validateEmail } from '../../services/utilsService';
import { langs } from '../../services/translationLangs';
import StatusUI from './StatusUI';
import 'react-tippy/dist/tippy.css';
import './UIStyle.css';

class EmailChecker extends Component {
  constructor (props) {
    super(props);
    this.state = {
      emailApproved: false,
      validationText: 'emailIsGood',
    };
  }

  async checkEmailExist () {
    let approved = false;
    const {emailStatusId, inputId, showTooltip }  = this.props;
    if (validateEmail(this.state.email)) {
      try {
        addClass(`#${emailStatusId}`, 'isLoading');
        removeClass(`#${inputId}`, 'notApproved');
        removeClass(`#${inputId}`, 'approved');
        approved = await this.props.handleCheckEmail(this.state.email);
        if (approved && (approved.status !== 200 || approved.exist)) {
          this.state.validationText = approved.text;
          console.log('validationText', approved )
          addClass(`#${inputId}`, 'notApproved');
         if (showTooltip) this.showTooltip(approved.text);
        } else if (approved && !approved.exist && approved.status === 200) {
          removeClass(`#${inputId}`, 'notApproved');
          addClass(`#${inputId}`, 'approved');
        }
        if (approved && approved.status === 200) {
          console.log('email not exist');
          removeClass(`#${inputId}`, 'notApproved');
          addClass(`#${inputId}`, 'approved');
          if (showTooltip) this.showTooltip('emailIsGood');
        } else if (approved && approved.status === 409) {
          console.log('email exist');
          removeClass(`#${inputId}`, 'approved');
          addClass(`#${inputId}`, 'notApproved');
          if (showTooltip) this.showTooltip('emailAlreadyExist');
        }
        removeClass(`#${emailStatusId}`, 'isLoading');
        this.state.emailApproved = approved;
        if (this.props.handleApprovedEmail) this.props.handleApprovedEmail(approved);
      } catch (e) {
        console.log('something is wrong');
        addClass(`#${inputId}`, 'notApproved');
        removeClass(`#${emailStatusId}`, 'isLoading');
        if (this.props.handleApprovedEmail) this.props.handleApprovedEmail(approved);
      }
    } else {
      console.log('email invalid');
      addClass(`#${this.props.inputId}`, 'notApproved');
      if (showTooltip) this.showTooltip('invalidEmail');
    }
    this.state.emailApproved = approved;
    if (this.props.handleApprovedEmail)this.props.handleApprovedEmail(approved);
  }

  showTooltip(text) {
    this.state.validationText = text;
    this.setState(prevState => ({ openTutorial: !prevState.openTutorial }), () => {
      this.timeoutTooltip = setTimeout(() => {
        this.setState(() => ({ openTutorial: false}));
        clearTimeout(this.timeoutTooltip);
      }, 3000);
    })
  }

  handleChangeEmail (e) {
    const value = e.currentTarget.value
    this.state.email = value.toLowerCase();
    this.props.handleChangeEmail(value.toLowerCase());
    if (this.props.checkEmailExistOnChange) this.checkEmailExist()
  }

  render () {
    const { openTutorial, validationText } = this.state;
    const { noLabel, checkEmailExistOnChange, className } = this.props;
    return (
      <div className={`EmailChecker flex-100 layout-row layout-wrap layout-align-start-start ${className || ''}`}>
        { !noLabel && <label className="formLabel primary" htmlFor={this.props.inputId}><Trans i18nKey="email" /> </label> }
        <div className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
          <Tooltip
            // options
            open={openTutorial}
            position="top-middle"
            className="width100"
            arrow
            arrowSize="big"
            theme="light"
            distance={10}
            html={(
              <div className="flex-100 layout-row layout-wrap layout-align-start-start emailTooltip">
                {`${langs[this.props.appLang][validationText]}`}
              </div>
            )}
            trigger="manual">
              <input
                id={this.props.inputId}
                type="email"
                placeholder={langs[this.props.appLang].email}
                required={this.props.required}
                defaultValue={this.props.initialValue}
                onChange={(event) => { this.handleChangeEmail(event); }}
                onBlur={(event) => { if (!checkEmailExistOnChange) this.checkEmailExist(event); }}
                className="form-control width100"
              />
              <div className="positionAbsoluteTopEnd layout-row layout-wrap layout-align-center-center">
                <StatusUI id={this.props.emailStatusId} />
              </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default EmailChecker;
