import { apiMethods } from './apiService';

const sendWelcomeMailAgain = async (body) => {
  try {
    const result = await apiMethods.users.resendWelcomeEmail(body);
    return result;
  } catch (e) {
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
}

const funcs = { sendWelcomeMailAgain }

export default funcs;
