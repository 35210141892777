/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProfilePage from '../ProfilePage/ProfilePage';
import FeedNavigation from './FeedNavigation';

const StoreRoutes = React.lazy(() => import('../../Views/StoreRoutes'));

class FeedPage extends Component {

  componentDidMount() {

  }

  render () {
    const { user } = this.props;
    return (
      <div className="FeedPage flex-100 layout-row layout-wrap layout-align-start-start overFlowHidden">
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-start">
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <Switch>
              <Route exact path="/feed" render={() => <Redirect replace to="/feed/store" />} />
              <Route path="/feed/store" component={StoreRoutes} />
              <Route path="/feed/:public_id" component={ProfilePage} />
            </Switch>
            {user && user.public_id && (
              <FeedNavigation user={user} isProfilePage={this.props.location.pathname.indexOf('store') === -1} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userType: state.appR.userType,
  user: state.userR.userInfo
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedPage);
