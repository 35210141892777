/*
Color Options:
'light.aspire', "light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = 'light.aspire';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const colorSchemes = {
  defaultTheme: {
    apptheme: '#23263c' ,
    '--hr-border-grey': '1px solid rgba(221, 221, 221, 0.4)',
    '--separator-color-light': 'rgba(49, 49, 49, 1)',
    '--separator-color': 'rgb(234, 234, 234)',
    '--separator-color-rgb': '234, 234, 234',
    '--form-control-bg-rgb': '255,255,255',
    '--form-control-color': '#212121',
    /* background colors */
    '--background-color': '#fcfcfc' /* #f9fafd */,
    '--dark-background-color': 'rgb(23, 22, 54)',
    '--foreground-color': '#efefef',
    '--card-background': '#fff',
    '--card-image-background': '#969696',
    '--button-bg-hover-color': '#f2f2ff',
    '--button-bg-hover-color-rgb': '242, 242, 255',
    '--alert-color': '#c9276b',
    '--valid-color': '#209671',

    /* text colors */
    '--primary-color': '#212121',
    '--secondary-color': '#424242',
    '--third-color': '#313131',
    '--muted-color': '#969696',
    '--light-color': '#fff',
    '--dark-color': '#212121',
    '--button-text-color': '#fff',

    '--theme-color-1': '#353e82',
    '--theme-color-2': '#23263c',
    '--theme-color-3': '#4551af' /* good for blue color #4c6cc0 */,
    '--theme-color-4': '#4c508d',
    '--theme-color-5': '#6b77c9',
    '--theme-color-6': '#7b9fff',
    /*
'--theme-color-6': '#4f69ae',
*/
    '--theme-color-7': '#dddddd',
    '--theme-color-8': '#aeaeae',

    '--theme-color-1-rgb': '53,62,130',
    '--theme-color-2-rgb': '35,38,60',
    '--theme-color-3-rgb': '69,81,175',
    '--theme-color-4-rgb': '76,80,141',
    '--theme-color-5-rgb': '107,119,201',
    '--theme-color-6-rgb': '123,159,255',
    '--theme-color-7-rgb': '221,221,221',
    '--theme-color-8-rgb': '174,174,174',
    /* specific background */
    '--gradient-exercise-log':
      '5deg, rgba(252, 252, 252, 0) 0%, rgb(255, 255, 255) 0%, rgb(250, 250, 250) 85%',
    '--chosen-exercise-log': 'rgb(244, 244, 244) 0%, rgb(255, 255, 255) 85%',
    '--default-text-color': '#4c508d',
    '--stats-btn-details-gradient': '-87deg, #414481, #2f3368',
    '--stats-btn-details-gradient-light': '-87deg, #dadbfd, #fcfcff',
    '--profile-page-bg': '#ffffff',

    '--headerBg': '90deg, #353e82, #23263c',
    '--header-background-error': '90deg, #82355c, #3c232f',
    '--top-nav-error-gradient-text': '-87deg, #f7baba, #ffffff',
    '--navDrawerrBg': '90deg, #23263c, #282c4e',
    '--btn-purple': '165deg, #353e82, #23263c',
    '--key-lift-gradient': '60deg, #353e82, #23263c',
    '--filter-color': '60deg, #353e82, #23263c',
    '--checkboxBg': '90deg, #414481, #353e82',
    '--navBottomBg': '90deg, #414481, #353e82',
    '--progress-bar-gradient': '90deg, #414481, #353e82',
    '--media-card-skeleton-dark': '90deg, #282c4e 0%, #212044 20%, #171636 40%, #282c4e 100%',
    '--media-card-skeleton': '90deg, #282c4e 0%, #282c4e 20%, #171636 40%, #282c4e 100%',
    '--datesCubes-card-skeleton': 'left, #1c1b42 0%, #1d1f4c 20%, #1c1b42 40%, #1c1b42 100%',
    '--map-body-gradient':
      '180deg, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 0) 0%, rgba(249, 250, 253, 0) 60%, rgb(249, 250, 253) 100%',
    '--map-body-gradient-top':
      '180deg, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 0) 0%, rgba(249, 250, 253, 0) 50%, rgb(249, 250, 253) 93%',
    '--map-body-gradient-center-bottom':
      '180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 3%, rgba(255, 255, 255, 0.3) 10%, rgba(255, 255, 255, 0) 50%, rgb(255, 255, 255) 93%',
    '--gradient-text': '-87deg, rgb(119, 134, 224), rgb(89, 94, 164)',
    '--bodyMap-target': 'rgba(126, 141, 254,1)',
    '--bodyMap-Synergist': 'rgba(105, 103, 206, 0.35)',
    '--add-media-button': '#ECEBFA',
    '--add-media-button-form': '#f6f4ff',
    '--loading-skeleton-webkit':
      'linear, left center, right center, from(#f6f7f8), color-stop(.2, #edeef1), color-stop(.4, #f6f7f8), to(#f6f7f8)',
    '--loading-skeleton': 'left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%',
    '--loading-skeleton-profile':
      'left, #f6f7f800 0%, #21204400 20%, rgba(30, 27, 62, 0.83) 40%, #f6f7f800 100%',
    '--menu-expand-shadow': '62,57,107',
    '--input-color-border': '#6883ca',
  },
  light: {
    apptheme: '#c9276b' ,
    '--headerBg': '90deg, #f1f1f1, #7a7980',
    '--recent-workouts-bg-top': 'rgba(47, 47, 47, 0.38) 0%',
    '--recent-workouts-bg-center': 'rgba(43, 43, 43, 0.35) 30%',
    '--recent-workouts-bg-bottom': 'rgba(43, 43, 43, 0.12) 58%',
    // background colors
    'background-color': '#fff',
    'foreground-color': '#efefef',
    'card-background': '#fff',
    'dark-btn-background': '#131113',
    'light-btn-background': '#ececec',

    // text colors
    '--button-text-color': '#fff',
    '--primary-color': '#212121',
    '--secondary-color': '#424242',
    '--third-color': '#313131',
    '--muted-color': '#969696',
    '--light-color': '#fff',

    '--profile-page-bg': '#fff',
    '--gradient-exercise-log':
      '5deg, rgba(244, 245, 255, 0) 0%, rgb(251, 251, 253) 0%, rgb(255, 255, 255) 85%',
    '--chosen-exercise-log':
      'rgba(244, 245, 255, 0) 0%, rgb(232, 232, 248) 0%, rgb(255, 255, 255) 85%',
  },
  dark: {
    apptheme: '#232223' ,
    '--separator-color-light': 'rgba(49, 49, 49, 1)',
    '--separator-color': 'rgba(66, 66, 66, 1)',
    '--separator-color-rgb': '66, 66, 66',

    '--background-color': '#232223',
    '--foreground-color': '#191919',
    '--card-background': '#3c3c3c',
    '--dark-btn-background': '#8d8d8d',
    '--light-btn-background': '#e4e4e4',
    '--card-image-background': '#3c3c3c',
    '--button-bg-hover-color': 'rgb(74,74,74)',
    //     '--button-bg-hover-color': '#f2f2ff',
    '--button-bg-hover-color-rgb': '74,74,74',
    '--form-control-bg-rgb': '60, 60, 60',
    '--form-control-color': '#aeaeae',

    '--button-text-color': '#d0d0d0',
    '--primary-color': '#dedede',
    '--secondary-color': '#424242',
    '--third-color': '#919191',
    '--muted-color': '#969696',

    '--theme-color-1': '#dedede',
    '--theme-color-2': '#252525',
    '--theme-color-3': '#7b9fff',
    '--theme-color-4': '#424242',
    '--theme-color-5': '#3c3c3c',
    '--theme-color-6': '#8d8d8d',
    '--theme-color-7': '#6b6b6b',
    '--theme-color-8': '#aeaeae',

    '--theme-color-1-rgb': '222,222,222',
    '--theme-color-2-rgb': '37,37,37',
    '--theme-color-3-rgb': '123,159,255',
    '--theme-color-4-rgb': '66,66,66',
    '--theme-color-5-rgb': '60,60,60',
    '--theme-color-6-rgb': '141,141,141',
    '--theme-color-7-rgb': '107,107,107',
    '--theme-color-8-rgb': '174,174,174',

    '--add-media-button': '#3c3c3c',
    '--add-media-button-form': '#3c3c3c',
    '--profile-image-bg': 'rgb(30, 30, 31)',
    '--client-drawer-bg': 'rgba(39, 39, 39, 0.85)',
    '--headerBg': '90deg, #1b1b1b, #2b2b2b',
    '--gradient-text': '-87deg, rgb(207, 207, 207), rgb(135, 135, 135)',
    '--gradient-exercise-log': '5deg, rgba(244, 245, 255, 0) 0%, #201f1f 0%, rgb(36, 34, 35) 85%',
    '--chosen-exercise-log': 'rgb(51, 50, 50) 0%, rgb(59, 59, 59) 85%',
    '--map-body-gradient':
      '180deg, rgba(60, 60, 60, 1) 0%, rgba(60, 60, 60, 1) 0%, rgba(60, 60, 60, 0) 0%, rgba(60, 60, 60, 0) 60%, rgb(60, 60, 60) 100%',
    '--map-body-gradient-center-bottom':
      '180deg, rgb(60, 60, 60) 0%, rgba(60, 60, 60, 0.3) -1%, rgba(60, 60, 60, 0) 90%, rgb(60, 60, 60) 100%',
    '--loading-skeleton-webkit':
      'linear, left center, right center, from(rgba(52, 61, 128, 0)), color-stop(.2, rgba(53, 61, 127, 0.4)), color-stop(.4, rgba(52, 61, 128, 0)), to(rgba(52, 61, 128, 0))',
    '--loading-skeleton':
      'left, rgba(52, 61, 128, 0) 0%, rgba(53, 61, 127, 0.4) 20%, rgba(52, 61, 128, 0) 40%, rgba(52, 61, 128, 0) 100%',
    '--menu-expand-shadow': '0,0,0',
    '--bodyMap-target': '#972B59',
    '--bodyMap-Synergist': 'rgba(207,101,56,0.55)',
  },
  purple: {
    '--profile-page-bg':
      '0deg, rgba(23, 22, 54, 1) 0%, rgb(30, 28, 63) 30%, rgba(23, 22, 54, 1) 58',

    '--map-body-gradient-top':
      '180deg, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 0) 0%, rgba(249, 250, 253, 0) 50%, rgb(249, 250, 253) 93%',
    '--map-body-gradient-bodyMapThumb-center-bottom':
      '180deg, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 0) 0%, rgba(249, 250, 253, 0) 50%, rgb(249, 250, 253) 93%',
    '--map-body-gradient-bodyMapCmp-center-bottom':
      '180deg, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 1) 0%, rgba(249, 250, 253, 0) 0%, rgba(249, 250, 253, 0) 50%, rgb(249, 250, 253) 93%',

    'theme-color-1': '#86367e',
    'theme-color-2': '#3c4b9a',
    'theme-color-3': '#af67a4',
    'theme-color-4': '#743c6e',
    'theme-color-5': '#4b5480',
    'theme-color-6': '#795d75',

    'primary-color': '#969696',
    'secondary-color': '#757575',
    'muted-color': '#5a5a5a',

    'gradient-color-1 ': '#582553',
    'gradient-color-2 ': '#461d42',
    'gradient-color-3 ': '#64295e',
  },
};
