import React, { Component } from 'react';
import { connect } from 'react-redux';
import nanoid from 'nanoid/generate';
import { showAlert } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import { handleModalFullScreen, styleUtils } from '../../services/utilsService';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './UIStyle.css';
import { nanoidOption } from '../../services/storeDoll';
import { aspirePlaceholder, mediaType } from '../../services/enums';
import Image from './Image';

class MediaDisplayUI extends Component {
  constructor (props) {
    super(props);
    this.state = {
      height: this.props.height,
      thumbnail: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/logoScreen.jpg'
    };
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !!(nextProps.shouldUpdate || nextState.shouldUpdate);
  }

  checkMultipleLinks (media) {
    if (media && Array.isArray(media)) {
      return (media.map((mediaObj, index) => {
        if (mediaObj && mediaObj.media && mediaObj.media.source) {
          const mediaKey = mediaObj.id || (mediaObj.media && mediaObj.media._id) || 'null';
          return (
            <div key={mediaKey} className={`width100 layout-column layout-align-center-center positionAbsoluteTopLeft mediaLink mediaLink_${mediaObj.order}`} style={{ minHeight: `${this.props.height}px`, zIndex: media.length - index }}>
              { this.renderVideo(mediaObj.media, mediaKey)}
            </div>
          );
        }
        return this.renderDefaultIcon();
      }));
    }
    if (media && media.media && media.media.source) {
      return this.renderVideo(media.media, media.id || media.media._id);
    }
    return this.renderDefaultIcon();
  }

  goFullScreen (media, isPortrait) {
    if (this.props.handleOrientation) this.props.handleOrientation(isPortrait);
    else {
      const data = handleModalFullScreen(media, isPortrait);
      if (this.props.disableFSLogo) data.disableFSLogo = true;
      this.props.showAlert(data);
    }
  }

  renderDefaultIcon () {
    return (
      <div key={`default_${nanoid(nanoidOption, 4)}`} className="width100 layout-column text-center layout-align-center-center positionAbsoluteTopLeft whiteBackground borderRadius5" style={{ height: `${this.props.height}px` }}>
        <img src="https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogo_250x225.png" className="aspireIcon" style={{ width: '80px' }} />
        <h2 className="text-uppercase fontWeight600">Video</h2>
      </div>
    );
  }

  renderVideo (mediaObj, mediaElemId) {
    const { isImageClass, thumbnail } = this.props;
    if (mediaObj && mediaObj.source && mediaObj.type === mediaType.video) {
      return (
        <VideoPlayer
          key={mediaElemId}
          id={mediaElemId}
          height={this.props.height || 230}
          width={this.props.width || window.innerWidth}
          media={mediaObj}
          isMuteBtn={this.props.isMuteBtn}
          controls={this.props.controls}
          playPauseBtn={this.props.playPauseBtn}
          fullScreenBtn={this.props.fullScreenBtn}
          isAutoPlay={this.props.isAutoPlay}
          isLandscape={this.props.isLandscape}
          appLang={this.props.appLang}
          showThumbnailLoader={this.props.showThumbnailLoader}
          handleModalFullScreen={(media, isPortrait) => { this.goFullScreen(mediaObj, isPortrait); }} />
      );
    } if (mediaObj && mediaObj.source && mediaObj.type === mediaType.image) {
      return (<img alt="infoImg" src={(mediaObj && mediaObj.source) || this.state.thumbnail} className={`imageSize ${isImageClass || ''}`} />);
    } return this.renderDefaultIcon();
  }

  renderImg (asImg, source, isImageClass, height, fallbackThumbnail){
    if (asImg) return (<img alt="infoImg" src={source} className={`imageSize ${isImageClass || ''}`} />)
    return (
      <Image
        backgroundImage
        src={source}
        fallbackSrc={fallbackThumbnail || source || aspirePlaceholder[0]}
        height={height}
        className="mediaImg backgroundImage flex-100 layout-column layout-wrap layout-align-center-center positionRelative"
        color1="rgba(0, 0, 0, 0)"
        color2="rgba(0, 0, 0, 0)"
        color3="rgba(0, 0, 0, 0)"
        backgroundProps={{height: `${height}px`}}
      />
      )
  }

  render () {
    const {
      isImage, media, videoPoster, isImageClass, thumbnail, asImg, height, fallbackThumbnail
    } = this.props;
    const videoDisplay = this.checkMultipleLinks(media);
    return (
      <div
        id={this.props.uiId}
        className={`MediaDisplayUI flex-100 layout-row layout-align-start-start ${this.props.className ? this.props.className : ''}`}
        style={{ height: `${this.state.height}px`, position: 'relative', zIndex: 5 }}>
        <div className="flex-100 layout-row layout-align-center-center">
          { isImage ? this.renderImg(asImg, (videoPoster || thumbnail || this.state.thumbnail), isImageClass, height, fallbackThumbnail) : videoDisplay }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDisplayUI);
