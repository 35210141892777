/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import nanoid from 'nanoid';
import { updateClientPassword, updateEvent, updateFollowingEvents, updateAllEvents, removeEvent, removeFollowingEvents } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import Input from '../../Components/UI/Input';
import '../../Components/Alerts/MsgModal.css';
import BtnLoader from '../../Components/UI/BtnLoader';


export const editEventOptions = [
  { containerClass: 'flex-100 marginBottom10px', name: 'single', group: 'editEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'thisEventOnly', conditionType: 'single' },
  { containerClass: 'flex-100 marginBottom10px', name: 'following', group: 'editEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'thisEventAndFollowing', conditionType: 'following' },
  { containerClass: 'flex-100 marginBottom10px', name: 'allEvents', group: 'editEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'allEvents', conditionType: 'allEvents' }
];

export const deleteEventOptions = [
  { containerClass: 'flex-100 marginBottom10px', name: 'single', group: 'deleteEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'thisEventOnly', conditionType: 'single' },
  { containerClass: 'flex-100 marginBottom10px', name: 'following', group: 'deleteEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'thisEventAndFollowing', conditionType: 'following' },
];

class EditEventOptionsModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      options: this.props.editEvent ? editEventOptions : deleteEventOptions,
      editEvent: this.props.editEvent,
      chosenOption: 'single',
    };
  }

  setOption(value) {
    this.setState({ chosenOption: value });
  }

  async updateEvent () {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { chosenOption } = this.state;
      const { event, editEvent } = this.props;
      let data = null;
      switch (chosenOption) {
        case 'following':{
          if (editEvent) await this.props.updateFollowingEvents(event);
          else await this.props.removeFollowingEvents(event);
          this.props.alert2Props.onEditDone();
          break;
        }
        case 'allEvents':{
          data = await this.props.updateAllEvents(event);
          this.props.alert2Props.onEditDone();
          break;
        }
        default: {
          if (editEvent) await this.props.updateEvent(event);
          else await this.props.removeEvent(event);
          this.props.alert2Props.onEditDone();
          break;
        }
      }
    } catch (e) {
      this.setState({ error: true });
      this.isLoading = false;
    }
  }

  render () {
    const { options } = this.state;
    return (
      <div className="flex-100 layout-row layout-align-start-start">
        <div className="flex-100 layout-row layout-wrap layout-align-center-center ">
          <div className="flex-100 layout-row layout-align-center-center">
            <div className="title boldText gradientText">{ this.props.title }</div>
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start padd20">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start">
              { options.map((input) => (
                <div key={input.name} className={`layout-row layout-align-start-center positionRelative ${input.containerClass ? input.containerClass : ''}`}>
                  <Input
                    noLabel
                    id={`${input.name}_${nanoid(4)}`}
                    key={input.name}
                    name={input.name}
                    label={input.name}
                    group={input.group}
                    elementType={input.elementType}
                    appLang={this.props.appLang}
                    initialValue={this.state.chosenOption === input.conditionType}
                    className="width100"
                    handleChange={() => { this.setOption(input.conditionType); }}>
                    <div className="flex-100 layout-row layout-align-start-center">
                      <p className="margin0"><Trans i18nKey={input.text} /></p>
                    </div>
                  </Input>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-100 layout-row layout-align-start-start">
            <button type="button" className="button flex" onClick={() => this.props.hideAlertBtn()}><Trans i18nKey="cancel" /></button>
            <BtnLoader
              containerClassName="flex layout-row layout-wrap layout-align-center-center alertBtnBg"
              className="flex padd0"
              type="button"
              btnText="confirm"
              btnClicked={() => this.updateEvent()} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  alert2Props: state.appR.alert2Props,
  appLang: state.appR.appLang
});

const mapDispatchToProps = dispatch => ({
  updateEvent: data => dispatch(updateEvent(data)),
  removeEvent: data => dispatch(removeEvent(data)),
  updateFollowingEvents: data => dispatch(updateFollowingEvents(data)),
  removeFollowingEvents: data => dispatch(removeFollowingEvents(data)),
  updateAllEvents: data => dispatch(updateAllEvents(data)),
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
  updateClientPassword: body => dispatch(updateClientPassword(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEventOptionsModal);
