import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './MsgModal.css';

class MsgModal extends Component {
  closeAlert () {
    this.props.hideAlertBtn();
    if (this.props.onCloseAlert) this.props.onCloseAlert();
    if (this.props.closeAlertBtn && this.props.closeAlertBtn.onClick) this.props.closeAlertBtn.onClick();
  }

  render () {
    return (
      <div className="flex-100 layout-row layout-align-start-start">
        <div className="flex-100 layout-row layout-wrap layout-align-center-center ">
          <div className="flex-100 layout-row layout-align-center-center">
            <div className="title boldText primary">{ this.props.title }</div>
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-center-center">
            <p className="content margin0">{ this.props.content } </p>
          </div>
          <div className="flex-100 layout-row layout-align-start-start">
            { this.props.closeAlertBtn && (
              <button type="button" className={`${ this.props.closeAlertBtn.className ? this.props.closeAlertBtn.className : 'button flex'}`} onClick={() => this.closeAlert()}>{this.props.closeAlertBtn.text}</button>
            )}
            { this.props.buttonRight && this.props.buttonRight.text && (
              <button className="button flex" type="button" {...this.props.buttonRight}>{ this.props.buttonRight.text }</button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MsgModal.defaultProps = {
  title: 'notice',
  content: ''
};

MsgModal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  /* closeAlertBtn: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  }),
  buttonRight: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  }), */
  onCloseAlert: PropTypes.func
};
const mapStateToProps = state => ({
  user: state.userR.userInfo,
});

export default connect(mapStateToProps)(MsgModal);
