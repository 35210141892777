import React from 'react';
import './UIStyle.css';

const LoadingDots = props => (
  <div id={props.hiddenLoader || props.active ? 'loadingDots' : ''} className={`LoadingDots flex-100 layout-row text-center layout-align-center-center ${props.hiddenLoader ? 'opacity0' : ''}`}>
    <div className="flex-initial layout-row layout-align-center-center loadingDotsContainer">
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>
  </div>
);

export default LoadingDots;
