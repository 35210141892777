import React, { Component } from 'react';
import nanoid from 'nanoid/generate';
import { calcUtils, inputUtils } from '../../services/utilsService';
import './UIStyle.css';
import Input from './Input';
import { goalBestTimeInputs, nanoidOption } from '../../services/storeDoll';

class TimeInputUI extends Component {
  constructor (props) {
    super(props);
    this.state = {
      elementId: `TimeUI${nanoid(nanoidOption, 6)}`,
      value: this.props.value
    };
    if (this.props && this.props.value && this.props.value > 0) {
      this.state.value = calcUtils.calcMillisToMinutesAndSeconds(this.props.value, true);
      const time = this.state.value.split(':');
      this.minutes = Number(time[0]);
      this.seconds = Number(time[1]);
    }
  }

  onFocus(event, key) {
    const input = document.querySelector(`#${this.state.elementId} input#${key}`);
    input.value = '';
  }

  handleChange (event, key) {
    this[key] = event.target.value;
    if (key === 'minutes' && (this[key].length > 1)) {
      const secondsInput = document.querySelector(`#${this.state.elementId} input#seconds`);
      secondsInput.value = '';
      secondsInput.focus();
    }
    if (key === 'seconds' && (this[key] >= 0 && this[key] < 60 && (this[key].length > 1))) {
      const secondsInput = document.querySelector(`#${this.state.elementId} input#seconds`);
      secondsInput.blur();
    }
    if (this.props.onChange) {
      const time = ((this.minutes || 0) * (1000 * 60) + (this.seconds || 0) * 1000);
      this.props.onChange(time);
    }
  }

  render () {
    return (
      <div id={this.state.elementId} className={`TimeInputUI flex-100 layout-row layout-wrap layout-align-center-start directionChangeLtr text-center ${this.props.className}`}>
        <div className="flex-100 layout-row layout-align-start-center">
          <div className="flex layout-column layout-wrap layout-align-center-center positionRelative">
            <Input
              {...goalBestTimeInputs[0]}
              noLabel
              appLang={this.props.appLang}
              inputClass="text-center"
              initValue={this.minutes && this.minutes > 0 ? this.minutes : '00'}
              onFocus={(event, key) => { this.onFocus(event, key); }}
              handleChange={(event, key) => { this.handleChange(event, key); }} />
          </div>
          <div className="flex-initial sidePadd5px layout-column layout-wrap layout-align-center-center">
            <p className="set-time margin0">:</p>
            <span className="font13 comment colorGrey" />
          </div>
          <div className="flex layout-column layout-wrap layout-align-center-center positionRelative">
            <Input
              {...goalBestTimeInputs[1]}
              noLabel
              appLang={this.props.appLang}
              inputClass="text-center"
              initValue={this.seconds && this.seconds > 0 ? this.seconds : '00'}
              onFocus={(event, key) => { this.onFocus(event, key); }}
              handleChange={(event, key) => { this.handleChange(event, key); }} />
          </div>
        </div>
      </div>
    );
  }
}

export default TimeInputUI;
