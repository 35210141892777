/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import localStorage from 'local-storage';
import moment from 'moment'
import TutorialGuide from './TutorialGuide';
import { handleModalFullScreen, removeElement } from '../../services/utilsService';
import { showAlert } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import 'react-tippy/dist/tippy.css';
import './TutorialGuide.css';

class TutorialGuideBtn extends Component {
  constructor (props) {
    super(props);
    this.state = {
      icon: 'https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogo_250x225.png',
      openTutorial: false,
      dataKey: this.props.dataKey
    };
  }

  toggleTutorial (hideTutorial) {
    this.setState(prevState => ({ openTutorial: !prevState.openTutorial }), () => {
      if (hideTutorial) {
        localStorage.set(`${this.props.dataKey}-aspirePopup`, { time: moment().subtract(5,'days').toDate() });
        removeElement('#tutorialGuide')
      }
    });
  }

  handleVideoModal (media) {
    this.toggleTutorial();
    const data = handleModalFullScreen(media, true);
    data.disableFSLogo = true;
    data.controls = true;
    data.modalClassName = `flex-100 layout-row layout-wrap layout-align-start-center videoBlackBg`
    this.props.showAlert(data);
  }

  handleGoToPage (url) {
    this.props.history.push(url);
  }

  initiateFunction(funcName, data){
    this[funcName](data)
  }

  renderTutorialTooltip () {
    return (
      <TutorialGuide
        initiateFunction={(funcName, data)=> {this.initiateFunction(funcName, data)}}
        dataKey={this.state.dataKey}
        handleVideoModal={media => this.handleVideoModal(media)}
        handleGoToPage={option => this.handleGoToPage(option)}
        appLang={this.props.appLang} />
    );
  }

  render () {
    const { dataKey, openTutorial, icon } = this.state;
    const renderTutorial = dataKey && this.renderTutorialTooltip();
    return (
      <div id="tutorialGuide" className="TutorialGuideBtn flex-100 layout-row layout-wrap layout-align-space-start-start">
        <div className="positionFixed bottomRightCorner zIndex15">
          <div className="positionRelative flex-initial layout-row">
            <Tooltip
            // options
              open={openTutorial}
              title="Welcome to Aspire"
              position="top-end"
              interactive
              arrow
              arrowSize="big"
              html={renderTutorial}
              theme="light"
              distance={0}
              trigger="click">
              <button type="button" onClick={() => { this.toggleTutorial(); }} className="TutorialGuideBtn flex-initial layout-column layout-align-center-center roundButton margin20 pulsingButton">
                <img src={icon} alt="aspire icon" />
              </button>
            </Tooltip>
          </div>
        </div>
        { openTutorial && (<div className="black_back" onClick={() => this.toggleTutorial()} />)}
      </div>
    );
  }
}

TutorialGuideBtn.defaultProps = {
  data: {}
};

TutorialGuideBtn.propTypes = {
  data: PropTypes.shape({})
};

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang
});

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorialGuideBtn);
