/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localStorage from 'local-storage';
import ReactGA from 'react-ga';
import { showAlert3, introduceProgramToClient } from '../../../store/actions';
import { handleModalFullScreen, addClass } from '../../../services/utilsService';
import { actionTypes } from '../../../store/actions/actionTypes';
import UserModalCard from '../../../Components/ProfileCards/UserModalCard';
import { userTypes } from '../../../services/enums';
import '../CongratulationsPage.css';
import './UserIntroductionPage.css';
import json from './userIntroductionData';

class UserIntroductionPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: {},
      dontShowAgain: false,
    };
    this.windowSize = { height: window.outerHeight, width: window.innerWidth };
  }

  componentDidMount() {
    const { userType } = this.props.user
    let data = null;
    if (userType === userTypes.TRAINER) {
      data = json.TRAINER[this.props.alertProps.dataKey](this.props.user, this.props.appLang);
    } else {
      data = json[this.props.user.userType][this.props.alertProps.dataKey](this.props.user, this.props.appLang);
    }
    this.setState({ data})
  }

  handleVideoModal (media) {
    const data = handleModalFullScreen(media, true, 'UserIntroduction');
    data.disableFSLogo = true;
    data.controls = true;
    data.className = `${data.className}`;
    data.modalClassName = `flex-100 layout-row layout-wrap layout-align-start-center videoBlackBg`
    this.props.showAlert3(data);
  }

  handleMediaOption (option) {
    const data = option.media;
    const media = {
      user: null,
      public_id: 'VID-ASPIRE_TUTORIAL',
      title: 'aspire tutorial',
      type: 'video',
      sourceType: 'amazonS3',
      mediaGroup: 'aspireInformation',
      source: data.sourceHls.master,
      sourceHls: data.sourceHls,
      thumbnail: null
    };
    this.handleVideoModal(media, data.isPortrait);
  }

  handleCheckboxChange(e) {
    this.setState(prevState => ({ dontShowAgain: !prevState.dontShowAgain }), () => {
      // console.log('dontShowAgain ', this.state.dontShowAgain);
    });
  }

  async introduceProgramToClient() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      ReactGA.event({ category: 'UserIntroduction', action: 'Get Free Program To Client' });
      const data = await this.props.introduceProgramToClient();
      if (data && data.public_id) {
        this.props.history.push(`/program/${data.public_id}`);
      }
      this.isLoading = false;
    } catch (e) {
      ReactGA.exception({
        description: 'An error occurred on introduceProgramToClient()',
        fatal: false
      });
      this.isLoading = false;
    }
  }

  closeModal () {
    // if (this.state.dontShowAgain) localStorage.set(`intro-dontShowAgain`, this.state.dontShowAgain);
    localStorage.set(`intro-dontShowAgain`, true);
    addClass('#CongratulationsPage', 'opacity0');
    ReactGA.event({ category: 'UserIntroduction', action: `No Thanks, ${ this.state.dontShowAgain ? 'dont show again.' : 'show me next time.'}` });
    this.timeoutId = setTimeout(() => {
      this.props.hideAlert();
      clearTimeout(this.timeoutId);
    }, 400);
  }

  goToPage (option) {
    ReactGA.event({ category: 'UserIntroduction', action: option.text });
    this.props.activateAliceTutorial();
    localStorage.set(`intro-dontShowAgain`, true)
    this.props.history.push(`${option.url}?isTutorial=true`);
  }

  render () {
    return (
      <div id="CongratulationsPage" className="CongratulationsPage UserIntroductionPage flex-100 layout-row layout-wrap layout-align-start-start fullSizeWindow content-start">
        <div className="width100 layout-row layout-wrap layout-align-start-start fullSizeWindow positionAbsoluteTopLeft content-start zIndex5" />
        <div className="width100 layout-row layout-wrap layout-align-start-start zIndex10 confettiCardPosition sidePadd20px">
          <div className="flex-100 layout-row layout-wrap layout-align-center-center">
            <UserModalCard
              data={this.state.data}
              userData={this.props.user}
              appLang={this.props.appLang}
              // isChecked={!this.state.dontShowAgain}
              cancel={() => this.closeModal()}
              handleMediaOption={(option) => this.handleMediaOption(option)}
              goToPage={(option) => this.goToPage(option)}
              introduceProgramToClient={(option) => this.introduceProgramToClient(option)}
              handleCheckboxChange={(e) => this.handleCheckboxChange(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  showAlert3: data => dispatch(showAlert3(data)),
  introduceProgramToClient: () => dispatch(introduceProgramToClient()),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  activateAliceTutorial: () => dispatch({ type: actionTypes.ACTIVATE_ALICE_TUTORIAL_MODE })
});
export default connect(mapStateToProps, mapDispatchToProps)(UserIntroductionPage);
