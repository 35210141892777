import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { toggleClass } from '../../services/utilsService';
import './UIStyle.css';
import LoadingDots from './LoadingDots';

class StatusUI extends Component {
  clickedDone () {
    if (this.props.clickedDone) {
      this.props.clickedDone();
    }
    toggleClass(`#${this.props.uiId}`, 'is_active');
  }

  clickedActive () {
    if (this.props.clickedActive) {
      this.props.clickedActive();
    }
    toggleClass(`#${this.props.uiId}`, 'is_active');
  }

  render () {
    return (
      <div id={this.props.id} className={`StatusUI flex-initial layout-row layout-align-center-center positionRelative height45px ${this.props.isLoading ? 'isLoading' : ''} ${this.props.status ? 'isGood' : 'isBad'}`}>
        <div className="isLoading flex-initial layout-row layout-align-center-center">
          <LoadingDots />
        </div>
        <div className="isGood flex-initial layout-row layout-align-center-center">
          <svg className="checkmark" width="70" height="70">
            <path d="m31.5,46.5l15.3,-23.2" />
            <path d="m31.5,46.5l-8.5,-7.1" />
          </svg>
        </div>
        <div className="isBad flex-initial layout-row layout-align-center-center">
          <svg className="cross" width="70" height="70">
            <path d="m35,35l-9.3,-9.3" />
            <path d="m35,35l9.3,9.3" />
            <path d="m35,35l-9.3,9.3" />
            <path d="m35,35l9.3,-9.3" />
          </svg>
        </div>
      </div>
    );
  }
}

export default StatusUI;
