import localStorage from 'local-storage';
import { actionTypes } from '../actions/actionTypes';
import { user, navDrawer } from '../../appConsts/pageHeaders';
import { userTypes } from '../../services/enums';

const initialState = {
  appLang: 'en',
  disableTopNavPlx: false,
  showTopNav: false,
  pageHeader: { showTopNav: false },
  navBottom: { showBottomNav: false, configBottom: [] },
  alertProps: {},
  isBackButtonDisabled: false,
  navDrawer,
  modalMinimized: false,
  tutorialMode: { isActive: false, key: null },
  aliceTutorialMode: false,
  searchQuery: {},
  // aspireServices: [],
  // userType
  // navBottomKey: '',
  // chosenPublicId
  // currentPage: ''
  // pageHeaderInfo: {}
  // activityList: []
  // unReadActivitiesList: []
  // searchResults: {},
  // categories: []
  // categoryDetails: {}
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REFRESH_PAGE_HEADER: {
      let pageHeader = {};
      if (state.userType && state.userType !== userTypes.CLIENT)
        pageHeader = user[state.userType][action.payload];
      else pageHeader = user.CLIENT[action.payload];
      if (action.params && pageHeader) pageHeader.params = action.params;
      return {
        ...state,
        pageHeader: { ...pageHeader },
        pageHeaderInfo: {},
        showTopNav: pageHeader && pageHeader.showTopNav ? pageHeader.showTopNav : false,
      };
    }
    case actionTypes.REFRESH_PAGE_HEADER_INFO: {
      // title & subtitle only
      return { ...state, pageHeaderInfo: { ...action.payload } };
    }
    case actionTypes.VALIDATION_ERROR: {
      // validation Error only
      return { ...state, pageHeader: { ...state.pageHeader, validationError: action.payload } };
    }
    case actionTypes.CLEAR_VALIDATION_ERROR: {
      // validation Error only
      return { ...state, pageHeader: { ...state.pageHeader, validationError: null } };
    }
    case actionTypes.SAVE_USER_CREDENTIALS:
      return {
        ...state,
        unReviewedDoc: action.payload.unReviewedDoc,
        unReviewedSurvey: action.payload.unReviewedSurvey,
        userType: action.payload.user.userType,
        navDrawer: action.payload.navDrawer || state.navDrawer,
      };
    case actionTypes.SAVE_ASPIRE_MASSAGES: {
      return { ...state, aspireMsgs: action.payload };
    }
    case actionTypes.SAVE_SEARCH_QUERY: {
      // validation Error only
      return { ...state, searchQuery: action.payload };
    }
    case actionTypes.CLEAR_SEARCH_QUERY: {
      // validation Error only
      return { ...state, searchQuery: null };
    }
    case actionTypes.REFRESH_BOTTOM_NAV:
      return { ...state, navBottom: action.payload, navBottomKey: null };
    case actionTypes.BOTTOM_NAV_CLICKED:
      return { ...state, navBottomKey: action.payload };
    case actionTypes.HIDE_ALERT:
      return { ...state, alertProps: { isOpen: false } };
    case actionTypes.SHOW_ALERT:
      return { ...state, alertProps: action.payload };
    case actionTypes.HIDE_ALERT2: // secondLayer
      return { ...state, alert2Props: { isOpen: false } };
    case actionTypes.SHOW_ALERT2: // secondLayer
      return { ...state, alert2Props: action.payload };
    case actionTypes.SHOW_ALERT3: // secondLayer
      return { ...state, alert3Props: action.payload };
    case actionTypes.HIDE_ALERT3: // secondLayer
      return { ...state, alert3Props: { isOpen: false } };
    case actionTypes.CLEAR_ALERT:
      return {
        ...state,
        alertProps: { isOpen: false },
        alert2Props: { isOpen: false },
        alert3Props: { isOpen: false },
      };
    case actionTypes.HIDE_BOTTOM_NAV:
      return { ...state, navBottom: { showBottomNav: false } };
    case actionTypes.SHOW_BOTTOM_NAV:
      return { ...state, navBottom: { showBottomNav: true } };
    case actionTypes.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case actionTypes.SET_APP_LANG: {
      let appLang = action.payload;
      if (!appLang || (appLang !== 'en' && appLang !== 'he' && appLang !== 'es')) appLang = 'en';
      return { ...state, appLang };
    }
    case actionTypes.SET_EXTRA_TITLE:
      return { ...state, extraTitle: action.payload };
    case actionTypes.SET_ACTIVITY_LIST:
      return { ...state, activityList: action.payload };
    case actionTypes.SET_UNREAD_ACTIVITY_LIST:
      return { ...state, unReadActivitiesList: action.payload };
    case actionTypes.SET_DYNAMIC_SEARCH_RESULTS:
      return { ...state, searchResults: { ...state.searchResults, ...action.payload } };
    case actionTypes.DISABLE_TOP_NAV_PLX:
      return { ...state, disableTopNavPlx: action.payload };
    case actionTypes.SET_ALL_CATEGORIES:
      return { ...state, categories: action.payload };
    case actionTypes.SET_CATEGORY_DETAILS:
      return { ...state, categoryDetails: action.payload };
    case actionTypes.DISABLE_BACK_BTN:
      return { ...state, isBackButtonDisabled: true };
    case actionTypes.ENABLE_BACK_BTN:
      return { ...state, isBackButtonDisabled: false };
    case actionTypes.MINIMIZE_UPLOAD:
      return { ...state, modalMinimized: true };
    case actionTypes.EXPAND_UPLOAD:
      return { ...state, modalMinimized: false };
    case actionTypes.ACTIVATE_TUTORIAL_MODE:
      return { ...state, tutorialMode: { isActive: true, key: action.payload } };
    case actionTypes.DISABLE_TUTORIAL_MODE:
      return { ...state, tutorialMode: { isActive: false, key: null } };
    case actionTypes.ACTIVATE_ALICE_TUTORIAL_MODE:
      return { ...state, aliceTutorialMode: true };
    case actionTypes.DISABLE_ALICE_TUTORIAL_MODE:
      return { ...state, aliceTutorialMode: false };
    case actionTypes.CLEAR_UNREVIEWED_DOCUMENT:
      return { ...state, unReviewedDoc: null };
    case actionTypes.CLEAR_UNREVIEWED_SURVEY:
      return { ...state, unReviewedSurvey: null };
    case actionTypes.SET_ASPIRE_SERVICES: {
      return { ...state, aspireServices: action.payload };
    }
    default:
      return state;
  }
};

export default appReducer;
