/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { configureInputBySettings, getStatsMeasurement } from '../../services/utilsService';
import './InfoBox.css';

const bodyStatsTemplate = (props, stat) => {
  let display = stat;
  const statsMeasurement = getStatsMeasurement(stat.text);
  display = configureInputBySettings(props.settings, stat.value, statsMeasurement);
  return (
    <div key={`${stat.text}_id`} className={`${props.statsClasses || 'flex layout-column layout-align-start-center'} squareInfo `}>
      <label className="font20 layout-row layout-align-start-end">
        {display.value}
        { display.display && !(display.display === 'BMI' || display.display === 'KCAL') ? <span className="statText"><Trans i18nKey={display.display || stat.type} /></span> : null}
      </label>
      <p className="text-capitalize text-nowrap"><Trans i18nKey={stat.text} /></p>
    </div>
  );
};

const InfoBox = (props) => {
  const statsArray = props.stats && props.stats.length > 0 ? props.stats : [];
  const stats = statsArray.length > 0 ? statsArray.map((stat, index) => {
    if (!props.isTrainer && props.isBodyStats) {
      return bodyStatsTemplate(props, stat);
    }
    return (
      <div key={`${stat.text + index}_id`} className={`${props.statsClasses || 'flex layout-column layout-align-start-center'} squareInfo ${stat.className || ''}`}>
        <label className="font20 layout-row layout-align-start-center text-capitalize margin0">{stat.value && stat.value.value ? stat.value.value : stat.value} {(stat.type || (stat.value && stat.value.display)) ? <span className="statText"><Trans i18nKey={stat.type || stat.value.display} /></span> : null}</label>
        <p className="text-capitalize margin0 lineHeight12"><Trans i18nKey={stat.text} /></p>
      </div>
    );
  }) : null;

  return (
    <div className={`flex-100 layout-row layout-wrap layout-align-start-start InfoBox ${props.className || ''}`}>
      <div className="flex-100 layout-row layout-wrap layout-align-center-center positionRelative">
        { props.children }
      </div>
      {stats && (
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          {stats}
        </div>
      )}
      { props.description && props.description.length > 2 && (
        <div className={`${props.descriptionClasses || 'flex-100 layout-row layout-wrap layout-align-center-center'} description-container padd10px15px`}>
          { props.descriptionTitle && <p className={`text-capitalize title ${props.descriptionTitleClasses || 'font18 primary fontWeight400'}`}>{props.descriptionTitle}</p> }
          <p>{props.description}</p>
        </div>
      )}
    </div>
  );
};

InfoBox.propTypes = {
  stats: PropTypes.array,
  description: PropTypes.string,
  descriptionTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  descriptionClasses: PropTypes.string,
  isTrainer: PropTypes.bool,
  isBodyStats: PropTypes.bool,
  statsClasses: PropTypes.string,
  settings: PropTypes.shape({
    weightUnits: PropTypes.string,
    distanceUnits: PropTypes.string,
    language: PropTypes.string
  })
};

export default InfoBox;
