/* eslint-disable max-len */
import React from 'react';

function CountryResult(props) {
  const { data: { name, dialCode, image, countryCode } } = props;
  return (
    <div
      id={countryCode}
      key={`key_${countryCode}`}
      className={`StripExercise StripExerciseBasic DynamicResult ${props.className || 'flex-100 layout-row layout-align-start-center'}`}>
        <div className="flex-100 layout-row layout-align-space-between-center">
          <div className="flex-initial layout-row layout-align-start-center">
            <div className="flex-initial layout-row layout-wrap layout-align-start-center">
              <img src={image} alt={name} className="height25px btn-shadow" style={{maxWidth: '38px'}} />
            </div>
            <div className="flex-initial layout-row layout-wrap layout-align-start-center paddStart10px">
              <label className="flex-100 fontWeight600 title margin0 text-capitalize text-nowrap">{ name && name.length > 20 ? `${name.slice(0,20)}...` : name }</label>
            </div>
          </div>
          <div className="flex-initial layout-row layout-wrap layout-align-start-center">
            <label className="flex-100 fontWeight400 margin0 text-capitalize text-nowrap directionLtr">{dialCode}</label>
          </div>
        </div>
    </div>
  );
}
export default CountryResult;
