export const postMsgTypes = {
  RESTART_WEBVIEW_GLOBALS: 'RESTART_WEBVIEW_GLOBALS',
  DISABLE_BACK_BTN: 'DISABLE_BACK_BTN',
  ENABLE_BACK_BTN: 'ENABLE_BACK_BTN',
  GO_BACK: 'GO_BACK',
  GET_UNIQUE_PHONE_ID: 'GET_UNIQUE_PHONE_ID',
  LOCK_ORIENTATION: 'LOCK_ORIENTATION',
  UNLOCK_ORIENTATION: 'UNLOCK_ORIENTATION',
  LOCK_ORIENTATION_PORTRAIT: 'LOCK_ORIENTATION_PORTRAIT',
  LOCK_ORIENTATION_LANDSCAPE: 'LOCK_ORIENTATION_LANDSCAPE',
  SAVE_CURRENT_URL: 'SAVE_CURRENT_URL',
  MAKE_ERROR: 'MAKE_ERROR',
  GET_GCM_TOKEN: 'GET_GCM_TOKEN',
  GOOGLE_LOGIN: 'GOOGLE_OAUTH_LOGIN',
  GOOGLE_LOGOUT: 'GOOGLE_OAUTH_LOGOUT',
  GO_TO_URL: 'GO_TO_URL',
  PAGE_IS_READY: 'PAGE_IS_READY',
  GET_NOTIFICATION_URL: 'GET_NOTIFICATION_URL',
  CLEAR_NOTIFICATION_URL: 'CLEAR_NOTIFICATION_URL',
  HIDE_STATUS_BAR: 'HIDE_STATUS_BAR',
  SHOW_STATUS_BAR: 'SHOW_STATUS_BAR',
  HANDLE_THEME_COLOR: 'HANDLE_THEME_COLOR',
  GET_CONTACTS_LIST: 'GET_CONTACTS_LIST',
  GET_LOCATION: 'GET_LOCATION',
  TRY_LINKING_URL: 'TRY_LINKING_URL',
  ACTIVATE_KEEP_AWAKE: 'ACTIVATE_KEEP_AWAKE',
  DEACTIVATE_KEEP_AWAKE: 'DEACTIVATE_KEEP_AWAKE'
};

export const notificationsActions = {
  SIMPLE_NOTIFICATION: 'SIMPLE_NOTIFICATION',
  SESSION_NOTIFICATION: 'SESSION_NOTIFICATION',
  SESSION_SET_NOTIFICATION: 'SESSION_SET_NOTIFICATION',
  OPEN_APP: 'OPEN_APP',
  GO_TO_LINK: 'GO_TO_LINK',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  NEXT_SET: 'NEXT_SET',
  PREV_SET: 'PREV_SET',
  SKIP_EXERCISE: 'SKIP_EXERCISE',
  SKIP_SET: 'SKIP_SET',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  REFRESH_SESSION_NOTIFICATION: 'REFRESH_SESSION_NOTIFICATION',
  SESSION_COMMENT_NOTIFICATION: 'SESSION_COMMENT_NOTIFICATION',
  END_SESSION_NOTIFICATION: 'END_SESSION_NOTIFICATION',
  TO_SESSION_SUMMERY: 'TO_SESSION_SUMMERY',
  END_SESSION: 'END_SESSION',
  PLAY_TIME: 'PLAY_TIME',
  PAUSE_TIME: 'PAUSE_TIME',
  FINISH_REST: 'FINISH_REST',
  he: {
    activeSessionActions: '["סט הושלם"]',
    registerActiveSessionActions: ['סט הושלם'],
    activeAerobicSessionActions: '["סט הושלם","עצור שעון"]',
    registerActiveAerobicSessionActions: ['עצור שעון', 'סט הושלם'],
    pausedAerobicSessionActions: '["סט הושלם","הפעל שעון"]',
    registerPausedActiveAerobicSessionActions: ['הפעל שעון', 'סט הושלם'],
    restSessionActions: '["סיים מנוחה"]',
    registerRestSessionActions: ['סיים מנוחה'],
    endSessionActions: '["סיום"]',
    registerEndSessionActions: ['סיום'],
    commentSessionActions: '["אישור"]',
    registerCommentSessionActions: ['אישור']
  },
  en: {
    activeSessionActions: '["Set Completed"]',
    registerActiveSessionActions: ['Set Completed'],
    activeAerobicSessionActions: '["Pause","Set Completed"]',
    registerActiveAerobicSessionActions: ['Pause', 'Set Completed'],
    pausedAerobicSessionActions: '["Play Timer","Finish Set"]',
    registerPausedActiveAerobicSessionActions: ['Play Timer', 'Finish Set'],
    restSessionActions: '["Finish Rest"]',
    registerRestSessionActions: ['Finish Rest'],
    endSessionActions: '["Done"]',
    registerEndSessionActions: ['Done'],
    commentSessionActions: '["Confirm"]',
    registerCommentSessionActions: ['Confirm']
  }
};
