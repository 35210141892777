import React, { useState } from 'react';
import { connect } from 'react-redux';
import Drawer from 'react-bottom-drawer/dist';
import ClientList from '../ClientList/ClientList';
import './ClientsDrawer.css';
import { Trans } from 'react-i18next';

const ClientsDrawer = (props) => {
  const [isDrawerOpen, setOpenDrawer] = useState(false);

  const closeDrawer = () => {
    setOpenDrawer(false);
  }

  const toggleDrawer = () => {
    setOpenDrawer(!isDrawerOpen);
  }

  const clientClicked = (client) => {
    if (client && client[0] && client[0].public_id) {
      props.history.push(`/feed/${client[0].public_id}`);
    }
  }

  return (
    <div className={`ClientsDrawer layout-row layout-wrap layout-align-start-start ${isDrawerOpen ? ' open' : ''}`}>
      <div className="layout-row layout-wrap layout-align-start-start">
        { props.auth && (
          <label onClick={() => toggleDrawer()} className="OpenMenuButton margin0">
            <i className="la la-users" />
          </label>
        )}
        { props.auth && (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <Drawer
              duration={300}
              hideScrollbars
              onClose={() => closeDrawer()}
              isVisible={isDrawerOpen}
              className="Drawer">
              <div className="flex-100 layout-row layout-wrap layout-align-center-start">
                <label className="flex-initial formBoxInput primary text-uppercase rendered valued paddTop5px paddBottom10px font18">
                  <Trans i18nKey="clientsList" />
                </label>
                <ClientList singleSelect showTrainer clientClicked={client => clientClicked(client)} />
              </div>
            </Drawer>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  auth: state.userR.authenticated,
  clients: state.userR.userInfo.clients
});
export default connect(mapStateToProps)(ClientsDrawer);
