import { actionTypes } from '../actions/actionTypes';

const initialState = {
  eventsList: [],
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EVENTS_LIST:
      return { ...state, eventsList: action.payload };
    case actionTypes.SET_CALENDAR_SETTINGS:
      return { ...state, infoListKV: action.payload };
    default:
      return state;
  }
};

export default calendarReducer;
