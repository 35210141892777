/* eslint-disable react/destructuring-assignment */
import React, {useState} from 'react';
import PhoneInputCmp , { formatPhoneNumberIntl, parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneInput.css';
import { Trans } from 'react-i18next';

const PhoneInput = (props) => {
  const [value, setValue] = useState(props.initValue || null);
  const onChange = (value) => {
    setValue(value)
    let number = null;
    if (props.internationalValue) {
      number = { value, international: value && formatPhoneNumberIntl(value), countryCode: value && parsePhoneNumber(value) }
    } else {
      number = { value }
    }
    if (value && isValidPhoneNumber(value)) {
      console.log('isValidPhoneNumber(value)', isValidPhoneNumber(value))
      props.onChange(number)
    } else {
      props.onChange('')
    }
  }
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  return (
    <div className={`width100 layout-row layout-wrap layout-align-start-start ${ props.className }`}>
      <label id={`${props.name}_label`} className={`formBoxInput primary text-capitalize valued rendered fontWeight600 valued ${props.labelClasses}`} htmlFor={props.name}><Trans i18nKey={props.label || props.name} /></label>
      <PhoneInputCmp
        className="marginBottom5px"
        international={props.international}
        name={props.name}
        id={props.id}
        // flags={flags}
        defaultCountry={props.defaultCountry || 'US'}
        placeholder="Enter phone number"
        countryCallingCodeEditable={props.countryCallingCodeEditable}
        value={value}
        onChange={(value) => {onChange(value)}} />
      {props.comment && (
        <span className={`form-comment ${props.commentClasses}`}><Trans i18nKey={props.comment} /></span>
      )}
    </div>

  )
};

export default PhoneInput;
