/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import Collapse from 'react-css-collapse';
import { refreshPageHeader, createEvent, updateEvent, showAlert2 } from '../../store/actions';
import { addClass, removeClass, utils } from '../../services/utilsService';
import { aspireColorsPicker, mediaType, userDevices, userTypes } from '../../services/enums';
import { uploadSvgIcon } from '../../services/storeDoll';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { langs } from '../../services/translationLangs';
import ColorPicker from '../../Components/UI/ColorPicker';
import Input from '../../Components/UI/Input';
import AddDescriptionToggle from '../../Components/UI/AddDescriptionToggle';
import DateRangeInputs from '../../Components/DatePickers/DateRangeInputs';
import AddRecurringToggle from '../../Components/RecurringEventConfig/AddRecurringToggle';
import ClientList from '../../Components/ClientList/ClientList';
import MediaUploadWithThumbnail from '../../Components/MediaUpload/MediaUploadWithThumbnail';
import BtnLoader from '../../Components/UI/BtnLoader';
import '../ModalPages/CongratulationsPage.css';
import './CreateEvent.css';

class CreateEventPage extends Component {
  constructor(props) {
    super(props);
    const data = this.props.event || {};
    this.state = {
      isIOS: window.userDevice === userDevices.ios,
      public_id: (data && data.public_id) || null,
      title: (data && data.title) || null,
      description: (data && data.description) || null,
      startDate: (data && data.startDate) || moment(),
      endDate: (data && data.endDate) || (data && data.startDate) || moment().add(1, 'minute'),
      sessionStart: (data && data.sessionStart) || null,
      sessionEnd: (data && data.sessionEnd) || null,
      color: (data && data.color) || '#3c4889',
      allDay: (data && data.allDay) || false,
      timezone: (data && data.timezone) || momentTZ.tz.guess(),
      participants: (data && data.participants) || [],
      thumbnail: (data && data.thumbnail) || null,
      recurring: (data && data.recurring) || {},
      advancedOptions: !!(data && this.props.editMode),
      editMode: !!(data && this.props.editMode),
      showRecurringConfig: !!(data && !data.event),
      eventDatesChanged: false,
      calendarSettings: this.props.calendarSettings,
      errors: {
        dateStartMissing: null,
        checkEndDate: null,
        title: null
      },
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.userType === userTypes.CLIENT) {
      const trainerId = this.props.trainerInfo && this.props.trainerInfo.public_id
      if (trainerId && this.props.userCalendarSettings && this.props.userCalendarSettings[trainerId]) {
        this.setState({calendarSettings: this.props.userCalendarSettings[trainerId]});
      }
    }
  }

  closeModal() {
    if (this.props.closeDrawer) this.props.closeDrawer()
  }

  handleChange(e, key) {
    this.state.hasChanged = true;
    if (e && e.target && (e.target.type === 'checkbox' || e.target.type === 'radio')) {
      this.state[key] = e.target.checked;
    } else if (e && e.target) {
      this.state[key] =
        e.target.type === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value;
    } else {
      this.state[key] = e; // value
    }
  }

  handleDates(startDate, endDate) {
    this.state.hasChanged = true;
    this.state.eventDatesChanged = true;
    this.state.startDate = startDate
    if (this.state.allDay) this.state.endDate = null;
    else this.state.endDate = endDate
    this.validateElements();
  }

  checkAllDayEvent(e) {
    this.state.hasChanged = true;
    this.state.allDay = e.target.checked;
    const { startDate } = this.state;
    if (this.state.allDay) {
      if (startDate) {
        const start = moment(startDate).startOf('day');
        const end = moment(startDate).endOf('day');
        this.setState({ startDate: start, endDate: end });
      } else {
        this.setState({ startDate: moment(startDate).startOf('day')});
      }
    } else {
      this.setState({ startDate: null, endDate: null });
    }
  }

  chooseClient(clients) {
    this.state.hasChanged = true;
    this.state.participantsChanged = true;
    this.state.participants = clients;
    if ((clients && clients.length > 0) && this.props.userCalendarSettings && this.props.userCalendarSettings[clients[0].public_id]) {
      this.setState({ calendarSettings: this.props.userCalendarSettings[clients[0].public_id], participantsChanged: true});
    }
  }

  toggleAdvancedOptions() {
    this.setState((prevState) => ({ advancedOptions: !prevState.advancedOptions }));
  }

  onThumbnailUpload(media) {
    this.setState({ thumbnail: media, hasChanged: true });
  }

  clearRecurring() {
    this.state.hasChanged = true;
    this.state.recurring = {};
  }

  setRecurringSettings(settings) {
    this.state.hasChanged = true;
    this.state.recurring = settings;
  }

  validateElements() {
    const { title, startDate, endDate, allDay } = this.state;
    let isValid = true;
    if (!title || title === '' || title.length < 2) {
      this.state.errors.title = langs[this.props.appLang].isRequired(`${langs[this.props.appLang].title}`);
      addClass(`input[label="title"]`, 'invalid-input');
      isValid = false;
    } else this.state.errors.title = null;
    if (allDay && endDate) this.state.endDate = undefined;
    if (!startDate) {
      this.state.errors.startDate = langs[this.props.appLang].isRequired(`${langs[this.props.appLang].startDate}`);
      isValid = false;
    } else this.state.errors.startDate = null;
    if (!allDay && startDate && endDate) {
      const isSameDay = moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD');
      const isBeforeDate = moment(endDate).isBefore(moment(startDate), 'day');
      const isBeforeTime = new Date(endDate).valueOf() <= new Date(startDate).valueOf();
      if ((isSameDay && isBeforeTime) || isBeforeDate) {
        this.state.errors.endDate = 'invalidDate';
        isValid = false;
      } else this.state.errors.endDate = null;
    } else if (!allDay && !(startDate && endDate)) {
      console.log('dates missing');
      this.state.errors.endDate = 'missingDate';
      isValid = false;
    } else this.state.errors.endDate = null;
    this.setState({ ...this.state, startDate: new Date(startDate), endDate: new Date(endDate) });
    return isValid;
  }

  chooseUpdateEventOptions(event) {
    removeClass('.CreateEventPage #BtnLoader.BtnLoader', 'isLoading');
    const data = {
      isOpen: true,
      title: <Trans i18nKey="editRecurringEventSelect" />,
      content: '',
      editEvent: true,
      event,
      className: 'ModalPage',
      id: eventActions.EVENT_UPDATE_OPTIONS,
      onEditDone: () => {
        this.props.refreshEventsList();
        this.props.hideAlert2(data);
      },
    };
    this.props.showAlert2(data);
  }

  async saveChanges() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { editMode, hasChanged, title, color, startDate, endDate, description, allDay, participants,
        recurring, thumbnail, sessionStart, timezone, sessionEnd, eventDatesChanged, participantsChanged } = this.state;
      if (hasChanged && this.validateElements()) {
        const body = { title, color, startDate, endDate, description, allDay, participants, recurring,
          sessionStart, sessionEnd, timezone, eventDatesChanged, participantsChanged };
        body.thumbnail = (thumbnail && thumbnail._id) || null;
        if (editMode) {
          body.eventId = this.state.public_id;
          if (recurring && recurring.repeat) this.chooseUpdateEventOptions(body);
          else {
            await this.props.updateEvent(body);
            this.props.refreshEventsList();
            ReactGA.event({ category: 'Calendar', action: 'Calendar Event Updated' });
          }
          this.isLoading = false;
        } else {
          await this.props.createEvent(body);
          this.props.refreshEventsList();
          ReactGA.event({ category: 'Calendar', action: 'Calendar Event Created' });
          this.isLoading = false;
        }
      } else {
        removeClass('.CreateEventPage #BtnLoader.BtnLoader', 'isLoading');
        this.isLoading = false;
      }
    } catch (e) {
      removeClass('.CreateEventPage #BtnLoader.BtnLoader', 'isLoading');
      this.isLoading = false;
      ReactGA.exception({
        description: 'An error occurred on createEvent() or updateEvent()',
        fatal: true,
      });
    }
  }

  renderClientList() {
    let participants;
    const { clients, user } = this.props;
    if ((clients && clients.length > 0) || user.userType === userTypes.TRAINER || (user.userType === userTypes.CLIENT && this.props.trainerInfo)) {
      const title = user.userType === userTypes.CLIENT && this.props.trainerInfo ? 'chooseTrainer' : 'participants';
      if (this.state.participants && this.state.participants.length > 0) {
        participants = utils.arrayToKeyValue(this.state.participants, 'public_id');
      }
      return (
        <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom5px">
          <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom5px sidePadd15px">
            <p className="margin0 primary fontWeight600 title text-capitalize">
              <Trans i18nKey={title} />
            </p>
          </div>
          <ClientList
            autoChooseTrainer={!this.props.editMode && (this.props.trainerInfo && this.props.trainerInfo.public_id)}
            chosenClients={participants}
            containerClassName="paddEnd15px"
            horizontalBar
            showTrainer={user.userType === userTypes.CLIENT}
            editMode={this.state.editMode}
            clientClicked={(client, isTrainer) => this.chooseClient(client, isTrainer)}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const { title, description, startDate, endDate, allDay, editMode, thumbnail, recurring,
      sessionStart, sessionEnd, showRecurringConfig, calendarSettings, isIOS } = this.state;
    return (
      <div id="createEvent" className="CreateEventPage flex-100 layout-row layout-wrap layout-align-start-start content-start">
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className="flex-100 layout-row layout-wrap layout-align-center-center">
            <div className="flex-100 layout-row layout-wrap layout-align-center-center">
              <div className="flex-100 layout-row layout-align-center-center">
                <lable className="flex-100 fontWeight600 text-uppercase font15 primary titleEvent">
                  <Trans i18nKey={editMode ? 'editAnEvent' : 'bookASession'} />
                </lable>
              </div>
            </div>
            <div className="flex-100 layout-row layout-wrap layout-align-start-start content-start">
              <div className="flex-100 layout-row layout-wrap layout-align-start-start sidePadd15px">
                <Input
                  name="title"
                  error={this.state.errors.title ? 'colorRed' : 'primary'}
                  label={this.state.errors.title || 'title'}
                  placeholder="writeATitle"
                  initValue={title || ''}
                  type="text"
                  elementType="input"
                  validation={{ required: true, minLength: 2 }}
                  classes="flex-100"
                  appLang={this.props.appLang}
                  className="width100 marginBottom10px"
                  handleChange={(event, key) => { this.handleChange(event, key); }}
                />
                <AddDescriptionToggle
                  rows={3}
                  handleChange={(event, key) => { this.handleChange(event, key); }}
                  appLang={this.props.appLang}
                  initValue={description}
                  className="marginBottom10px"
                />
                <div className="flex-100 layout-row layout-wrap layout-align-start-center">
                  <DateRangeInputs
                    calendarSettings={calendarSettings}
                    showDatePicker
                    showTimePicker
                    isIOS={isIOS}
                    timegrid={!isIOS}
                    errors={this.state.errors}
                    appLang={this.props.appLang}
                    initStartDate={sessionStart || startDate}
                    initEndDate={sessionEnd || endDate}
                    showRange={!allDay}
                    min={moment().subtract(1, 'years')}
                    timeMin={(calendarSettings && calendarSettings.timeInput && calendarSettings.timeInput.min) || null}
                    timeMax={(calendarSettings && calendarSettings.timeInput && calendarSettings.timeInput.max) || null}
                    onDatesChange={(startDate, endDate) => { this.handleDates(startDate, endDate); }}
                  />
                </div>
                {!this.state.editMode && (
                  <div className="flex-100 layout-row layout-wrap layout-align-start-center marginBottom5px">
                    <Input
                      noLabel
                      className="marginBottom5px"
                      name="allDay"
                      label="allDay"
                      elementType="checkBox"
                      clickText
                      appLang={this.props.appLang}
                      initValue={allDay}
                      handleChange={(event) => {this.checkAllDayEvent(event);}}>
                      <Trans i18nKey="makeAllDayEvent" />
                    </Input>
                  </div>
                )}
              </div>
              {this.renderClientList()}
              <div className="flex-100 layout-row layout-wrap layout-align-start-start borderGrayTop">
                <button
                  className="flex btn-transparent layout-row layout-wrap layout-align-center-center fontWeight600 text-uppercase font15 padd15px"
                  onClick={() => { this.toggleAdvancedOptions(); }}>
                  <Trans i18nKey="advancedOptions" />
                </button>
              </div>
              <Collapse
                isOpen={this.state.advancedOptions}
                transition="height 250ms cubic-bezier(.4, 0, .2, 1)">
                <div className="flex-100 layout-row layout-wrap layout-align-start-center marginBottom5px sidePadd15px">
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
                    <MediaUploadWithThumbnail
                      costumeTextBtn="uploadFile"
                      folder="Profile"
                      className="borderRadius10"
                      imageHeight={150}
                      iconClass="bgPic"
                      icon={uploadSvgIcon}
                      text="uploadBackgroundImage"
                      media={thumbnail}
                      allowLibraryShow={[mediaType.image]}
                      onMediaUpload={(media) => {
                        this.onThumbnailUpload(media);
                      }}
                    />
                  </div>
                  <ColorPicker
                    label="pickAColor"
                    colors={aspireColorsPicker}
                    className="marginBottom10px"
                    handleChange={(event) => { this.handleChange(event, 'color'); }} />
                  {showRecurringConfig && (
                    <AddRecurringToggle
                      appLang={this.props.appLang}
                      clearRecurring={() => { this.clearRecurring(); }}
                      setRecurringSettings={(settings) => { this.setRecurringSettings(settings); }}
                      className="marginBottom10px"
                      recurring={recurring}
                    />
                  )}
                </div>
              </Collapse>
            </div>
            <div className="flex-100 layout-row layout-wrap layout-align-center-center bottomBtns directionRtl">
              <BtnLoader
                containerClassName="flex layout-row layout-wrap layout-align-center-center alertBtnBg"
                className="flex-100 btn-transparent fontWeight600 text-uppercase font15 padd15px"
                type="button"
                btnText="bookSession"
                btnClicked={() => this.saveChanges()} />
              <button
                type="button"
                className="flex btn-transparent layout-row layout-wrap layout-align-center-center fontWeight600 text-uppercase font15 padd15px"
                onClick={() => this.closeModal()}>
                {' '}
                <Trans i18nKey="cancel" />
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userR.userInfo,
  trainerInfo: state.userR.trainerInfo,
  appLang: state.appR.appLang,
  alertProps: state.appR.alertProps,
  userCalendarSettings: state.userR.userCalendarSettings,
});

const mapDispatchToProps = (dispatch) => ({
  refreshPageHeader: (page) => dispatch(refreshPageHeader(page)),
  createEvent: (data) => dispatch(createEvent(data)),
  updateEvent: (data) => dispatch(updateEvent(data)),
  showAlert2: (data) => dispatch(showAlert2(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventPage);
