import { actionTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse } from './index';


export const setCalendarSettings = (payload) => ({
  type: actionTypes.SET_CALENDAR_SETTINGS,
  payload
});

export const setEventsList = (data, nextPage) => ({
  type: actionTypes.SET_EVENTS_LIST,
  payload: data,
  nextPage
});

// Events
export const getEvents = (queryObj, nextPage) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const list = await apiMethods.calendar.getEventsList(query);
    dispatch(setEventsList(list, nextPage));
    return list;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return [];
  }
};

export const getEventDetails = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.calendar.getEventDetails(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getEventRescheduleDetails = publicId => async (dispatch) => {
  try {
    return await apiMethods.calendar.getEventRescheduleDetails(publicId);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createEvent = body => async (dispatch) => {
  try {
    return await apiMethods.calendar.create(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateEvent = body => async (dispatch) => {
  try {
    return await apiMethods.calendar.updateEvent(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateFollowingEvents = body => async (dispatch) => {
  try {
    return await apiMethods.calendar.updateFollowingEvents(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateAllEvents = body => async (dispatch) => {
  try {
    return await apiMethods.calendar.updateAllEvents(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeEvent = body => async (dispatch) => {
  try {
    await apiMethods.calendar.remove(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeFollowingEvents = body => async (dispatch) => {
  try {
    return await apiMethods.calendar.removeFollowingEvents(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const confirmEvent = body => async (dispatch) => {
  try {
    await apiMethods.calendar.confirm(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const cancelEvent = body => async (dispatch) => {
  try {
    await apiMethods.calendar.cancel(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const rescheduleEvent = body => async (dispatch) => {
  try {
    await apiMethods.calendar.reschedule(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
