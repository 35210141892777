import React, { Component } from 'react';
import './UIStyle.css';

class CircularProgressBar extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    // Size of the enclosing square
    const sqSize = this.props.sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * this.props.percentage / 100;

    return (
      <button className="CircularProgressBar flex-initial layout-row layout-align-center-center" onClick={() => this.props.clickedPlay()}>
        <svg
          viewBox={viewBox}
          id="playable0"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={this.props.sqSize}
          height={this.props.sqSize}
          data-play="playable"
          className={`${this.props.isPlaying ? 'playing' : 'paused'}`}>
          <g className="shape">
            <circle
              className="progress-track"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              stroke="#8594fe"
              strokeOpacity="0.25"
              strokeLinecap="round"
              fill="none"
              strokeWidth={`${this.props.strokeWidth}px`} />
            <circle
              className="progress-bar"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              stroke="#8a99ff"
              strokeOpacity="1"
              strokeLinecap="round"
              fill="none"
              strokeWidth={`${this.props.strokeWidth}px`}
              transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset
              }} />
          </g>
          <circle
            className="controls"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            stroke="none"
            fill="#000000"
            opacity="0.0"
            pointerEvents="all" />
          { this.props.text && (
            <text
              className="circle-text"
              x="50%"
              y="50%"
              dy=".3em"
              textAnchor="middle">
              {this.props.text}
            </text>
          )}
          { this.props.showInnerCircle && (
            <g className="control pause">
              <line
                x1="15"
                y1="14"
                x2="15"
                y2="27"
                stroke="#6b78d4"
                fill="none"
                strokeWidth="4"
                strokeLinecap="round" />
              <line
                x1="25"
                y1="14"
                x2="25"
                y2="27"
                stroke="#6b78d4"
                fill="none"
                strokeWidth="4"
                strokeLinecap="round" />
            </g>
          )}
          { this.props.showInnerCircle && (
            <g className="control play">
              <polygon points="14,11 31,21 14,29" fill="#6b78d4" strokeWidth="1" />
            </g>
          )}
          { this.props.showInnerCircle && (
            <g className="control stop">
              <rect x="14" y="14" width="17" height="17" stroke="#6b78d4" fill="#6b78d4" strokeWidth="1" />
            </g>
          )}
        </svg>
      </button>
    );
  }
}

export default CircularProgressBar;
