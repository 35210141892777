import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import Collapse from 'react-css-collapse';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { langs } from '../../services/translationLangs';
import { loginByEmail, loginByPhone, UserVerifyInvitationCode, verifyUserAndLogin, refreshPageHeader, showAlert, sendVerificationCodeToLogin } from '../../store/actions';
import { addClass, removeClass, securePostMsgUtils, validateEmail } from '../../services/utilsService';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import TranslationChangeUI from '../../Components/UI/TranslationChangeUI';
import userAlertsFuncs from '../../services/userAlertsFuncs';
import Input from '../../Components/UI/Input';
import PhoneInput from '../../Components/PhoneInput/PhoneInput';
import { postMsgTypes } from '../../services/postMsgTypes';
import AspireBtnLoader from '../../Components/UI/AspireBtnLoader';
import CredentialTypeSelector from '../../Components/UI/CredentialTypeSelector';
import PhoneCountryInput from '../../Components/PhoneInput/PhoneCountryInput';
import LoadingDots from '../../Components/UI/LoadingDots';
import verifySms from '../../assets/svg/verify_sms.svg';
import verifyInvitationCode from '../../assets/svg/insert_code_sms.svg';
import './LoginPage.css';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      error: false,
      emailSent: false,
      os: null,
      token: null,
      loginBtnText: 'signin',
      signInByPhone: false,
      isUserInvited: false,
      selectedCountry: null,
      showPhoneInput: true,
      phoneNumber: '',
      errors: {
        countryCodeError: null,
        phoneNumberInvalid: null
      }
    };

    const storeProps = { ...this.props };
    storeProps.refreshPageHeader('login');
    storeProps.disableTopNavPlx(false);
  }

  componentDidMount() {
    const formContainer = document.getElementById('formContainer');
    formContainer.style.minHeight = `${window.outerHeight - 90}px`;
    const LoginPagElm = document.querySelector('.LoginPage');
    LoginPagElm.style.minHeight = `${window.outerHeight}px`;
    LoginPagElm.style.height = `100vh`;
    if (securePostMsgUtils.listenToValidMessage()) {
      window.addEventListener('message', this.receiveTokenMessage, false);
      const intervalId = setInterval(() => {
        if (this.checkForToken()) clearInterval(intervalId);
        else this.getToken()
      }, 1000);
      this.getToken();
    }
  }

  componentWillUnmount() {
    if (securePostMsgUtils.listenToValidMessage()) {
      window.removeEventListener('message', this.receiveTokenMessage);
    }
  }

  handleSelectedCountry(country) {
    let phoneNumber = ''
    if (this.state.phoneNumber && this.state.phoneNumber.length > 6 && this.state.phoneNumber.indexOf('+') === -1 && country && country.dialCode){
      phoneNumber = country.dialCode + this.state.phoneNumber;
    } else phoneNumber = this.state.phoneNumber;
    this.setState({selectedCountry: country, phoneNumber, showPhoneInput: false}, () => {
      this.time = setTimeout(() => {
        this.setState({showPhoneInput: true})
        clearTimeout(this.time)
      }, 150)
      this.props.hideAlert();
    })
  }

  // phone login funcs
  handleModalVerifyPhone() {
    const data = {
      isOpen: true,
      isModal: true,
      id: eventActions.VERIFY_PHONE,
      image: verifySms,
      showTimer: true,
      className: 'PageModal',
      resendSMS: async () => this.sendVerificationCode(true),
      confirmVerificationCode: async (code) => this.verifyAndLogin(code),
    };
    data.buttonRight = {
      text: <i className="la la-arrow-left iconFontSize30 goBackIconColor" />,
      onClick: () => {
        this.props.hideAlert();
      },
    };
    this.isLoading = false;
    this.props.showAlert(data);
  }

  handleModalInviteByTrainer() {
    const title = langs[this.props.appLang].insertTrainerInvite;
    const description = langs[this.props.appLang].insertTrainerInvite_sub;
    const data = {
      isOpen: true,
      isModal: true,
      title,
      description,
      thumbnail: verifyInvitationCode,
      id: eventActions.VERIFY_PHONE,
      className: 'PageModal',
      backgroundColor: 'rgb(254, 236, 245)',
      confirmVerificationCode: async (code) => this.checkInvitationCode(code),
    };
    data.buttonRight = {
      text: <i className="la la-arrow-left iconFontSize30 goBackIconColor" />,
      onClick: () => {
        this.props.hideAlert();
      },
    };
    this.isLoading = false;
    this.props.showAlert(data);
  }

  async checkInvitationCode(code) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { phoneNumber, token } = this.state;
      if (isValidPhoneNumber(phoneNumber) && code && code.length === 5 && this.checkForToken()) {
        const body = { code, phoneNumber, token };
        const data = await this.props.UserVerifyInvitationCode(body);
        ReactGA.event({ category: 'User', action: 'User invite code by SMS confirm' });
        if (data && data.text) {
          // error
          this.setState({
            error: true,
            loginBtnText: data.text && data.text !== '' ? data.text : 'signin',
          });
          this.isLoading = false;
          return { error: data.text && data.text !== '' ? data.text : 'error' };
        } // continue to verify phone
        this.props.hideAlert();
        this.handleModalVerifyPhone();
        this.isLoading = false;
        return { success: 'success' };
      }
      return { error: 'invalidForm' };
    } catch (e) {
      console.log('handle error');
      ReactGA.exception({
        description: 'An error occurred on checkInvitationCode()',
        fatal: true,
      });
      this.isLoading = false;
      return { error: 'error' };
    }
  }

  async verifyAndLogin(code) {
    try {
      const { token, phoneNumber, user } = this.state;
      if (isValidPhoneNumber(phoneNumber) && this.checkForToken()) {
        const body = { token, code, phoneNumber };
        const data = await this.props.verifyUserAndLogin(body);
        ReactGA.event({ category: 'User', action: 'User verified SMS code & login' });
        if (data && data.user && data.user.public_id) {
          this.goToProfilePage(data.user.public_id);
        } else if (data && data.text) {
          this.isLoading = false;
          return { error: data.text && data.text !== '' ? data.text : 'signin' };
        } else {
          this.isLoading = false;
          return {};
        }
      }
    } catch (e) {
      console.log('handle error');
      ReactGA.exception({
        description: 'An error occurred on verifyAndLogin()',
        fatal: true,
      });
      return { error: 'error' };
      this.isLoading = false;
    }
  }

  handlePhoneChange(value) {
    this.state.phoneNumber = value.value;
    this.state.hasChanged = true;
  }

  handleChange(e, key) {
    if (e.target.type === 'checkbox' || e.target.type === 'radio') {
      this.state[key] = e.target.checked;
    } else {
      this.state[key] =
        e.target.type === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value;
    }
  }

  async sendVerificationCode(isResend) {
    try {
      const { phoneNumber } = this.state;
      if (!this.checkForToken() && securePostMsgUtils.listenToValidMessage()) this.getToken();
      if (isValidPhoneNumber(phoneNumber) && this.checkForToken()) {
        const { token } = this.state;
        const body = { token, phoneNumber, language: this.props.appLang };
        const data = await this.props.sendVerificationCodeToLogin(body);
        if (data && !data.sent && data.text) {
          return { error: data.text && data.text !== '' ? data.text : 'signin' };
        }
        ReactGA.event({
          category: 'User',
          action: `${isResend ? 'Resent' : 'Sent'} verification to phone`,
        });
        this.isLoading = false;
        return { success: 'success' };
      }
      this.isLoading = false;
      return { error: 'invalidForm' };
    } catch (e) {
      console.log('handle error');
      ReactGA.exception({
        description: 'An error occurred on sendVerificationCodeToLogin()',
        fatal: true,
      });
      this.isLoading = false;
      return { error: 'error' };
    }
  }

  // token funcs
  checkForToken() {
    if (window && window.aspire && window.aspire.token && window.aspire.token.length > 5) {
      this.state.token = window.aspire.token;
      this.state.os = window.aspire.os || undefined;
      return true;
    }
    return false;
  }

  receiveTokenMessage = (event) => {
    const data = event && event.data ? event.data : null;
    if (!window.aspire) window.aspire = {};
    window.aspire.token = data && data.token;
    window.aspire.os = (data && data.os) || undefined;
    this.state.token = data && data.token;
    this.state.os = data && data.os;
  };

  getToken() {
    window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.GET_GCM_TOKEN }));
  }

  goToProfilePage(publicId) {
    if (this.state.isUserInvited) {
      // invited client by phone
      this.props.history.push('/welcome/client');
    } else this.props.history.push(`/feed/${publicId}`);
  }

  // email login funcs
  goToUpdatePasswordPage() {
    this.props.history.push(`/welcome/updatePassword`);
  }

  async sendVerificationMail() {
    if (this.isLoading) return;
    this.isLoading = true;
    if (this.state.email && validateEmail(this.state.email)) {
      await userAlertsFuncs.sendWelcomeMailAgain({
        email: this.state.email,
        language: this.props.appLang,
      });
      this.sending = setTimeout(() => {
        this.setState({ emailSent: true });
        this.isLoading = false;
      }, 3000);
      return { success: 'emailSent' };
    } else {
      this.isLoading = false;
      return { error: 'invalidEmail' };
    }
  }

  signinBy(isPhone) {
    if (!this.checkForToken() && securePostMsgUtils.listenToValidMessage()) this.getToken();
    this.setState({ signInByPhone: isPhone });
  }

  validateElements() {
    if (this.isValidating) return;
    this.isValidating = true;
    const errors = [];
    let isObjDetailsValid = true;
    const { signInByPhone, phoneNumber, email, password } = this.state;
    if (signInByPhone && (!phoneNumber || !isValidPhoneNumber(phoneNumber || ''))) {
      errors.push('invalidPhoneNumber');
      isObjDetailsValid = false;
      addClass('.PhoneInput', 'invalid-input');
    } else removeClass('.PhoneInput', 'invalid-input');
    if (!signInByPhone && !email) {
      errors.push('invalidEmail');
      isObjDetailsValid = false;
    }
    if (!signInByPhone && (!password || password === "") ) {
      errors.push('wrongPassword');
      isObjDetailsValid = false;
    }
    this.isValidating = false;
    this.state.formIsValid = isObjDetailsValid;
    if (!this.state.formIsValid) {
      this.isLoading = false;
      return { error: errors[0] || 'invalidForm' };
    }
    return null;
  }

  async submitLogin(event) {
    if (event) event.preventDefault();
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const error = this.validateElements();
    if (error) return error;
    if (this.state.formIsValid) {
      const { signInByPhone, phoneNumber, email } = this.state;
      if (signInByPhone && phoneNumber && isValidPhoneNumber(phoneNumber) && this.checkForToken()) {
        // login by phone
        const { token } = this.state;
        const body = { token, phoneNumber, language: this.props.appLang };
        return await this.loginByPhone(body);
      } else if (!signInByPhone && email) {
        // login by email
        const { password, token, os } = this.state;
        const body = { email: email.toLowerCase(), password, token, os };
        return await this.loginByEmail(body);
      } else {
        this.isLoading = false;
        return { error: 'invalidForm' };
      }
    }
    this.isLoading = false;
    return { error: 'invalidForm' };
  }

  async loginByPhone(body) {
    try {
      const data = await this.props.loginByPhone(body);
      if (data && (data.isUserInvited || data.resendInvitation)) {
        // on client first entry
        this.state.isUserInvited = true;
        this.handleModalInviteByTrainer();
        this.isLoading = false;
      } else if (data && data.text) {
        // error
        this.isLoading = false;
        return { error: data.text && data.text !== '' ? data.text : 'signin' };
      } else {
        // continue to verify phone
        this.handleModalVerifyPhone();
        this.isLoading = false;
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      return { error: 'signin' };
    }
  }

  async loginByEmail(body) {
    try {
      const data = await this.props.loginByEmail(body);
      if (data && data.isNewClient) {
        // on client first entry
        this.goToUpdatePasswordPage(data.user.public_id);
      } else if (data && data.user && data.user.public_id) {
        this.goToProfilePage(data.user.public_id);
      } else if (data && data.text) {
        this.isLoading = false;
        if (data && data.text === 'emailNotVerified') this.setState({ loginBtnText: data && data.text });
        return { error: data.text && data.text !== '' ? data.text : 'signin' };
      } else {
        this.isLoading = false;
        return { error: 'signin' };
      }
    } catch (e) {
      this.isLoading = false;
      return { error: 'error' };
    }
  }

  render() {
    const { signInByPhone, errors, showPhoneInput, selectedCountry } = this.state;
    return (
      <div id="LoginPage"
        className="LoginPage flex-100 layout-column layout-align-start-center fullSizeWindow positionRelative">
        <div className="width100 positionAbsoluteTopLeft layout-row layout-align-start-start directionChangeRtl paddTop10px">
          <TranslationChangeUI isBtns />
        </div>
        <div
          id="formContainer"
          className="flex width100 layout-row layout-wrap layout-align-start-start sidePadd15px content-center form-container">
          <div className="flex-100 layout-row layout-wrap layout-align-center-center">
            <div className="flex-initial layout-column text-center layout-align-center-center ">
              <img src="https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/iconSmall_civksq.png" className="aspireIcon" />
              <p className="font24 marginBottom15px text-uppercase fontWeight600">Aspire</p>
            </div>
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <form
              name="loginForm"
              /* onSubmit={(event) => { this.submitLogin(event); }}*/
              className="flex-100 layout-row layout-wrap layout-align-start-start sidePadd5px formContainer ">
              <CredentialTypeSelector
                className="marginBottom10px CredentialTypeSelector"
                title="signinBy"
                onChange={(value) => this.signinBy(value)}
              />
              <Collapse
                isOpen={signInByPhone}
                className="width100"
                transition="height 250ms cubic-bezier(.4, 0, .2, 1)">
                <div className="flex-100 layout-row layout-wrap layout-align-center-start">
                  <div className="flex-100 layout-row layout-wrap layout-align-center-start marginBottom10px">
                    <PhoneCountryInput handleSelectedCountry={(country) => this.handleSelectedCountry(country)}
                                       errorText={errors.countryCodeError} />
                  </div>
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom20px">
                    { showPhoneInput ? (
                      <Fragment>
                        <PhoneInput
                          isRequired
                          id="phone"
                          name="phone_number"
                          defaultCountry={selectedCountry && selectedCountry.countryCode ? (selectedCountry.countryCode) : (this.props.appLang === 'he' ? 'IL' : 'US')}
                          label="phone"
                          internationalValue
                          countryCallingCodeEditable
                          international
                          initValue={this.state.phoneNumber}
                          onChange={(value) => this.handlePhoneChange(value)}
                        />
                        { errors.phoneNumberInvalid && (
                          <div className="flex-100 layout-row layout-wrap layout-align-start-start paddTop5px">
                            <label className="colorRed text-capitalize fontWeight600 margin0">
                              <Trans i18nKey={this.state.errors.phoneNumberInvalid} />
                            </label>
                          </div>
                        )}
                      </Fragment>
                    ) : (
                      <div className="flex-100 layout-row layout-wrap layout-align-center-center marginBottom10px">
                        <LoadingDots active />
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
              <Collapse
                isOpen={!signInByPhone}
                className="width100"
                transition="height 250ms cubic-bezier(.4, 0, .2, 1)"
              >
                <div className="flex-100 layout-row layout-wrap layout-align-center-start">
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
                    <Input
                      name="email"
                      placeholder="email"
                      type="email"
                      required
                      appLang={this.props.appLang}
                      className="width100 flex-100"
                      classes="flex-100"
                      handleChange={(event, key) => {
                        this.handleChange(event, key);
                      }}
                    />
                  </div>
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                    <Input
                      name="password"
                      label="passwordOrInvite"
                      placeholder="password"
                      type="password"
                      validation={{ minLength: 8 }}
                      required
                      appLang={this.props.appLang}
                      className="width100 flex-100"
                      classes="flex-100"
                      handleChange={(event, key) => {
                        this.handleChange(event, key);
                      }}
                    />
                    <div className="flex-100 layout-row layout-wrap layout-align-start-start paddTop10px">
                      <div className="flex-100 layout-row layout-wrap layout-align-end-start">
                        <Link
                          to="/forgotPassword"
                          className="flex-initial layout-row layout-wrap layout-align-end-start"
                        >
                          <p className="flex-initial text-nowrap">
                            <Trans i18nKey="forgotPassword" />
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
              <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                <div className="flex-100 layout-row layout-wrap layout-align-center-center padd10px">
                  <AspireBtnLoader
                    btnText="signin"
                    containerClassName="flex-100 layout-row layout-wrap layout-align-center-center"
                    btnBaseClass="flex-50 btn-aspire-primary borderRadius5"
                    classNameText="fontWeight600 text-capitalize"
                    btnClicked={async () => this.submitLogin()}
                  />
                </div>
              </div>
              <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                {this.state.loginBtnText === 'emailNotVerified' ? (
                  <div className="flex-100 layout-row layout-wrap layout-align-center-center paddTop20px text-center">
                    <p className="font15 fontWeight600 width100">
                      <Trans i18nKey="cantFindVerificationMail" />
                    </p>
                    <p className="font13 text-muted fontWeight400 width100">
                      <Trans i18nKey="cantFindVerificationMail_comment" />
                    </p>
                    {this.state.emailSent ? (
                      <p className="aspire-text fontWeight600 width100">
                        <Trans i18nKey="verificationSent" />
                      </p>
                    ) : (
                      <div className="flex-100 layout-row layout-wrap layout-align-center-center text-center">
                        <AspireBtnLoader
                          btnText="sendAgain"
                          containerClassName="width100"
                          btnBaseClass="btn-transparent width100 layout-row layout-wrap padd5px aspire-text font15 fontWeight600"
                          classNameText="fontWeight600 text-capitalize aspire-text"
                          btnClicked={() => this.sendVerificationMail()}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex-100 layout-row layout-wrap layout-align-center-center paddTop20px text-center">
                    <p className="font15 fontWeight600">
                      <Trans i18nKey="dontHaveAnAccount" />
                      <Link
                        to="/signUp"
                        className="flex-100 layout-row layout-wrap layout-align-center-center padd5px primary"
                      >
                        <span className="aspire-text">
                          <Trans i18nKey="signup" />
                        </span>
                      </Link>
                    </p>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userR.userInfo,
  error: state.userR.response,
  appLang: state.appR.appLang,
});

const mapDispatchToProps = (dispatch) => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  clearError: () => dispatch({ type: actionTypes.CLEAR_ERROR }),
  showAlert: (data) => dispatch(showAlert(data)),
  loginByPhone: (body) => dispatch(loginByPhone(body)),
  loginByEmail: (res) => dispatch(loginByEmail(res)),
  UserVerifyInvitationCode: (body) => dispatch(UserVerifyInvitationCode(body)),
  verifyUserAndLogin: (body) => dispatch(verifyUserAndLogin(body)),
  sendVerificationCodeToLogin: (body) => dispatch(sendVerificationCodeToLogin(body)),
  refreshPageHeader: (page) => dispatch(refreshPageHeader(page)),
  disableTopNavPlx: (isDisable) => dispatch({ type: actionTypes.DISABLE_TOP_NAV_PLX, payload: isDisable }),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
