/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { getExerciseDetails, refreshPageHeader, showAlert } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import { securePostMsgUtils } from '../../services/utilsService';
import BodyMap from '../../Components/BodyMap/BodyMap';
import FilterStripe from '../../Components/Filter/FilterStripe';
import MusclesInvolved from '../../Components/UI/MusclesInvolved';
import VideoPlayer from '../../Components/VideoPlayer/VideoPlayer';
import { postMsgTypes } from '../../services/postMsgTypes';
import NoteUI from '../../Components/UI/NotesUI';
import SwiperCarousel from '../../Components/SwiperCarousel/SwiperCarousel';
import MediaDisplayUI from '../../Components/UI/MediaDisplayUI';
import { mediaType } from '../../services/enums';
import './ExercisePage.css';

class ExerciseDetailsPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      exercise: {},
      exercises: [],
      bodyMapMuscleIds: [],
      isMusclesInvolvedOpen: false,
      isMultiExercises: false,
      videosByEquipment: {},
      chosenEquipment: null,
      isPageReady: false,
      isLandscape: false,
      sliderNum: 0,
      carouselRef: null,
      carouselOptions: {
        autoHeight: true,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      },
      sectionsProps: ['description', 'preparation', 'execution'],
      skeleton: [
        { margin: 'marginBottom20px', class: 'titlePlaceHolder' }, { margin: 'marginBottom10px', class: 'paragraphPlaceHolder' }, { margin: 'marginBottom10px', class: 'paragraphPlaceHolder' }, { margin: 'marginBottom10px', class: 'paragraphPlaceHolder' }, { margin: 'marginBottom10px', class: 'paragraphPlaceHolder' }, { margin: 'marginBottom20px', class: 'paragraphEndPlaceHolder' },
        { margin: 'marginBottom20px', class: 'titlePlaceHolder' }, { margin: 'marginBottom10px', class: 'paragraphPlaceHolder' }, { margin: 'marginBottom10px', class: 'paragraphPlaceHolder' }, { margin: 'marginBottom10px', class: 'paragraphPlaceHolder' }, { margin: 'marginBottom10px', class: 'paragraphPlaceHolder' }, { margin: 'marginBottom20px', class: 'paragraphEndPlaceHolder' }
      ]
    };
  }

  async componentDidMount () {
    try {
      const exerciseIds = this.handleExercisesIds();
      const exercises = await this.props.getExerciseDetails(exerciseIds);
      if (exercises && exercises.details.length > 0) {
        this.state.exercises = exercises.details;
        this.state.exercise = exercises.details[0];
        this.configurePageByExercises(exercises);
        this.setState({ ...this.state, isPageReady: true });
        this.renderChangeSection(0);
      } else {
        this.closeModal();
      }
    } catch (e) {
      console.log('handle error');
    }
  }

  onSlideChange (value) {
    this.renderChangeSection(value);
  }

  getCarouselRef (carousel) {
    this.state.carouselRef = carousel;
  }

  handleExercisesIds () {
    let exerciseIds = [];
    if (this.props.alert2Props.isModal) {
      if (Array.isArray(this.props.alert2Props.exercises)) {
        exerciseIds = this.props.alert2Props.exercises.map(exercise => exercise.public_id);
      } else {
        exerciseIds.push(this.props.alert2Props.exercises.public_id);
      }
    } else if (this.props.match && this.props.match.params && this.props.match.params.public_id) {
      exerciseIds.push(this.props.match.params.public_id);
    }
    return exerciseIds;
  }

  handleVideoByEquipment () {
    const { exercise } = this.state;
    this.state.defaultVideoLink = exercise.defaultEquipment && exercise.defaultEquipment.media ? exercise.defaultEquipment.media : null;
    this.state.exercise.equipments.forEach((equipment) => {
      if (!this.state.media && (equipment && equipment.media && equipment.media[0])) this.state.media = equipment.media[0];
      this.state.videosByEquipment[equipment.equipment] = (equipment.media && equipment.media[0]) || '';
    });
    if (Array.isArray(this.props.alert2Props.exercises) && this.props.alert2Props.exercises[0].chosenEquipment) {
      this.state.chosenEquipment = this.props.alert2Props.exercises[0].chosenEquipment.equipment;
    } else if (this.props.alert2Props.exercises && this.props.alert2Props.exercises.chosenEquipment) {
      this.state.chosenEquipment = this.props.alert2Props.exercises.chosenEquipment.equipment;
    }
    this.state.media = (this.state.chosenEquipment && this.state.videosByEquipment[this.state.chosenEquipment]) || this.state.defaultVideoLink;
  }

  configurePageByExercises (exercises) {
    if (exercises && exercises.length > 0) {
      this.state.isMultiExercises = true;
    }
    if (this.state.exercise && this.state.exercise.muscles && Object.keys(this.state.exercise.muscles).length > 0) {
      this.handleMuscleClasses(this.state.exercise.muscles);
    }
    // setting videos List
    this.handleVideoByEquipment();
  }

  closeModal () {
    this.props.hideAlert2();
  }

  // body map functions
  handleMuscleClasses (muscles) {
    let array = [],
      array2 = [],
      result = [];
    if (muscles.target.length > 0) {
      array = muscles.target.map(option => (option.muscleId));
      result = array;
    }
    if (muscles.synergist.length > 0) {
      array2 = muscles.synergist.map(option => (`${option.muscleId}_2`));
      result = array.concat(array2);
    }
    this.setState({ bodyMapMuscleIds: result });
  }

  handleOrientation (isPortrait) {
    if (this.isLoading) return;
    this.isLoading = true;
    if (securePostMsgUtils.listenToValidMessage()) {
      if (this.state.isLandscape) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.LOCK_ORIENTATION_PORTRAIT }));
      } else if (!isPortrait) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.LOCK_ORIENTATION_LANDSCAPE }));
      }
    }
    this.setState(prevState => ({ isLandscape: !prevState.isLandscape }));
    this.isLoading = false;
  }

  renderExerciseEquipmentInfo (data) {
    if (this.state.videosByEquipment && this.state.videosByEquipment[data.publicIds[0]]) {
      this.state.media = this.state.videosByEquipment[data.publicIds[0]];
    } else return;
    this.setState({ ...this.state });
  }

  renderNavTopForModal (exercise) {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        <div className="content-header flex-100 layout-row layout-wrap layout-align-space-between-end">
          <div className="flex-100 layout-row layout-align-space-between-center topNav height50px">
            <div className="flex-initial sidePadd5px">
              { this.props.alert2Props.buttonRight && this.props.alert2Props.buttonRight.text && (
                <button
                  type="button"
                  className="flex-initial textIcon btn-transparent"
                  onClick={() => this.closeModal()}>
                  { this.props.alert2Props.buttonRight.text }
                </button>
              )}
            </div>
            <div className={`flex layout-row layout-align-start-center NavTop-label-direction ${this.props.isModalPage && !this.props.alert2Props.buttonRight ? 'sidePadd15px' : ''}`}>
              { exercise && exercise.title && (
                <label className="primary text-capitalize font20 fontWeight600 margin0 fadeInLeft-anime textLimitWidth">{exercise.title[this.props.appLang] || exercise.title.en }</label>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChangeSection (slideNum) {
    this.state.sliderNum = slideNum;
    this.setState({ ...this.state });
  }

  render () {
    const { bodyMapMuscleIds, exercise } = this.state;
    const { exercises } = this.props.alert2Props;
    const programExerciseNotes = exercises && exercises.notes && exercises.notes.length > 0 ? exercises.notes : [];
    const exerciseBasicNotes = exercise && exercise.notes && exercise.notes.length > 0 ? exercise.notes : [];
    const notes = [...exerciseBasicNotes , ...programExerciseNotes ]
    const modalNavTop = this.renderNavTopForModal(exercise);
    return (
      <div className="ExerciseDetailsPage flex-100 layout-row layout-wrap layout-align-start-start fullSizeWindow content-start ">
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          { this.props.isModalPage && modalNavTop }
        </div>
        { this.state.isPageReady ? (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start fadeIn-anime">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start">
              <div className="SliderContainer flex-100 layout-row layout-wrap layout-align-center-start padd15px">
                <SwiperCarousel
                  id="ExerciseDetailsSwiper"
                  swiperIndex={0}
                  options={this.state.carouselOptions}
                  pagination
                  className="ExerciseDetailsClass width100"
                  carouselRef={carousel => this.getCarouselRef(carousel)}
                  onSlideChange={(value, isEnd) => this.onSlideChange(value, isEnd)}>
                  { this.state.media && this.state.media.source ? (
                    <div className="flex-100 layout-row swiper-slide layout-wrap layout-align-center-center paddBottom30px">
                      <MediaDisplayUI
                        media={[{ media: this.state.media }]}
                        width={window.innerWidth}
                        shouldUpdate
                        appLang={this.props.appLang}
                        isImage={this.state.media.type === mediaType.image}
                        videoPoster={this.state.media.thumbnail}
                        fullScreenBtn
                        playPauseBtn
                        isAutoPlay
                        height={210}
                        showThumbnailLoader
                        handleOrientation={(isPortrait) => { this.handleOrientation(isPortrait); }}
                      />
                    </div>
                  ) : null }
                  <div id="BodyMapSection" className="flex-100 swiper-slide layout-row layout-wrap layout-align-center-center paddBottom20px">
                    <div className="flex-100 layout-row layout-wrap layout-align-center-center ">
                      { exercise && (
                        <BodyMap chosenMuscle={bodyMapMuscleIds} classPosition={(exercise.thumbnail && exercise.thumbnail.classPosition) ? exercise.thumbnail.classPosition : ''} />
                      )}
                    </div>
                  </div>
                </SwiperCarousel>
              </div>
              <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
                { this.state.sliderNum === 1 && this.state.exercise && (
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start sidePadd15px">
                    <MusclesInvolved exercise={this.state.exercise} />
                  </div>
                )}
                { this.state.sliderNum === 0 && exercise.equipments && exercise.equipments.length > 0 && (
                  <div id="filterStripeContainer" className="flex-100 layout-row layout-wrap layout-align-start-start">
                    <FilterStripe byProp="equipment"
                      filterList={exercise.equipments}
                      classNameOverflow="sidePadd15px"
                      singleSelect
                      isOverflow
                      onFilterChange={(data) => { this.renderExerciseEquipmentInfo(data); }} />
                  </div>
                )}
              </div>
              <div className="flex-100 layout-row layout-wrap layout-align-start-start sidePadd15px fadeInBottom-anime">
                { notes && (
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
                    <NoteUI name="notes" title="exerciseNotes" notes={notes} />
                  </div>
                )}
                { exercise && this.state.sectionsProps.map((section) => {
                  if ((exercise[section] && ((exercise[section][this.props.appLang] && exercise[section][this.props.appLang] !== '') || (exercise[section].en && exercise[section].en !== ''))) || (Array.isArray(exercise[section]) && exercise[section].length > 0)) {
                    return (
                      <div key={section} className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
                        <div className="flex-100 layout-row layout-wrap layout-align-space-between-center marginBottom10px">
                          <p className="text-uppercase primary fontWeight600 font16"><Trans i18nKey={section} /></p>
                          {/* <button type="button"
                            className="flex-initial layout-row layout-align-start-start btn-transparent">
                            <p className="flex-initial colorGrey margin0 font15 sidePadd2px text-capitalize"><Trans i18nKey="showMore" /></p>
                          </button> */}
                        </div>
                        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                          <p> {exercise[section][this.props.appLang] || exercise[section].en}</p>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
            { this.state.isLandscape && (
              <div id="videoFullScreen" className="flex-100 layout-row layout-wrap layout-align-center-center" style={{ height: `${window.innerHeight}px` }}>
                <VideoPlayer
                  id="VideoPlayerFS"
                  height={window.innerWidth}
                  width={window.innerHeight}
                  paddingControls={40}
                  isAutoPlay
                  media={this.state.media}
                  isLandscape
                  playPauseBtn
                  fullScreenBtn
                  appLang={this.props.appLang}
                  handleOrientation={(isPortrait) => { this.handleOrientation(isPortrait); }} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start exSkeleton skeletonPage placeHolderContainer">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom30px">
              <div className="carousel loading positionRelative" />
            </div>
            <div className="flex-100 layout-row layout-wrap layout-align-start-start sidePadd15px">
              <div className="flex-100 layout-row layout-align-start-center height45px overFlowHidden marginBottom10px">
                { this.state.skeleton && this.state.skeleton.map((num, index) => (
                  <div key={`key_${index}`} className="flex-initial layout-row layout-wrap layout-align-start-center transition03">
                    <label className="propsName borderRadius3 sidePadd2px" style={{ width: `95px` }}>
                      <span className="flex-initial layout-row layout-wrap layout-align-start-start filterPlaceHolder positionRelative loading" />
                    </label>
                  </div>
                ))}
              </div>
              { this.state.skeleton && this.state.skeleton.map((obj, index) => (
                <label key={`key_${obj.class}_${index}`} className={`flex-100 layout-row layout-wrap layout-align-start-start ${obj.margin} borderRadius3`}>
                  <span className={`flex-initial layout-row layout-wrap layout-align-start-start ${obj.class} positionRelative loading`} />
                </label>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  exerciseDetails: state.exerciseR.exerciseDetails,
  appLang: state.appR.appLang,
  alert2Props: state.appR.alert2Props
});

const mapDispatchToProps = dispatch => ({
  refreshPageHeader: page => dispatch(refreshPageHeader(page)),
  getExerciseDetails: publicId => dispatch(getExerciseDetails(publicId)),
  showAlert: data => dispatch(showAlert(data)),
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 })
});

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseDetailsPage);
