import { actionTypes } from '../store/actions/actionTypes';

export const TRAINER = {
  login: { showTopNav: false },
  transparent: {
    showTopNav: true,
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: null },
    },
  },
  profile: {
    showTopNav: true,
    config: {
      tutorial: { key: 'profile_tutorial', storage: 'profile', autoPlayTutorial: true },
      navClass: 'transparent profilePage',
      clientsIcon: true,
      menuIcon: true,
      // activitiesIcon: true, no need for the page for now
      parallax: 'opacity',
      showTitle: true,
      startValue: '30vh',
      endValue: '35vh',
      bgColor: {
        start: 'rgba(23, 22, 54, 0)',
        end: 'rgba(23, 22, 54, 1)',
      },
    },
  },
  inviteClient: {
    showTopNav: true,
    title: 'inviteClient',
    subtitle: `fill clients details`,
    config: {
      menuIcon: true,
      parallax: 'default',
      // tutorial: { show: true, key: 'inviteClient_tutorial', storage: 'inviteClient', autoPlayTutorial: true },
    },
  },
  workouts: {
    showTopNav: true,
    title: 'workouts',
    subtitle: 'workoutsArchive',
    config: {
      menuIcon: true,
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      menuExpand: { show: true, plusIcon: false, onlyTrainer: true },
      menuExpandLinks: [
        {
          show: true,
          text: 'createAProgram',
          key: actionTypes.GO_TO_PAGE,
          url: '/workouts/create/details',
        },
        {
          show: true,
          text: 'myExercises',
          key: actionTypes.GO_TO_PAGE,
          url: '/myExercises',
          // permission: 'programs.myExercises'
        },
      ],
    },
  },
  workoutPlan: {
    showTopNav: true,
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: '/workouts' },
      parallax: 'opacity',
      showTitle: true,
    },
  },
  workoutProgram: {
    showTopNav: true,
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: null }, // '/workouts'
      parallax: 'opacity',
      showTitle: true,
    },
  },
  workoutProgress: {
    showTopNav: true,
    title: 'modifyProgress',
    subtitle: 'modifyProgress_sub',
    config: {
      // goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutSession: {
    showTopNav: true,
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: null },
      parallax: 'opacity',
      showTitle: true,
    },
  },
  workoutsArchive: {
    showTopNav: true,
    title: 'workoutsArchive_2',
    config: {
      goBackIcon: { show: true, link: null },
      clientList: { show: false, text: 'clientsList' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutPlanArchive: {
    showTopNav: true,
    title: 'workoutPlanArchive',
    config: {
      goBackIcon: { show: true, link: null },
      clientList: { show: false, text: 'clientsList' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutSessionArchive: {
    showTopNav: true,
    title: 'sessionsArchive',
    config: {
      goBackIcon: { show: true, link: null },
      clientList: { show: false, text: 'clientsList' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  // create workouts
  createWorkout: {
    showTopNav: true,
    title: 'createWorkout',
    subtitle: 'createWorkout_sub',
    config: {
      goBackIcon: { show: true, link: null },
      // onBoarding: { show: true, key: 'createWorkout' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutList: {
    showTopNav: true,
    title: 'chooseExercises',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, funcName: 'handleGoBackByStore' },
      onBoarding: { show: true, key: 'createWorkout', isTutorial: 'createWorkout_tutorial2' },
      filter: { show: true, filterIcon: true, search: true },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutLogger: {
    showTopNav: true,
    title: 'workoutLoggerHeader',
    subtitle: 'workoutLoggerHeader_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null, isDisabled: true },
      onBoarding: { show: true, key: 'createWorkout', isTutorial: 'createWorkout_tutorial3' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  sessionLogger: {
    showTopNav: true,
    title: 'workoutLoggerHeader',
    subtitle: 'workoutLoggerHeader_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  routineUpdateDetails: {
    showTopNav: true,
    title: 'routineUpdateDetails',
    subtitle: 'routineUpdateDetails_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null},
      parallax: 'default',
      showTitle: true,
      opacityText: true
    },
  },
  // create workoutPlan
  createPlan: {
    showTopNav: true,
    title: 'createPlan',
    subtitle: 'createPlan_sub',
    config: {
      goBackIcon: { show: true, link: null },
      parallax: 'default',
    },
  },
  menusList: {
    showTopNav: true,
    title: 'addPrograms',
    subtitle: 'Select Programs to Add',
    config: {
      goBackIcon: { show: true, link: null },
      parallax: 'default',
    },
  },
  // create Session
  startSession: {
    showTopNav: true,
    title: 'sessionStart',
    subtitle: 'Session Started At',
    config: { navClass: 'transparent', menuIcon: true, textIcon: { show: true, text: 'skip' } },
  },
  sessionSummary: { showTopNav: false },
  createPastSession: {
    showTopNav: true,
    title: 'createPastSession',
    subtitle: 'workoutLoggerHeader_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },

  statistics: {
    showTopNav: true,
    title: 'statistics',
    config: {
      menuIcon: true,
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      // plusIcon: { show: true, url: '/statistics/bodyStats/create' }
    },
  },
  bodyStats: {
    showTopNav: true,
    title: 'bodyStats',
    subtitle: 'bodyStats_sub',
    config: {
      menuIcon: true,
      clientList: { show: true, text: 'clientsList' },
      tutorial: { key: 'statistics_tutorial', storage: 'statistics', autoPlayTutorial: true },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      // plusIcon: { show: true, url: '/statistics/bodyStats/create' }
    },
  },
  bodyStatsDetails: {
    showTopNav: true,
    title: 'bodyStatsDetails',
    subtitle: 'bodyStatsDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      insertSubtitle: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  bodyStatsInfo: {
    showTopNav: true,
    title: 'loading',
    subtitle: 'loading',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      insertSubtitle: true,
      skipChosenClient: true,
      opacityText: true,
    },
  },
  createBodyStats: {
    showTopNav: true,
    title: 'createBodyStats',
    subtitle: 'UpdateYourCurrentBodyStats',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  updateBodyStats: {
    showTopNav: true,
    title: 'updateBodyStats',
    subtitle: 'UpdateYourCurrentBodyStats',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  performance: {
    showTopNav: true,
    title: 'performance',
    subtitle: 'performance_sub',
    config: {
      menuIcon: true,
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  performanceList: {
    showTopNav: true,
    title: 'performance',
    subtitle: 'performance_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  performanceDetails: {
    showTopNav: true,
    title: 'performanceDetails',
    subtitle: 'performanceDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      insertClientsName: true,
      insertSubtitle: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  // achievements
  activeAchievements: {
    showTopNav: true,
    title: 'activeAchievements',
    config: {
      menuIcon: true,
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  completedAchievements: {
    showTopNav: true,
    title: 'completedAchievements',
    config: {
      menuIcon: true,
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  achievementDetails: {
    showTopNav: true,
    title: 'achievementDetails',
    subtitle: 'achievementDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      insertSubtitle: true,
    },
  },
  chooseGoal: {
    showTopNav: true,
    title: 'chooseGoal',
    subtitle: 'chooseGoalSub',
    config: {
      goBackIcon: { show: true, link: null },
      onBoarding: { show: true, key: 'createGoal' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  goalDetails: {
    showTopNav: true,
    title: 'goalDetails',
    subtitle: 'goalDetailsSub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  activities: {
    showTopNav: true,
    title: 'myActivities',
    subtitle: 'myActivities_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  // settings
  settings: {
    showTopNav: true,
    title: 'settings',
    subtitle: 'applicationSettings',
    config: { menuIcon: true },
  },
  myClientsList: {
    showTopNav: true,
    title: 'myClientsList',
    subtitle: 'myClientsList_sub',
    config: {
      goBackIcon: { show: true, link: null },
    },
  },
  subscription: {
    showTopNav: true,
    title: 'subscription',
    subtitle: 'subscription_sub',
    config: {
      goBackIcon: { show: true, link: '/settings' },
    },
  },
  updateUserCredentials: {
    showTopNav: true,
    title: 'updateUserCredentials',
    subtitle: 'updateUserCredentials_sub',
    config: {
      goBackIcon: { show: true, link: null },
    },
  },
  profileDetails: {
    showTopNav: true,
    title: 'profileDetails',
    subtitle: 'profileDetails',
    config: {
      goBackIcon: { show: true, link: null },
      tutorial: {
        key: 'profileDetails_tutorial',
        storage: 'profileDetails',
        autoPlayTutorial: true,
      },
    },
  },
  // my exercises
  exercise: {
    showTopNav: true,
    title: 'exercise',
    subtitle: 'Exercise archive',
    config: {
      menuIcon: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  myExercises: {
    showTopNav: true,
    title: 'myExercises',
    subtitle: 'myExercises_sub',
    config: {
      goBackIcon: { show: true, link: '/workouts' },
      // plusIcon: { show: true, url: '/myExercises/create' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  createExercise: {
    showTopNav: true,
    title: 'createExercise',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  // help center
  createInquiry: {
    showTopNav: true,
    title: 'createInquiry',
    subtitle: 'createInquiry_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  createSolution: {
    showTopNav: true,
    title: 'createSolution',
    subtitle: 'createSolution_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  replayInquiry: {
    showTopNav: true,
    title: 'replayInquiry',
    subtitle: 'replayInquiry_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  info: {
    showTopNav: true,
    title: 'information',
    // subtitle: 'inquiriesAnswers_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      menuIcon: true,
      filter: { show: true, filterIcon: false, search: true },
      // plusIcon: { show: true, url: '/helpCenter/inquiry/create' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  infoDetails: {
    showTopNav: true,
    title: 'infoDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      insertSubtitle: true,
    },
  },
  // documents
  documents: {
    showTopNav: true,
    title: 'documents',
    subtitle: 'documentArchive',
    config: {
      menuIcon: true,
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      menuExpand: { show: true, plusIcon: true, onlyTrainer: true },
      menuExpandLinks: [
        {
          show: true,
          text: 'createDocument',
          key: actionTypes.GO_TO_PAGE,
          url: '/paperwork/document/create',
          permission: 'paperwork.actions.create'
        },
        {
          show: true,
          text: 'createSurvey',
          key: actionTypes.GO_TO_PAGE,
          url: '/paperwork/survey/create',
          permission: 'paperwork.actions.create'
        },
      ],
    },
  },
  surveys: {
    showTopNav: true,
    title: 'surveys',
    subtitle: 'surveyArchive',
    config: {
      menuIcon: true,
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      menuExpand: { show: true, plusIcon: true, onlyTrainer: true },
      menuExpandLinks: [
        {
          show: true,
          text: 'createDocument',
          key: actionTypes.GO_TO_PAGE,
          url: '/paperwork/document/create',
          permission: 'paperwork.actions.create'
        },
        {
          show: true,
          text: 'createSurvey',
          key: actionTypes.GO_TO_PAGE,
          url: '/paperwork/survey/create',
          permission: 'paperwork.actions.create'
        },
      ],
    },
  },
  documentDetails: {
    showTopNav: true,
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: null },
      parallax: 'opacity',
      showTitle: true,
    },
  },
  createDocument: {
    showTopNav: true,
    title: 'createDocument',
    subtitle: 'createDocument_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  updateDocument: {
    showTopNav: true,
    title: 'updateDocument',
    subtitle: 'updateDocument_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  createSurvey: {
    showTopNav: true,
    title: 'createSurvey',
    subtitle: 'createSurvey_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  updateSurvey: {
    showTopNav: true,
    title: 'updateSurvey',
    subtitle: 'updateSurvey_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  surveyDetails: {
    showTopNav: true,
    title: 'createSurvey',
    subtitle: 'createSurvey_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      insertClientsName: true,
      insertSubtitle: true,
      showTitle: true,
      opacityText: true,
    },
  },
  surveyFillDetails: {
    showTopNav: true,
    title: 'surveyFillDetails',
    subtitle: 'surveyFillDetails_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      insertSubtitle: true,
      opacityText: true,
    },
  },
  chooseClients: {
    showTopNav: true,
    title: 'chooseClients',
    subtitle: 'chooseClients_doc',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },

  calendar: {
    showTopNav: true,
    config: {
      menuIcon: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  calendarSettings: {
    showTopNav: true,
    title: 'calendarSettings',
    subtitle: 'calendarSettings',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      insertSubtitle: true,
      opacityText: true,
    },
  },
  contacts: {
    showTopNav: true,
    title: 'potentialClients',
    subtitle: 'contactsList',
    config: {
      menuIcon: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  contactDetails: {
    showTopNav: true,
    title: 'contactDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      insertSubtitle: true,
    },
  },

  // admin
  createExerciseAdmin: {
    showTopNav: true,
    title: 'createExerciseAdmin',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  categories: {
    showTopNav: true,
    title: 'categories',
    subtitle: 'categories_sub',
    config: {
      goBackIcon: { show: true, link: null },
      plusIcon: { show: true, url: '/category/create/details' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  createCategory: {
    showTopNav: true,
    title: 'createCategory',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: '/categories' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
};

export const CLIENT = {
  login: { showTopNav: false },
  profile: {
    showTopNav: true,
    config: {
      tutorial: { key: 'profileClient_tutorial', storage: 'profileClient', autoPlayTutorial: true },
      navClass: 'transparent profilePage',
      trainerIcon: true,
      menuIcon: true,
      // activitiesIcon: true,
      parallax: 'opacity',
      showTitle: true,
      startValue: '15vh',
      endValue: '20vh',
    },
  },
  workouts: {
    showTopNav: true,
    title: 'workouts',
    subtitle: 'workoutsArchive',
    config: {
      menuIcon: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutPlan: {
    showTopNav: true,
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: '/workouts' },
      parallax: 'opacity',
      showTitle: true,
    },
  },
  workoutProgress: {
    showTopNav: true,
    title: 'modifyProgress',
    subtitle: 'modifyProgress_sub',
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: null },
      parallax: 'opacity',
      showTitle: true,
    },
  },
  workoutProgram: {
    showTopNav: true,
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: null }, // '/workouts'
      parallax: 'opacity',
      showTitle: true,
    },
  },
  workoutSession: {
    showTopNav: true,
    config: {
      goBackIcon: { show: true, link: null }, // '/workouts'
      navClass: 'transparent',
      menuIcon: true,
      parallax: 'opacity',
      showTitle: true,
    },
  },
  workoutLogger: {
    showTopNav: true,
    title: 'workoutLoggerHeader',
    subtitle: 'workoutLoggerHeader_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  sessionLogger: {
    showTopNav: true,
    title: 'workoutLoggerHeader',
    subtitle: 'workoutLoggerHeader_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutsArchive: {
    showTopNav: true,
    title: 'workoutsArchive_2',
    config: {
      goBackIcon: { show: true, link: null },
      clientList: { show: false, text: 'clientsList' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutPlanArchive: {
    showTopNav: true,
    title: 'workoutPlanArchive',
    config: {
      goBackIcon: { show: true, link: null },
      clientList: { show: false, text: 'clientsList' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  workoutSessionArchive: {
    showTopNav: true,
    title: 'sessionsArchive',
    config: {
      goBackIcon: { show: true, link: null },
      clientList: { show: false, text: 'clientsList' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  // create Session
  startSession: {
    showTopNav: true,
    title: 'sessionStart',
    subtitle: 'Session Started At',
    config: {
      navClass: 'transparent',
      menuIcon: true,
      textIcon: { show: true, text: 'skip' },
    },
  },
  sessionSummary: { showTopNav: false },
  createPastSession: {
    showTopNav: true,
    title: 'createPastSession',
    subtitle: 'workoutLoggerHeader_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  statistics: {
    showTopNav: true,
    title: 'statistics',
    config: {
      menuIcon: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  bodyStats: {
    showTopNav: true,
    title: 'bodyStats',
    subtitle: 'bodyStats_sub_client',
    config: {
      menuIcon: true,
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      // plusIcon: { show: true, url: '/statistics/bodyStats/create' }
    },
  },
  bodyStatsDetails: {
    showTopNav: true,
    title: 'bodyStatsDetails',
    subtitle: 'bodyStatsDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      insertClientsName: true,
      insertSubtitle: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  bodyStatsInfo: {
    showTopNav: true,
    title: 'loading',
    subtitle: 'loading',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      insertSubtitle: true,
      skipChosenClient: true,
      opacityText: true,
    },
  },
  createBodyStats: {
    showTopNav: true,
    title: 'createBodyStats',
    subtitle: 'UpdateYourCurrentBodyStats',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  updateBodyStats: {
    showTopNav: true,
    title: 'updateBodyStats',
    subtitle: 'UpdateYourCurrentBodyStats',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  performance: {
    showTopNav: true,
    title: 'performance',
    subtitle: 'performance_sub_client',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  performanceList: {
    showTopNav: true,
    title: 'performance',
    subtitle: 'performance_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      clientList: { show: true, text: 'clientsList' },
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  performanceDetails: {
    showTopNav: true,
    title: 'performanceDetails',
    subtitle: 'performanceDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      insertClientsName: true,
      insertSubtitle: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  // achievements
  activeAchievements: {
    showTopNav: true,
    title: 'activeAchievements',
    config: {
      menuIcon: true,
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  completedAchievements: {
    showTopNav: true,
    title: 'completedAchievements',
    config: {
      menuIcon: true,
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  achievementDetails: {
    showTopNav: true,
    title: 'achievementDetails',
    subtitle: 'achievementDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      insertSubtitle: true,
    },
  },
  chooseGoal: {
    showTopNav: true,
    title: 'chooseGoal',
    subtitle: 'chooseGoalSub',
    config: {
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  goalDetails: {
    showTopNav: true,
    title: 'goalDetails',
    subtitle: 'goalDetailsSub',
    config: {
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  activities: {
    showTopNav: true,
    title: 'myActivities',
    subtitle: 'myActivities_sub',
    config: {
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  createInquiry: {
    showTopNav: true,
    title: 'createInquiry',
    subtitle: 'createInquiry_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  createSolution: {
    showTopNav: true,
    title: 'createSolution',
    subtitle: 'createSolution_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  replayInquiry: {
    showTopNav: true,
    title: 'replayInquiry',
    subtitle: 'replayInquiry_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  info: {
    showTopNav: true,
    title: 'information',
    // subtitle: 'inquiriesAnswers_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      menuIcon: true,
      filter: { show: true, filterIcon: false, search: true },
      // plusIcon: { show: true, url: '/helpCenter/inquiry/create' },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  infoDetails: {
    showTopNav: true,
    title: 'infoDetails',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
      insertSubtitle: true,
    },
  },
  // documents
  documents: {
    showTopNav: true,
    title: 'documents',
    subtitle: 'documentArchive',
    config: {
      menuIcon: true,
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  surveys: {
    showTopNav: true,
    title: 'surveys',
    subtitle: 'surveyArchive',
    config: {
      menuIcon: true,
      insertClientsName: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  documentDetails: {
    showTopNav: true,
    config: {
      navClass: 'transparent',
      goBackIcon: { show: true, link: null },
      parallax: 'opacity',
      showTitle: true,
    },
  },
  updateDocument: {
    showTopNav: true,
    title: 'updateDocument',
    subtitle: 'updateDocument_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  surveyFillDetails: {
    showTopNav: true,
    title: 'surveyFillDetails',
    subtitle: 'surveyFillDetails_sub',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      insertSubtitle: true,
      opacityText: true,
    },
  },
  calendar: {
    showTopNav: true,
    config: {
      menuIcon: true,
      parallax: 'default',
      showTitle: true,
      opacityText: true,
    },
  },
  calendarSettings: {
    showTopNav: true,
    title: 'calendarSettings',
    subtitle: 'calendarSettings',
    config: {
      navClass: 'noMarginBottom thinNavBar',
      goBackIcon: { show: true, link: null },
      parallax: 'default',
      showTitle: true,
      insertSubtitle: true,
      opacityText: true,
    },
  },
  // settings
  settings: {
    showTopNav: true,
    title: 'settings',
    subtitle: 'applicationSettings',
    config: { menuIcon: true },
  },
  subscription: {
    showTopNav: true,
    title: 'subscription',
    subtitle: 'subscription_sub',
    config: {
      goBackIcon: { show: true, link: null },
    },
  },
  updateUserCredentials: {
    showTopNav: true,
    title: 'updateUserCredentials',
    subtitle: 'updateUserCredentials_sub',
    config: {
      goBackIcon: { show: true, link: null },
    },
  },
  profileDetails: {
    showTopNav: true,
    title: 'profileDetails',
    subtitle: 'profileDetails',
    config: {
      goBackIcon: { show: true, link: null },
      // tutorial: { key: 'profileDetails_tutorial', storage: 'profileDetails', autoPlayTutorial: true }
    },
  },
};

export const navDrawer = {
  workouts: {
    url: '/workouts',
    text: 'workouts',
    permission: 'user.programsAccess'
  },
  statistics: {
    url: '/statistics',
    text: 'statistics',
    permission: 'user.statisticsAccess'

  },
  achievements: {
    url: '/achievements',
    text: 'achievements',
    permission: 'user.achievementsAccess'
  },
  calendar: {
    url: '/calendar',
    text: 'calendar',
    permission: 'user.calendarAccess'

  },
  paperwork: {
    url: '/paperwork',
    text: 'documents',
    permission: '', // 'user.paperworkAccess'
  },
  settings: {
    url: '/settings',
    text: 'settings',
    permission: 'user.settingsAccess'
  },
};

export const user = { CLIENT, TRAINER };
