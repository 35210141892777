/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import AwsS3 from '@uppy/aws-s3';
import uppyLocales from '../../services/uppyPlugins/locales';
import { apiMethods } from '../../services/apiService';
import { addMediaTo } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import { addClass, removeClass, utils } from '../../services/utilsService';
import EmptyListUI from '../../Components/UI/EmptyListUI';
import MinimizedUpload from '../../Components/MediaUpload/MinimizedUpload';
import UploadOptionsNav from '../../Components/MediaUpload/UploadOptionsNav';
import uploadFiles from '../../assets/onBoarding/upload_files.svg';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import './VideouploadPage.css';
import { mediaType } from '../../services/enums';

let modelId = '';
class FileUploadPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      uppy: null,
      completedUploads: {},
      inProgress: false,
      minimized: false,
      progressFiles: {}
    };
    modelId = this.props.alertProps.modelId || 'Profile';
  }

  componentDidMount () {
    this.state.uppy = new Uppy({
      autoProceed: true,
      locale: uppyLocales[(this.props.appLang || 'en')],
      restrictions: {
        maxFileSize: null, // (this.props.alertProps.allow.indexOf('video/mp4') !== -1 || this.props.alertProps.allow.indexOf('video/*') !== -1) ? null : 20000000, // 20MB for images
        maxNumberOfFiles: 15,
        minNumberOfFiles: 1,
        allowedFileTypes: this.props.alertProps.allow // ['image/*', 'video/mp4', 'video/mpeg', 'video/webm', 'application/pdf']
      }
    })
      .use(Dashboard, {
        inline: false,
        showProgressDetails: true,
        height: 250,
        browserBackButtonClose: false
      })
      .use(Webcam, {
        modes: ['picture', 'video-audio'],
        facingMode: 'environment'
      })
      .use(AwsS3, {
        async getUploadParameters (file) {
        // Send a request to our PHP signing endpoint.
          const params = {
            modelId,
            fileName: file.name,
            fileType: file.type
          };
          const query = utils.buildQuery(params);
          const response = await apiMethods.media.getPreSignedUrl(query);
          return {
            method: 'PUT',
            url: response.signedRequest,
            headers: {
              'Content-Type': response.fileType
            }
          };
        }
      })
      .on('file-added', (file) => {
        this.state.numberOfFiles = this.state.uppy.getFiles().length;
      })
      .on('file-removed', (file) => {
        this.state.numberOfFiles = this.state.numberOfFiles - 1;
        if (this.state.numberOfFiles === 0) {
          if (this.props.isBackButtonDisabled) this.props.enableBackButton();
          this.goBack();
        }
      })
      .on('dashboard:modal-open', () => {
     /*   this.timeout = setTimeout(() => {
          const button = document.querySelector('.uppy-Dashboard-AddFiles .uppy-Dashboard-AddFiles-title button.uppy-Dashboard-browse');
          button.click();
        }, 250); */
      })
      .on('upload', (data) => {
        this.props.disableBackButton();
      })
      .on('upload-error', (file, error, response) => {
        console.log('upload-error: enableBackButton');
        this.props.enableBackButton();
      })
      .on('upload-progress', (file, progress) => {
        if (!this.props.isBackButtonDisabled) this.props.disableBackButton();
        this.state.inProgress = true;
        const id = file.id.split('/').pop();
        this.state.progressFiles[id] = {
          load: progress.bytesUploaded,
          total: progress.bytesTotal
        };
      })
      .on('complete', (result) => {
        this.props.enableBackButton();
        if (result.successful.length > 0) {
          this.state.completedUploads = result.successful;
          this.uploadImages();
        }
      })
      .run(); // run uppy
    this.setState({ isPageReady: true, uppyIsOpen: true });
  }

  componentWillUnmount () {
    removeClass('body.minimized', 'minimized');
    removeClass('body', 'uppy-Dashboard-isFixed');
    this.props.enableBackButton();
  }

  getResourceType (fileType) {
    if (fileType.match(/^image\//)) {
      return 'image';
    } if (fileType.match(/^video\//)) {
      return 'video';
    } if (fileType.match(/^application\//)) {
      return 'form';
    }
    return 'auto';
  }

  goBack () {
    if (this.props.isBackButtonDisabled) return;
    this.state.uppy.close();
    this.props.alertProps.buttonRight.onClick();
  }

  minimize () {
    addClass('body', 'minimized');
    this.setState({ minimized: true });
  }

  expand () {
    removeClass('body.minimized', 'minimized');
    this.setState({ minimized: false });
  }

  configAwsFile (obj) {
    return {
      source: obj.uploadURL,
      sourceId: obj.uploadURL.split('/').pop(),
      title: obj.name,
      size: obj.size,
      type: this.getResourceType(obj.type),
      extension: obj.type.split('/').pop(),
      format: obj.type
      // duration: 0 // milliseconds
    };
  }

  async uploadImages () {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const body = {
        completedUploads: this.state.completedUploads.map(result => this.configAwsFile(result))
      };
      const mediaArray = await apiMethods.media.saveUploadedMedia(body);
      const { model, modelId } = this.props.alertProps;
      if (model && modelId && (mediaArray && mediaArray.length > 0)) {
        // if we have model && modelId && uploads we go add to document
        await this.props.addMediaTo(model, modelId, mediaArray);
      }
      this.props.alertProps.successUpload(mediaArray);
      this.props.enableBackButton();
      this.state.uppy.close();
      this.setState({ uppyIsOpen: false });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }

  passImageAndClosePage(images) {
    const imageArray = this.props.isMulti ? images : [images];
    this.props.alertProps.successUpload(imageArray);
    this.state.uppy.close();
    this.setState({ uppyIsOpen: false }, () => {
      this.props.hideAlertBtn();
    });
  }

  renderNavTopForModal () {
    return (
      <div className="NavTopForModal content-header flex-100 layout-row layout-wrap layout-align-start-start positionAbsoluteTop width100 borderBottomGrey whiteBackground">
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-end ">
          <div className="flex-100 layout-row layout-wrap layout-align-space-between-center topNav height50px ">
            <div className="flex-initial layout-row">
              <div className="flex-initial">
                { this.props.alertProps.buttonRight && this.props.alertProps.buttonRight.text && (
                  <button
                    type="button"
                    className="flex-initial textIcon btn-transparent sidePadd10px"
                    onClick={() => this.goBack()}>
                    { this.props.alertProps.buttonRight.text }
                  </button>
                )}
              </div>
              <div className="flex-initial">
                <button
                  type="button"
                  className="flex-initial textIcon btn-transparent sidePadd10px"
                  onClick={() => this.minimize()}>
                  <i className="la la-sort-down font22 lineHeight1 primary" />
                </button>
              </div>
            </div>
            <div className="flex layout-row layout-align-start-center sidePadd15px NavTop-label-direction">
              <label className="primary text-capitalize font20 fontWeight600 margin0"><Trans i18nKey={this.props.alertProps.title || 'uploadImage'} /></label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render () {
    const isModalPage = this.renderNavTopForModal();
    return (
      <div className="UppyUploadPage flex-100 layout-row layout-wrap layout-align-start-start fullSizeWindow content-start">
        { this.state.isPageReady && (
          <div className="flex-100 layout-row layout-wrap layout-align-center-center whiteBackground">
            <div className="flex-100 layout-row layout-wrap layout-align-center-center uppySection">
              {isModalPage}
              <div className="flex-100 layout-row layout-wrap layout-align-center-center sidePadd15px">
                <EmptyListUI
                  svg={uploadFiles}
                  isLoading={false}
                  className="uploadEmpty fullScreen"
                  text="uploadFiles"
                  text2="uploadFiles_desc"
                />
              </div>
              <DashboardModal // uppy dashboard component
                uppy={this.state.uppy}
                plugins={['Webcam']}
                allowMultipleUploads={this.props.alertProps.data.isMulti}
                proudlyDisplayPoweredByUppy={false}
                showLinkToFileUploadResult={false}
                onRequestCloseModal={() => this.goBack()}
                animateOpenClose={false}
                open={this.state.uppyIsOpen} />
              <UploadOptionsNav
                aspireLibrary={{ allowImages: true, isMulti: this.props.isMulti}}
                allowLibraryShow={(this.props.alertProps && this.props.alertProps.allowLibraryShow) || [mediaType.image]}
                onChooseImage={(images) => {this.passImageAndClosePage(images)}} />
            </div>
            <div className="flex-100 layout-row layout-wrap layout-align-center-center">
              { this.state.minimized && (
                <MinimizedUpload expand={() => this.expand()} numberOfFiles={this.state.numberOfFiles} progress={this.state.progressFiles || 0} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  isBackButtonDisabled: state.appR.isBackButtonDisabled,
});

const mapDispatchToProps = dispatch => ({
  disableBackButton: () => dispatch({ type: actionTypes.DISABLE_BACK_BTN }),
  enableBackButton: () => dispatch({ type: actionTypes.ENABLE_BACK_BTN }),
  addMediaTo: (model, publicId, body) => dispatch(addMediaTo(model, publicId, body))
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadPage);
