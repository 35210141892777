import { actionTypes } from '../actions/actionTypes';
import { storeUtils } from '../../services/utilsService';

const initialState = {
  media: {}
  // mediaKV {}
  // mediaIds []
  // achievementData {}
  // userMedia []
};

const achievementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MEDIA_GALLERY:
      return { ...state, mediaKV: action.payload.mediaKV, mediaIds: action.payload.mediaIds };
    case actionTypes.SET_USER_MEDIA_GALLERY:
      return { ...state, userMedia: action.payload };
    case actionTypes.SET_MEDIA_DATA:
      return {
        ...state,
        mediaData: action.payload
      };
    case actionTypes.CLEAR_MEDIA_DATA:
      return {
        ...state,
        newAchievement: null,
        mediaData: null
      };
    case actionTypes.REMOVE_MEDIA: {
      const mediaKV = storeUtils.removeFromObjectInStore(state.mediaKV, action.payload);
      const mediaIds = storeUtils.removeFromArrayInStore(state.mediaIds, action.payload);
      return { ...state, mediaIds, mediaKV };
    }
    default:
      return state;
  }
};

export default achievementReducer;
