import 'regenerator-runtime/runtime.js';
import './services/globals';
import './styleSheets/flex-layout-mobile.css';
import './styleSheets/bootstrap337.css';
import 'intro.js/introjs.css';
import './styleSheets/line-awesome.css';
import './styleSheets/animations.css';
import './styleSheets/styleSheet.css';
import './styleSheets/paddAndMargin.css';
import './index.css';
import './styleSheets/spanishStyle.css';
import './styleSheets/indexRTL.css';
import './styleSheets/iosFixes.css'; // for ios devices
import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
} from './appConsts/colorSchemes';

const color =
  (isMultiColorActive || isDarkSwitchActive) && localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then((x) => {
    require('./AppRenderer');
  });
};

render();
