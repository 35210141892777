/* eslint-disable max-len */
import React from 'react';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import BackSVG from '../../assets/images/Back.svg';
import frontSVG from '../../assets/images/bodyFront_new.svg';
import './BodyMap.css';
import LoadingDots from '../UI/LoadingDots';

const BodyMap = props => (
  <div className={`BodyMap flex-100 layout-row layout-wrap layout-align-center-center positionRelative ${props.previewImgPosition === 2 ? 'previewImgPositionBack' : 'previewImgPositionTop'}`}>
    <div className={`BodyMapCmp flex-100 layout-row layout-wrap layout-align-center-center positionRelative transition03
      ${props.targetMuscles ? props.targetMuscles.join(' ') : ''} ${props.synergistMuscles ? props.synergistMuscles.join(' ') : ''} ${props.chosenMuscle ? props.chosenMuscle.join(' ') : ''} ${props.classPosition ? props.classPosition : ''}`}>
      <div className="flex-100 layout-row layout-wrap layout-align-center-center scrollBar">
        <div className="flex-100 layout-row layout-wrap layout-align-center-center mapContainer">
          <div className="flex-50 layout-row layout-wrap layout-align-center-center">
            <ReactSVG
              src={BackSVG}
              fallback={() => <span>Error!</span>}
              loading={() => (
                <LoadingDots active />
              )} />
          </div>
          <div className="flex-50 layout-row layout-wrap layout-align-center-center">
            <ReactSVG
              src={frontSVG}
              fallback={() => <span>Error!</span>}
              loading={() => (
                <LoadingDots active />
              )} />
          </div>
        </div>
      </div>
    </div>
    { props.showPositionBtn && (
      <div className="flex-100 layout-row layout-wrap layout-align-center-center marginBottom10px">
        <div className={`flex-100 layout-row layout-wrap layout-align-center-center padd10px positionBtns ${props.classPosition ? props.classPosition : ''}`}>
          <button type="button" className="flex-33 btn btn-transparent primary text-center top" onClick={() => { props.positionClicked(''); }}>
            <Trans i18nKey="top" />
          </button>
          <button type="button" className="flex-33 btn btn-transparent primary text-center center" onClick={() => { props.positionClicked('center'); }}>
            <Trans i18nKey="center" />
          </button>
          <button type="button" className="flex-33 btn btn-transparent primary text-center bottom" onClick={() => { props.positionClicked('bottom'); }}>
            <Trans i18nKey="bottom" />
          </button>
        </div>
      </div>
    )}
    { props.showPreviewImgPosition && (
      <div className="flex-100 layout-row layout-wrap layout-align-center-center">
        <div className="layout-row layout-wrap layout-align-center-center previewImgPositionContainer">
          <div className="flex-50 layout-row layout-wrap layout-align-center-center height200px" onClick={() => { props.previewImgPositionClicked ? props.previewImgPositionClicked(2) : null; }}>
          </div>
          <div className="flex-50 layout-row layout-wrap layout-align-center-center height200px" onClick={() => { props.previewImgPositionClicked ? props.previewImgPositionClicked(1) : null; }}>
          </div>
        </div>
        <div className="positionAbsolute previewImgPosition flex-50">
          <div className="relative width100">
            <div className="positionAbsoluteBottomLeft width100 layout-row layout-align-center-center">
              <p className="font13 margin0 previewImgP">Preview Image</p>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

BodyMap.defaultProps = {
  previewImgPosition: 1,
};

BodyMap.propTypes = {
  chosenMuscle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  targetMuscles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  synergistMuscles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  showPositionBtn: PropTypes.bool,
  classPosition: PropTypes.string,
  positionClicked: PropTypes.func,
  showPreviewImgPosition: PropTypes.bool,
  previewImgPosition: PropTypes.number,
  previewImgPositionClicked: PropTypes.func
};

export default BodyMap;
