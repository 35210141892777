import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { logoutUser } from '../../store/actions';
import { checkAccessControl } from '../../services/utilsService';
import './NavDrawer.css';


class NavDrawer extends Component {
  constructor (props) {
    super(props);
    this.state = { openDrawer: false };
  }

  openMenu () {
    this.setState(prevState => ({ openDrawer: !prevState.openDrawer }));
  }

  logoutUser () {
    this.props.logoutUser();
  }

  renderLinks (navDrawer) {
    if (navDrawer && Object.keys(navDrawer).length > 0) {
      return Object.entries(navDrawer).map(([key, value]) => (
        <Link key={value.text} to={value.url} className="flex-100 layout-row layout-align-start-start">
          <span className="menu-title layout-row layout-align-start-center">
            <Trans i18nKey={value.text} />
     {/*       { !checkAccessControl(value.permission) && (
              <i className="la la-lock colorWhite font20 opacity-75 sideMargin5px" />
            )} */}
          </span>
        </Link>
      ));
    }
    return null;
  }

  render () {
    const links = this.renderLinks(this.props.navDrawer);
    return (
      <div className={`NavDrawer layout-row layout-wrap layout-align-start-start ${this.state.openDrawer ? ' open' : ''}`}>
        { this.props.auth && (
          <label onClick={() => this.openMenu()} className="OpenMenuButton margin0">
            <i className="la la-bars menu" />
          </label>
        )}
        { this.props.auth && (
          <aside className="flex-100 layout-row layout-wrap layout-align-start-start" onClick={() => this.openMenu()}>
            <div className="flex-100 layout-column layout-wrap layout-align-space-between-start listContainer">
              <div className="width100 flex-initial layout-row layout-wrap layout-align-start-start content-start">
                <Link to={`/feed/${this.props.user.public_id}`} className="flex-100 layout-row layout-align-start-start">
                  <span className="menu-title layout-row layout-wrap layout-align-start-start"><Trans i18nKey="myProfile" /></span>
                </Link>
                { links }
              </div>
              {/* <Link to="/events" className="flex-100 layout-row layout-align-start-start">
                <span className="menu-title layout-row layout-wrap layout-align-start-start"><Trans i18nKey="calendar" /></span>
              </Link> */}
              <Link to="/helpCenter/info" className="flex-initial layout-row layout-align-start-center width100 help">
                <i className="la la-info-circle colorWhite font24" />
                <span className="menu-title layout-row layout-wrap layout-align-start-start sidePadd5px"><Trans i18nKey="support" /></span>
              </Link>
            </div>
          </aside>
        )}
        { this.state.openDrawer && ( <div className="black_back zIndex10" onClick={() => this.openMenu()} /> )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  auth: state.userR.authenticated,
  navDrawer: state.appR.navDrawer
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavDrawer);
