import React from 'react';
import { Trans } from 'react-i18next';
import { aspirePlaceholder } from '../../services/enums';
import Image from '../UI/Image';
import AspireBtnLoader from '../UI/AspireBtnLoader';

const renderButtons = (data, props) => {
  const isAppVersionCompatible = !!(window && window.aspire && window.aspire.appVersion && (Number(window.aspire.appVersion) > 1.4));

  return data.buttons.map((option) => {
    if (option.text === 'handleLinkingApp' && !isAppVersionCompatible) return null;
    if (option && option.isLink) {
      return (
        <a key={option.text} href={`${option.href}`} className="uploadFilesBtn flex-100 layout-align-center-center text-center">
          <span className={`fontWeight600 text-capitalize ${option.classBtnText}`}><Trans i18nKey={option.text} defaults={option.text} /></span>
        </a>
      )
    }
    if (option && option.aspireLoader) {
      return (
        <AspireBtnLoader
          btnText={option.text}
          containerClassName="flex-100 layout-row layout-wrap layout-align-center-center marginBottom10px"
          classNameText={option.classBtnText}
          className={option.classBtn || ''}
          btnClicked={() => props[option.funcName](option)} />
      )
    }
    return (
      <button key={option.text} type="button" onClick={() => props[option.funcName](option)} className="uploadFilesBtn flex-100 layout-align-center-center text-center">
        <p className={`margin0 fontWeight600 text-capitalize ${option.classBtnText}`}><Trans i18nKey={option.text} defaults={option.text} /></p>
      </button>
    )
  })
}

const AspireMsgModal = (props) => {
  const { userData, data, appLang, components, showImage, isCard } = props;
  const thumbnail = (data.thumbnail && data.thumbnail.uri) || (userData.thumbnail && userData.thumbnail.uri ? userData.thumbnail.uri : null);
  const content = data && data.content && data.content[appLang] ? data.content[appLang] : (data.content.en || null)
  return (
    <div className="AspireMsgModal flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
      {showImage && (
        <div className="layout-row layout-wrap layout-align-start-start social-profile-img">
          <Image
            src={thumbnail}
            fallbackSrc={thumbnail || aspirePlaceholder[0]}
            backgroundImage
            className="backgroundImage layout-row layout-wrap layout-align-start-start borderRadius img-thumbnail card-img"
            height={300}
            color1="rgba(0, 0, 0, 0)"
            color2="rgba(0, 0, 0, 0)"
            color3="rgba(0, 0, 0, 0.05)" />
        </div>
      )}
      <div className={`marginBottom15px flex-100 ${isCard ? 'card borderRadius15' : ''}`}>
        <div className="card-body">
          <div className={`text-center ${isCard ? 'pt-5' : ''}`}>
            <h3 className="marginBottom10px fontWeight600 pt-4">
              <Trans i18nKey={data && data.title} defaults={data && data.title} />
              {' '}
            </h3>
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px">
            { content && <div dangerouslySetInnerHTML={{ __html: content }} className="width100" /> }
          </div>
          {components}
          <p className="width100 marginBottom10px lineHeight18" />
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            { data && data.buttons && data.buttons.length > 0 && renderButtons(data, props)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AspireMsgModal;
