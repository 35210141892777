import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import formReducer from './reducers/formReducer';
import userReducer from './reducers/userReducer';
import appReducer from './reducers/appReducer';
import workoutReducer from './reducers/workoutReducer';
import exerciseReducer from './reducers/exerciseReducer';
import workoutSessionReducer from './reducers/workoutSessionReducer';
import userStatsReducer from './reducers/userStatsReducer';
import achievementReducer from './reducers/achievementReducer';
import mediaReducer from './reducers/mediaReducer';
import infoReducer from './reducers/infoReducer';
import timerReducer from './reducers/timerReducer';
import paperworkReducer from './reducers/paperworkReducer';
import calendarReducer from './reducers/calendarReducer';
import storeReducer from './reducers/storeReducer';

const rootReducer = combineReducers({
  formR: formReducer,
  userR: userReducer,
  appR: appReducer,
  exerciseR: exerciseReducer,
  workoutR: workoutReducer,
  sessionR: workoutSessionReducer,
  userStatsR: userStatsReducer,
  achieveR: achievementReducer,
  mediaR: mediaReducer,
  infoR: infoReducer,
  docR: paperworkReducer,
  timerR: timerReducer,
  calR: calendarReducer,
  storeR: storeReducer
});

const logger = store => next => (action) => {
  console.log('[Middleware] dispatching', action);
  const result = next(action);
  console.log('[Middleware] next state', store.getState());
  return result;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

export default store;
