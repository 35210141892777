/* eslint-disable no-trailing-spaces,max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { aspireClient, aspireClientConfig } from '../../services/apiService';
import { langs } from '../../services/translationLangs';
import { addClass, cleanInput, removeClass } from '../../services/utilsService';
import './Filter.css';

let cancelToken

const searchEndPoints = {
  createWorkout: { api: '/api/exercises/getFilteredExercises', query: {} },
  infoPage: { api: '/api/information/getFilteredInfos', query: { limit: 30 } },
  searchStorePrograms: { api: '/api/store/getFilteredAspirePrograms',  query: {} },
};

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
    };
  }

  async handleSearch (pageProps, body) {
    try {
      // Check if there are any previous pending requests
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
      }
      // Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      const config = {...aspireClientConfig, cancelToken: cancelToken.token}
      const response = await aspireClient.post(pageProps.api, body, config);
      this.props.dispatchResults(body, response)
    } catch (error) {
      console.log(error)
    }
  }

  async handleChange(e) {
    this.state.searchInput = cleanInput(e.currentTarget.value);
    const minLength = this.props.minLength || 3;
    if (this.state.searchInput.length < minLength && this.state.searchInput.length > 0) return;
    const { searchInput } = this.state;
    removeClass('#loadingDots', 'opacity0');
    addClass('#skeletonLoading', 'loadingFinished');
    if (document.getElementById('skeletonLoading')) {
      removeClass('#skeletonLoading', 'loadingFinished');
    }
    const pageProps = searchEndPoints[this.props.currentPage];
    const bodyProps = this.props.bodyProps ? this.props.bodyProps : {};
    const body = {
      ...pageProps.query,
      ...bodyProps,
      searchText: searchInput.length > 0 ? searchInput : null,
    };
    if (this.props.inputValue) this.props.inputValue(searchInput);
    await this.handleSearch(pageProps, body);
    addClass('#loadingDots', 'opacity0');
    if (document.getElementById('skeletonLoading')) {
      addClass('#skeletonLoading', 'loadingFinished');
    }
  }

  render() {
    return (
        <div className={`SearchInput flex-100 layout-row layout-align-start-start positionRelative ${this.props.className ? this.props.className : ''}`}>
          <input
            required={this.props.required}
            type="text"
            placeholder={langs[this.props.appLang].search}
            ref={(input) => (this.searchInput = input)}
            name="i_searchInput"
            onChange={(event) => {this.handleChange(event, 'searchInput');}}
            className="form-control"
          />
          <i className="la la-search font28" />
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
