export const aspireLogo = 'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/logo_exdvpj.jpg';

export const categoryGroups = {
  muscles: 1,
  exerciseType: 2, // bodyBuilding, Yoga, StreetWO, powerLifting, Stretch
  certifications: 3,
  locations: 4,
  foodGroups: 5,
  equipment: 6, // Barbell, Cable, Dumbbell, Smith, BodyWeight, TRX,
  infoSubjects: 8,
  blogCategories: 9,
};
export const programTypes = {
  // other: 0,
  personal: 1,
  group: 2,
  public: 3, // template
  // paid: 4,
  store: 5,
};

export const programTypesArr = ['', 'personal', 'group', 'public', 'paid', 'store'];

export const programViews = {
  SET: 'SET',
  CIRCUIT: 'CIRCUIT',
};

export const workoutLogTypes = {
  straight: 'straight',
  superset: 'superset',
  custom: 'custom',
  static: 'static',
  aerobic: 'aerobic', // AEROBIC
};
export const programDifficulty = {
  beginner: 'beginner',
  trained: 'trained',
  wellTrained: 'wellTrained',
  athlete: 'athlete',
};

export const allowedFieldsByWorkoutTypes = {
  straight: {
    reps: true,
    weight: true,
    time: false,
    rest: false,
    distance: false,
  },
  superset: {
    reps: true,
    weight: true,
    time: false,
    rest: false,
    distance: false,
  },
  aerobic: {
    time: true,
    intensity: true,
    speed: true,
    distance: true,
    weight: false,
    rest: false,
  },
  static: {
    reps: false,
    weight: true,
    time: true,
    rest: false,
  },
  custom: {
    reps: true,
    weight: true,
    time: false,
    rest: false,
    intensity: false,
    speed: false,
    distance: false,
  },
};

export const workoutLogTypesButtons = [
  {
    id: 'normal',
    title: {
      he: 'בחירת תרגיל יחיד',
      en: 'Select Exercise',
      es: 'Seleccione ejercicio',
    },
    isChosen: true,
    type: 'straight',
  },
  {
    id: 'superset',
    title: {
      he: 'יצירת סופר-סט',
      en: 'Create a Super-Set',
      es: 'Crear un Superserie',
    },
    isChosen: false,
    type: 'superset',
  },
];

export const units = {
  weightUnits: {
    KG: 'KG', // KG
    LBS: 'LBS', // LBS
    T: 'T', // TON
  },
  distanceUnits: {
    CM: 'CM', // CM
    M: 'M', // M
    KM: 'KM', // KM
    INCH: 'INCH', // INCH
    FT: 'FT', // FEET
    YARD: 'YD', // YARD
    MILE: 'MILE', // MILE
  },
  other: {
    PERCENT: 'PERCENT',
    KCAL: 'KCAL',
    BMI: 'BMI',
    TIME: 'blank',
    BLANK: 'blank',
  },
};

export const selectUnits = {
  KG_CM: 'KG_CM', // KG
  LBS_INCH: 'LBS_INCH', // LBS
};

export const measurements = {
  weightUnits: 'weightUnits',
  distanceUnits: 'distanceUnits',
};

export const bodyStatsOrder = ['height', 'weight', 'muscleMass', 'fat', 'calories', 'water', 'bmi'];
export const bodyStatsAchieve = ['height', 'weight', 'muscleMass', 'fat', 'water', 'bmi'];
export const bodyMeasurementsOrder = [
  'shoulders',
  'chest',
  'arms',
  'rightArm',
  'leftArm',
  'waist',
  'hips',
  'thighs',
  'rightThigh',
  'leftThigh',
  'lowerThighs',
  'rightLowerThigh',
  'leftLowerThigh',
  'calves',
  'rightCalf',
  'leftCalf',
];

// measurementByType
export const measurementByType = {
  // bodyStats
  bmi: units.other.BMI,
  calories: units.other.KCAL,
  water: units.other.PERCENT,
  height: units.distanceUnits.CM,
  weight: units.weightUnits.KG,
  fat: units.other.PERCENT,
  muscleMass: units.weightUnits.KG,
  timeDone: units.other.TIME,
  // goalType
  bestSet: units.weightUnits.KG, // highest weight / most reps
  mostReps: units.other.BLANK, // most reps
  highestWeight: units.weightUnits.KG, // highest weight
  bestByRM: units.weightUnits.KG, // highest weight / specific num of reps (1RM - 2RM - 6RM...)
  longestTime: units.other.TIME, // longest Time - forward stopper
  bestTime: units.distanceUnits.CM, // shortest Time by distance
  longestDistance: units.distanceUnits.CM, // longest Distance
  tonnage: units.weightUnits.KG, // highest tonnage by set
  // mostCaloriesBurned
};

export const propNameByType = {
  // bodyStats
  bmi: 'value',
  calories: 'value',
  water: 'value',
  height: 'value',
  weight: 'value',
  fat: 'value',
  muscleMass: 'value',
  // goalType
  bestSet: 'weight', // highest weight / most reps
  mostReps: '', // most reps
  highestWeight: 'weight', // highest weight
  bestByRM: 'weight', // highest weight / specific num of reps (1RM - 2RM - 6RM...)
  longestTime: 'timeDone', // longest Time - forward stopper
  bestTime: 'distance', // shortest Time by distance
  longestDistance: 'distance', // longest Distance
  tonnage: 'value', // highest tonnage by set
  // mostCaloriesBurned
};

export const language = {
  EN: 'en',
  HE: 'he',
};

export const userTypes = {
  CLIENT: 'CLIENT',
  TRAINER: 'TRAINER',
  NUTRITIONIST: 'NUTRITIONIST',
  GROUP_ADMIN: 'GROUP_ADMIN'
};

export const mediaType = {
  image: 'image',
  video: 'video',
  url: 'url',
  location: 'location',
  media: 'media',
  aspireLibrary: 'aspireLibrary',
};

export const sourceTypes = {
  amazonS3: 'amazonS3',
  cloudinary: 'cloudinary',
  instegram: 'instegram',
  vimeo: 'vimeo',
  youtube: 'youtube',
  dailymotion: 'dailymotion',
  url: 'url',
};

export const allowedTypesList = {
  image: ['image/jpg', 'image/jpeg', 'image/webp', 'image/png', 'image/gif'],
  video: [
    'video/mpg',
    'video/mpeg',
    'video/mp4',
    'video/wmv',
    'video/avi',
    'video/webm',
    'video/quicktime',
  ],
  pdf: ['application/pdf', '.csv'],
};

export const durationType = {
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
};

export const planReviewType = {
  duration: 'duration',
  workoutPerWeek: 'workoutPerWeek',
  workoutDuration: 'workoutDuration',
};

export const navigationActions = {
  CHOOSE_EXERCISE: 'CHOOSE_EXERCISE',
  TEXT: 'TEXT',
  SAVE_CHOSEN_LIST: 'SAVE_CHOSEN_LIST',
  SKIP_STEP: 'SKIP_STEP',
  DONE: 'DONE',
  SAVE: 'SAVE',
  CANCEL: 'CANCEL',
  RESEND_INVITE: 'RESEND_INVITE',
  CONFIRM_EVENT: 'CONFIRM_EVENT',
  CANCEL_EVENT: 'CANCEL_EVENT',
  RESCHEDULE_EVENT: 'RESCHEDULE_EVENT',
};

export const chooseClientCallByPage = {
  bodyStats: { funcName: 'getBodyStats', query: { limit: 40, isBasic: true } },
  performance: { funcName: 'getStatistics', query: {} },
  getExercisesStatistics: {
    funcName: 'getExercisesStatistics',
    query: { limit: 8, graphLimit: 6 },
  },
  getProgramStatistics: { funcName: 'getProgramStatistics', query: { limit: 5, graphLimit: 6 } },
  bodyStatsDetails: { funcName: 'getBodyStatsHistory', query: { limit: 40 } },
  workoutsPage: { funcName: 'getAllWorkoutPageData', query: { isBasic: true, limit: 15 } },
  createWorkout: { funcName: 'getFilteredExercises', query: {} },
  activeAchievementsPage: { funcName: 'getAchievements', query: { limit: 20 }, type: 'active' },
  completedAchievementsPage: {
    funcName: 'getAchievements',
    query: { limit: 20, isCompleted: true },
    type: 'completed',
  },
  documents: { funcName: 'getDocuments', query: { limit: 30 } },
  surveys: { funcName: 'getFilledSurveys', query: { limit: 30 } },
};

export const chooseFilterCallByPage = {
  createWorkout: {
    funcName: 'getFilteredExercises',
    dispatch: 'dispatchFilteredExercises',
    query: {},
  },
  infoPage: {
    funcName: 'getFilteredInfos',
    dispatch: 'dispatchFilteredInfos',
    query: { limit: 30 },
  },
};

export const appLang = {
  english: 'en',
  hebrew: 'he',
  spanish: 'es',
};

export const achievementType = {
  body: 'body',
  performance: 'performance',
};

export const statsTypes = {
  programsStats: 'programsStats',
  exerciseStats: 'exerciseStats',
  sessionStats: 'sessionStats',
};

export const performanceTypes = {
  bestSet: 'bestSet', // highest weight / most reps
  mostReps: 'mostReps', // most reps
  highestWeight: 'highestWeight', // highest weight
  bestByRM: 'bestByRM', // highest weight / specific num of reps (1RM - 2RM - 6RM...)
  longestTime: 'longestTime', // longest Time - forward stopper
  bestTime: 'bestTime', // shortest Time by distance
  longestDistance: 'longestDistance', // longest Distance
  tonnage: 'tonnage', // highest tonnage by set
  // mostCaloriesBurned
};

export const inputsByAchievementTypes = {
  bestSet: ['reps', 'setX', 'weight'], // highest weight / most reps
  mostReps: ['reps'], // most reps
  highestWeight: ['weight'], // highest weight
  longestTime: ['timeDone'], // longest Time - forward stopper
  bestTime: ['timeDone', 'distance'], // shortest Time by distance
  longestDistance: ['distance'], // longest Distance
  bestByRM: ['reps', 'setX', 'weight'], // highest weight / specific num of reps (1RM - 2RM - 6RM...)
  tonnage: ['value'], // highest tonnage by set
  body: ['value'],
  // mostCaloriesBurned
};

export const goalFormInputs = [
  'initial.reps',
  'initial.weight',
  'initial.timeDone',
  'initial.distance',
  'initial.value',
  'target.reps',
  'target.weight',
  'target.timeDone',
  'target.distance',
  'target.value',
];

export const modelLinks = {
  achievement: '/achievement',
  bodyStats: '/statistics/bodyStats/info',
  package: '/package',
  event: '/event',
  statistics: '/statistics/performance',
  programs: '/program',
  program: '/program',
  plan: '/program',
  session: '/session',
  user: '/feed',
  document: '/paperwork/document',
  survey: '/paperwork/survey',
  surveyFill: '/paperwork/surveyFill',
  contact: '/contacts/details',
  // bodyStatsDetails: '/statistics/bodyStats/${stateName}',
};

export const logInputs = [
  'reps',
  'weight',
  'feels',
  'time',
  'speed',
  'distance',
  'intensity',
  'timeDone',
  'rest',
  'restDone',
];

export const domainWhiteList = ['http://localhost:2020', 'https://application.aspire.co.il'];

export const inputConversionsProps = [
  'weight',
  'distance',
  'height',
  'muscleMass',
  'tonnage',
  'totalDistance',
  'value',
];

export const inputConversionsPropsUnits = {
  weight: 'weightUnits',
  distance: 'distanceUnits',
  height: 'distanceUnits',
  muscleMass: 'weightUnits',
  tonnage: 'weightUnits',
  totalDistance: 'distanceUnits',
  value: 'weightUnits', // tonnage
};

export const activityModels = {
  achievement: 'achievement',
  bodyStats: 'bodyStats',
  package: 'package',
  event: 'event',
  statistics: 'statistics',
  programs: 'programs',
  program: 'program',
  session: 'session',
  user: 'user',
  information: 'information',
  document: 'document',
  survey: 'survey',
  surveyFill: 'surveyFill',
  contact: 'contact',
};

export const daysArray = [
  { text: { he: 'א', en: 'Sun' }, value: 0 },
  { text: { he: 'ב', en: 'Mon' }, value: 1 },
  { text: { he: 'ג', en: 'Tue' }, value: 2 },
  { text: { he: 'ד', en: 'Wed' }, value: 3 },
  { text: { he: 'ה', en: 'Thu' }, value: 4 },
  { text: { he: 'ו', en: 'Fri' }, value: 5 },
  { text: { he: 'ש', en: 'Sat' }, value: 6 },
];

export const daysFullNameArray = [
  { text: { he: 'ראשון', en: 'Sunday', es: 'Domingo' }, value: 0, calendar: 'SU' },
  { text: { he: 'שני', en: 'Monday', es: 'Lunes' }, value: 1, calendar: 'MO' },
  { text: { he: 'שלישי', en: 'Tuesday', es: 'Martes' }, value: 2, calendar: 'TU' },
  { text: { he: 'רביעי', en: 'Wednesday', es: 'Miércoles' }, value: 3, calendar: 'WE' },
  { text: { he: 'חמישי', en: 'Thursday', es: 'Jueves' }, value: 4, calendar: 'TH' },
  { text: { he: 'שישי', en: 'Friday', es: 'Sábado' }, value: 5, calendar: 'FR' },
  { text: { he: 'שבת', en: 'Saturday', es: 'Domingo' }, value: 6, calendar: 'SA' },
];

export const aspireColors = [
  // pink
  'rgba(144, 27, 73, 0.4)',
  // orange
  'rgba(121, 52, 22, 0.3)',
  // purple
  'rgba(88, 85, 160, 0.3)',
  // orange
  'rgba(225, 119, 74, 0.3)',
  // pink
  'rgba(212, 64, 123, 0.3)',
];

export const aspirePlaceholder = [
  'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/placeholderGray_ioowu3.jpg',
  'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/placeholder_pll2qh.jpg',
  'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/placeholderColor.jpg',
  'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/placeholderDocBW.jpg',
];

export const mediaPlaceholder = {
  video:
    'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/aspireTranscoding_sbw5wk.jpg',
};

export const optionAnswerType = {
  inputOption: 'input',
  numberOption: 'number',
  longInputOption: 'longInput',
  checkboxOption: 'checkBox',
  radioOption: 'radio',
  dateOption: 'date',
  // for answer purposes
  // textarea: 'textarea'
};

export const optionElementType = {
  input: [
    {
      containerClass: 'width100',
      name: 'value',
      elementType: 'input',
      type: 'text',
      inputOptions: {
        type: 'text',
        readOnly: true,
        placeholder: 'optionsPlaceHolderText',
        classes: 'flex-100',
        config: { readOnly: true },
      },
    },
  ],
  number: [
    {
      containerClass: 'width100',
      name: 'value',
      elementType: 'input',
      type: 'number',
      inputOptions: {
        type: 'number',
        placeholder: 'optionsPlaceHolderText',
        classes: 'flex-100',
        config: { readOnly: true },
      },
    },
  ],
  date: [
    {
      containerClass: 'width100',
      name: 'value',
      elementType: 'input',
      type: 'date',
      inputOptions: {
        type: 'date',
        readOnly: true,
        placeholder: 'optionsPlaceHolderText',
        classes: 'flex-100',
        config: { readOnly: true },
      },
    },
  ],
  longInput: [
    {
      containerClass: 'width100',
      name: 'value',
      elementType: 'textarea',
      type: 'text',
      inputOptions: {
        readOnly: true,
        placeholder: 'optionsPlaceHolderText',
        classes: 'flex-100',
        config: { readOnly: true, rows: 2 },
      },
    },
  ],
  checkBox: [
    {
      containerClass: 'flex-initial',
      name: 'checkbox',
      elementType: 'checkBox',
      type: 'text',
      value: false,
      classes: 'flex-initial paddEnd5px',
    },
    {
      containerClass: 'flex inputWithIcon',
      name: 'label',
      elementType: 'input',
      type: 'text',
      inputOptions: {
        type: 'text',
        placeholder: 'optionsPlaceHolderLabel',
        classes: 'flex',
        validation: { required: true },
        config: { noLabel: true },
      },
    },
  ],
  radio: [
    {
      containerClass: 'flex-initial',
      name: 'radio',
      elementType: 'radio',
      type: 'text',
      value: false,
      classes: 'flex-initial paddEnd5px',
    },
    {
      containerClass: 'flex inputWithIcon',
      name: 'label',
      elementType: 'input',
      type: 'text',
      inputOptions: {
        type: 'text',
        placeholder: 'optionsPlaceHolderLabel',
        classes: 'flex',
        validation: { required: true },
        config: { noLabel: true },
      },
    },
  ],
};

export const allowedFieldsList = {
  timeDone: 'timeDone',
  time: 'time',
  intensity: 'intensity',
  speed: 'speed',
  distance: 'distance',
  weight: 'weight',
  rest: 'rest',
  reps: 'reps',
  tonnage: 'tonnage',
  totalTimeDone: 'totalTimeDone',
  totalDistance: 'totalDistance',
};

const chartTypes = {
  bar: 'bar',
  line: 'line',
};

export const statisticsConfig = {
  timeDone: () => ({
    propValue: allowedFieldsList.totalTimeDone,
    measurements: units.other.TIME,
    chartType: chartTypes.line,
    inObject: null,
  }),
  distance: () => ({
    propValue: allowedFieldsList.totalDistance,
    measurements: units.distanceUnits.CM,
    chartType: chartTypes.line,
    inObject: null,
  }),
  reps: () => ({
    propValue: allowedFieldsList.reps,
    measurements: units.other.BLANK,
    chartType: chartTypes.bar,
    inObject: 'log',
  }),
  weight: () => ({
    propValue: allowedFieldsList.weight,
    measurements: units.weightUnits.KG,
    chartType: chartTypes.bar,
    inObject: 'log',
  }),
  speed: () => ({
    propValue: allowedFieldsList.speed,
    measurements: units.other.BLANK,
    chartType: chartTypes.line,
    inObject: 'log',
  }),
  intensity: () => ({
    propValue: allowedFieldsList.intensity,
    measurements: units.other.BLANK,
    chartType: chartTypes.line,
    inObject: 'log',
  }),
  rest: () => ({
    propValue: allowedFieldsList.rest,
    measurements: units.other.TIME,
    chartType: chartTypes.line,
    inObject: 'log',
  }),
  tonnage: () => ({
    propValue: allowedFieldsList.tonnage,
    measurements: units.weightUnits.KG,
    chartType: chartTypes.bar,
    inObject: null,
  }),
  totalTimeDone: () => ({
    propValue: allowedFieldsList.totalTimeDone,
    measurements: units.other.TIME,
    chartType: chartTypes.line,
    inObject: null,
  }),
  totalDistance: () => ({
    propValue: allowedFieldsList.totalDistance,
    measurements: units.distanceUnits.CM,
    chartType: chartTypes.line,
    inObject: null,
  }),
};

export const aspireColorsPicker = [
  '#007bff',
  '#3c4889',
  '#6f42c1',
  '#e24585',
  '#dc3545',
  '#e85c3c',
  '#ffc107',
  '#28a745',
  '#20c997',
  '#17a2b8',
];

export const repeatEventType = {
  daily: 'daily',
  weekly: 'weekly',
};

export const repeatEventConditions = {
  never: 'never',
  untilDate: 'untilDate',
  numOfTimes: 'numOfTimes',
};

export const eventTypes = {
  personal: 'personal',
  group: 'group',
  nutrition: 'nutrition',
  publicEvent: 'publicEvent',
  other: 'other',
};

export const eventStatus = {
  pending: 'pending',
  confirmed: 'confirmed',
  canceled: 'canceled',
  completed: 'completed',
  postponed: 'postponed',
};

export const eventResponse = {
  confirmed: 'confirmed',
  canceled: 'canceled',
};
export const categoryColor = {
  blue: '#3c4889',
  purple: '#5954a0',
  darkPink: '#b3387e',
  pink: '#e24585',
  orange: '#e85c3c',
  green: '#54a42a',
};

export const requestTypes = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
};

export const firstDayOfTheWeek = {
  sundayToSunday: 0,
  mondayToMonday: 1,
};

export const timeInputDisplay = {
  timegrid: 'timegrid',
  timeWheel: 'time',
};

export const timeInputSteps = {
  fiveMinutes: 5,
  tenMinutes: 10,
  fifteenMinutes: 15,
  thirtyMinutes: 30,
};

export const userDevices = {
  android: 'android',
  chrome: 'chrome',
  ios: 'ios',
  web: 'web',
};


export const serviceIcons = {
  free_trainer: 'la la-user',
  professional_trainer: 'las la-certificate',
  free_trainer_m: 'la la-user',
  professional_trainer_m: 'las la-certificate',
  gold_trainer: 'las la-trophy',
};
