import { actionTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { handleErrorResponse, handleSuccessResponse } from './index';
import { utils } from '../../services/utilsService';

const setWorkoutSessions = (data, nextPage) => ({
  type: actionTypes.SET_WORKOUT_SESSIONS,
  payload: data,
  nextPage
});

const setWorkoutSessionDetails = data => ({
  type: actionTypes.SET_SESSION_DETAILS,
  payload: data
});

const setProgramSessionDetails = data => ({
  type: actionTypes.SET_PROGRAM_SESSION_DETAILS,
  payload: data
});

const setUnfinishedSession = data => ({
  type: actionTypes.SET_UNFINISHED_SESSION_DETAILS,
  payload: data
});

const setSessionDuration = data => ({
  type: actionTypes.SET_DURATION,
  payload: data
});

export const getWorkoutSessions = (queryObj, nextPage) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : {};
    const data = await apiMethods.sessions.getWorkoutSessions(query);
    dispatch(setWorkoutSessions(data, nextPage));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getWorkoutSessionData = (publicId, isPopulate) => async (dispatch) => {
  try {
    const data = await apiMethods.sessions.getWorkoutSession(publicId, isPopulate);
    dispatch(setWorkoutSessionDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getSessionPageData = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.sessions.getSessionPageData(publicId);
    dispatch(setWorkoutSessionDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getWorkoutProgramForSession = queryObj => async (dispatch) => {
  try {
    const queryO = { ...queryObj, isSingleUser: true };
    const query = queryO ? utils.buildQuery(queryO) : '';
    const data = await apiMethods.sessions.getWorkoutProgramForSession(query);
    dispatch(setProgramSessionDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const createWorkoutSession = body => async (dispatch) => {
  try {
    let result = true;
    const data = await apiMethods.sessions.create(body);
    result = await dispatch(getWorkoutSessionData(data.public_id));
    dispatch(handleSuccessResponse(data));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const createPastSession = body => async (dispatch) => {
  try {
    const result = true;
    const data = await apiMethods.sessions.createPastSession(body);
    dispatch(handleSuccessResponse(data));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const updateCurrentSession = (body, refreshData) => async (dispatch) => {
  try {
    let result = true;
    await apiMethods.sessions.updateCurrentSession(body);
    if (refreshData) {
      result = await dispatch(getWorkoutSessionData(body.session.public_id));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const updateSession = body => async (dispatch) => {
  try {
    await apiMethods.sessions.updateSession(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getUnfinishedSessionData = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : {};
    const result = await apiMethods.sessions.getUnfinishedSessionData(query);
    dispatch(setUnfinishedSession(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const editWorkoutSession = (body, refreshData) => async (dispatch) => {
  try {
    let result = true;
    await apiMethods.sessions.edit(body);
    if (refreshData) {
      result = await dispatch(getWorkoutSessionData(body.session.public_id));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeWorkoutSession = publicIds => async (dispatch) => {
  try {
    if (!Array.isArray(publicIds)) {
      publicIds = [publicIds];
    }
    await apiMethods.sessions.remove(publicIds);
    return true;
    // dispatch(removeSessionWorkLogs(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const handleSessionDone = body => async (dispatch) => {
  try {
    const data = await apiMethods.sessions.handleSessionDone(body);
    dispatch(setSessionDuration(data));
    return [];
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const addMediaToSession = body => async (dispatch) => {
  try {
    return await apiMethods.sessions.addMedia(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeMediaFromSession = body => async (dispatch) => {
  try {
    const result = true;
    await apiMethods.sessions.removeMedia(body);
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getSessionMedia = publicId => async (dispatch) => {
  try {
    return await apiMethods.sessions.getSessionMedia(publicId);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return [];
  }
};
