/* eslint-disable no-trailing-spaces,max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import './Filter.css';

class FilterStripe extends Component {
  constructor (props) {
    super(props);
    this.state = {
      chosenFilter: [],
      chosenFilterPublicIds: [],
      byProp: this.props.byProp || 'public_id',
      filterIsOpen: false,
      defaultChosen: this.props.defaultChosenIndex,
      skeleton: [1, 2, 3, 4, 5, 6, 7, 8]
    };
  }

  componentDidMount () {
    this.state = {
      chosenFilter: [],
      byProp: this.props.byProp || 'public_id'
    };
  }

  categoryClicked (category) {
    let data = category;
    if (this.state.byProp) {
      data = category[this.state.byProp];
    }
    const chosenField = category[this.state.byProp] || category.public_id;
    const chosenFieldId = category._id || category[this.state.byProp] || category.public_id;
    this.state.defaultChosen = null;
    if (!this.state.chosenFilterPublicIds) this.state.chosenFilterPublicIds = [];
    if (this.props.singleSelect) {
      this.state.chosenFilter = [chosenFieldId];
      this.state.chosenFilterPublicIds = [chosenField];
    } else if (this.state.chosenFilter.indexOf(chosenFieldId) === -1) {
      this.state.chosenFilter.push(chosenFieldId);
      this.state.chosenFilterPublicIds.push(chosenField);
    } else {
      this.state.chosenFilter.splice(this.state.chosenFilter.indexOf(chosenFieldId), 1);
      this.state.chosenFilterPublicIds.splice(this.state.chosenFilter.indexOf(chosenField), 1);
    }
    // update Component
    this.filterChosen();
    this.setState({ ...this.state });
  }

  filterChosen () {
    const body = {
      selectedIds: this.state.chosenFilter,
      publicIds: this.state.chosenFilterPublicIds
    };
    this.props.onFilterChange(body);
  }

  renderFilter (categories) {
    if (categories && categories.length > 0) {
      return categories.map((category, index) => {
        const isChosen = this.state.defaultChosen === index || this.state.chosenFilter.indexOf(category[this.state.byProp]) !== -1;
        return (
          <button
            type="button"
            key={category._id || category.public_id}
            className={`btn btn-exerciseType text-capitalize ${isChosen ? 'chosen' : ''}`}
            onClick={() => this.categoryClicked(category)}>{category.title[this.props.appLang] ? category.title[this.props.appLang] : category.title.en}
          </button>
        );
      });
    }
    return (
      <div className="flex-100 layout-row layout-align-start-center exSkeleton placeHolderContainer height45px">
        { this.state.skeleton && this.state.skeleton.map((num) => (
          <div key={`key_${num}`} className="flex-initial layout-row layout-wrap layout-align-start-center">
            <label className="propsName borderRadius3 sidePadd2px" style={{ width: `${Math.floor(Math.random() * 16) + 50}px` }}>
              <span className="flex-initial layout-row layout-wrap layout-align-start-start filterPlaceHolder positionRelative loading" />
            </label>
          </div>
        ))}
      </div>
    );
  }

  render () {
    const {
      filterList, className, title, classNameOverflow, flowTitle, children
    } = this.props;
    const filterBts = this.renderFilter(filterList);
    return (
      <div className={`FilterStripe flex-100 layout-row layout-wrap layout-align-start-start ${className || ''}`}>
        { title && (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom10px paddStart15px">
            <p className="flex-100 text-uppercase font14 margin0"> <Trans i18nKey={title} />  </p>
          </div>
        )}
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className={`flex-100 layout-row layout-align-start-start paddBottom5px ${this.props.isOverflow ? 'horizontal-overflow' : 'layout-wrap'}`} style={{ width: this.props.isOverflow ? `${window.innerWidth}px` : 'auto' }}>
            <div className={`layout-row layout-align-start-start ${classNameOverflow || ''}`}>
              { !this.props.insideFilterBtns && children}
              <div className="layout-row layout-wrap layout-align-start-start">
                { flowTitle && (
                  <div className="flex-100 layout-row layout-align-start-start">
                    <p className="margin0 font13 colorGrey"><Trans i18nKey={flowTitle} /></p>
                  </div>
                )}
                <div className="flex-initial width100 layout-row layout-align-start-start">
                  {this.props.insideFilterBtns && children}
                  <div className="flex-initial layout-row layout-align-start-start filter-btns">
                    {!this.props.disableFilterList && filterBts}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FilterStripe.defaultProps = {
  classNameOverflow: '',
  singleSelect: false,
  isOverflow: false
};

FilterStripe.propTypes = {
  filterList: PropTypes.arrayOf(PropTypes.object),
  classNameOverflow: PropTypes.string,
  singleSelect: PropTypes.bool,
  isOverflow: PropTypes.bool,
  onFilterChange: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang
});

export default connect(mapStateToProps)(FilterStripe);
