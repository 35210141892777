import React from 'react';
import ReactSVG from 'react-svg';
import cancelIcon from '../../assets/icons/trash.svg';
import editIcon from '../../assets/icons/edit.svg';
import './StripProgress.css';

const MinimalStrip = props => (
  <div className={`StripProgress MinimalStrip flex-100 layout-row layout-wrap layout-align-start-start stripe transition03 ${props.itemClass}`}>
    <div className="flex-100 layout-row layout-wrap layout-align-start-start">
      <div className="flex-initial layout-row layout-wrap layout-align-center-center">
        {props.order && (
          <div className="circle layout-row layout-wrap layout-align-center-center">
            <span className="orderNum">{props.order}</span>
          </div>
        )}
      </div>
      <div className="flex layout-row layout-align-space-between-center paddStart10px">
        { props.appLang ? (
          <div className="flex layout-column layout-wrap layout-align-start-start">
            {(props && props.content && ((props.content[props.appLang] || props.content.en) || typeof props.content === 'string')) && (
              <p className="margin0 paddTop5px">{((props.content && (props.content[props.appLang] || props.content.en)) || (typeof props.content === 'string' && props.content))}</p>
            )}
            {((props && props.subtitle && (props.subtitle[props.appLang] || props.subtitle.en)) || (props && props.description && (props.description[props.appLang] || props.description.en)) || (props && (props.subtitle || props.description))) && (
              <p className="subtitle colorGrey paddTop5px margin0">{(props.subtitle[props.appLang] || props.subtitle.en) || (props.description[props.appLang] || props.description.en) || (props.subtitle || props.description)}</p>
            )}
          </div>
        ) : (
          <div className="flex layout-column layout-wrap layout-align-start-start">
            { (props.title || props.content) && (
              <p className="margin0 paddTop5px">{props.title || props.content}</p>
            )}
            { (props.subtitle || props.description) && (
              <p className="subtitle colorGrey margin0 paddTop5px">{props.subtitle || props.description}</p>
            )}
          </div>
        )}
        <div className="flex-initial layout-row layout-wrap layout-align-end-center">
          {props.editItem && (
            <button type="button" className="btn btn-icon btn-transparent editIcon" onClick={props.editItem}>
              <ReactSVG src={editIcon} className="doneIcon_svg" />
            </button>
          )}
          {props.removeItem && (
            <button type="button" className="btn btn-icon btn-transparent cancelIcon" onClick={props.removeItem}>
              <ReactSVG src={cancelIcon} className="doneIcon_svg" />
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default MinimalStrip;
