/* eslint-disable no-trailing-spaces,max-len */
import axios from 'axios';
import { aspireClient, aspireClientConfig } from './apiService';
import { utils } from './utilsService';
import { requestTypes } from './enums';

let cancelToken

const handleRequest = async (api, type, data, callback, onError) => {
  try {
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.")
      console.log("Operation canceled due to new request.")
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const config = {...aspireClientConfig, cancelToken: cancelToken.token}
    let body = { method: type, url: api, data: { ...data }, ...config };
    if (type === requestTypes.GET) {
      const query = utils.buildQuery(data);
      const url = `${api}${query}`;
      body = { method: type, url, ...config};
    }
    const response = await aspireClient(body);
    if (response && callback) callback(response);
    return response;
  } catch (error) {
    console.log(error)
    if (onError) onError();
  }
}

export default handleRequest;
