/* eslint-disable react/jsx-first-prop-new-line */
import React, { useState, Fragment } from 'react';
import { Trans } from 'react-i18next';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import { Eventcalendar, localeEn, localeEs, localeHe } from '@mobiscroll/react';
import EventCard from '../CalendarUI/EventCard_2';
import LoadingDots from '../UI/LoadingDots';
import '../MobiEventCalendar/MobiCalendar.css';
import './EventsList.css';

const AgendaList = (props) => {
  const locales = { he: localeHe, es: localeEs, en: localeEn };
  const { openEventDrawer, getEventDetails, onPageLoading, user } = props;
  const locale = (locales && locales[props.appLang]) || locales.en;
  const localtime = props.settings && props.settings.timezone || momentTZ.tz.guess();
  const [toggle, setToggle] = useState(false);

  const renderEvent = (data) => {
    const event = data.original;
    if(event && !(event.participants.length > 0)) return null;
    return (
      <EventCard
        key={event.public_id}
        data={data}
        eventOriginal={event}
        user={user}
        clicked={() => getEventDetails(event)}
        clientsListKV={props.clientsListKV}
        timezone={localtime}
        trainerInfo={props.trainerInfo ? props.trainerInfo : (user.trainer || user)}
      />
    );
  }

  const showMore = () => setToggle(!toggle);

  const eventsList = props.events && props.events.length > 0 && props.events.map((event) => {
    event.isWaiting = event.waitingForApproval;
    event.startDate = momentTZ.tz(event.startDate, localtime).format();
    event.endDate = momentTZ.tz(event.endDate, localtime).format();
    event.start = momentTZ.tz(event.startDate, localtime).format();
    event.end = momentTZ.tz(event.endDate, localtime).format();
    return event;
  });

  return (
    <div className="AgendaList flex-100 layout-row layout-wrap layout-align-start-center marginBottom10px">
      <div className="MobiCalendar width100 layout-row layout-wrap layout-align-start-start aspire-theme">
        <Eventcalendar
          className="width100"
          theme="ios"
          min={new Date()}
          themeVariant="light"
          locale={locale}
          clickToCreate={false}
          dragToCreate={false}
          dragToMove={false}
          dragToResize={false}
          data={eventsList}
          height={560}
          dateFormatLong="DD DDDD, MMM YYYY"
          // eventOrder={orderMyEvents}
          view={{ calendar: { type: 'week', size: 1, labels: false }, agenda: { type: 'week', size: 1 } }}
          onPageLoading={(event) => {onPageLoading(event);}}
          renderEvent={(data) => renderEvent(data)}
        />
      </div>
      <div className="flex-100 layout-row layout-wrap layout-align-start-center paddTop5px">
        <LoadingDots hiddenLoader />
      </div>
      { (props.isTrainer || props.showCreateBtn) && (
        <div className="flex-100 layout-row layout-wrap layout-align-center-start padd5px15px">
          <button
            type="button"
            onClick={() => openEventDrawer()}
            className="btn-aspire-primary whiteBg flex-100 layout-row layout-align-center-center height50px borderRadius5 addWorkoutBtn fontWeight600 marginBottom10px">
            <i className="la la-plus font24 primary" />
            <span className="paddStart5px">
              <p className="margin0 font15 text-capitalize primary"><Trans i18nKey="bookASession" /></p>
            </span>
          </button>
        </div>
      )}
      { props.showMore && (
        <div className="flex-100 layout-row layout-wrap layout-align-center-start">
          <a onClick={() => { showMore() }} className="flex-initial layout-row layout-align-start-start">
            <p className="flex-initial colorGrey margin0 font15 sidePadd2px text-capitalize">
              <Trans i18nKey={toggle ? 'showLess' : 'showMore'} />
              {' '}
            </p>
          </a>
        </div>
      )}
    </div>
  );
}


export default AgendaList;
