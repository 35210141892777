/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { checkTutorialGuide } from '../../store/actions';
import tutorialsData from './tutorialJson';
import 'react-tippy/dist/tippy.css';
import './TutorialGuide.css';
import TutorialGuideBtn from './TutorialGuideBtn';

class ActivateGuideCheck extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: tutorialsData[this.props.dataKey],
      isActive: true
    };
  }

  checkTutorialGuide() {
    this.state.isActive = this.props.checkTutorialGuide(this.props.dataKey, { ...this.state.data })
    return this.state.isActive;
  }

  render () {
    const isActive = this.checkTutorialGuide()
    return (
      <Fragment>
        { isActive && (
          <TutorialGuideBtn dataKey={this.props.dataKey} history={this.props.history} />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  checkTutorialGuide: (key, data) => dispatch(checkTutorialGuide(key, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateGuideCheck);
