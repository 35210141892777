/* eslint-disable react/destructuring-assignment */
import React, { Component, Suspense } from 'react';
import ReactGA from "react-ga4";
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import localStorage from 'local-storage';
import { hotjar } from 'react-hotjar';
import { actionTypes } from '../../store/actions/actionTypes';
import { checkIfLoggedIn, openUserMessage } from '../../store/actions';
import { addClass, securePostMsgUtils, changeThemeColor } from '../../services/utilsService';
import NavTop from '../../Components/NavTop/NavTop';
import PublicRoutes from '../../Views/PublicRoutes';
import FeedPage from '../FeedPage/FeedPage';
import ModalsContainer from '../ModalPages/ModalsFirstLayerContainer';
import ModalsSecondLayerContainer from '../ModalPages/ModalsSecondLayerContainer';
import ModalsThirdLayerContainer from '../ModalPages/ModalsThirdLayerContainer';
import { postMsgTypes } from '../../services/postMsgTypes';
import { envs } from '../../services/globals';
import './App.css';

const TRACKING_ID = (window && window.globals && window.globals.googleAnalitics) || ''; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

if (window.globals.ENV === envs.PROD) {
  hotjar.initialize(2680146, 6);
}

const publicPages = ['/intro', '/login', '/signUp', '/clientInvite', '/forgotPassword', '/info'];
/* Views */
const WelcomeRoutes = React.lazy(() => import('../../Views/WelcomeRoutes'));
const StatisticsRoutes = React.lazy(() => import('../../Views/StatisticsRoutes'));
const WorkoutsRoutes = React.lazy(() => import('../../Views/WorkoutsRoutes'));
const WorkoutPlanRoutes = React.lazy(() => import('../../Views/WorkoutPlanRoutes'));
const StartSessionRoutes = React.lazy(() => import('../../Views/StartSessionRoutes'));
const SessionRoutes = React.lazy(() => import('../../Views/SessionRoutes'));
const AchievementsRoutes = React.lazy(() => import('../../Views/AchievementsRoutes'));
const SetGoalRoutes = React.lazy(() => import('../../Views/SetGoalRoutes'));
const MyExercisesRoutes = React.lazy(() => import('../../Views/MyExercisesRoutes'));
const CalendarRoutes = React.lazy(() => import('../../Views/CalendarRoutes'));
const SettingsRoutes = React.lazy(() => import('../../Views/SettingsRoutes'));
const HelpCenterRoutes = React.lazy(() => import('../../Views/HelpCenterRoutes'));
const PaperworkRoutes = React.lazy(() => import('../../Views/PaperworkRoutes'));
const ContactsRoutes = React.lazy(() => import('../../Views/ContactsRoutes'));
/* Pages */
const ActivityPage = React.lazy(() => import('../ActivityPage/ActivityPage'));
const CreateClientPage = React.lazy(() => import('../CreateClientPage/CreateClientPage'));
const AchievementDetailsPage = React.lazy(() => import('../AchievementsPages/AchievementDetailsPage'),);
const ExerciseDetailsPage = React.lazy(() => import('../ModalPages/ExerciseDetailsPage'));
const DocumentDetailsPage = React.lazy(() => import('../DocumentPages/DocumentDetails'));
const SurveyFillPage = React.lazy(() => import('../SurveyPages/SurveyFillPage'));

const AuthRoute = ({ component: Component, userAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (userAuth ? <Component {...props} /> : <Redirect to="/intro" />)}
    />
  );
};

const configLang = (lang) => {
  const body = document.getElementsByTagName('body')[0];
  body.setAttribute('dir', lang && lang === 'he' ? 'rtl' : 'ltr');
  body.setAttribute('lang', lang);
  moment.locale(lang);
};

class App extends Component {
  async componentDidMount() {
    try {
      const { i18n } = this.props;
      configLang(i18n.language);
      const deviceType = securePostMsgUtils.detectUserAgent();
      addClass('#root', deviceType);
      this.props.setAppLang(i18n.language);
      this.changePageListener();
      // on welcome page we stop here
      if (this.props.history.location.pathname === '/') return;
      const data = await this.props.checkIfLoggedIn();
      // if (data.messages && data.messages.length > 0) this.props.openUserMessage(data.messages[0])
      if (!data) {
        if (publicPages.indexOf(window.location.pathname) === -1) {
          this.props.history.push('/intro');
        }
      } else if (data || data.user) {
        if (data.user.public_id) hotjar.identify(data.user.public_id, { userProperty: data.user.userType || 'user' });
        this.checkUserLanguage(data);
      }
      this.configureThemeValue();
    } catch (e) {
      console.log('handle error');
    }
  }

  changePageListener() {
    this.props.history.listen((location) => {
      this.props.hideAlert();
      console.log('location: ', location)
      if (securePostMsgUtils.listenToValidMessage()) {
        const pathname = {
          key: postMsgTypes.SAVE_CURRENT_URL,
          url: location.pathname,
        };
        const stringifierUrl = JSON.stringify(pathname);
        window.ReactNativeWebView.postMessage(stringifierUrl);
        ReactGA.set({ page: location.pathname });
        hotjar.stateChange(`${location.pathname}`);
        // console.log('on route change', location.pathname, action);
      }
    });
  }

  configureThemeValue() {
    let themeKey = 'defaultTheme';
    try {
      themeKey = localStorage.get('Aspire-theme');
    } catch (e) {
      console.log('no value theme, change to default');
      themeKey = 'defaultTheme';
    }
    if (themeKey && themeKey !== '') {
      themeKey = 'defaultTheme';
    }
    changeThemeColor(themeKey);
    document.body.classList.add(themeKey);
  }

  checkUserLanguage(data) {
    const { i18n, appLang } = this.props;
    const lang = data.user.settings.language || data.settings.language;
    if (appLang !== lang) {
      configLang(lang);
      i18n.changeLanguage(lang);
      moment.locale(lang);
      this.props.setAppLang(lang);
    }
  }

  handleTextIconClick(key, url, currentModel) {
    if (key === actionTypes.GO_TO_PAGE) {
      let urlRoute = '';
      if (currentModel && this.props.chosenPublicId) {
        urlRoute = `${url}/${this.props.chosenPublicId}`;
      } else urlRoute = `${url}`;
      this.props.history.push(urlRoute);
    }
  }

  render() {
    const auth = this.props.authenticated;
    const deviceType = securePostMsgUtils.detectUserAgent();
    return (
      <div
        className="App flex-100 layout-row layout-wrap layout-align-start-start"
        device={deviceType}
      >
        {auth && this.props.showTopNav && (
          <NavTop
            isAuth={auth}
            history={this.props.history}
            textIconClick={(key, url, currentModel) => this.handleTextIconClick(key, url, currentModel)}
          />
        )}
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <Suspense fallback={<div className="gogo-loading" />}>
            {/* Views */}
            <Route path="/" component={PublicRoutes} />
            <AuthRoute path="/welcome" userAuth={auth} component={WelcomeRoutes} />
            {/* pages */}
            <AuthRoute path="/feed" userAuth={auth} component={FeedPage} />
            <AuthRoute path="/activities" exact userAuth={auth} component={ActivityPage} />
            <AuthRoute path="/inviteClient" exact userAuth={auth} component={CreateClientPage} />
            {/* Views */}
            <AuthRoute path="/statistics" userAuth={auth} component={StatisticsRoutes} />
            <AuthRoute path="/workouts" userAuth={auth} component={WorkoutsRoutes} />
            <AuthRoute path="/program" userAuth={auth} component={WorkoutPlanRoutes} />
            <AuthRoute path="/startSession" userAuth={auth} component={StartSessionRoutes} />
            <AuthRoute path="/session" userAuth={auth} component={SessionRoutes} />
            <AuthRoute path="/achievements" userAuth={auth} component={AchievementsRoutes} />
            {/* page */}
            <AuthRoute
              path="/achievement/:public_id"
              userAuth={auth}
              component={AchievementDetailsPage}
            />
            {/* Views */}
            <AuthRoute path="/setGoal" userAuth={auth} component={SetGoalRoutes} />
            <AuthRoute path="/myExercises" userAuth={auth} component={MyExercisesRoutes} />
            <AuthRoute path="/calendar" userAuth={auth} component={CalendarRoutes} />
            {/* page */}
            <AuthRoute
              path="/exercise/:public_id"
              userAuth={auth}
              component={ExerciseDetailsPage}
            />
            {/* Views */}
            <AuthRoute path="/paperwork" userAuth={auth} component={PaperworkRoutes} />
            <AuthRoute path="/contacts" userAuth={auth} component={ContactsRoutes} />
            <AuthRoute path="/settings" userAuth={auth} component={SettingsRoutes} />
            <AuthRoute path="/helpCenter" userAuth={auth} component={HelpCenterRoutes} />
            <Switch>
              {/* pages */}
              <AuthRoute
                path="/fillPaperwork/document/:public_id"
                exact
                userAuth={auth}
                component={DocumentDetailsPage}
              />
              <AuthRoute
                path="/fillPaperwork/survey/:id"
                exact
                userAuth={auth}
                component={SurveyFillPage}
              />
            </Switch>
          </Suspense>
        </div>
        <ModalsContainer history={this.props.history} />
        <ModalsSecondLayerContainer history={this.props.history} />
        <ModalsThirdLayerContainer history={this.props.history} />
        <div
          className={`flex-100 layout-row layout-wrap layout-align-start-start NavBottom-spaceMaker transition05 ${
            this.props.navBottom && this.props.navBottom.showBottomNav ? 'open' : ''
          }`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showTopNav: state.appR.showTopNav,
  authenticated: state.userR.authenticated,
  userAuth: state.userR.auth,
  chosenPublicId: state.workoutR.chosenPublicId,
  navBottom: state.appR.navBottom,
  appLang: state.appR.appLang,
});

const mapDispatchToProps = (dispatch) => ({
  setAppLang: (lang) => dispatch({ type: actionTypes.SET_APP_LANG, payload: lang }),
  checkIfLoggedIn: () => dispatch(checkIfLoggedIn()),
  openUserMessage: (data) => dispatch(openUserMessage(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)));
