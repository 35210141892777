import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { aspirePlaceholder, userTypes } from '../../services/enums';
import './UserBg.css';
import Image from '../UI/Image';
import { styleUtils } from '../../services/utilsService';

class UserBg extends Component {
  constructor (props) {
    super(props);
    this.state = {
    };
  }

  getCarouselRef (carousel) {
    this.state.carouselRef = carousel;
  }

  render () {
    const { user, bgBottomColor, bgMidColor, bgTopColor, introMode, showIntro } = this.props;
    const thumbnail = user.backgroundImage && user.backgroundImage.thumbnail ? user.backgroundImage.thumbnail : null;
    const source = user.backgroundImage && user.backgroundImage.source ? user.backgroundImage.source : aspirePlaceholder;
    return (
      <div className={`UserBg flex-100 layout-row layout-wrap layout-align-start-start ${user.userType === userTypes.TRAINER ? 'isTrainer' : 'isClient'}`}>
        <div className={`profileImg backgroundImage layout-column layout-align-center-center ${introMode ? 'introduction' : ''}`}>
          <div className="backgroundImage width100 layout-column layout-align-end-center borderRadius15 bottom userBgImage" style={styleUtils.imageBackground(aspirePlaceholder[0], bgBottomColor, bgMidColor, bgTopColor, null)}>
            <div className="width100 layout-row layout-wrap layout-align-space-between-center zIndex5">
              <Image
                backgroundImage
                src={thumbnail}
                fallbackSrc={source}
                height={600}
                className="userBgImage width100 flex-100 backgroundImage"
                color1={bgBottomColor}
                color2={bgMidColor}
                color3={bgTopColor}
                backgroundProps={{backgroundPosition: 'top center'}}
              />
              <div className="flex-100 layout-row layout-wrap layout-align-space-between-center sidePadd15px">
                {showIntro && (
                  <div className="flex-initial layout-row layout-wrap layout-align-end-center">
                    <button type="button" className="cyan" onClick={() => this.props.goToIntro()}><Trans i18nKey="intro" /></button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!user.firstName && (<div className="profileImg ProgramBg loading positionAbsoluteTop profileLoader zIndex10" />)}
        </div>
      </div>
    );
  }
}

export default UserBg;
