/* eslint-disable max-len */
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { styleUtils } from '../../services/utilsService';
import './UserInfo.css';
import { userTypes } from '../../services/enums';

class UserInfo extends Component {
  constructor (props) {
    super(props);
    this.state = {
      users: [],
      userIds: [],
      moreUsers: 0,
      maxFor: this.props.maxFor || 2
    };
    this.getUserIds(this.props.users);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.updateUserIds) this.getUserIds(nextProps.users);
    return true;
  }

  getUserIds () {
    const { inProps } = this.props;
    const isTrainer = this.props.user && this.props.user.userType === userTypes.TRAINER;
    this.state.userIds = [];
    if (Array.isArray(this.props.users) || inProps) {
      if (isTrainer) {
        this.props.users.map((user, index) => {
          if (inProps) {
            if (user && user[inProps]) {
              this.state.userIds.push(user[inProps]);
            }
          } else if (user) this.state.userIds.push(user);
        })
      } else {
        this.state.userIds = this.props.users;
      }
    }
    else {
      this.state.userIds.push(this.props.users);
    }
  }

  getUserName(user){
    const { userIds, } = this.state;
    let name = `${this.props.user.firstName}`;
    if (this.props.clientsListKV && this.props.clientsListKV[user] && this.props.clientsListKV[user].name) {
      if (userIds.length === 1) {
        name = this.props.clientsListKV[user].name;
      } else name = this.props.clientsListKV[user].name.split(' ')[0];
    } else if (user === this.props.user.public_id) {
      return name;
    } else name = user && user.name && user.name.split(' ')[0]
    return name;
  }

  render () {
    const { maxFor, userIds, } = this.state;
    const isTrainer = this.props.user && this.props.user.userType === userTypes.TRAINER;
    if (userIds && userIds.length > 1) {
      this.state.moreUsers = this.props.users.length - (maxFor || 2);
    }
    return (
      <div
        className={`UserInfo transition03 ${this.props.className || 'flex layout-row layout-wrap layout-align-start-center'}`}>
        <div className="flex-100 layout-row layout-align-start-start">
          <div
            className={`flex-initial layout-row layout-align-start-start UserImages ${userIds.length > 1 ? 'moreUsers' : ''}`}>
            { this.state.userIds.slice(0, isTrainer ? maxFor : 1).map((user, index) => {
              let thumbnail = this.props.user.thumbnail && this.props.user.thumbnail.uri ? this.props.user.thumbnail.uri : 'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/placeholderGray_ioowu3.jpg';
              if (this.props.clientsListKV[user] && this.props.clientsListKV[user].thumbnail && this.props.clientsListKV[user].thumbnail.uri) {
                thumbnail = this.props.clientsListKV[user].thumbnail.uri;
              } else if (this.props.clientsListKV[user]) {
                thumbnail = 'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/placeholderGray_ioowu3.jpg';
              }
              return (
                <div key={user} className="flex-initial layout-row layout-wrap layout-align-start-start userThumbnail" style={{zIndex: index + 1}}>
                  <div className="image backgroundImage" style={styleUtils.imageBackground(thumbnail)} title="User Image" />
                </div>
              );
            })}
          </div>
          <div className="flex-initial layout-row layout-wrap layout-align-start-start UserNames">
            <p className="margin0 text-capitalize textLimitHeight font14Height">
              { userIds.slice(0, maxFor).map((user, index) => {
                const name = this.getUserName(user)
                return (<span key={user.public_id}>{index > 0 ? ',' : ''} {name}</span>);
              })}
              { this.state.moreUsers > 0 && <span> + {this.state.moreUsers} <Trans i18nKey="more" /></span> }
            </p>
            {this.props.text && <span className="flex-100 font13 fontWeight600 text-capitalize">{this.props.text}</span>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  appLang: state.appR.appLang,
  user: state.userR.userInfo,
  clientsListKV: state.userR.clientsListKV
});

export default connect(mapStateToProps)(UserInfo);
