import React, { useState } from 'react';
import { Trans } from 'react-i18next';

const ColorPicker = props => {
  const [chosenColor, setColor] = useState('transparent');

  const choseColor = (color) => {
    setColor(color);
    props.handleChange(color)
  }

  return (
    <div className={`ColorPicker flex-100 layout-row layout-wrap layout-align-start-start ${props.className}`}>
      { props.label && (
        <label className="formBoxInput primary text-capitalize rendered fontWeight400 valued">
          <Trans i18nKey={props.label || 'pickAColor'} />
        </label>
      )}
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        {props.colors.map((color, index) => (
          <div
            key={color}
            className={`colorIndicator ${index > 0 ? 'marginStart5px' : 'marginStart5px'} ${chosenColor === color ? 'chosen' : ''}`}
            onClick={() => choseColor(color)}
            style={{ backgroundColor: color }} />
            ))}
      </div>
    </div>
  )
};

export default ColorPicker;
