import React, { Fragment, useState } from 'react';
import { Trans } from 'react-i18next';
import { Tooltip } from 'react-tippy';
import { checkAccessControl } from '../../services/utilsService';

const PermissionsGate = (props) => {
  const { permission, className, primaryText, secondaryText, costumeHtml, children, restrictedClass, lockIcon, lockClassName, history, appLang, dissmis: dismiss } = props;
  const [toggle, setToggle] = useState(false);
  const [hasPermission, setPermission] = useState(permission ? checkAccessControl(permission) : true);

  const checkPermission = () => {
    if (permission && !checkAccessControl(permission)) {
      setToggle(true);
      const timeoutTooltip = setTimeout(() => {
        setToggle(false);
        clearTimeout(timeoutTooltip);
      }, 4000);
    }
  }

  const renderHtml = () => {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10">
        <p className="margin0 width100"><Trans i18nKey={primaryText} /> { secondaryText && (<Trans i18nKey={secondaryText} />)}</p>
      </div>
    )
  }

  return (
    <div className={`flex-100 layout-row layout-wrap layout-align-start-start positionRelative ${className}`}>
      { dismiss ? children : (
        <Fragment>
          <Tooltip
            open={toggle}
            position="top-middle"
            className="width100"
            arrow
            arrowSize="big"
            theme="light"
            distance={10}
            html={renderHtml()}
            trigger="manual">
            { !hasPermission && lockIcon && (
              <div className={`flex-100 layout-row layout-wrap layout-align-start-start ${ appLang === 'he' ? 'positionAbsoluteTopLeft' : 'positionAbsoluteTopRight'} zIndex10`}>
                <div className={`flex-initial zIndex10 position-relative ${lockClassName}`}>
                  <div className="btn-subscriptionLock icon-button btn-shadow whiteBg btn rounded">
                    <i className="las la-lock font20 primary" />
                  </div>
                </div>
              </div>
            )}
            <div className={`PermissionsGate flex-100 layout-row layout-wrap layout-align-start-start positionRelative ${hasPermission ? '' : restrictedClass }`} onClick={() => checkPermission()}>
              <div className={`flex-100 layout-row layout-wrap layout-align-start-start positionAbsoluteTopLeft width100 height100 ${hasPermission ? 'displayNone' : '' }`} />
              { children }
            </div>
          </Tooltip>
        </Fragment>
      )}
    </div>
  );
};

export default PermissionsGate;
