import React, { Suspense, Fragment } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import WelcomePage from '../Containers/WelcomePage/WelcomePage';
import Intro from '../Containers/WelcomeUserPage/AppIntroductionPage';
import LoginPage from '../Containers/LoginPage/LoginPage';

const SignUpPage = React.lazy(() =>
  import('../Containers/SignUpPage/SignUpPage')
);

const ForgotPasswordPage = React.lazy(() =>
  import('../Containers/LoginPage/ForgotPasswordPage')
);

const PublicRoutes = ({ match }) => {
  return (
    <Fragment>
      <Suspense fallback={<div className="gogo-loading" />}>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <Switch>
            <Route path="/" exact render={props => <WelcomePage {...props} />} />
            <Route path="/intro" exact render={props => <Intro {...props} />} />
            <Route path="/login" exact render={props => <LoginPage {...props} />} />
            <Route path="/signUp" exact render={props => <SignUpPage {...props} />} />
            <Route path="/forgotPassword" exact render={props => <ForgotPasswordPage {...props} />} />
          </Switch>
        </div>
      </Suspense>
    </Fragment>
  );
};

export default withRouter(PublicRoutes);
