import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Tooltip } from 'react-tippy';
import { resendClientInvitation } from '../../store/actions';
import AspireBtnLoader from '../UI/AspireBtnLoader';
import './InviteUsersCmp.css'

class InviteUsersCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      code: null
    };
  }

  handleCopyCode(client) {
    const text = client.inviteCode;
    navigator.clipboard.writeText(text).then(() => {
        client.openTooltip = true;
        this.setState({ ...this.state} ,
          () => {
            this.timeoutTooltip = setTimeout(() => {
              client.openTooltip = false;
              this.setState({ ...this.state})
              clearTimeout(this.timeoutTooltip);
            }, 3000);
          }
        );
      }, (err) => {
        console.error('Async: Could not copy text: ', err);
      },
    );
  }


  async resendClientInvitation(client) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const body = { publicId: client.public_id, inviteByPhone: !!(client.phoneNumber) };
      const data = await this.props.resendClientInvitation(body);
      if (data && data.text) {
        this.isLoading = false;
        return { error: data && data.text ? data.text : null };
      }
      if (data && data.code) {
        client.inviteCode = data && data.code;
        this.setState({...this.state})
      }
      console.log('resendClientInvitation', data);
      this.isLoading = false;
      return { success: 'invitationSent' };
    } catch (e) {
      this.isLoading = false;
      console.log('handle error');
      return { error: 'error' };
    }
  }

  renderClientStripe() {

  }


  render () {
    const { data } = this.props;
    return (
      <div className="InviteUsersCmp width100 layout-row layout-wrap layout-align-start-center">
        { data && data.length > 0 && data.map((client) => {
           return (
             <div key={client.public_id} className="flex-100 layout-row layout-wrap layout-align-start-center marginBottom10px">
               <div className="flex layout-row layout-wrap layout-align-start-start">
                 <span className="flex-100 font15 fontWeight600">{ `${client.firstName} ${client.lastName}` }</span>
                 <span className="font13 text-muted directionChangeLtr textLimitWidth" style={{ maxWidth: '160px'}}>{ `${client.phoneNumber || client.email}` }</span>
               </div>
               <div className="flex-initial layout-row layout-wrap layout-align-center-center">
                 { client.inviteCode ? (
                   <div className="flex-initial layout-row layout-align-center-center">
                     <div className="flex-initial zIndex10 position-relative">
                       <Tooltip
                         open={client.openTooltip}
                         position="top-middle"
                         className="width100"
                         arrow
                         arrowSize="big"
                         theme="light"
                         distance={10}
                         html={(
                           <div className="flex-100 layout-row layout-wrap layout-align-start-start emailTooltip">
                             <Trans i18nKey="copied" />
                           </div>
                         )}
                         trigger="manual">
                         <button type="button" onClick={() => {this.handleCopyCode(client);}} className="icon-button btn-shadow margin10 btn rounded whiteBackground">
                           <i className={`las la-copy font24 ${client.openTooltip ? 'primary' : 'text-muted'}`} />
                         </button>
                       </Tooltip>
                     </div>
                     <div className="flex-initial layout-column layout-align-center-center">
                       <span className="font13 text-muted"><Trans i18nKey="inviteCode" /></span>
                       <span className="font15 fontWeight600">{ client.inviteCode }</span>
                     </div>
                   </div>
                 ) : (
                   <AspireBtnLoader
                     btnText="resend"
                     containerClassName="flex-initial layout-row layout-wrap layout-align-center-center"
                     classNameText="font15 aspire-text text-capitalize"
                     className="height40px whiteBg"
                     btnClicked={() => (this.resendClientInvitation(client))} />
                 )}
               </div>
             </div>
           )
        })}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  resendClientInvitation: (publicId) => dispatch(resendClientInvitation(publicId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteUsersCmp);
