import React, { useState } from 'react';
import moment from 'moment';
import { Datepicker, localeHe, localeEn, localeEs } from '@mobiscroll/react';
import './DateTimeInputs_new.css';

const DateTime = props => {
  const { onChange, showDatePicker, showTimePicker, timegrid, invalidTime, invalidDate, startDate, stepMinute,
    timeMin, timeMax, calendarSettings, initValue, isIOS} = props;
  let isTimeGrid = !isIOS && (showTimePicker || timegrid) ? ['timegrid'] : ['time'];
  isTimeGrid = (!isIOS && (calendarSettings && calendarSettings.timeInput && calendarSettings.timeInput.display)) ? [calendarSettings.timeInput.display] : isTimeGrid;
  const steps = (calendarSettings && calendarSettings.timeInput && calendarSettings.timeInput.steps) || stepMinute || 15;
  const firstDay = (calendarSettings && calendarSettings.calendarType) || 0;
  const locales = { he: localeHe, es: localeEs, en: localeEn };
  const locale = (locales && locales[props.appLang]) || locales.en;
  const [selectedDate, setSelectedDate] = useState(new Date(initValue) || new Date());
  const [timePickerRef, setTimePickerRef] = React.useState(null);
  const [datePickerRef, setDatePickerRef] = React.useState(null);
  const onDateSelect = (event) => {
    if (!event.value) return ;
    setSelectedDate(event.value);
    if (showTimePicker) timePickerRef.open();
    onChange(event.value);
  }

  const onTimeSelect = (event) => {
    const momentt = moment(event.value);
    if(!momentt.isValid()) return;
    const date = new Date(event.value);
    const hrs = date.getHours();
    const min = date.getMinutes();
    selectedDate.setHours(hrs, min);
    const dateTemp = selectedDate;
    setSelectedDate(selectedDate);
    onChange(dateTemp)
  }

  const clickCurtain = () => {
    if (showDatePicker) {
      datePickerRef.open();
      return;
    }
    if (showTimePicker) setTimePickerRef.open()
  }

  const sameDay = moment(startDate).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD');
  return (
    <div className={`flex-100 layout-row layout-wrap layout-align-start-start positionRelative ${props.className}`}>
      <div className="flex-100 layout-row layout-align-space-between-start dateTime-form-control">
        { showDatePicker && (
          <div className="flex layout-row layout-align-start-start date-form-control">
            <div className="width100 layout-row positionAbsoluteTopLeft height40px dateTime-form-control-curtain zIndex5" onClick={() => {clickCurtain()}} />
            <Datepicker
              locale={locale}
              ref={setDatePickerRef}
              themeVariant="light"
              display="bottom"
              closeOnOverlayClick={false}
              min={props.min || moment().subtract(80,'years')}
              theme="ios"
              firstDay={firstDay}
              controls={['calendar']}
              select="date"
              dateFormat="DDDD, DD MMM"
              defaultValue={selectedDate}
              invalid={invalidDate || []}
              cssClass="date-form-control-popup"
              onClose={(event, inst) => { onDateSelect(event, inst)}}
              onCancel={(event, inst) => { onDateSelect(event, inst)}}
              touchUi />
          </div>
        )}
        { showTimePicker && (
          <div className="flex layout-row layout-align-end-start time-form-control">
            <Datepicker
              locale={locale}
              ref={setTimePickerRef}
              themeVariant="light"
              theme={!isIOS ? (isTimeGrid[0] === 'timegrid' ? 'ios' : 'material') : 'material'}
              display="bottom"
              controls={!isIOS ? isTimeGrid : ['time']}
              timeFormat="HH:mm"
              select="date"
              stepMinute={steps}
              defaultValue={moment(selectedDate).format('HH:mm')}
              touchUi
              min={timeMin}
              max={timeMax}
              invalid={sameDay && invalidTime || []}
              cssClass="date-form-control-popup"
              onCancel={(event, inst) => { onTimeSelect(event, inst)}}
              onClose={(event, inst) => { onTimeSelect(event, inst)}} />
          </div>
        )}
      </div>
    </div>
  )
};

export default DateTime;
