import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import './UIStyle.css';
import Collapse from 'react-css-collapse';

class MusclesInvolved extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMusclesInvolvedOpen: false
    };
  }

  showMoreMusclesInvolved (isOpen) {
    this.setState(prevState => ({ isMusclesInvolvedOpen: isOpen || !prevState.isMusclesInvolvedOpen }));
  }

  renderMusclesInvolved (exercise, muscleClass) {
    if (exercise && exercise.muscles && exercise.muscles[muscleClass] && exercise.muscles[muscleClass].length > 0) {
      return exercise.muscles[muscleClass].map(muscle => (<button type="button" key={muscle._id} className={`btn btn-musclesInvolved ${muscleClass}`}>{muscle.title[this.props.appLang] ? muscle.title[this.props.appLang] : muscle.title.en}</button>));
    }
    return null;
  }

  render () {
    const { exercise } = this.props;
    const targetMuscles = this.renderMusclesInvolved(exercise, 'target');
    const synergistMuscles = this.renderMusclesInvolved(exercise, 'synergist');
    const stabilizersMuscles = this.renderMusclesInvolved(exercise, 'stabilizers');
    const antagonistMuscles = this.renderMusclesInvolved(exercise, 'antagonist');
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-center marginBottom10px">
          <p className="text-uppercase primary fontWeight600 font16"><Trans i18nKey="muscles" /></p>
          <button type="button" onClick={() => { this.showMoreMusclesInvolved(); }} className="flex-initial layout-row layout-align-start-start btn-transparent">
            <p className="flex-initial colorGrey margin0 font15 sidePadd2px"><Trans i18nKey="showMore" /> </p>
          </button>
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start musclesInvolved ">
          {targetMuscles}
          {synergistMuscles}
          <Collapse isOpen={this.state.isMusclesInvolvedOpen} transition="height 250ms cubic-bezier(.4, 0, .2, 1)">
            {stabilizersMuscles}
            {antagonistMuscles}
          </Collapse>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10px">
            <div className="flex-initial layout-row layout-wrap layout-align-start-center">
              <div className="muscleIndeicator target" />
              <p className="margin0"><Trans i18nKey="target" /></p>
            </div>
            <div className="flex-initial layout-row layout-wrap layout-align-start-center paddStart5px">
              <div className="muscleIndeicator synergist" />
              <p className="margin0"><Trans i18nKey="synergist" /></p>
            </div>
            { this.state.isMusclesInvolvedOpen && (
              <div className="flex-initial layout-row layout-wrap layout-align-start-center fadeInBottom-anime paddStart5px">
                <div className="muscleIndeicator stabilizers" />
                <p className="margin0"><Trans i18nKey="stabilizers" /></p>
              </div>
            )}
            { this.state.isMusclesInvolvedOpen && (
              <div className="flex-initial layout-row layout-wrap layout-align-start-center fadeInBottom-anime paddTop5px">
                <div className="muscleIndeicator antagonist" />
                <p className="margin0"><Trans i18nKey="antagonist" /></p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MusclesInvolved;
