import React from 'react';
import { Trans } from 'react-i18next';
import ReactSVG from 'react-svg';
import { aspirePlaceholder, userTypes } from '../../services/enums';
import Image from '../UI/Image';
import Input from '../UI/Input';

const UserModalCard = (props) => {

  const handleCheckboxChange = (e) => {
    props.askMeAgain = e.target.checked;
  }

  const { userData, data } = props;
  const thumbnail = (data.thumbnail && data.thumbnail.uri) || (userData.thumbnail && userData.thumbnail.uri ? userData.thumbnail.uri : null);
  return (
    <div className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
        <div className="layout-row layout-wrap layout-align-start-start social-profile-img">
          <Image
            src={thumbnail}
            fallbackSrc={thumbnail || aspirePlaceholder[0]}
            backgroundImage
            className="backgroundImage layout-row layout-wrap layout-align-start-start borderRadius img-thumbnail card-img"
            height={300}
            color1="rgba(0, 0, 0, 0)"
            color2="rgba(0, 0, 0, 0)"
            color3="rgba(0, 0, 0, 0.1)" />
        </div>
      <div className="marginBottom15px flex-100 card borderRadius15">
        <div className="card-body">
          <div className="text-center pt-5">
            <h3 className="marginBottom10px fontWeight600 pt-4">{data && data.title}</h3>
          </div>
          <p className="width100 marginBottom10px lineHeight18">
            { data && data.content && <Trans i18nKey={data.content} /> }
          </p>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            { data && data.buttons && data.buttons.length > 0 && data.buttons.map((option) => (
              <button key={option.text} type="button" onClick={() => props[option.funcName](option)} className="uploadFilesBtn flex-100 layout-align-center-center text-center">
                <p className={`margin0 fontWeight600 text-capitalize ${option.classBtnText}`}><Trans i18nKey={option.text} defaults={option.text} /></p>
              </button>
            ))}
            { data.showCheckBox && (
              <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                <Input
                  noLabel
                  className="margin0"
                  name="termsAgreement"
                  elementType="checkBox"
                  initialValue={props.isChecked}
                  appLang={props.appLang}
                  validation={{required: true}}
                  inputClass="smallInput"
                  handleChange={(event, key) => { props.handleCheckboxChange(event, key); }}>
                  <span>
                    <Trans i18nKey="askMeAgain" />
                  </span>
                </Input>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserModalCard;
