import React from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import localStorage from 'local-storage';
import { actionTypes, eventActions } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import userAlertsFuncs from '../../services/userAlertsFuncs';

export const refreshPageHeader = (page, params) => ({
  type: actionTypes.REFRESH_PAGE_HEADER,
  payload: page,
  params
});

export const setAllCategories = data => ({
  type: actionTypes.SET_ALL_CATEGORIES,
  payload: data
});

export const setCategoryDetails = data => ({
  type: actionTypes.SET_CATEGORY_DETAILS,
  payload: data
});

export const refreshPageHeaderInfo = pageInfo => ({
  type: actionTypes.REFRESH_PAGE_HEADER_INFO,
  payload: pageInfo
});

export const refreshBottomNav = config => ({
  type: actionTypes.REFRESH_BOTTOM_NAV,
  payload: config
});

export const bottomNavClicked = key => ({
  type: actionTypes.BOTTOM_NAV_CLICKED,
  payload: key
});

export const setDynamicSearchResults = payload => ({
  type: actionTypes.SET_DYNAMIC_SEARCH_RESULTS,
  payload
});

export const handleErrorResponse = err => ({
  type: actionTypes.HANDLE_ERROR_RESPONSE,
  payload: err
});

export const handleSuccessResponse = () => ({
  type: actionTypes.HANDLE_SUCCESS_RESPONSE,
  payload: 'Handled Successfully'
});

export const showAlert = data => ({
  type: actionTypes.SHOW_ALERT,
  payload: data
});

export const showAlert2 = data => ({
  type: actionTypes.SHOW_ALERT2,
  payload: data
});

export const showAlert3 = data => ({
  type: actionTypes.SHOW_ALERT3,
  payload: data
});

const hideAlert = () => ({ type: actionTypes.HIDE_ALERT });

const hideAlert2 = () => ({ type: actionTypes.HIDE_ALERT2 });

const hideAlert3 = () => ({ type: actionTypes.HIDE_ALERT3 });

export const setActivityList = key => ({
  type: actionTypes.SET_ACTIVITY_LIST,
  payload: key
});

export const setUnReadActivitiesList = payload => ({
  type: actionTypes.SET_UNREAD_ACTIVITY_LIST,
  payload
});

export const setAspireServices = data => ({
  type: actionTypes.SET_ASPIRE_SERVICES,
  payload: data
});


export const openMediaGalleryPage = (media, index) => async (dispatch) => {
  const data = {
    isOpen: true,
    isModal: true,
    id: eventActions.MEDIA_GALLERY,
    className: 'PageModal',
    itemIndex: index || 0,
    media,
    buttonRight: {
      text: <i className="la la-arrow-left iconFontSize30 goBackIconColor " />,
      onClick: () => {
        hideAlert2();
      }
    }
  };
  dispatch({ type: actionTypes.SHOW_ALERT2, payload: data });
};

const creatingContent = title => (<span>
    <span>
      <Trans i18nKey="Are you sure you want to delete" />
    </span>
    <span className="paddStart5px text-wordBreak">{title}</span>?
  </span>);

export const deleteItemModal = (title, content, callback) => async (dispatch) => {
  const titleData = title || <Trans i18nKey="notice" />;
  const contentData = creatingContent(content);
  const data = {
    isOpen: true,
    title: titleData,
    content: contentData,
    className: 'ModalPage',
    id: eventActions.ALERT_MODAL,
    closeAlertBtn: {
      text: <Trans i18nKey="cancel" />
    },
    buttonRight: {
      text: <Trans i18nKey="confirm" />,
      onClick: () => {
        callback();
        hideAlert();
      }
    }
  };
  dispatch({ type: actionTypes.SHOW_ALERT, payload: data });
};


export const openUserMessage = (data, funcBody, callback) => async (dispatch) => {
  const buttons = () => {
    const btnsStructure = {};
    if (data.closeAlertBtn && (data.closeAlertBtn.text || data.closeAlertBtn.funcName)) {
      btnsStructure.closeAlertBtn = { text: <Trans i18nKey={data.closeAlertBtn.text || 'cancel'} /> };
      if (data.buttonRight.funcName) btnsStructure.closeAlertBtn.onClick = () => {
        if (userAlertsFuncs[data.closeAlertBtn.funcName]) userAlertsFuncs[data.closeAlertBtn.funcName]()
        if (callback) callback();
      }
    }
    if (data.buttonRight && (data.buttonRight.text || data.buttonRight.funcName)) {
      btnsStructure.buttonRight = { text: <Trans i18nKey={data.buttonRight.text || 'confirm'} /> };
      if (data.buttonRight.funcName) btnsStructure.buttonRight.onClick = async () => {
        if (userAlertsFuncs[data.buttonRight.funcName]) {
          await userAlertsFuncs[data.buttonRight.funcName](data.buttonRight.data);
          if (data.buttonRight.onClose) dispatch({ type: data.buttonRight.onClose });
        }
        if (callback) callback();
      }
    }
    return btnsStructure;
  }
  const alert = {
    isOpen: true,
    isModal: data.isModal || false,
    data: data && data.data,
    title: <Trans i18nKey={data.title} />,
    content: <Trans i18nKey={data.content} />,
    className: data.className,
    id: data.id,
    closeAlertBtn: {
      text: <Trans i18nKey={(data.closeAlertBtn && data.closeAlertBtn.text) || 'cancel'} />
    },
    ...buttons()
  };
  dispatch(showAlert(alert));
};


export const openUserMessageCenter = () => async (dispatch) => {
  const alert = {
    isOpen: true,
    id: eventActions.ASPIRE_MSG_CENTER,
    className: 'PageModal AspireUpdatesPage',
  };
  dispatch(showAlert(alert));
};


export const getUnReadActivityList = () => async (dispatch) => {
  try {
    const result = await apiMethods.activities.getUnReadActivityList();
    dispatch(setUnReadActivitiesList(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
export const goBackAlert = (props, callback) => async (dispatch) => {
  const title = <Trans i18nKey="notice" />;
  const content = <Trans i18nKey="goBackNotice" />;
  const data = {
    isOpen: true,
    title,
    content,
    className: 'ModalPage',
    id: eventActions.ALERT_MODAL_3,
    closeAlertBtn: {
      text: <Trans i18nKey="cancel" />,
      onClick: () => {
        hideAlert3();
        callback()
      }
    },
    buttonRight: {
      text: <Trans i18nKey="confirm" />,
      onClick: () => {
        hideAlert3();
        callback(true)
      }
    }
  };
  dispatch({ type: actionTypes.SHOW_ALERT3, payload: data });
}



export const getActivityList = () => async (dispatch) => {
  try {
    const result = await apiMethods.activities.getActivityList();
    dispatch(setActivityList(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getAllCategories = () => async (dispatch) => {
  try {
    const data = await apiMethods.categories.getCategories();
    dispatch(setAllCategories(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getCategoryByPublicId = body => async (dispatch) => {
  try {
    const data = await apiMethods.categories.getCategory(body);
    dispatch(setCategoryDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createCategory = body => async (dispatch) => {
  try {
    const data = await apiMethods.categories.create(body);
    dispatch(setCategoryDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const editCategory = body => async (dispatch) => {
  try {
    const data = await apiMethods.categories.edit(body);
    dispatch(setCategoryDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateMassageSeen = msgId => async (dispatch) => {
  try {
    const body = { msgId }
    return await apiMethods.users.updateMassageSeen(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeCategory = publicId => async (dispatch) => {
  try {
    return await apiMethods.categories.remove([publicId]);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const checkTutorialGuide = (key, data, activateNow) => () => {
  try {
    const localData = localStorage.get(`${key}-aspirePopup`);
    if (localData && localData.time) {
      const dateActivated = localData.time;
      const endDuration = moment(dateActivated).add( data.activeTime.num, data.activeTime.unit).endOf('day')
      if (activateNow) {
        localStorage.set(`${key}-aspirePopup`, { time: moment().toDate() });
        return true;
      }
      console.log('is BEFORE? ', `${moment().isBefore(endDuration)} , end time is ${endDuration}`)
      if (moment().isBefore(endDuration)) return true; // if moment is still in the active tiem duration
      return false;
    } else {
      localStorage.set(`${key}-aspirePopup`, { time: moment().toDate() });
      return true;
    }
  } catch (e) {
    return false;
  }
};


export const getAspireServices = queryObj => async (dispatch) => {
  try {
    // const query = queryObj ? utils.buildQuery(queryObj) : {};
    const data = await apiMethods.products.getAspireServices();
    dispatch(setAspireServices(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
