/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CodeInput from 'react-code-input';
import nanoid from 'nanoid/generate';
import { refreshPageHeader, showAlert } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import { nanoidOption } from '../../services/storeDoll';
import { langs } from '../../services/translationLangs';
import DefaultSection from '../../Components/Survey/DefaultSection';
import verifySms from '../../assets/svg/verify_sms.svg';
import AspireBtnLoader from '../../Components/UI/AspireBtnLoader';
import TimerToResend from '../../Components/PhoneUI/TimerToResend';
import './ModalStyles.css'

class VerifyPhonePage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isPageReady: false,
      thumbnail: this.props.alertProps.thumbnail || verifySms,
      title: this.props.alertProps.title || langs[this.props.appLang].smsOnItsWay,
      description: this.props.alertProps.description || langs[this.props.appLang].smsWillBeReceived,
      showTimer: this.props.alertProps.showTimer || false,
      backgroundColor: this.props.alertProps.backgroundColor || 'rgb(236, 237, 254)',
    };
  }

  async componentDidMount () {
    try {
      this.setState({ isPageReady: true })
    } catch (e) {
      console.log('handle error', e);
    }
  }

  handleCodeChange (value) {
    this.state.verifyCode = value;
  }

  resendSms() {
    if (this.props.alertProps.resendSMS) {
      this.props.alertProps.resendSMS();
    }
  }

  async conformVerificationCode () {
    const { verifyCode } = this.state;
    if (this.props.alertProps.confirmVerificationCode && verifyCode && verifyCode.length === 5) {
      return this.props.alertProps.confirmVerificationCode(verifyCode);
    }
    return { error: 'invalidForm'}
  }

  closeModal () {
    this.props.hideAlert();
  }

  renderNavTopForModal () {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start whiteBackground">
        <div className="content-header flex-100 layout-row layout-wrap layout-align-space-between-end">
          <div className="flex-100 layout-row layout-wrap layout-align-space-between-center topNav height50px">
            <div className="flex-initial sidePadd5px">
              { this.props.alertProps.buttonRight && this.props.alertProps.buttonRight.text && (
                <button
                  type="button"
                  className="flex-initial textIcon btn-transparent"
                  onClick={() => this.closeModal()}>
                  { this.props.alertProps.buttonRight.text }
                </button>
              )}
            </div>
            <div className="flex layout-row layout-align-start-center sidePadd15px NavTop-label-direction">
             {/* <label className="primary text-capitalize font20 fontWeight600 margin0 fadeInLeft-anime">
                {this.props.alertProps.title && (<Trans i18nKey={this.props.alertProps.title} />)}
              </label> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTimer() {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-center-center marginBottom10px">
        <TimerToResend
          resendSms={() => {this.resendSms()}}
          tryLimit={this.props.alertProps.tryLimit || 3}
          showButtonFirst={this.props.alertProps.showButtonFirst || false}
          btnText={this.props.alertProps.timerBtnText || null}
        />
      </div>
    )
  }

  renderCodeInput () {
    const { showTimer} = this.state;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom15px">
        { showTimer && this.renderTimer() }
        <div className="flex-100 layout-row layout-wrap layout-align-start-start directionLtr padd10px">
          <CodeInput name="verifyCode" type="number" inputMode="numeric" fields={5} autoFocus onChange={(value) => { this.handleCodeChange(value); }} className="flex-100 CodeInput" />
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-center-center">
          <div className="flex-60 layout-row layout-wrap layout-align-center-start paddTop30px">
            <AspireBtnLoader
              btnText="confirm"
              btnBaseClass="uploadFilesBtn flex-100"
              containerClassName="width100"
              classNameText="fontWeight600 text-uppercase"
              btnClicked={ async () => ( this.conformVerificationCode())} />
          </div>
        </div>
      </div>
    )
  }

  render () {
    const modalNavTop = this.state.isPageReady ? this.renderNavTopForModal() : null;
    const codeInput = this.renderCodeInput();
    const { title, description, thumbnail, backgroundColor} = this.state;
    return (
      <div className="VerifyPhonePage flex-100 layout-row layout-wrap layout-align-start-start fullSizeWindow content-start ">
        <div className="flex-100 layout-row layout-wrap layout-align-start-start fadeIn-anime">
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            { this.props.isModalPage && modalNavTop }
          </div>
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
          {this.state.isPageReady && (
            <div className="flex-100 layout-row layout-wrap layout-align-start-start">
              <DefaultSection id={nanoid(nanoidOption, 4)}
                title={title || langs[this.props.appLang].smsOnItsWay}
                description={description || langs[this.props.appLang].smsWillBeReceived}
                thumbnail={thumbnail || verifySms}
                thumbnailClass="flex-initial"
                backgroundColor={backgroundColor || 'rgb(236, 237, 254)'}
                isSwiperCarousel
                appLang={this.props.appLang}>
                { codeInput }
              </DefaultSection>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang
});

const mapDispatchToProps = dispatch => ({
  refreshPageHeader: page => dispatch(refreshPageHeader(page)),
  showAlert: data => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});
export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhonePage);
