import { actionTypes } from '../actions/actionTypes';
import { storeUtils, mergeUnFinishedSessionWorkoutLogs } from '../../services/utilsService';

const initialState = {
  workoutLogsKV: {},
  workoutLogsIds: [],
  workoutSessionData: {},
  // sessionPublicId
  // workoutProgramInfo
  // isSmartSession
  sessionsListKV: {},
  sessionsListIds: [],
  // session {}
  // workoutProgramInfo  {}
  // workoutLogs: []
  // sessionChart: []
  // keyLifts: []
  // sessionUser: []
  // exercisesList: [] exerciseId/equipmentId
  // notificationMode: false
  carouselPosition: 0
  // currentWorkoutLog: null // for notification
  // currentActiveLog: null  // for notification
  // sessionDuration: 0
};

const workoutSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WORKOUT_SESSIONS: {
      return { ...state, sessionsListKV: action.nextPage ? {...state.sessionsListKV, ...action.payload.keyValueObj } : {...action.payload.keyValueObj },
        sessionsListIds: action.nextPage ? [ ...state.sessionsListIds, ...action.payload.dataIds] : [...action.payload.dataIds] };
    }
    case actionTypes.CLEAR_ALL_WORKOUTS_PAGE_DATA: {
      return { ...state, sessionsListKV: {}, sessionsListIds: [] };
    }
    case actionTypes.SET_PROGRAM_SESSION_DETAILS:
      return { ...state, ...action.payload }; /* workoutLogsKV, workoutLogsIds, workoutProgramInfo, isSmartSession, sessionUser, exercisesList */
    case actionTypes.SET_SESSION_DETAILS: {
      // session {},  workoutProgramInfo  {},  workoutLogs: [], sessionChart: [] , keyLifts: [] --> has two end points ... different props are coming
      const sessionPublicId = action.payload.session.public_id;
      return { ...state, ...action.payload, sessionPublicId };
    }
    case actionTypes.SET_SESSION_WORKOUTLOGS:
      return {
        ...state,
        workoutLogsKV: action.payload.listKV,
        workoutLogsIds: action.payload.dataIds
      };
    case actionTypes.SET_UNFINISHED_SESSION_DETAILS:
      return { ...state, ...action.payload };
    case actionTypes.MODIFY_SESSION_WORKOUT_LOGS: {
      return { ...state, workoutLogsIds: action.payload.workoutLogsIds, workoutLogsKV: action.payload.workoutLogsKV };
    }
    case actionTypes.UPDATE_SESSION_LIST:
      return { ...state, sessionsListKV: action.payload.sessionsListKV, sessionsListIds: action.payload.dataIds };
    case actionTypes.REMOVE_SESSION: {
      const workoutLogsKV = storeUtils.removeFromObjectInStore(state.sessionsListKV, action.payload);
      const workoutLogsIds = storeUtils.removeFromArrayInStore(state.sessionsListIds, action.payload);
      return { ...state, workoutLogsIds, workoutLogsKV };
    }
    case actionTypes.SET_SESSION_CHOSEN_USER:
      return { ...state, chosenUser: action.payload };
    case actionTypes.SET_DURATION:
      return { ...state, sessionDuration: (action.payload && action.payload.duration) || 0 };
    case actionTypes.UPDATE_EQUIPMENT_IN_SESSION: {
      const { workoutLogsKV } = state;
      workoutLogsKV[action.payload.id] = action.payload.workoutLog;
      return { ...state, workoutLogsKV };
    }
    case actionTypes.CLEAR_SESSION:
      return {
        ...state, session: {}, workoutSessionData: {}, sessionPublicId: null, isSmartSession: null, workoutLogsKV: {}, workoutLogsIds: [], sessionDuration: null, chosenUser: null
      };
    case actionTypes.CLEAR_SESSION_DETAILS:
      return {
        ...state, session: {}, workoutProgramInfo: {}, workoutLogs: [], sessionChart: [], keyLifts: [], chosenUser: null
      };
    case actionTypes.UPDATE_NOTIFICATION_WORKOUT_LOG:
      return { ...state, currentWorkoutLog: action.payload.currentWorkoutLog, currentActiveLog: action.payload.currentActiveLog };
    case actionTypes.UPDATE_CAROUSEL_POSITION:
      return { ...state, carouselPosition: action.payload.position, currentWorkoutLog: action.payload.currentWorkoutLog };
    default:
      return state;
  }
};

export default workoutSessionReducer;
