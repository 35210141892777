import axios from 'axios';
import localStorage from 'local-storage';
import { serverUrl } from './globals';

const token = localStorage.get('Aspire-auth') || null;

const aspireClient = axios.create({
  baseURL: serverUrl[window.globals.ENV]
});

const aspireClientConfig = {
  headers: {
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT'
  }
};

if (token) {
  aspireClientConfig.headers.Authorization = token;
}

aspireClient.interceptors.request.use((request) => {
  console.log('interceptors request: ', request);
  return request;
}, (error) => {
  console.log('interceptors request error: ', error.message);
  return Promise.reject(error);
});

aspireClient.interceptors.response.use((response) => {
  console.log('interceptors response: ', response);
  return response.data;
}, (error) => {
  console.log('interceptors response error: ', error.message);
  console.log(`code: ${error.response.status}, data: ${error.response.data}`);
  return Promise.reject(error);
});

const apiMethods = {
  users: {
    checkIfLoggedIn: query => aspireClient.get(`/api/users/checkIfLoggedIn${query}`, aspireClientConfig),
    updateUserToken: body => aspireClient.put('/api/users/updateUserToken', body, aspireClientConfig),
    getInviteCode: () => aspireClient.get('/api/users/getInviteCode', aspireClientConfig),
    getUserDetailsForClients: body => aspireClient.put('/api/users/getUserDetailsForClients', body, aspireClientConfig),
    register: body => aspireClient.post('/public/users/register', body, aspireClientConfig),
    loginByEmail: body => aspireClient.post('/public/users/loginByEmail', body, aspireClientConfig),
    loginByPhone: body => aspireClient.post('/public/users/loginByPhone', body, aspireClientConfig),
    sendVerificationCode: body => aspireClient.put('/public/users/sendVerificationCode', body, aspireClientConfig),
    sendVerificationCodeToLogin: body => aspireClient.put('/public/users/sendVerificationCodeToLogin', body, aspireClientConfig),
    resendInvitationCode: body => aspireClient.put('/public/users/resendInvitationCode', body, aspireClientConfig),
    verifyUserAndLogin: body => aspireClient.put('/public/users/verifyUserAndLogin', body, aspireClientConfig),
    verifyInvitationCode: body => aspireClient.put('/public/users/verifyInvitationCode', body, aspireClientConfig),
    getCountryCodes: () => aspireClient.get(`/public/users/getCountryCodes`, aspireClientConfig),
    updateUserCredentials: body => aspireClient.put('/api/users/updateUserCredentials', body, aspireClientConfig),
    createClient: body => aspireClient.post('/api/users/createClient', body, aspireClientConfig),
    unlinkClient: body => aspireClient.put('/api/users/unlinkClient', body, aspireClientConfig),
    saveUpdatedClientsList: body => aspireClient.put('/api/users/saveUpdatedClientsList', body, aspireClientConfig),
    checkAccountExistByEmail: (query) => {
      const api = '/public/users/checkAccountExistByEmail';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    checkEmailExist: (query) => {
      const api = '/api/users/checkEmailExist';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    checkPhoneExist: (query) => {
      const api = '/api/users/checkPhoneExist';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getProfileInfo: publicId => aspireClient.get(`/api/profiles/getProfileInfo/${publicId}`, aspireClientConfig),
    logout: () => {
      localStorage.remove('Aspire-auth');
    },
    updateUserAccount: body => aspireClient.put('/api/profiles/updateUserProfile', body, aspireClientConfig),
    saveSettings: body => aspireClient.put('/api/users/saveSettings', body, aspireClientConfig),
    resendClientInvitation: body => aspireClient.put('/api/users/resendClientInvitation', body, aspireClientConfig),
    updateEmailInvitation: body => aspireClient.put('/api/users/updateEmailInvitation', body, aspireClientConfig),
    updateSMSInvitation: body => aspireClient.put('/api/users/updateSMSInvitation', body, aspireClientConfig),
    updatePassword: body => aspireClient.put('/api/users/updateUserPassword', body, aspireClientConfig),
    updateClientPassword: body => aspireClient.put('/api/users/updateClientPassword', body, aspireClientConfig),
    sendResetPasswordEmail: body => aspireClient.put('/public/users/sendResetPasswordEmail', body, aspireClientConfig),
    resetPassword: body => aspireClient.put('/public/users/resetPassword', body, aspireClientConfig),
    resendWelcomeEmail: (body) => aspireClient.put('/public/users/resendWelcomeEmail', body, aspireClientConfig),
    updateTrainerProfession: body => aspireClient.put('/api/users/updateTrainerProfession', body, aspireClientConfig),
    updateMassageSeen: body => aspireClient.put('/api/users/updateMassageSeen', body, aspireClientConfig),
    getMyCalendarSettings: () => aspireClient.get(`/api/users/getMyCalendarSettings`, aspireClientConfig),
    updateCalendarSettings: body => aspireClient.put('/api/users/updateCalendarSettings', body, aspireClientConfig),
    getUserCalendarSettings: (query) => {
      const api = '/api/users/getUserCalendarSettings';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    }
  },
  categories: {
    getCategories (query) {
      const api = '/api/categories/getCategories';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getCategoriesByGroup: body => aspireClient.post('/api/categories/getCategoriesByGroup', body, aspireClientConfig),
    getCategory: publicId => aspireClient.get(`/api/categories/getCategory/${publicId}`, aspireClientConfig),
    create: body => aspireClient.post('/api/categories/create', body, aspireClientConfig),
    edit: body => aspireClient.put('/api/categories/edit', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/categories/remove', body, aspireClientConfig)
  },
  workoutPlans: {
    getWorkoutPlans: (query) => {
      const api = '/api/workoutPlans/getWorkoutPlans';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWorkoutPlanToModify: (query) => {
      const api = '/api/workoutPlans/getWorkoutPlanToModify';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWorkoutPlanPageData: (query) => {
      const api = '/api/workoutPlans/getWorkoutPlanPageData';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    create: body => aspireClient.post('/api/workoutPlans/create', body, aspireClientConfig),
    edit: body => aspireClient.put('/api/workoutPlans/edit', body, aspireClientConfig),
    copy: body => aspireClient.post('/api/workoutPlans/copy', body, aspireClientConfig),
    editPrograms: body => aspireClient.put('/api/workoutPlans/editPrograms', body, aspireClientConfig),
    addNewProgram: body => aspireClient.post('/api/workoutPlans/addNewProgram', body, aspireClientConfig),
    introduceProgramToClient: () => aspireClient.post('/api/workoutPlans/introduceProgramToClient', {}, aspireClientConfig),
    remove: body => aspireClient.put('/api/workoutPlans/remove', body, aspireClientConfig)
  },
  workoutProgram: {
    getWorkoutPrograms: (query) => {
      const api = '/api/workoutPrograms/getWorkoutPrograms';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWorkoutProgramsForPlan: (query) => {
      const api = '/api/workoutPrograms/getPlanPrograms';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getPlanPrograms: (query) => {
      const api = '/api/workoutPrograms/getPlanPrograms';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWorkoutProgram (query) {
      const api = '/api/workoutPrograms/getWorkoutProgram';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWorkoutProgramPageData (query) {
      const api = '/api/workoutPrograms/getWorkoutProgramPageData';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    assign: body => aspireClient.post('/api/workoutPrograms/assign', body, aspireClientConfig),
    edit: body => aspireClient.post('/api/workoutPrograms/edit', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/workoutPrograms/remove', body, aspireClientConfig)
  },
  workoutProgress: {
    getFullWorkoutProgress: (query) => {
      const api = '/api/workoutProgress/getFullWorkoutProgress';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWeeklyWorkoutProgress: (query) => {
      const api = '/api/workoutProgress/getWeeklyWorkoutProgress';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    update: body => aspireClient.put('/api/workoutProgress/update', body, aspireClientConfig),
    changeCurrentWeek: body => aspireClient.put('/api/workoutProgress/changeCurrentWeek', body, aspireClientConfig),
  },
  sessions: {
    getSessionsProfile: (query) => {
      const api = '/api/workoutSessions/getSessionsProfile';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWorkoutSessions: (query) => {
      const api = '/api/workoutSessions/getSessions';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWorkoutSession (publicId, isPopulate) {
      const query = isPopulate ? '?isPopulate=true' : '';
      return aspireClient.get(`/api/workoutSessions/getSession/${publicId}${query}`, aspireClientConfig);
    },
    getSessionPageData (publicId) {
      return aspireClient.get(`/api/workoutSessions/getSessionPageData/${publicId}`, aspireClientConfig);
    },
    getUnfinishedSessionData: (query) => {
      const api = '/api/workoutSessions/getUnfinishedSessionData';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getWorkoutProgramForSession (query) {
      const api = '/api/workoutSessions/getProgram';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    create: body => aspireClient.post('/api/workoutSessions/create', body, aspireClientConfig),
    updateCurrentSession: body => aspireClient.put('/api/workoutSessions/updateCurrentSession', body, aspireClientConfig),
    handleSessionDone: body => aspireClient.put('/api/workoutSessions/handleSessionDone', body, aspireClientConfig),
    updateSession: body => aspireClient.put('/api/workoutSessions/updateSession', body, aspireClientConfig),
    createPastSession: body => aspireClient.post('/api/workoutSessions/createPastSession', body, aspireClientConfig),
    edit: body => aspireClient.post('/api/workoutSessions/edit', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/workoutSessions/remove', body, aspireClientConfig),
    getSessionMedia: publicId => aspireClient.get(`/api/workoutSessions/getSessionMedia/${publicId}`, aspireClientConfig),
    addMedia: body => aspireClient.put('/api/workoutSessions/addMedia', body, aspireClientConfig),
    removeMedia: body => aspireClient.put('/api/workoutSessions/removeMedia', body, aspireClientConfig)
  },
  exercises: {
    getPopularExercises (query) {
      const api = '/api/exercises/getPopularExercises';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getFilteredExercises: body => aspireClient.post('/api/exercises/getFilteredExercises', body, aspireClientConfig),
    filterExercisesByCategory (query) {
      const api = '/api/exercises/filterExercisesByCategory';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getExercise: body => aspireClient.post(`/api/exercises/getExercise`, body, aspireClientConfig),
    getExerciseByPublicId: publicId => aspireClient.get(`/api/exercises/getExerciseByPublicId/${publicId}`, aspireClientConfig),
    getMyExercises: (query) => {
      const api = '/api/exercises/getMyExercises';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getExercisesEquipments: publicId => aspireClient.get(`/api/exercises/getExercisesEquipments/${publicId}`, aspireClientConfig),
    getLatestStatsOfExercise (query) {
      const api = '/api/exercises/getLatestStatsOfExercise';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    create: body => aspireClient.post('/api/exercises/create', body, aspireClientConfig),
    edit: body => aspireClient.put('/api/exercises/edit', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/exercises/remove', body, aspireClientConfig),
    addMedia: body => aspireClient.put('/api/exercises/addMedia', body, aspireClientConfig),
    removeMedia: body => aspireClient.put('/api/exercises/removeMedia', body, aspireClientConfig),
    createQuickExercise: body => aspireClient.post('/api/exercises/createQuickExercise', body, aspireClientConfig),
    saveMediaToEquipment: body => aspireClient.post('/api/exercises/saveMediaToEquipment', body, aspireClientConfig),
    removeEquipmentMedia: body => aspireClient.post('/api/exercises/removeEquipmentMedia', body, aspireClientConfig)
  },
  bodyStats: {
    getBodyStats (query) {
      const api = '/api/bodyStats/getBodyStats';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getBodyStatsHistory (query) {
      const api = '/api/bodyStats/getBodyStatsHistory';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getBodyStatsData (publicId) {
      return aspireClient.get(`/api/bodyStats/getBodyStatsData/${publicId}`, aspireClientConfig);
    },
    getLatestBodyStats (query) {
      const api = '/api/bodyStats/getLatestBodyStats';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    createBodyStats: body => aspireClient.post('/api/bodyStats/create', body, aspireClientConfig),
    updateBodyStats: body => aspireClient.put('/api/bodyStats/update', body, aspireClientConfig),
    removeBodyStats: body => aspireClient.put('/api/bodyStats/remove', body, aspireClientConfig),
    getBodyStatsMedia: publicId => aspireClient.get(`/api/bodyStats/getBodyStatsMedia/${publicId}`, aspireClientConfig)
  },
  statistics: {
    getStatisticsInfo (query) {
      const api = '/api/statistics/getStatisticsInfo';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getProgramStatistics (query) {
      const api = '/api/statistics/getProgramStatistics';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getExercisesStatistics (query) {
      const api = '/api/statistics/getExercisesStatistics';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getUserProgramInfo (query) {
      const api = '/api/statistics/getUserProgramInfo';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getUserExerciseInfo (query) {
      const api = '/api/statistics/getUserExerciseInfo';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    }
  },
  achievements: {
    getAchievements (query) {
      const api = '/api/achievements/getAchievements';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getAchievementProgress (query) {
      const api = '/api/achievements/getAchievementProgress';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getAchievementData (publicId) {
      return aspireClient.get(`/api/achievements/getAchievementData/${publicId}`, aspireClientConfig);
    },
    getCompletedAchievements (body) {
      return aspireClient.put('/api/achievements/getCompletedAchievements', body, aspireClientConfig);
    },
    checkForCompletedGoals: () => aspireClient.get('/api/achievements/checkForCompletedGoals', aspireClientConfig),
    updateBodyStatsAchievement: body => aspireClient.put('/api/achievements/updateBodyStatsAchievement', body, aspireClientConfig),
    getAchievementsForSession: body => aspireClient.post('/api/achievements/getAchievementsForSession', body, aspireClientConfig),
    assignAchievementAsCompleted: body => aspireClient.put('/api/achievements/assignAchievementAsCompleted', body, aspireClientConfig),
    create: body => aspireClient.post('/api/achievements/create', body, aspireClientConfig),
    update: body => aspireClient.put('/api/achievements/update', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/achievements/remove', body, aspireClientConfig),
    getAchievementMedia: publicId => aspireClient.get(`/api/achievements/getAchievementMedia/${publicId}`, aspireClientConfig),
    addMedia: body => aspireClient.put('/api/achievements/addMedia', body, aspireClientConfig),
    removeMedia: body => aspireClient.put('/api/achievements/removeMedia', body, aspireClientConfig)
  },
  media: {
    getMediaGallery (query) {
      const api = '/api/media/getMediaGallery';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getUserMedia (query) {
      const api = '/api/media/getUserMedia';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getAspireLibraryMedia (query) {
      const api = '/api/media/getAspireLibraryMedia';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getPreSignedUrl (query) {
      const api = '/api/media/getPreSignedUrl';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getMediaData (publicId) {
      return aspireClient.get(`/api/media/getMediaData/${publicId}`, aspireClientConfig);
    },
    getMediaById (id) {
      return aspireClient.get(`/api/media/getMediaById/${id}`, aspireClientConfig);
    },
    create: body => aspireClient.post('/api/media/create', body, aspireClientConfig),
    update: body => aspireClient.put('/api/media/update', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/media/remove', body, aspireClientConfig),
    saveUploadedMedia: body => aspireClient.post('/api/media/saveUploadedMedia', body, aspireClientConfig)
  },
  activities: {
    getUnReadActivityList: () => aspireClient.get('/api/activities/getUnReadActivityList', aspireClientConfig),
    getActivityList: () => aspireClient.get('/api/activities/getActivityList', aspireClientConfig),
    checkForUpdates: () => aspireClient.get('/api/activities/checkForUpdates', aspireClientConfig)
  },
  info: {
    getPopularInfos: () => aspireClient.get('/api/information/getPopularInfos', aspireClientConfig),
    getFilteredInfos: body => aspireClient.post('/api/information/getFilteredInfos', body, aspireClientConfig),
    filterInfosByCategory (query) {
      const api = '/api/information/filterInfosByCategory';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getInfoByPublicId: publicId => aspireClient.get(`/api/information/getInfoByPublicId/${publicId}`, aspireClientConfig),
    assignInquiryAsAnswered: body => aspireClient.put('/api/information/assignInquiryAsAnswered', body, aspireClientConfig),
    getMyInquiries: (query) => {
      const api = '/api/information/getMyInquiries';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    create: body => aspireClient.post('/api/information/create', body, aspireClientConfig),
    createAspire: body => aspireClient.post('/api/information/createAspire', body, aspireClientConfig),
    edit: body => aspireClient.put('/api/information/edit', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/information/remove', body, aspireClientConfig),
    getSolution: body => aspireClient.put('/api/information/getSolution', body, aspireClientConfig),
    addSolution: body => aspireClient.put('/api/information/addSolution', body, aspireClientConfig),
    editSolution: body => aspireClient.put('/api/information/editSolution', body, aspireClientConfig),
    addAReplay: body => aspireClient.put('/api/information/addAReplay', body, aspireClientConfig)
  },
  documents: {
    getInitialDocuments (query) {
      const api = '/api/documents/getDocuments';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getFilteredDocuments (query) {
      const api = '/api/documents/getFilteredDocuments';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getDocumentByPublicId (publicId) {
      return aspireClient.get(`/api/documents/getDocumentByPublicId/${publicId}`, aspireClientConfig);
    },
    checkUnreviewedDocs: () => aspireClient.get('/api/documents/checkUnreviewedDocs', aspireClientConfig),
    getSignedDocuments: () => aspireClient.get('/api/documents/getSignedDocuments', aspireClientConfig),
    agreeToDocumentTerms: body => aspireClient.put('/api/documents/agreeToDocumentTerms', body, aspireClientConfig),
    assignDocumentToClient: body => aspireClient.post('/api/documents/assignDocumentToClient', body, aspireClientConfig),
    duplicateDocumentToTrainer: body => aspireClient.post('/api/documents/duplicateDocumentToTrainer', body, aspireClientConfig),
    create: body => aspireClient.post('/api/documents/create', body, aspireClientConfig),
    update: body => aspireClient.put('/api/documents/update', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/documents/remove', body, aspireClientConfig),
    addMedia: body => aspireClient.put('/api/documents/addMedia', body, aspireClientConfig),
    removeMedia: body => aspireClient.put('/api/documents/removeMedia', body, aspireClientConfig)
  },
  survey: {
    getInitialSurveys (query) {
      const api = '/api/survey/getSurveys';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getFilteredSurveys (query) {
      const api = '/api/survey/getFilteredSurveys';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getSurveyByPublicId (publicId) {
      return aspireClient.get(`/api/survey/getSurveyByPublicId/${publicId}`, aspireClientConfig);
    },
    assignSurveyToClients: body => aspireClient.put('/api/survey/assignSurveyToClients', body, aspireClientConfig),
    duplicateSurveyToTrainer: body => aspireClient.put('/api/survey/duplicateSurveyToTrainer', body, aspireClientConfig),
    create: body => aspireClient.post('/api/survey/createNewSurvey', body, aspireClientConfig),
    update: body => aspireClient.put('/api/survey/editSurvey', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/survey/removeSurvey', body, aspireClientConfig),
    addMedia: body => aspireClient.put('/api/survey/addMedia', body, aspireClientConfig),
    removeMedia: body => aspireClient.put('/api/survey/removeMedia', body, aspireClientConfig)
  },
  surveyFill: {
    getFilteredSurveyFills: (query) => {
      const api = '/api/survey/getFilteredSurveyFills';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getSurveyFillById: (id) => {
      return aspireClient.get(`/api/survey/getSurveyFillById/${id}`, aspireClientConfig);
    },
    getSurveyFillsBySurveyId: (surveyId) => {
      return aspireClient.get(`/api/survey/getSurveyFillsBySurveyId/${surveyId}`, aspireClientConfig);
    },
    createFill: body => aspireClient.post('/api/survey/createSurveyFill', body, aspireClientConfig),
    updateFill: body => aspireClient.put('/api/survey/editSurveyFill', body, aspireClientConfig),
    completeSurveyFill: body => aspireClient.put('/api/survey/completeSurveyFill', body, aspireClientConfig),
    completeClientWelcome: body => aspireClient.put('/api/survey/completeClientWelcome', body, aspireClientConfig),
    removeSurveyFill: body => aspireClient.put('/api/survey/removeSurveyFill', body, aspireClientConfig),
    addMedia: body => aspireClient.put('/api/survey/addMedia', body, aspireClientConfig),
    removeMedia: body => aspireClient.put('/api/survey/removeMedia', body, aspireClientConfig)
  },
  contacts: {
    getContactsList: (query) => {
      const api = '/api/contacts/getContactsList';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getContactById: (id) => aspireClient.get(`/api/contacts/getContactById/${id}`, aspireClientConfig),
    update: body => aspireClient.put('/api/contacts/updateContact', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/contacts/removeContact', body, aspireClientConfig),
    updateReadContact: body => aspireClient.put('/api/contacts/updateReadContact', body, aspireClientConfig),
  },
  calendar: {
    getEventsList: (query) => {
      const api = '/api/calendar/getEvents';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getEventDetails: (query) => {
      const api = '/api/calendar/getEventDetails';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    create: body => aspireClient.post('/api/calendar/create', body, aspireClientConfig),
    updateEvent: body => aspireClient.put('/api/calendar/updateEvent', body, aspireClientConfig),
    updateFollowingEvents: body => aspireClient.put('/api/calendar/updateFollowingEvents', body, aspireClientConfig),
    updateAllEvents: body => aspireClient.put('/api/calendar/updateAllEvents', body, aspireClientConfig),
    remove: body => aspireClient.put('/api/calendar/remove', body, aspireClientConfig),
    removeFollowingEvents: body => aspireClient.put('/api/calendar/removeFollowingEvents', body, aspireClientConfig),
    confirm: body => aspireClient.put('/api/calendar/confirm', body, aspireClientConfig),
    cancel: body => aspireClient.put('/api/calendar/cancel', body, aspireClientConfig),
    getEventRescheduleDetails: eventId => aspireClient.get(`/api/calendar/getEventRescheduleDetails/${eventId}`, aspireClientConfig),
    reschedule: body => aspireClient.post('/api/calendar/reschedule', body, aspireClientConfig),
  },
  store: {
    getStoreMainPagePrograms (query) {
      const api = '/api/store/getStoreMainPagePrograms';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getStoreCategories (query) {
      const api = '/api/store/getStoreCategories';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getFilteredAspirePrograms: body => aspireClient.post('/api/store/getFilteredAspirePrograms', body, aspireClientConfig),
    getStoreProgramDetails (query) {
      const api = '/api/store/getStoreProgramDetails';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    copyTemplateToTrainer: body => aspireClient.post('/api/store/copyProgramToUser', body, aspireClientConfig),
    getArticlesForStore (query) {
      const api = '/api/store/getArticlesForStore';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getArticleData (uri) {
      return aspireClient.get(`/api/articles/getArticleDetails/${uri}`, aspireClientConfig);
    }
  },
  products: {
    getAspireServices (query) {
      const api = '/api/products/getAspireServices';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getAspireProductDetails (publicId) {
      return aspireClient.get(`/api/products/getAspireProductDetails/${publicId}`, aspireClientConfig);
    },
    getProductDetails (publicId) {
      return aspireClient.get(`/api/products/getProductDetails/${publicId}`, aspireClientConfig);
    },
    // ---- Subscriptions ----
    getSubscriptionList (query) {
      const api = '/api/products/getSubscriptionList';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    },
    getMySubscription: () => aspireClient.get(`/api/products/getMySubscription`, aspireClientConfig),
    getSubscriptionDetails (publicId) {
      return aspireClient.get(`/api/products/getSubscriptionDetails/${publicId}`, aspireClientConfig);
    },
    cancelSubscription() {
      return aspireClient.get(`/api/products/cancelSubscription`, aspireClientConfig);
    },
  },
  payments: {
    getUserSubscriptionStatus: () => aspireClient.get(`/api/payments/getUserSubscriptionStatus`, aspireClientConfig),
    completePaypalSubscription (query) {
      const api = '/api/payments/completePaypalSubscription';
      return aspireClient.get(query ? (api + query) : api, aspireClientConfig);
    }
  }
};

export { apiMethods, aspireClient, aspireClientConfig };
