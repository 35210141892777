import { actionTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse, handleSuccessResponse } from './index';

const setBodyStats = data => ({
  type: actionTypes.SET_BODY_STATS,
  payload: data
});

const clearBodyStats = () => ({
  type: actionTypes.CLEAR_BODY_STATS
});

const setStatsHistory = data => ({
  type: actionTypes.SET_STATS_TYPE_HISTORY,
  payload: data
});

const setStatData = data => ({
  type: actionTypes.SET_STATS_DATA,
  payload: data
});

const setStatistics = data => ({
  type: actionTypes.SET_STATISTICS,
  payload: data
});

const setProgramStats = (data, nextPage) => ({
  type: actionTypes.SET_PROGRAM_STATISTICS,
  payload: data,
  nextPage
});

const setExerciseStats = (data, nextPage) => ({
  type: actionTypes.SET_EXERCISE_STATISTICS,
  payload: data,
  nextPage
});


const setStatisticsHistory = data => ({
  type: actionTypes.SET_STATISTICS_HISTORY,
  payload: data
});

const setStatisticsHistoryNextPage = data => ({
  type: actionTypes.SET_STATISTICS_HISTORY_PAGE,
  payload: data
});

export const changeEquipmentDetails = selectedEquipment => ({
  type: actionTypes.CHANGE_EQUIPMENT_SELECT,
  payload: selectedEquipment
});

export const getBodyStats = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.bodyStats.getBodyStats(query);
    dispatch(setBodyStats(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getBodyStatsHistory = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.bodyStats.getBodyStatsHistory(query);
    dispatch(setStatsHistory(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getBodyStatsData = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.bodyStats.getBodyStatsData(publicId);
    dispatch(setStatData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createBodyStats = (body, refreshData) => async (dispatch) => {
  try {
    let result = true;
    await apiMethods.bodyStats.createBodyStats(body);
    if (refreshData) {
      result = await dispatch(getBodyStats(true));
      dispatch(setBodyStats(result));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateBodyStats = (body, refreshData) => async (dispatch) => {
  try {
    let result = true;
    await apiMethods.bodyStats.updateBodyStats(body);
    if (refreshData) {
      result = await dispatch(getBodyStats(true));
      dispatch(setBodyStats(result));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeBodyStats = body => async (dispatch) => {
  try {
    await apiMethods.bodyStats.removeBodyStats(body);
    dispatch(handleSuccessResponse(body));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getBodyStatsMedia = publicId => async (dispatch) => {
  try {
    return await apiMethods.bodyStats.getBodyStatsMedia(publicId);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return [];
  }
};

export const getLatestBodyStats = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.bodyStats.getLatestBodyStats(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return null;
  }
};

/* -------   PERFORMANCE STATS   ------- */

export const getStatistics = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const promiseArr = [];
    promiseArr.push(apiMethods.statistics.getStatisticsInfo(query));
    const programsQuery = utils.buildQuery({ ...queryObj, limit: 3 });
    promiseArr.push(apiMethods.statistics.getProgramStatistics(programsQuery));
    promiseArr.push(apiMethods.statistics.getExercisesStatistics(query));
    Promise.all(promiseArr).then((data) => {
      dispatch({
        type: actionTypes.SET_OVERALL_STATISTICS,
        payload: data[0]
      });
      dispatch({
        type: actionTypes.SET_PROGRAM_STATISTICS,
        payload: data[1]
      });
      dispatch({
        type: actionTypes.SET_EXERCISE_STATISTICS,
        payload: data[2]
      });
      return data;
    });
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getProgramStatistics = (queryObj, nextPage) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.statistics.getProgramStatistics(query);
    dispatch(setProgramStats(data, nextPage));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getExercisesStatistics = (queryObj, nextPage) => async (dispatch) => {
  try {
    if (!nextPage) dispatch(setExerciseStats({ exerciseStats: [] }, nextPage));
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.statistics.getExercisesStatistics(query);
    dispatch(setExerciseStats(data, nextPage));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

// for pagination
export const getStatisticsHistoryNextPage = (queryObj, type) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    let data;
    switch (type) {
      case 'program':
        data = await apiMethods.statistics.getUserProgramInfo(query);
        break;
      case 'exercise':
        data = await apiMethods.statistics.getUserExerciseInfo(query);
        break;
      default:
        data = await apiMethods.statistics.getUserExerciseInfo(query);
    }
    dispatch(setStatisticsHistoryNextPage(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserProgramInfo = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.statistics.getUserProgramInfo(query);
    dispatch(setStatisticsHistory(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserExerciseInfo = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.statistics.getUserExerciseInfo(query);
    dispatch(setStatisticsHistory(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
