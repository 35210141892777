import React from 'react';
import { Trans } from 'react-i18next';
import introJs from 'intro.js';
import { userTypes } from '../services/enums';
import { langs } from '../services/translationLangs';
const aliceImg = "https://thumbs-dev.aspire.co.il/360xAUTO/Users/USR-qUsgrynFX2Mqrkj/1616787212388_alice_3.jpg"
import aspireIcon from '../assets/icon_circle.png';


const aliceTutorial = (string, thumbnail, name) => {
  return `<div class="flex-100 layout-row layout-align-start-start paddTop10px">
            <div class="flex-initial layout-column layout-wrap layout-align-center-center text-center">
                  <div class="intro-img-tutorial flex-initial layout-column width100 layout-align-center-center backgroundImage" style="background-image: url(${thumbnail || aliceImg})"></div>
                  <p class="width100 font11 margin0 text-muted">${name || 'Alice'}</p>
            </div>
            <div class="flex layout-row layout-wrap layout-align-start-start paddStart10px">
              <div class="flex-100 layout-row layout-wrap layout-align-start-start">
                <p class="font14">${string}</p>
              </div>
            </div>
          </div>`
}


const aliceHint = (string, thumbnail, name) => {
  return `<div class="flex-100 layout-row layout-align-start-start padd10px">
            <div class="flex-initial layout-column layout-wrap layout-align-center-center text-center">
                <div class="intro-img-tutorial flex-initial layout-column width100 layout-align-center-center backgroundImage" style="background-image: url(${thumbnail || aliceImg})"></div>
                <p class="width100 font11 margin0 text-muted">${name || 'Alice'}</p>
            </div>
            <div class="flex layout-row layout-wrap layout-align-start-start paddStart10px">
              <div class="flex-100 layout-row layout-wrap layout-align-start-start">
                <p class="font14">${string}</p>
              </div>
            </div>
          </div>`
}
const TRAINER = {
  login: { showTopNav: false },
  // create Workout Program
  createWorkout: {
    GACategory: 'WorkoutProgram',
    title: 'createWorkout',
    config: {
      backgroundColor: ''
    },
    slides: [
      {
        svg: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/fill_details.svg',
        backgroundColor: 'rgb(221, 228, 255)',
        title: 'fillWorkoutDetails',
        direction: 'start',
        isTemplateDescription: true,
        templateDescription: lang => (
          <div className="width100 layout-row layout-wrap layout-align-start-start">
            <div className="width100 layout-row layout-wrap layout-align-start-start">
              <p className="margin0 lineHeight18"><Trans i18nKey="fillWorkoutDetails_desc" /></p>
            </div>
            <div className="width100 layout-row layout-wrap layout-align-start-start">
              <ul className="listCircle">
                { langs[lang].fillWorkoutDetails_list.map(point => (
                  <li>{point}</li>
                ))}
              </ul>
            </div>
          </div>
        )
      },
      {
        svg: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/add_exercise.svg',
        backgroundColor: 'rgb(255, 255, 255)',
        title: 'selectTheExercises',
        description: 'selectTheExercises_desc',
        direction: 'start'
      },
      {
        svg: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/config_exercises.svg',
        backgroundColor: 'rgb(252, 252, 252)',// 'rgb(254, 236, 245)',
        title: 'customizeYourProgram',
        direction: 'start',
        isTemplateDescription: true,
        showTutorialBtns: true,
        templateDescription: lang => (
          <div className="width100 layout-row layout-wrap layout-align-start-start">
            <div className="width100 layout-row layout-wrap layout-align-start-start marginBottom15px">
              <ul className="listNumbers">
                { langs[lang].customizeYourProgram_list.map(point => (
                  <li>{point}</li>
                ))}
              </ul>
            </div>
            <p>{langs[lang].wouldYouLikeAWalkThru}</p>
          </div>
        ),
        // videoPoster: 'https://res.cloudinary.com/hxecphixw/image/upload/onboardingAssets/createWorkoutStage3_aznazd.jpg',
        // videoUrl: 'https://res.cloudinary.com/hxecphixw/video/upload/onboardingAssets/createPlan_stage3_naxgn3',
        config: {
          done: { show: true, text: 'done' }
        }
      }
    ]
  },
  // create Goal
  createGoal: {
    GACategory: 'Achievements',
    slides: [
      {
        svg: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/chooseGoal.svg', // https://res.cloudinary.com/hxecphixw/video/upload/onboardingAssets/createGoal-performance_ttajxc',
        backgroundColor: 'rgb(255, 255, 255)',
        title: 'creatingGoal',
        direction: 'start',
        isTemplateDescription: true,
        templateDescription: lang => (
          <div className="width100 layout-row layout-wrap layout-align-start-start">
            <div className="width100 layout-row layout-wrap layout-align-start-start">
              <p className="margin0 lineHeight18"><Trans i18nKey="creatingGoal_desc" /></p>
            </div>
            <div className="width100 layout-row layout-wrap layout-align-start-start">
              <ul className="listCircle">
                { langs[lang].creatingGoal_list.map(point => (
                  <li>{point}</li>
                ))}
              </ul>
            </div>
          </div>
        )
      },
      {
        svg: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/fill_goal_details.svg', // 'https://res.cloudinary.com/hxecphixw/video/upload/onboardingAssets/createGoal-performance2_u7zz0p',
        backgroundColor: 'rgb(221, 228, 255)',
        title: 'fillGoalsDetails',
        description: 'fillGoalsDetails_desc',
        direction: 'start',
        isTemplateDescription: true,
        templateDescription: lang => (
          <div className="width100 layout-row layout-wrap layout-align-start-start">
            <ul className="listCircle">
              { langs[lang].fillGoalsDetails_list.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        ),
        config: {
          done: { show: true, text: 'done' }
        }
      }
    ],
    title: 'createGoal',
    config: {
      backgroundColor: ''
    }
  },
  // tutorials
  profile_tutorial: (appLang, callback) => {
    introJs().setOptions({
      showBullets: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          // title: langs[appLang].editProfile,
          element: document.querySelector('#edit-profile'),
          intro: aliceTutorial(langs[appLang].editProfile_sub)
        },
        /* {
          title: langs[appLang].uploadImage,
          element: document.querySelector('.social-profile-img'),
          intro: langs[appLang].uploadImage_sub
        } */]
    }).start().oncomplete(() => {
      // if (callback) callback('goToPage', '/settings/updateProfile');
    });
  },
  profileClient_tutorial: (appLang, user) => {
    let thumbnail, name = null;
    if (user && user.userType === userTypes.CLIENT){
      thumbnail = (user.trainer[0] && user.trainer[0].thumbnail && user.trainer[0].thumbnail.uri) || aspireIcon;
      name = (user.trainer[0] && user.trainer[0].firstName) || 'Aspire';
    }
    introJs().setOptions({
      showBullets: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          // title: langs[appLang].editProfile,
          element: document.querySelector('#edit-profile', thumbnail, name),
          intro: aliceTutorial(langs[appLang].editProfile_sub)
        },
/*      {
          title: langs[appLang].uploadImage,
          element: document.querySelector('.social-profile-img'),
          intro: langs[appLang].uploadImage_sub
        }, */
        {
          title: langs[appLang].trainersProfile,
          intro: aliceTutorial(langs[appLang].trainersProfile_sub)
        }]
    }).start().oncomplete(() => {
     // if (callback) callback('goToPage', '/settings/updateProfile');
    });
  },
  groupWorkout_tutorial: (appLang) => {
    introJs().setOptions({
      showBullets: false,
      exitOnOverlayClick: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [{
        element: document.querySelector('.ClientIcon button')[1],
        intro: aliceTutorial(langs[appLang].groupClients_sub)
        }]
    }).start();
  },
  createWorkout_tutorial: (appLang) => {
    introJs().setOptions({
      showBullets: false,
      exitOnOverlayClick: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          element: document.querySelector('.ClientIcon button'),
          intro: aliceTutorial(langs[appLang].chooseClient_sub)
        },
        {
          // title: langs[appLang].fillWorkoutDetails,
          element: document.querySelector('#title'),
          intro: aliceTutorial(langs[appLang].fillProgramTitle),
        },
        {
          // title: langs[appLang].next,
          element: document.querySelector('.ProgramEditorCard .infoContent'),
          intro: aliceTutorial(langs[appLang].clickHereToAddExercises)
        }]
    }).start();
  },
  createWorkout_tutorial2: (appLang) => {
    introJs().setOptions({
      showBullets: false,
      exitOnOverlayClick: false,
      disableInteraction: true,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          tooltipClass: 'selectedExercisesTag-tooltip',
          // title: langs[appLang].filterOptions,
          element: document.querySelector('#filterContainerId .filterIcon '),
          intro: aliceTutorial(langs[appLang].filterOptions_sub),
          position: 'left'
        },
        {
          tooltipClass: 'selectedExercisesTag-tooltip',
          // title: langs[appLang].chooseExercises,
          element: document.querySelector('#exerciseOption_EX-BENCH_PRESS .checkBox'),
          intro: aliceTutorial(langs[appLang].chooseExercisesTutorial_sub),
          position: 'left'
        }
      ]
    }).start();
  },
  createWorkout_tutorial2_2: (appLang, callback) => {
    introJs().setOptions({
      showBullets: false,
      exitOnOverlayClick: false,
      disableInteraction: true,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          tooltipClass: 'selectedExercisesTag-tooltip',
          // title: langs[appLang].selectedExercises,
          element: document.querySelector('.selectedExercisesTag'),
          intro: aliceTutorial(langs[appLang].selectedExercisesTutorial_sub),
          position: 'left'
        },
        {
          // title: langs[appLang].exerciseType_categories,
          element: document.querySelector('.FilterStripe .filter-group'),
          intro: aliceTutorial(langs[appLang].filterTutorial_sub),
        }
        /*      {
                  title: langs[appLang].next,
                  element: document.querySelector('.TopNav-rightCorner button .text'),
                  intro: langs[appLang].nextToProceed2
                } */
      ]
    }).start().onchange((targetElement) => {
      const targetStep = document.querySelector('.FilterStripe .filter-group');
      if ( targetStep === targetElement) {
        const timeout = setTimeout(() => {
           callback(2); // step number
           clearTimeout(timeout);
        }, 1500);
      }
    });
  },
  createWorkout_tutorial2_3: (appLang, callback) => {
    introJs().setOptions({
      showBullets: false,
      exitOnOverlayClick: false,
      disableInteraction: true,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
         {
              // title: langs[appLang].next,
              element: document.querySelector('.TopNav-rightCorner button .text'),
              intro: aliceTutorial(langs[appLang].nextToProceed2),
              // intro: langs[appLang].nextToProceed2
         }
      ]
    }).start();
  },
  createWorkout_tutorial3: (appLang, callback) => {
    introJs().setOptions({
      showBullets: false,
      exitOnOverlayClick: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          // title: langs[appLang].edit,
          element: document.querySelector('.edit_btn'),
          intro: aliceTutorial(langs[appLang].editBtnTutorial_sub),
        },
        {
          tooltipClass: 'selectedExercisesTag-tooltip',
          // title: langs[appLang].moreOptions,
          element: document.querySelector('#menuIcon_btn'),
          intro: aliceTutorial(langs[appLang].exerciseOptionsTutorial_sub),
          position: 'left'
        },
        {
          tooltipClass: 'exerciseEquipment-tooltip',
          // title: langs[appLang].exerciseEquipment,
          element: document.querySelector('.StripExercise .equipmentSelect'),
          intro: aliceTutorial(langs[appLang].exerciseEquipmentTutorial_sub),
          position: 'left'
        }
      ]
    }).start();
  },
  createWorkout_tutorial3_1: (appLang, callback) => {
    introJs().setOptions({
      showBullets: false,
      exitOnOverlayClick: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          tooltipClass: 'exerciseEquipment-tooltip',
          // title: langs[appLang].exerciseEquipment,
          element: document.querySelector('.StripExercise .equipmentSelect'),
          intro: aliceTutorial(langs[appLang].exerciseEquipmentTutorial_sub),
          position: 'left'
        },
        {
          // title: langs[appLang].edit,
          element: document.querySelector('.edit_btn'),
          intro: aliceTutorial(langs[appLang].editBtnTutorial_sub),
          // intro: `<p class="font13">${langs[appLang].editBtnTutorial_sub}</p>`
        }
      ]
    }).start().onbeforeexit(() => {
      if(callback) callback(); // step number
    });
  },
  createWorkout_tutorial3_2: (appLang, callback) => {
    introJs().setOptions({
      showBullets: false,
      exitOnOverlayClick: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          tooltipClass: 'selectedExercisesTag-tooltip',
          // title: langs[appLang].moreOptions,
          element: document.querySelector('#menuIcon_btn'),
          intro: aliceTutorial(langs[appLang].exerciseOptionsTutorial_sub),
          // intro: `<p class="font13">${langs[appLang].exerciseOptionsTutorial_sub}</p>`,
          position: 'left'
        },
        /*      {
                  title: langs[appLang].done,
                  element: document.querySelector('.TopNav-rightCorner button .text'),
                  intro: langs[appLang].doneToProceed
                } */
      ]
    }).start();
  },
  workoutProgramPage_tutorial: (appLang, user) => {
    let thumbnail, name = null;
    if (user && user.userType === userTypes.CLIENT){
      thumbnail = (user.trainer[0] && user.trainer[0].thumbnail && user.trainer[0].thumbnail.uri) || aspireIcon;
      name = (user.trainer[0] && user.trainer[0].firstName) || 'Aspire';
    }
    introJs().setOptions({
      showButtons: false,
      hints: [
        {
          hint:  aliceHint(langs[appLang].squareInfo_hint, thumbnail, name),
          element: '.squareInfo p',
          hintPosition: appLang === 'en' ? 'middle-middle' : 'top-left'
        },
        {
          hint:  aliceHint(langs[appLang].sessionUpdateBtn_hint, thumbnail, name),
          element: '#updateSessionBtns .hintPlacement',
          hintPosition: appLang === 'en' ? 'top-middle' : 'top-left'
        },
        {
          hint:  aliceHint(langs[appLang].exerciseDescription_hint, thumbnail, name),
          element: '.WorkoutLogCmp .StripExercise.StripExerciseBasic .exerciseImages',
          hintPosition: appLang === 'en' ? 'top-middle' : 'top-right'
        },
        {
          hint:  aliceHint(langs[appLang].superset_hint, thumbnail, name),
          element: '.superSetStripe .gradientText',
          hintPosition: 'middle-middle'
        },
      ]
    }).addHints().showHints();
  },
  startSession_tutorial: (appLang, user) => {
    let thumbnail, name = null;
    if (user && user.userType === userTypes.CLIENT){
      thumbnail = (user.trainer[0] && user.trainer[0].thumbnail && user.trainer[0].thumbnail.uri) || aspireIcon;
      name = (user.trainer[0] && user.trainer[0].firstName) || 'Aspire';
    }
    introJs().setOptions({
      showButtons: false,
      hints: [
        {
          hint:  aliceHint(langs[appLang].exerciseEquipmentTutorial_sub, thumbnail, name),
          element: '#SessionLogCard .exerciseImages',
          hintPosition: appLang === 'en' ? 'middle-middle' : 'middle-middle'
        },
        {
          hint:  aliceHint(langs[appLang].clickToCheck_hint, thumbnail, name),
          element: '#SessionLogCard .ExerciseLogDisplay .maxWidth55px',
          hintPosition: appLang === 'en' ? 'top-middle' : 'middle-middle'
        },
        {
          hint:  aliceHint(langs[appLang].modifyExerciseBtn_hint, thumbnail, name),
          element: '.SessionLogCmp .expandBtn',
          hintPosition: appLang === 'en' ? 'top-left' : 'top-right'
        },
      ]
    }).addHints().showHints();
  },
  statistics_tutorial: (appLang, callback) => {
    introJs().setOptions({
      showBullets: false,
      // exitOnOverlayClick: true,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          tooltipClass: 'statistics-tooltip',
          element: document.querySelector('.NavTop .clientsBtn'),
          intro: aliceTutorial(langs[appLang].clickOnAlice_sub),
          position: 'right'
        }
      ]
    }).start().onbeforeexit(() => {
      const clientList = document.querySelector('.NavTop .clientsBtn');
      if (clientList) clientList.click();
      // if(callback) callback(); // step number
    });
  },
  inviteClient_tutorial: (appLang) => {
    introJs().setOptions({
      showBullets: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          // title: langs[appLang].fillClientInfo,
          element: document.querySelector('#_firstName_id'),
          intro: aliceTutorial(langs[appLang].fillClientInfo_sub),
          // intro: `<p class="font13">${langs[appLang].fillClientInfo_sub}</p>`
        },
        {
          // title: langs[appLang].next,
          intro: aliceTutorial(langs[appLang].nextToProceed),
        }
      ]
    }).start();
  },
  inviteClient_tutorial2: (appLang) => {
    introJs().setOptions({
      showBullets: false,
      disableInteraction: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          // title: langs[appLang].fillClientInfo,
          element: document.querySelector('.inputContainers'),
          intro: aliceTutorial(langs[appLang].changeClientsAppSettings_sub),
        }
      ]
    }).start();
  },
  profileDetails_tutorial: (appLang, user) => {
    let thumbnail, name = null;
    if (user && user.userType === userTypes.CLIENT){
      thumbnail = (user.trainer[0] && user.trainer[0].thumbnail && user.trainer[0].thumbnail.uri) || aspireIcon;
      name = (user.trainer[0] && user.trainer[0].firstName) || 'Aspire';
    }
    introJs().setOptions({
      showBullets: false,
      nextLabel: langs[appLang].next,
      prevLabel: langs[appLang].prev,
      doneLabel: langs[appLang].done,
      steps: [
        {
          // title: langs[appLang].editProfile,
          intro: aliceTutorial(langs[appLang].saveToProceed, thumbnail, name),
        }
      ]
    })
      // .start();
  },
  hideHints: () => {introJs().hideHints();}
};

export default TRAINER;
