import { actionTypes, storeTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { handleErrorResponse } from './index';
import { utils } from '../../services/utilsService';

const setStoreMainPagePrograms = data => ({
  type: storeTypes.SET_STORE_MAIN_PAGE_PROGRAMS,
  payload: data
});

const setStoreCategories = data => ({
  type: storeTypes.SET_STORE_CATEGORIES,
  payload: data
});

const setStoreArchivePrograms = (data, nextPage) => ({
  type: storeTypes.SET_STORE_ARCHIVE,
  payload: data,
  nextPage
});

const setWorkoutPlanPageData = data => ({
  type: actionTypes.SET_WORKOUT_PLAN_PAGE_DATA,
  payload: data
});

export const clearWorkoutProgram = () => ({
  type: actionTypes.CLEAR_PROGRAM
});


// STORE API

export const getStoreMainPagePrograms = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.store.getStoreMainPagePrograms(query);
    dispatch(setStoreMainPagePrograms(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getFilteredAspirePrograms = (body, nextPage) => async (dispatch) => {
  try {
    const data = await apiMethods.store.getFilteredAspirePrograms(body);
    dispatch(setStoreArchivePrograms(data, nextPage));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const dispatchFilteredAspirePrograms = (body, nextPage) => async (dispatch) => {
  try {
    dispatch(setStoreArchivePrograms(body, nextPage));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getStoreProgramDetails = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.store.getStoreProgramDetails(query);
    dispatch(setWorkoutPlanPageData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const copyProgramToMyProfile = (body) => async (dispatch) => {
  try {
    return await apiMethods.store.copyTemplateToTrainer(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getArticlesForStore = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.store.getArticlesForStore(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getArticleData = uri => async (dispatch) => {
  try {
    return await apiMethods.store.getArticleData(uri);
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getStoreCategories = () => async (dispatch) => {
  try {
    const body = { group: [10, 11, 6] }; // 10 - storePrograms ,11 - storeMuscleTarget, 6 - equipments
    const data = await apiMethods.categories.getCategoriesByGroup(body);
    dispatch(setStoreCategories(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const filterProgramsByCategory = body => async (dispatch) => {
  try {
    if (body.category && body.category.length > 0 && utils.validateObjectIds(body.category)) {
      return await apiMethods.store.getFilteredAspirePrograms(body);
    } return false;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};
