import React from 'react';
import './mapMarker.css'

function GoogleMapUI(props) {
  return (
    // Important! Always set the container height explicitly
    <div className={props.className}>
      <div className='pin' />
      <div className='pulse' />
    </div>
  );
}

export default GoogleMapUI;
