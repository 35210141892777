/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localStorage from 'local-storage';
import ReactGA from 'react-ga';
import Drawer from 'react-bottom-drawer';
import Slider from 'nuka-carousel';
import Confetti from 'react-confetti';
import { showAlert3, updateMassageSeen } from '../../store/actions';
import userAlertsFuncs from '../../services/userAlertsFuncs';
import { handleModalFullScreen, linkingAppUrl, validateEmail } from '../../services/utilsService';
import AspireMsgModal from '../ProfileCards/AspireMsgModal';
import InviteUsersCmp from '../InviteUsers/InviteUsersCmp';
import './AspireMsgCenter.css';
import Image from '../UI/Image';
import { aspirePlaceholder } from '../../services/enums';

class AspireMessageCenter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isDrawerOpen: true,
      isConfetti: (this.props.aspireMsgs && this.props.aspireMsgs.length > 0 && this.props.aspireMsgs[0] && this.props.aspireMsgs[0].confetti) || false ,
      slideIndex: 0,
      components: {
        inviteUsersCmp: InviteUsersCmp
      },
      aspireUpdate: localStorage.get(`aspire-updates`) || '',
      aspireUpdateNew: null
    }
    this.windowSize = { height: window.outerHeight, width: window.innerWidth };
  }


  componentDidMount() {
    if (this.props.aspireMsgs && this.props.aspireMsgs.length === 0) {
     if (this.props.hideAlertBtn) this.props.hideAlertBtn();
    }
  }

  handleVideoModal (media) {
    const data = handleModalFullScreen(media, true, 'UserIntroduction');
    data.disableFSLogo = true;
    data.controls = true;
    // data.className = `${data.className}`;
    data.modalClassName = `flex-100 layout-row layout-wrap layout-align-start-center videoBlackBg`
    this.props.showAlert3(data);
  }

  handleLinkingApp(option) {
    const url = option.url || ''
    linkingAppUrl(url)
  }

  handleMediaOption (option) {
    const data = option.media;
    const media = {
      user: null,
      public_id: 'VID-ASPIRE_UPDATES',
      title: 'aspire updates',
      type: 'video',
      sourceType: 'amazonS3',
      mediaGroup: 'aspireInformation',
      source: data.sourceHls.master,
      sourceHls: data.sourceHls,
      thumbnail: null
    };
    this.handleVideoModal(media, data.isPortrait);
  }

  async sendWelcomeMailAgain (option) {
    if (this.isLoading) return;
    this.isLoading = true;
    const email = option.body && option.body.email;
    if (email && validateEmail(email)) {
      const data = await userAlertsFuncs.sendWelcomeMailAgain({
        ...option.body, // email
        language: this.props.appLang,
      });
      if (data) {
        return { error: data.text && data.text !== '' ? data.text : 'error' };
      }
      this.isLoading = false;
      return { success: 'emailSent' };
    } else {
      this.isLoading = false;
      return { error: 'invalidEmail' };
    }
  }

  handleChangeSection (slideIndex) {
    let isAspireUpdate = null
    if (this.props.aspireMsgs && this.props.aspireMsgs.length > 0 && this.props.aspireMsgs[slideIndex].aspireUpdate) {
      isAspireUpdate = this.props.aspireMsgs[slideIndex] && this.props.aspireMsgs[slideIndex].data.msgId;
    }
    this.setState({ slideIndex, isConfetti: this.props.aspireMsgs[slideIndex].confetti, aspireUpdateNew: isAspireUpdate });
  }

  handleNextSection (aspireMsg) {
    if (aspireMsg.aspireUpdate) this.state.aspireUpdateNew = aspireMsg.data.msgId;
    this.props.updateMassageSeen(aspireMsg.data.msgId)
    if (this.props.aspireMsgs && this.props.aspireMsgs.length > 0 && ((this.state.slideIndex + 1) === this.props.aspireMsgs.length)) {
      this.handleCloseDrawer();
    } else {
      this.setState({ slideIndex: this.state.slideIndex + 1, isConfetti: aspireMsg.confetti });
    }
  }

  handleCloseDrawer() {
    if (this.state.aspireUpdateNew) localStorage.set(`aspire-updates`, this.state.aspireUpdateNew)
    this.setState({isDrawerOpen: false}, () => {
      ReactGA.event({ category: 'AspireUpdatesMsg', action: 'Confirm' });
      this.timeoutId = setTimeout(() => {
        this.props.hideAlertBtn();
        clearTimeout(this.timeoutId);
      }, 400);
    })
  }

  goToPage (option) {
    ReactGA.event({ category: 'AspireUpdatesMsg', action: option.text });
    localStorage.set(`aspire-updates`, this.props.msgId)
    this.props.history.push(`${option.url}`);
  }

  renderMessages() {
    if (this.props.aspireMsgs && this.props.aspireMsgs.length > 0) {
      return this.props.aspireMsgs.map((aspireMsg) => {
        const { data } = aspireMsg;
        if (this.state.aspireUpdate === data.msgId) return null;
        const components = this.renderComponent(data)
        return (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start sidePadd15px">
            <AspireMsgModal
              key={(data && data.msgId) || (aspireMsg && aspireMsg.msgId)}
              data={data}
              userData={this.props.user}
              appLang={this.props.appLang}
              cancel={() => this.handleNextSection(aspireMsg)}
              handleMediaOption={(option) => this.handleMediaOption(option)}
              handleLinkingApp={(option) => this.handleLinkingApp(option)}
              goToPage={(option) => this.goToPage(option)}
              sendWelcomeMailAgain={(option) => this.sendWelcomeMailAgain(option)}
              components={components}
            />
          </div>
        )
      })
    }
    return null;
  }

  renderComponent (data) {
    if (data && data.components && data.components.length > 0) {
      return (
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          { data.components.map((component, index) => {
            const ComponentItem = this.state.components[component.name];
            return (
              <div key={(data && data.msgId) || (component.data &&component.data.msgId) || `asd_${index}`} className="flex-100 layout-row layout-wrap layout-align-start-start">
                <ComponentItem data={component.data} />
              </div>
            )
          })}
        </div>
      )
    }
    return null;
  }

  render () {
    const { slideIndex, isConfetti } = this.state;
    const messagesList = this.renderMessages();
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        <div className="width100 layout-row layout-wrap layout-align-start-start fullSizeWindow positionAbsoluteTopLeft content-start zIndex5">
          <Confetti
            numberOfPieces={isConfetti ? 100 : 0}
            width={this.windowSize.width}
            height={this.windowSize.height}
            colors={['#384ab4', '#5855a0', '#824187', '#eb4b93', '#d74c3b', '#e1774a', '#eea04f']} />
        </div>
        <Drawer
          duration={300}
          hideScrollbars={true}
          onClose={() => this.handleCloseDrawer()}
          isVisible={this.state.isDrawerOpen}
          className="Drawer">
          <div className="AspireMsgModal flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
              <div className="layout-row layout-wrap layout-align-start-start social-profile-img">
                <Image
                  src={'https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogoName_600_600.jpg'}
                  fallbackSrc={'https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogoName_600_600.jpg' || aspirePlaceholder[0]}
                  backgroundImage
                  className="backgroundImage layout-row layout-wrap layout-align-start-start borderRadius img-thumbnail card-img"
                  height={300}
                  color1="rgba(0, 0, 0, 0)"
                  color2="rgba(0, 0, 0, 0)"
                  color3="rgba(0, 0, 0, 0.05)" />
              </div>
              <div className="width100 layout-row layout-wrap layout-align-start-start messagesOverflow pt-4">
                <Slider
                  className="overFlowHidden sliderDirection"
                  spring="gentle"
                  autoGenerateStyleTag={false}
                  withoutControls
                  slideIndex={slideIndex}
                  heightMode={'current'}
                  disableEdgeSwiping
                  afterSlide={slideNum => this.handleChangeSection(slideNum)}>
                  { messagesList }
                </Slider>
              </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  alertProps: state.appR.alertProps,
  aspireMsgs: state.appR.aspireMsgs
});

const mapDispatchToProps = dispatch => ({
  showAlert3: data => dispatch(showAlert3(data)),
  updateMassageSeen: id => dispatch(updateMassageSeen(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AspireMessageCenter);
