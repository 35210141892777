/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import { utils } from '../../services/utilsService';
import { actionTypes } from '../../store/actions/actionTypes';
import { language } from '../../services/enums';
import '../../Containers/SettingsPage/SettingsPage.css';

class TranslationChangeUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language:
        (this.props && this.props.settings && this.props.settings.language) ||
        this.props.appLang ||
        'en',
      isChanged: false,
    };
  }

  componentDidMount() {
    this.setState({ isPageReady: true });
    window.scrollTo(0, 0);
  }

  handleChange(e, key) {
    this.state.isChanged = true;
    if (e.target.type === 'checkbox' || e.target.type === 'radio') {
      this.state[key] = e.target.checked;
    } else {
      this.state[key] =
        e.target.type === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value;
    }
    if (this.props.onSelect) this.props.onSelect(e);
  }

  handleClick(value, key) {
    this.state.isChanged = true;
    this.state[key] = value;
    if (this.props.onSelect) this.props.onSelect(value);
  }

  changeFirstWeekDay() {
    // US, Canada
    moment.updateLocale('en', {
      week: {
        dow: 1, // First day of week is Monday
        doy: 1, // First week of year must contain 1 January (7 + 1 - 1)
      },
    });
    moment.updateLocale('he', {
      week: {
        dow: 0, // First day of week is Sunday
        doy: 1, // First week of year must contain 1 January (7 + 0 - 1)
      },
    });
  }

  renderOptions() {
    if (
      this.props.user &&
      this.props.user.public_id &&
      (this.props.user.public_id === 'USR-GMCYghhkAUNrDRB' ||
        this.props.user.public_id === 'USR-DorMoyalTrainer')
    ) {
      language.ES = 'es';
    }
    return language && Array.isArray(language)
      ? utils.getOptionsByArray(language, this.state.language)
      : utils.getOptionsByObject(language, this.props.appLang);
  }

  render() {
    const { i18n, settings, isSelect, isBtns } = this.props;
    const changeLanguage = (lang) => {
      if (this.props.dontRefresh) return;
      const body = document.getElementsByTagName('body')[0];
      body.setAttribute('dir', lang && lang === 'he' ? 'rtl' : 'ltr');
      body.setAttribute('lang', lang);
      this.props.setAppLang(lang);
      if (this.props.onChange) this.props.onChange();
      i18n.changeLanguage(lang);
      moment.locale(lang);
    };
    if (settings && settings.language) this.state.language = settings.language;
    return (
      <div
        className={`TranslationChangeUI flex-100 layout-row layout-wrap layout-align-start-start ${
          this.props.className || ''
        }`}
      >
        {this.state.isPageReady && isBtns && (
          <div className="flex-initial layout-row layout-align-start-start">
            <button
              className={`flex-initial btn btn-transparent layout-row layout-align-start-start ${
                this.state.language === 'he' ? '' : 'colorGrey'
              }`}
              onClick={(event) => {
                this.handleClick('he', 'language');
                changeLanguage('he');
              }}
            >
              <Trans i18nKey="HE_short" />
            </button>
            <div className="flex-initial layout-row layout-align-start-start height35px borderEndGrey" />
            <button
              className={`flex-initial btn btn-transparent layout-row layout-align-start-start ${
                this.state.language === 'en' ? '' : 'colorGrey'
              }`}
              onClick={(event) => {
                this.handleClick('en', 'language');
                changeLanguage('en');
              }}
            >
              <Trans i18nKey="EN_short" />
            </button>
            {this.props.user &&
              this.props.user.public_id &&
              (this.props.user.public_id === 'USR-GMCYghhkAUNrDRB' ||
                this.props.user.public_id === 'USR-DorMoyalTrainer') && (
                <Fragment>
                  <div className="flex-initial layout-row layout-align-start-start height35px borderEndGrey" />
                  <button
                    className={`flex-initial btn btn-transparent layout-row layout-align-start-start ${
                      this.state.language === 'es' ? '' : 'colorGrey'
                    }`}
                    onClick={(event) => {
                      this.handleClick('es', 'language');
                      changeLanguage('es');
                    }}
                  >
                    <Trans i18nKey="ES_short" />
                  </button>
                </Fragment>
              )}
          </div>
        )}
        {this.state.isPageReady && isSelect && (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <label className="formLabel primary" htmlFor="appLang">
              <Trans i18nKey="language" />
            </label>
            <div className="flex-100 layout-row layout-align-center-center positionRelative inputSelect selectContainer">
              <select
                id="appLang"
                className="form-control"
                required
                defaultValue={this.state.language}
                ref={(input) => (this.state.appLang = input)}
                key="i_language"
                onChange={(event) => {
                  this.handleChange(event, 'language');
                  changeLanguage(event.target.value);
                }}
              >
                {this.renderOptions()}
              </select>
              <i className="la la-sort-down" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  settings: state.userR.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setAppLang: (lang) => dispatch({ type: actionTypes.SET_APP_LANG, payload: lang }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TranslationChangeUI));
