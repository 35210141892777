/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import Drawer from 'react-bottom-drawer';
import { showAlert2, showAlert, getEvents, getUserCalendarSettings } from '../../store/actions';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { categoryColor, userTypes } from '../../services/enums';
import AgendaList from './AgendaList';
import CreateEventPage from '../../Containers/CalendarEventsPage/CreateEventDrawer';
import { addClass, removeClass } from '../../services/utilsService';
import '@mobiscroll/react/dist/css/mobiscroll.react.scss';
import '../../assets/css/sass/mobiscrollFix.style.scss';
import '../MobiEventCalendar/MobiCalendar.css';

class MiniCalendarCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      public_id: this.props.currentUser,
      startDate: moment(),
      currentWeekDate: moment(),
      timezone: momentTZ.tz.guess(),
      isDrawerOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.userType === userTypes.CLIENT) {
      this.state.public_id = this.props.currentUser;
      this.props.getUserCalendarSettings();
    }
  }

  componentDidUpdate() {
    if (this.props.toUpdate && this.state.public_id !== this.props.currentUser) {
      this.state.public_id = this.props.currentUser;
      this.onPageLoading();
    }
  }

  handleOpenDrawer() {
    this.setState({ isDrawerOpen: true, renderCreateEvent: true });
  }

  handleCloseDrawer() {
    this.setState({ isDrawerOpen: false }, () => {
      this.timeout = setTimeout(() => {
        this.setState({ renderCreateEvent: false });
      }, 350);
    });
  }

  handleDateConfig(event) {
    let startDate = event && event.firstDay ? event.firstDay : this.state.currentWeekDate;
    const firstDate = startDate;
    const lastDay = moment(firstDate).add(8, 'days');
    const firstDay = moment(firstDate);
    const today = moment();
    if ((moment(today).isAfter(firstDay, 'day') && moment(today).isBefore(lastDay, 'day')) ||
        (moment(today).isAfter(firstDay, 'day') && moment(today).isAfter(lastDay, 'day'))) {
      startDate = moment(today).format('DD-MM-YYYY');
    } else {
      startDate = moment(firstDate).format('DD-MM-YYYY');
    }
    return { startDate, fromNow: true, endDate: lastDay.format('DD-MM-YYYY') };
  }

  handleRefreshEventsList() {
    this.handleCloseDrawer();
    this.onPageLoading();
  }

  async onPageLoading(event) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      console.log('onPageLoading');
      removeClass('#loadingDots', 'opacity0');
      const body = {
        ...this.handleDateConfig(event),
        timezone: this.state.timezone,
        limit: 20,
      };
      if (this.props.isTrainer && this.props.currentUser !== this.props.user.public_id)
        body.user = this.props.currentUser;
      await this.props.getEvents(body);
      addClass('#loadingDots', 'opacity0');
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }

  getEventDetails(event) {
    if (!event || (event && !event.public_id)) {
      this.handleCreateEventDrawer();
      return;
    }
    const data = {
      isOpen: true,
      isModal: true,
      id: eventActions.EVENT_DETAILS,
      event,
      className: 'PageModal CongratsModal',
      buttonRight: {
        text: <i className="la la-arrow-left iconFontSize30 colorWhite " />,
      },
      refreshEventsList: () => this.handleRefreshEventsList(),
    };
    this.props.showAlert(data);
  }

  chooseNewDay(date) {
    const start = moment(date).startOf('day');
    this.setState({ startDate: start });
  }

  render() {
    return (
      <div className="MiniCalendarCmp flex-100 layout-row layout-wrap layout-align-start-center">
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className="flex-100 layout-row layout-wrap layout-align-space-between-center sidePadd15px">
            <h3 className="text-uppercase fontWeight600" style={{ color: categoryColor.blue }}>
              <Trans i18nKey="calendar" />
            </h3>
            <Link to="/calendar" className="flex-initial layout-row layout-align-start-start">
              <p className="flex-initial colorGrey margin0 font15 sidePadd2px">
                <i className="las la-arrow-circle-right font24 text-muted rotateOnDirection" />
              </p>
            </Link>
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-start-center marginBottom10px">
            <AgendaList
              showCreateBtn={this.props.currentUser === this.props.user.public_id}
              events={this.props.eventsList}
              user={this.props.user}
              isTrainer={this.props.isTrainer}
              appLang={this.props.appLang}
              getEventDetails={(event) => this.getEventDetails(event)}
              onPageLoading={(event) => {this.onPageLoading(event);}}
              openEventDrawer={() => this.handleOpenDrawer()}
            />
          </div>
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <Drawer
            duration={300}
            onClose={() => this.handleCloseDrawer()}
            isVisible={this.state.isDrawerOpen}
            className="Drawer">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start">
              {this.state.renderCreateEvent && (
                <CreateEventPage
                  applang={this.props.appLang}
                  event={{ startDate: this.state.startDate }}
                  isDrawer
                  calendarSettings={this.props.calendarSettings}
                  refreshEventsList={() => this.handleRefreshEventsList()}
                  closeDrawer={(publicId) => this.handleCloseDrawer(publicId)}
                />
              )}
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userR.userInfo,
  calendarSettings: state.userR.myCalendarSettings,
  eventsList: state.calR.eventsList,
  appLang: state.appR.appLang,
  settings: state.userR.settings
});

const mapDispatchToProps = (dispatch) => ({
  showAlert: (data) => dispatch(showAlert(data)),
  showAlert2: (data) => dispatch(showAlert2(data)),
  getEvents: (body) => dispatch(getEvents(body)),
  getUserCalendarSettings: (query) => dispatch(getUserCalendarSettings(query)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniCalendarCmp);
