/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import Modal from '../../Components/Alerts/Modal';
import MsgModal from '../../Components/Alerts/MsgModal';
import OnBoardingPage from './OnBoardingPage';

import UserIntroductionPage from './UserIntroductionPage/UserIntroductionPage';
import AspireUpdatesPage from './UserIntroductionPage/AspireUpdatesPage';
import ChangeFieldModal from '../../Components/Alerts/ChangeFieldModal_new';
import AspireMsgCenter from '../../Components/AspireMsgCenter/AspireMsgCenter';
import VerifyPhonePage from './VerifyPhonePage';

/* Lazy Modals */
const UserModal = React.lazy(() => import('../../Components/Alerts/UserModal'));
// const ChangeFieldModal = React.lazy(() => import('../../Components/Alerts/ChangeFieldModal_new'));
const CongratulationsPage = React.lazy(() => import('./CongratulationsPage'));
const IframePage = React.lazy(() => import('./IframePage'));
const SurveyPage = React.lazy(() => import('./SurveyPage'));
const FullScreenVideoPage = React.lazy(() => import('./FullScreenVideoPage'));
const PopupNotification = React.lazy(() => import('../../Components/UI/PopupNotification'));
const WorkoutLogsSearchPage = React.lazy(() => import('./WorkoutLogsSearchPage'));
const ExercisesListSearchPage = React.lazy(() => import('./ExercisesListSearchPage'));
const DynamicListSearchPage = React.lazy(() => import('./DynamicListSearchPage'));
const AddExerciseToSession = React.lazy(() => import('./AddExerciseToSession'));
const AddExerciseToProgress = React.lazy(() => import('./AddExerciseToProgress'));
const SuperSetExerciseOptions = React.lazy(() => import('./SuperSetExerciseOptions'));
const EventDetailsPage = React.lazy(() => import('../CalendarEventsPage/EventDetailsPage'));
const CopyPlanPage = React.lazy(() => import('../CreateWorkoutPage/CopyPlanPage'));
// const AspireMsgCenter = React.lazy(() => import('../../Components/AspireMsgCenter/AspireMsgCenter'));

class ModalsFirstLayerContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  openModal () {
    switch (this.props.alertProps.id) {
      case eventActions.ALERT_MODAL:
        return <MsgModal {...this.props.alertProps} hideAlertBtn={() => this.props.hideAlert()} />;
      case eventActions.ASPIRE_MSG_MODAL:
        return <AspireUpdatesPage {...this.props.alertProps} msgId={this.props.alertProps.data.msgId} hideAlertBtn={() => this.props.hideAlert()} history={this.props.history} />;
      case eventActions.ASPIRE_MSG_CENTER:
        return <AspireMsgCenter hideAlertBtn={() => this.props.hideAlert()} history={this.props.history} />;
      case eventActions.USER_MODAL:
        return <UserModal {...this.props.alertProps} data={this.props.alertProps.data} />;
      case eventActions.CONGRATS_PAGE:
        return <CongratulationsPage appLang={this.props.appLang} history={this.props.history} data={this.props.alertProps.achievements} />;
      case eventActions.GET_NEW_WORKOUT_LOG:
        return (
          <WorkoutLogsSearchPage
            isModalPage
            appLang={this.props.appLang}
            clickBack={() => { this.props.alertProps.closeModal(); }}
            addWorkoutLogToList={workoutLog => this.props.alertProps.getSelectedWorkoutLog(workoutLog)} />
        );
      case eventActions.EXERCISE_LIST_SEARCH:
        return (
          <ExercisesListSearchPage
            isModalPage
            title={this.props.alertProps.title}
            description={this.props.alertProps.description}
            appLang={this.props.appLang}
            clickBack={() => { this.props.alertProps.closeModal(); }}
            getSelectedExercises={exercises => this.props.alertProps.getSelectedExercises(exercises)} />
        );
      case eventActions.DYNAMIC_SEARCH_LIST:
        return (
          <DynamicListSearchPage
            isModalPage
            alertProps={this.props.alertProps}
            title={this.props.alertProps.title}
            description={this.props.alertProps.description}
            appLang={this.props.appLang}
            config={this.props.alertProps.config}
            itemsList={this.props.alertProps.itemsList}
            filterResults={(query) => { this.props.alertProps.filterResults(query); }}
            onSelect={array => this.props.alertProps.onSelect(array)}
            closeSearchList={() => { this.props.alertProps.closeSearchList(); }}
            hideAlertBtn={() => this.props.hideAlert()}
          />
        );
      case eventActions.ADD_EXERCISE_TO_SESSION:
        return (<AddExerciseToSession isModalPage appLang={this.props.appLang} data={this.props.alertProps.data} />);
      case eventActions.ADD_EXERCISE_TO_PROGRESS:
        return (<AddExerciseToProgress isModalPage appLang={this.props.appLang} data={this.props.alertProps.data} />);
      case eventActions.SUPERSET_EXERCISE_OPTIONS:
        return (<SuperSetExerciseOptions isModalPage appLang={this.props.appLang} data={this.props.alertProps.data} />);
      case eventActions.ONBOARDING_PAGE:
        return <OnBoardingPage appLang={this.props.appLang} data={this.props.alertProps.data} />;
      case eventActions.IFRAME_PAGE:
        return <IframePage appLang={this.props.appLang} isModalPage />;
      case eventActions.FULL_SCREEN_PAGE:
        return <FullScreenVideoPage appLang={this.props.appLang} alertProps={this.props.alertProps} isModalPage isPortrait={this.props.alertProps.isPortrait} media={this.props.alertProps.media} hideAlertBtn={() => this.props.hideAlert()} />;
      case eventActions.CHANGE_FIELD_MODAL:
        return (
          <ChangeFieldModal
            logs={this.props.alertProps.logs}
            indexNum={this.props.alertProps.indexNum}
            propName={this.props.alertProps.propName}
            appLang={this.props.appLang}
            {...this.props.alertProps} />
        );
      case eventActions.SURVEY_PAGE:
        return <SurveyPage appLang={this.props.appLang} isModalPage />;
      case eventActions.EVENT_DETAILS:
        return <EventDetailsPage isModalPage history={this.props.history} />;
      case eventActions.WELCOME_USER_MODAL:
        return <UserIntroductionPage appLang={this.props.appLang} history={this.props.history} data={this.props.alertProps.user} />;
      case eventActions.VERIFY_PHONE:
        return <VerifyPhonePage appLang={this.props.appLang} history={this.props.history} alertProps={this.props.alertProps} />;
      case eventActions.ASSIGN_PLAN_PAGE:
        return <CopyPlanPage appLang={this.props.appLang} history={this.props.history} alertProps={this.props.alertProps} />;
      default:
        return null;
    }
  }

  render () {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        { this.props.alertProps && this.props.alertProps.isOpen && (
          <Modal alertProps={this.props.alertProps}
            isOpen={this.props.alertProps.isOpen}
            className={this.props.alertProps.className}
            hideAlertBtn={() => this.props.hideAlert()}>
            <Suspense fallback={<div className="gogo-loading" />}>
              { this.openModal() }
            </Suspense>
          </Modal>
        )}
        { this.props.notificationProps && this.props.notificationProps.isOpen && (
          <PopupNotification text={this.props.notificationProps.text} icon={this.props.notificationProps.icon} color={this.props.notificationProps.color} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  appLang: state.appR.appLang,
  alertProps: state.appR.alertProps,
  notificationProps: state.appR.notificationProps
});

const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsFirstLayerContainer);
