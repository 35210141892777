import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import { aspirePlaceholder, userTypes } from '../../services/enums';
import imageUploadSvg from '../../assets/onBoarding/camera_upload.svg';
import Image from '../UI/Image';
import InfoBox from '../InfoBox/InfoBox';
import CredentialUpdater from './CredentialUpdater';

const ProfileDetailsCard = (props) => {
  const { profileData, userType, editIcon } = props;
  const thumbnail = profileData.thumbnail ? profileData.thumbnail.thumbnail : null;
  const source = profileData.thumbnail ? profileData.thumbnail.source : null;
  const renderUploadImage = () => {
    return (
      <Link to="/settings/updateProfile" className="renderUploadImage layout-column layout-align-center-center padd15px height150px">
        <div className="flex-initial layout-column layout-wrap layout-align-center-center ">
          <ReactSVG src={imageUploadSvg} className="image_upload_user_svg" />
        </div>
      </Link>
    );
  }
  return (
    <div className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
      <div className="layout-row layout-wrap layout-align-start-start social-profile-img">
        <Image
          src={thumbnail}
          fallbackSrc={source || aspirePlaceholder[0]}
          backgroundImage
          className="backgroundImage layout-row layout-wrap layout-align-start-start borderRadius img-thumbnail card-img"
          height={300}
          color1="rgba(0, 0, 0, 0.6)"
          color2="rgba(0, 0, 0, 0.3)">
          { editIcon && !thumbnail ? renderUploadImage() : null }
        </Image>
      </div>
      <div className="marginBottom15px flex-100 card borderRadius15 bottom">
        { editIcon && (
          <div className="zIndex10 layout-row width100 positionAbsoluteTopLeft">
            <div className="width100 margin1rem">
              <div className="flex-initial zIndex10 position-relative">
                <Link id="edit-profile" to="/settings/updateProfile" className="icon-button btn-shadow margin10 btn rounded">
                  <i className="las la-user-edit font24 primary" />
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="card-body">
          <div className="text-center pt-5">
            <h2 className="marginBottom10px fontWeight600">{`${profileData.firstName} ${ profileData.lastName}`}</h2>
          </div>
          { profileData.userType === userTypes.CLIENT && (<p className="width100 font13 text-muted text-center"><Trans i18nKey="clientProfileIsPrivate" /></p>)}
          <p className="width100 marginBottom10px lineHeight18">
            { profileData.about}
            { !profileData.about && editIcon && (
            <Link to="/settings/updateProfile" className="flex-100 lineHeight12 fontWeight400">
              <Trans i18nKey="addShortDescription" />
            </Link>
            )}
          </p>
          { profileData.userType === userTypes.CLIENT && (
            <InfoBox
              stats={profileData.stats}
              settings={props.settings}
              isBodyStats
              className="marginBottom10px"
              statsClasses="flex layout-column layout-align-start-center text-center" />
          )}
          { profileData.city && (<p className="width100 text-muted font13 marginBottom5px"><Trans i18nKey="location" /></p>)}
          <p className="width100 marginBottom5px">
            { profileData.city }
            { !profileData.city && editIcon && (
              <Link to="/settings/updateProfile" className="flex-100 lineHeight12 fontWeight400">
                <Trans i18nKey="addLocation" />
              </Link>
          )}
          </p>
          { userType === userTypes.TRAINER && (
          <Fragment>
            { profileData.expertiseTags && profileData.expertiseTags.length > 0 && (<p className="width100 text-muted font13 marginBottom10px"><Trans i18nKey="expertise" /></p> )}
            <p className="marginBottom10px">
              { profileData && profileData.expertiseTags && profileData.expertiseTags.length > 0 && profileData.expertiseTags.map((tag) => (
                <div key={tag} className="marginBottom5px mr-1 badge badge-outline-primary badge-pill">{tag}</div>
               ))}
              { !profileData.expertiseTags && editIcon && (
              <Link to="/settings/updateProfile" className="flex-100 lineHeight12 fontWeight400">
                <Trans i18nKey="addExpertise" />
              </Link>
              )}
            </p>
            { props.showInviteBtn && (
              <CredentialUpdater
                publicId={props.publicId}
                email={profileData && profileData.email}
                phone={profileData && profileData.phoneNumber} />
            )}
          </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileDetailsCard;
