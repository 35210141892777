/* eslint-disable no-underscore-dangle */
import { actionTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse } from './index';

const setMediaGallery = data => ({
  type: actionTypes.SET_MEDIA_GALLERY,
  payload: data
});

const setUserMediaGallery = data => ({
  type: actionTypes.SET_USER_MEDIA_GALLERY,
  payload: data
});

const setMediaData = data => ({
  type: actionTypes.SET_MEDIA_DATA,
  payload: data
});

export const splitMedia = publicId => ({
  type: actionTypes.REMOVE_MEDIA,
  payload: publicId
});

export const getMediaGallery = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.media.getMediaGallery(query);
    dispatch(setMediaGallery(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserMedia = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.media.getUserMedia(query);
    dispatch(setUserMediaGallery(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getAspireLibraryMedia = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.media.getAspireLibraryMedia(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getMediaData = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.media.getMediaData(publicId);
    dispatch(setMediaData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getMediaById = mediaId => async (dispatch) => {
  try {
    return await apiMethods.media.getMediaById(mediaId);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createMedia = body => async (dispatch) => {
  try {
    return await apiMethods.media.create(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateMedia = body => async (dispatch) => {
  try {
    return await apiMethods.media.update(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeMedia = publicId => async (dispatch) => {
  try {
    const body = [publicId];
    await apiMethods.media.remove(body);
    dispatch(splitMedia(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const addMediaTo = (model, publicId, data) => async (dispatch) => {
  try {
    const body = { publicId, media: [] };
    if (Array.isArray(data) && data.length > 0) body.media = data.map(media => media._id);
    else body.media.push(data._id);
    await apiMethods[model].addMedia(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeMediaFrom = (model, modelId, media) => async (dispatch) => {
  try {
    const body = {
      publicId: modelId,
      media: media._id
    };
    await apiMethods[model].removeMedia(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
